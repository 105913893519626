import React from 'react';
import aboutThumb from '../../assets/images/webnstack/IT-services/3rd-section.png';

function About() {
    return (
        <section className="appie-about-area mb-100 ACTUALLY-DO-margin-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-thumb">
                                        <img src={aboutThumb} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-about-content">
                                        <span> TECHNOLOGY INDEX​</span>
                                        <h1 className="title">
                                            IMPROVE AND INNOVATE WITH THE TECH TRENDS​
                                        </h1>
                                        <p>
                                            We hire and build your own remote dedicated development
                                            teams tailored to your specific needs.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="appie-about-service mt-30">
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <h1 className="title">Our Mission</h1>
                                                <p className="font-size16px-p">
                                                    {' '}
                                                    Help clients to accelerate the adoption of new
                                                    tech, and ongoing innovation.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="appie-about-service mt-30">
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <h1 className="title">Our vision</h1>
                                                <p className="font-size16px-p">
                                                    Our vision is to bridge the gap between
                                                    technology and the eCommerce environment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="appie-about-service mt-30">
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <h1 className="title">Our Philosophy</h1>
                                                <p className="font-size16px-p">
                                                    {' '}
                                                    Our philosophy is to embrace innovation, and new
                                                    tech to benefit our customers.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="appie-about-service mt-30">
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <h1 className="title">Our Strategy</h1>
                                                <p className="font-size16px-p">
                                                    We focus on exceptional results in the shortest
                                                    amount of time possible.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
