import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-100 hire-padding-bottom30 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-8 col-md-8">
                        <div className="appie-section-title hire-show-case-padding hire-web-developer-section-title">
                            <h2 className="appie-title">Get Ahead Of The Competiton</h2>
                            <p>Hire dedicated ecommerce developers for global solution.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-coins icon-size-30"></i>
                            </div>

                            <h1 className="title">Budget</h1>
                            <p>
                                The fixed pricing model of WebNStack, which is ideal for all sized
                                Ecommerce projects, allows customers to pay a specific amount for
                                each project deliverable.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-water icon-size-30"></i>
                            </div>
                            <h2 className="title">Flexible</h2>
                            <p>
                                If our clients wish to work with us on a long-term basis, they can
                                choose a dedicated Ecommerce model. We provide technical and skilled
                                remote resources.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-3 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user-check icon-size-30"></i>
                            </div>
                            <h1 className="title">Quality</h1>
                            <p>
                                The result obtained in the world for eCommerce projects, this is
                                used when demands are ever-changing and versatile. Clients must pay
                                based on the number.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-users icon-size-30"></i>
                            </div>
                            <h1 className="title">Support</h1>
                            <p>
                                Choose WebNStack to hire a skilled offsite ecommerce team that will
                                work from our location using our resources and providing 24*7
                                assistance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-handshake icon-size-30"></i>
                            </div>
                            <h1 className="title">Commitment</h1>
                            <p>
                                WebNStack will work on your project from our office and as needed.
                                We have a approach that is very adaptable for carrying out
                                development smoothly.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user-lock icon-size-30"></i>
                            </div>
                            <h1 className="title">Security</h1>
                            <p>
                                We prioritize the security of your data and intellectual property.
                                Our robust security protocols measures ensure that information is
                                safeguarded development process.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
