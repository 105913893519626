import React from 'react';
import { Link } from 'react-router-dom';
import almonaplastImages from '../../assets/images/portfolio/almona-plast.png';
import deelertImage from '../../assets/images/portfolio/deelert.png';
import hrpayrollImages from '../../assets/images/portfolio/hr-payroll.png';
import RkbuilderImages from '../../assets/images/portfolio/rk-builders.png';
import sandiabioImages from '../../assets/images/portfolio/sandiabio.png';
import sportsuisImages from '../../assets/images/portfolio/sportsuis.png';

function Projects() {
    return (
        <section className="portfolio-section portfolio-pt30">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 pb-20">
                        {' '}
                        GLIMPSE OF OUR WORK
                        <h1 className="font-size-28">REFLECTING OUR PORTFOLIO</h1>
                    </div>
                    <div className="col-md-6 hire-ecommerce-pb20">
                        We’ve flourished our experience across various industries to bring valuable
                        insights and provide our customers with the newest market trends.
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-12 text-center w-100">
                        <div className="form-row gallery">
                            <div className="col-sm-6 col-lg-4 mb-2 grid-item design ecommerce">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={RkbuilderImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/rkbuilders"
                                            >
                                                <h1 className="font-size-20">RK BUILDERS</h1>
                                            </Link>
                                            <p>[design, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development ">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={deelertImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/deelert"
                                            >
                                                <h1 className="font-size-20">DEELERT</h1>
                                            </Link>
                                            <p>[design, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development ">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={sandiabioImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/sandiabio"
                                            >
                                                <h1 className="font-size-20">SANDIABIO</h1>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 mb-2 mb-lg-0 grid-item design development">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={hrpayrollImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/hrpayroll"
                                            >
                                                <h1 className="font-size-20">HR PAYROLL</h1>
                                            </Link>
                                            <p>[design, development]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 mb-2 grid-item design development">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={almonaplastImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/almonaplast"
                                            >
                                                <h1 className="font-size-20">ALMONAPLAST</h1>
                                            </Link>
                                            <p>[design, development]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={sportsuisImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/sportshuis"
                                            >
                                                <h1 className="font-size-20">SPORTSHUIS</h1>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="appie-section-title text-center">
                    <Link className="main-btn" to="/our-projects">
                        VIEW ALL PROJECTS <i className="fal fa-arrow-right" />
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Projects;
