import React from 'react';
import HeroDot from '../../assets/images/hero-dot.png';
import heroThumb from '../../assets/images/hero-thumb-5.png';
import shape from '../../assets/images/shape/shape-10.png';

function HeroHomeFive() {
    return (
        <section className="appie-hero-area appie-hero-4-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="appie-hero-content appie-hero-content-4">
                            <span>IMPRESSIVE. OPTIMISED. SOLUTIONS.​</span>
                            <h1 className="appie-title">INDUSTRY-FOCUSED WEB DEVELOPMENT​</h1>
                            <p>
                                Develop a web application tailored to your industry needs. To help
                                your digital product succeed, we commit to comprehensively research
                                your market and build an innovative solution that’s customized to
                                your business, speaks your values and targets the right audience.
                                Using top-performing technologies, we’ve built over 350 products
                                from all over the world and across different industries.
                            </p>
                            <a className="main-btn" href="/contacts">
                                Get In Touch
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="appie-hero-thumb appie-hero-thumb-4">
                            <img src={heroThumb} alt="" />
                            <div className="hero-dot">
                                <img src={HeroDot} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-shape-1">
                <img src={shape} alt="" />
            </div>
        </section>
    );
}

export default HeroHomeFive;
