import React from 'react';
import FunFactThumb from '../../assets/images/fun-fact-thumb.png';
import CounterUpCom from '../../lib/CounterUpCom';

function FunFact() {
    return (
        <section className="appie-fun-fact-area" id="fun-fact-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-fun-fact-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="appie-fun-fact-content">
                                        <h1 className="title">Why Choose WebNStack?</h1>
                                        <p>
                                            A top React.js tech firm, WebNStack provides a variety
                                            of React.js solutions to help with the creation of
                                            online applications, such as Web apps, Social networking
                                            sites, Advanced streaming services, Platforms as
                                            services (PAAS), and more. The whole JavaScript
                                            framework will be used by our skilled team of developers
                                            as they design a cutting-edge and eye-catching user
                                            interface (UI) for your project. We offer specialised
                                            solutions that support the creation of top-notch web
                                            apps by business customers. The JavaScript framework
                                            helps the website be very user-focused and perform well.
                                            However, WebNStack uses ReactJS to provide more for its
                                            consumers.
                                        </p>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h1 className="title">
                                                        <CounterUpCom
                                                            endValue="27"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h1>
                                                    <span> ACTIVE CLIENTS</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h1 className="title">
                                                        <CounterUpCom
                                                            endValue="76"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h1>
                                                    <span>PROJECTS DONE</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h1 className="title">
                                                        <CounterUpCom
                                                            endValue="25"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h1>
                                                    <span>Team Advisors</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-fun-fact-play">
                                        <img src={FunFactThumb} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FunFact;
