import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import deelertlogo from '../../assets/images/webnstack/Itthikrit-K..png';
import Ecommercedevelopementproject2 from '../../assets/images/webnstack/Jim-Bradford.png';
import almonaplastLogo from '../../assets/images/webnstack/almona.png';
import Ecommercedevelopementproject1 from '../../assets/images/webnstack/asslreview.png';
import kakzonlogo from '../../assets/images/webnstack/kakzon.png';

function Testimonial({ className }) {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section
            className={`appie-testimonial-area pt-100 pb-160 ${className || ''}`}
            id="testimonial"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                            <span
                                className="prev slick-arrow"
                                style={{ display: 'block' }}
                                onClick={sliderNext}
                                role="button"
                                tabIndex="0"
                                aria-label="arrow-left"
                            >
                                <i className="fal fa-arrow-left" />
                            </span>
                            <Slider ref={sliderRef} dots arrows={false}>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={Ecommercedevelopementproject2} alt="" />
                                        <h1 className="title">Jim Bradford</h1>
                                        <span>New York </span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            We need assistance to get a custom-built website based
                                            on the Porto theme being categorized under e-commerce
                                            business. We admire WebNStack's project management team
                                            for their coordination in the website development.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={Ecommercedevelopementproject1} alt="" />
                                        <h1 className="title">Nalini Lalla</h1>
                                        <span>UAE</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Our idea would not have been successful without a
                                            compelling website. The platform that gave me a sense of
                                            satisfaction after the first chat was WebNStack. The
                                            project managers there were responsive to our needs and
                                            promised to deliver what we had in mind for the next
                                            project.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={deelertlogo} alt="" />
                                        <h1 className="title">Itthikrit K.</h1>
                                        <span>Thailand </span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            WebnStack has been a positive experience from start to
                                            finish. Their responsiveness, professionalism, skills
                                            made the collaboration seamless. They delivered the
                                            project as promised, to timelines and quality. I would
                                            not hesitate to engage them again for future projects.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={almonaplastLogo} alt="" />
                                        <h1 className="title">Hend Gomaa</h1>
                                        <span>Saudi Arab </span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            WebNStack is a team of integrity, consistently
                                            delivering honest and prompt development services. Our
                                            commitment to client satisfaction ensures a positive
                                            experience, and we look forward to the opportunity of
                                            working together again in the future.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img src={kakzonlogo} alt="" />
                                        <h1 className="title">Hamad Albulooshi</h1>
                                        <span>Bahrain</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Engaging WebnStack for our integration project with
                                            Magento proved to be a wise decision. They exhibited a
                                            deep understanding of Magento and its APIs. The team's
                                            expertise, clear communication, collaborative approach
                                            significantly contributed to the success of our project.
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Slider>
                            <span
                                onClick={sliderPrev}
                                role="button"
                                tabIndex="-1"
                                className="next slick-arrow"
                                style={{ display: 'block' }}
                                aria-label="arrow-right"
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
