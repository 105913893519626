import React from 'react';
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
import counterIconTwo from '../../assets/images/icon/counter-icon-2.svg';
import counterIconThree from '../../assets/images/icon/counter-icon-3.svg';
import counterIconFour from '../../assets/images/icon/counter-icon-4.svg';

function CounterArea({ style }) {
    return (
        <section className="appie-counter-area pt-90 pb-190" id="counter" style={style}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h3 className="appie-title">
                                An agency is a business that provides specific Services.
                            </h3>
                            <p>
                                In less than 24 hours, you may outsource your next service in a few
                                simple steps.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconOne} alt="" />
                                </div>
                                <p> Headless Commerce</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconTwo} alt="" />
                                </div>
                                <p>Web Development</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconThree} alt="" />
                                </div>
                                <p>Mobile Development</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconFour} alt="" />
                                </div>
                                <p>eCommerce Development</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterArea;
