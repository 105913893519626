import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterThree from '../Shared/SharedFooterThree';
import SharedHeader from '../Shared/SharedHeader';
import SharedInformationSection from '../Shared/SharedInformationSection';
import SharedShowCase from '../Shared/SharedShowCase';
import SharedTeamAbout from '../Shared/SharedTeamAbout';
import SharedTestimonialTwo from '../Shared/SharedTestimonialTwo';
import About from './About';
import Faq from './Faq';
import Hero from './Hero';
import Services from './Services';
import ServicesThree from './ServicesThree';
import WorkPart from './WorkPart';

function HeadlessCommerce() {
    const [drawer, setDrawer] = useToggle(false);
    useEffect(() => {
        document.title = 'Headless Commerce | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={setDrawer.toggle} />
            <SharedHeader drawer={drawer} action={setDrawer.toggle} />
            <Hero />
            <About />
            <ServicesThree />
            <Services />
            <WorkPart />
            <SharedShowCase />
            <Faq />
            <SharedTeamAbout />
            <SharedTestimonialTwo />
            <SharedInformationSection />
            <SharedFooterThree />
            <BackToTop className="back-to-top-8" />
        </>
    );
}

export default HeadlessCommerce;
