import React from 'react';

function ServicesTwo({ className }) {
    return (
        <section className={`appie-services-2-area pb-60 ${className}`} id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-8">
                        <div className="appie-section-title">
                            <h3 className="appie-title text-center">
                                Our Mission and Impact: Empowering Lives through Universal Access to
                                Advanced Courses
                            </h3>
                            <p>
                                At WebNStack, our mission transcends traditional education. We are
                                dedicated to the transformation and empowerment of individuals by
                                specializing in molding, developing, and fortifying their skills.
                                Our commitment is to change lives through universal access to
                                advanced courses.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-chalkboard-teacher icon-size-30"></i>
                            </div>
                            <h4 className="appie-title">Expert Guides in Skill Development</h4>
                            <p className="text-justify">
                                We take pride in our educators who serve as expert guides in the
                                journey of skill development. With a wealth of knowledge and
                                practical experience, our instructors are committed to fostering
                                growth and excellence in every learner.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-book-reader icon-size-30"></i>
                            </div>
                            <h4 className="appie-title">Skill Courses with Tailored to Empower</h4>
                            <p className="text-justify">
                                Our courses are meticulously designed to empower individuals by
                                enhancing their skills. Each course is a gateway to acquiring
                                practical and relevant knowledge, equipping learners for success in
                                their chosen fields.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-user-graduate icon-size-30"></i>
                            </div>
                            <h4 className="appie-title">
                                A Growing Community of Enrolled Learners
                            </h4>
                            <p className="text-justify">
                                Join a thriving community of accomplished learners at WebNStack. Our
                                impact is measured by the increasing number of students enrolled,
                                each on a unique path toward personal and professional development.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-chalkboard-teacher icon-size-30"></i>
                            </div>
                            <h4 className="appie-title">Active Learning for Lasting Knowledge</h4>
                            <p className="text-justify">
                                We believe in active learning. Through assigned quizzes and
                                assignments, learners actively engage with course material,
                                reinforcing their understanding and ensuring the retention of
                                valuable knowledge.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-bullseye icon-size-30"></i>
                            </div>
                            <h4 className="appie-title">
                                Goal Oriented and Focused on Your Success
                            </h4>
                            <p className="text-justify">
                                Our approach is goal-oriented, with a focus on your success. Whether
                                you're looking to enhance your current skills or explore new
                                horizons, our courses are designed to help you achieve your
                                professional and personal goals.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-memory icon-size-30"></i>
                            </div>
                            <h4 className="appie-title">
                                Advanced Technology with Future Ready Learning
                            </h4>
                            <p className="text-justify">
                                Stay ahead with our courses that leverage advanced technology. We
                                are committed to providing a learning experience that aligns with
                                the latest advancements, ensuring our learners are well-prepared for
                                the challenges of the future.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesTwo;
