import React from 'react';
import magentomigrationsection5th from '../../assets/images/webnstack/magento-migration-5th-section-image.png';
import magentomigrationsection6th from '../../assets/images/webnstack/magento-migration-6th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pb-60 Convert" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title CONCERNS">
                                IF YOU’RE THINKING ABOUT WHETHER OR NOT TO UPGRADE FROM MAGENTO 1,
                                HERE ARE SOME CONCERNS TO CONSIDER:
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={magentomigrationsection5th} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 pt-100 padding-top0">
                        <div>
                            <div>
                                <ul className="liste-image">
                                    <li>Extensions and plugins stop working or become unusable</li>
                                    <li>
                                        Magento 1 websites are no longer supported by Adobe/Magento
                                    </li>
                                    <li>
                                        You’ll put your store at risk of data loss and security
                                        breaches
                                    </li>
                                    <li>
                                        The original software publishers are not providing any new
                                        patches
                                    </li>
                                    <li>
                                        This is a problem for both security and compliance, such as
                                        PCI compliance
                                    </li>
                                    <li>
                                        Planning to upgrade to a new platform, whether Magento 2 or
                                        not, is the best long-term option
                                    </li>
                                    <li> That, however, takes time and money</li>
                                    <li>
                                        Because you’re still using Magento 1, the best thing you can
                                        do now is secure your site until you can upgrade
                                    </li>
                                    <li>
                                        So, your website won’t shut off, but you will not receive
                                        any security patches as vulnerabilities are detected
                                    </li>
                                    <li>
                                        Magento developers will get more familiar with the current
                                        version and its PCI DSS compliant alternatives when the
                                        merchant’s site becomes problematic or degrades
                                    </li>
                                    <li>
                                        If you accept credit cards, you may also run into problems
                                        with your payment processors, as PCI compliance standards
                                        require you to maintain your website patched
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title pt-60 text-center Choose-WebNStack">
                            <h1 className="appie-title ChooseWebNStackfont-size20px">
                                Why To Choose WebNStack?
                            </h1>
                            <p className="font-size16px-p">
                                WebNStack is aware that migrating from Magento 1.x to Magento 2.x
                                requires deep technical skills and knowledge. Therefore, WebNStack
                                always do the best with technical experts to help Magento 1.x stores
                                update data without data loss in our ability.
                                <br />
                                We follow the following steps before beginning the entire migration
                                procedure:
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="appie-traffic-service mb-10 item-3">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-window-restore shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">
                                            Back up
                                        </h1>
                                    </div>
                                    <p>
                                        Make a complete backup of your Magento 1 store’s files,
                                        directories, and database.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service item-2 mb-10 item-4">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="far fa-clone shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">Clone</h1>
                                    </div>
                                    <p>
                                        Convert your Magento 1 store to a clone. For the Magento
                                        migration procedure, we do not use the live store.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service mb-10 item-5">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-diagnoses shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">
                                            Examine
                                        </h1>
                                    </div>
                                    <p>
                                        We examine your shop. We keep what you think you’ll need for
                                        Magento 2 migration and remove the rest.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service item-2 mb-10 item-6">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-search shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">Coding</h1>
                                    </div>
                                    <p>
                                        Check to see if your current theme(s), extensions, and
                                        custom code are Magento 2 compatible.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service item-2 mb-10 item-3">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-server shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">
                                            Migrate
                                        </h1>
                                    </div>
                                    <p>
                                        Migrate the data from your Magento 1 store’s cloned
                                        database.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service mb-10 item-4">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-unlink shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">
                                            Clean up
                                        </h1>
                                    </div>
                                    <p>
                                        Remove logs, recently seen products, compared items, and any
                                        obsolete and worthless data from the database.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service mb-10 item-5">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-store shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">Hosted</h1>
                                    </div>
                                    <p>
                                        Finally, Install a clean copy of Magento 2 on the same
                                        hosting server as your Magento 1 store.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="appie-traffic-service mb-10 item-3">
                                    <div className="icon-flex">
                                        <div className="icon">
                                            <i
                                                className="fas fa-window-restore shadow-none"
                                                style={{
                                                    background: 'no-repeat',
                                                    fontSize: '20px',
                                                }}
                                            ></i>
                                        </div>
                                        <h1 className="title pl-10 margin-top-webnstack">
                                            Validate
                                        </h1>
                                    </div>
                                    <p>
                                        Verify the integrity of your migrated Magento 2 store by
                                        conducting thorough tests.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-center-items">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={magentomigrationsection6th} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
