import Isotope from 'isotope-layout';
import React from 'react';
import { Link } from 'react-router-dom';
import mcurtainImage from '../../assets/images/portfolio/3mcurtain.png';
import almonaplastImages from '../../assets/images/portfolio/almona-plast.png';
import asslImage from '../../assets/images/portfolio/assl.png';
import cloudearlyImages from '../../assets/images/portfolio/cloudearly.png';
import dealonImage from '../../assets/images/portfolio/dealon.png';
import deelertImage from '../../assets/images/portfolio/deelert.png';
import deliverzeroImage from '../../assets/images/portfolio/deliverzero.png';
import fragrenceshopImage from '../../assets/images/portfolio/fragrenceshop.png';
import goesmarteyewearImages from '../../assets/images/portfolio/goesmarteyewear.png';
import hrpayrollImages from '../../assets/images/portfolio/hr-payroll.png';
import kakzonImages from '../../assets/images/portfolio/kakzon.png';
import livelienImage from '../../assets/images/portfolio/livelien.png';
import RkbuilderImages from '../../assets/images/portfolio/rk-builders.png';
import sandiabioImages from '../../assets/images/portfolio/sandiabio.png';
import sportsuisImages from '../../assets/images/portfolio/sportsuis.png';
import toyfiestaImages from '../../assets/images/portfolio/toyfiesta.png';
import eltriviaImage from '../../assets/images/webnstack/el-trivia.png';
import FoodDrinkImages from '../../assets/images/webnstack/food-drink.png';
import GlowByMpiImages from '../../assets/images/webnstack/glow-by-mpi.png';
import mediawordImage from '../../assets/images/webnstack/media-word.png';

function Projects() {
    const isotope = React.useRef();
    const [filterKey, setFilterKey] = React.useState('*');

    const handleFilterKeyChange = (e, key) => {
        const filteringItemElements = document.querySelectorAll('.filtering-item');
        // eslint-disable-next-line no-restricted-syntax
        for (const filteringItemElement of filteringItemElements) {
            filteringItemElement.classList.remove('active');
        }
        if (e) {
            e.target.classList.add('active');
        }
        setFilterKey(key);
    };

    React.useEffect(() => {
        isotope.current = new Isotope('.gallery', {
            // options
            itemSelector: '.grid-item',
            layoutMode: 'fitRows',
        });
        isotope.current.arrange({ filter: `*` });
        // cleanup
        return () => isotope.current.destroy();
    }, []);

    React.useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        filterKey === '*'
            ? isotope.current.arrange({ filter: `*` })
            : isotope.current.arrange({ filter: `.${filterKey}` });
    }, [filterKey]);

    setTimeout(() => {
        if (isotope.current) {
            isotope.current.layout();
        }
    }, 300);

    // const handleFilterKeyChange = (e, key) => () => setFilterKey(key);

    return (
        <section className="portfolio-section">
            <div className="container">
                <div className="row no-gutters">
                    <div className="filtering col-sm-12 text-center refer">
                        <span
                            data-filter="*"
                            className="filtering-item active"
                            onClick={(e) => handleFilterKeyChange(e, '*')}
                        >
                            All
                        </span>
                        <span
                            data-filter=".design"
                            className="filtering-item "
                            onClick={(e) => handleFilterKeyChange(e, 'design')}
                        >
                            Design
                        </span>
                        <span
                            data-filter=".development"
                            className="filtering-item "
                            onClick={(e) => handleFilterKeyChange(e, 'development')}
                        >
                            Development
                        </span>
                        <span
                            data-filter=".ecommerce"
                            className="filtering-item "
                            onClick={(e) => handleFilterKeyChange(e, 'ecommerce')}
                        >
                            eCommerce
                        </span>
                    </div>
                    <div className="col-12 text-center w-100">
                        <div className="form-row gallery">
                            <div className="col-sm-6 col-lg-4 mb-2 grid-item design ecommerce">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={RkbuilderImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/rkbuilders"
                                            >
                                                <h4>RK BUILDERS</h4>
                                            </Link>
                                            <p>[design, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 mb-2 grid-item design development">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={almonaplastImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/almonaplast"
                                            >
                                                <h4>ALMONAPLAST</h4>
                                            </Link>
                                            <p>[design, development]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 mb-2 grid-item development ecommerce">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={goesmarteyewearImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/gosmarteyewear"
                                            >
                                                <h4>GOSMARTEYEWEAR</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 mb-2 mb-lg-0 grid-item design development">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={hrpayrollImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/hrpayroll"
                                            >
                                                <h4>HR PAYROLL</h4>
                                            </Link>
                                            <p>[design, development]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 mb-2 mb-sm-0 grid-item development ecommerce">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={kakzonImages} alt="" />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link className="popimg ml-0" to="/our-projects/kakzon">
                                                <h4>KAKZON</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={toyfiestaImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/toyfiesta"
                                            >
                                                <h4>TOYFIESTA</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={cloudearlyImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/cloudearly"
                                            >
                                                <h4>CLOUDEARLY</h4>
                                            </Link>
                                            <p>[design, development]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={deelertImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/deelert"
                                            >
                                                <h4>DEELERT</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={deliverzeroImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/deliverzero"
                                            >
                                                <h4>DELIVERZERO</h4>
                                            </Link>
                                            <p>[design , development , eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={fragrenceshopImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/fragranceshop"
                                            >
                                                <h4>FRAGRANCESHOP</h4>
                                            </Link>
                                            <p>[eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={livelienImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/livelinen"
                                            >
                                                <h4>LIVELINEN</h4>
                                            </Link>
                                            <p>[eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={mcurtainImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/3mcurtain"
                                            >
                                                <h4>3MCURTAIN</h4>
                                            </Link>
                                            <p>[eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={sandiabioImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/sandiabio"
                                            >
                                                <h4>SANDIABIO</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={sportsuisImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/sporthuis"
                                            >
                                                <h4>SPORTHUIS</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={asslImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link className="popimg ml-0" to="/our-projects/assl">
                                                <h4>ASSL</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={dealonImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link className="popimg ml-0" to="/our-projects/dealon">
                                                <h4>DEALON</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={GlowByMpiImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/glowbympi"
                                            >
                                                <h4>GLOW BY MPI</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={eltriviaImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/eltrivia"
                                            >
                                                <h4>El TRIVIA</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={FoodDrinkImages} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/kenwoodkitchen"
                                            >
                                                <h4>Kenwood kitchen</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 grid-item development pt-10">
                                <div className="portfolio-wrapper">
                                    <div className="portfolio-image">
                                        <img src={mediawordImage} alt="..." />
                                    </div>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-content">
                                            <Link
                                                className="popimg ml-0"
                                                to="/our-projects/mediaworldegypt"
                                            >
                                                <h4>MEDIA WORLD EGYPT</h4>
                                            </Link>
                                            <p>[development, eCommerce]</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;
