import React from 'react';
import glowbympisection4 from '../../../assets/images/webnstack/4th-section-mipy.png';

function About() {
    return (
        <section className="appie-about-3-area pt-20 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={glowbympisection4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">RESULTS & PROCESS</h2>
                            <p>
                                The journey from conceptualization to launch culminated in the
                                successful release of the "Glow By MPI" mobile application on both
                                Apple's App Store and Google Play Store. The impact of our strategic
                                approach and meticulous execution is evident in the app's
                                performance and reception.
                            </p>
                            <p>
                                The launched app delivers a seamless and user-friendly experience,
                                ensuring that both doctors and clients can effortlessly navigate
                                through skincare products, place orders, and accumulate points. The
                                Magento integration provides a secure and reliable foundation for
                                the app's e-commerce functionalities, guaranteeing a smooth
                                transactional experience for users.
                            </p>
                            <p>
                                {' '}
                                The implemented reward system has successfully engaged users,
                                driving consistent interactions within the app and fostering a loyal
                                community that actively participates in the skincare journey.
                            </p>
                            <p>
                                {' '}
                                Users now enjoy exclusive access to the best skincare deals and
                                promotions, contributing to a sense of exclusivity and value
                                associated with the "Glow By MPI" app. The app has facilitated
                                meaningful connections between doctors and clients, enhancing the
                                overall skincare experience by providing a platform for personalized
                                interactions and treatment recommendations.
                            </p>
                            <p>
                                The successful launch on both major platforms, Apple's App Store and
                                Google Play Store, ensures that "Glow By MPI" reaches a wide
                                audience, expanding its influence in the skincare community.
                            </p>
                            <p>
                                The app has received positive feedback from users, reflecting its
                                intuitive design, functionality, and the valuable skincare solutions
                                it offers.
                            </p>
                            <p>
                                With the successful launch of "Glow By MPI," our commitment to
                                delivering innovative, user-centric solutions has once again
                                manifested in a transformative product that elevates the skincare
                                experience for both doctors and clients. The positive reception and
                                continued engagement underscore the success of our collaborative
                                efforts with MPI in bringing their vision to life.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
