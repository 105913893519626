import React from 'react';

function SharedServicesThree() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-50 pb-100
             hire-web-developer-services-padding-top abt-us-pdd-btm-0"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center hire-web-developer-section-title">
                            <h1 className="appie-title">Our Methodology of Work</h1>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="far fa-handshake icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Requirements</h1>
                            <p className="text-justify">
                                We start your projects with the initial and most relevant priority
                                of gathering needs, resources, expectations, vision and information.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-paint-brush icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">UI/UX Design</h1>
                            <p className="text-justify">
                                We develop unique and appealing designs by using the most up-to-date
                                design technologies to provide the best user experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-window-restore icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Development</h1>
                            <p className="text-justify">
                                The development of a mobile application, website, or blockchain
                                started with visibility, employing the newest technology and tools.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-comments icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Support & Upkeep</h1>
                            <p className="text-justify">
                                WebNStack provides you with support & is always available to any
                                questions you may have after implementation.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-terminal icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Implementation</h1>
                            <p className="text-justify">
                                After testing and completing all requirements, your project is ready
                                to be released on the Application Store.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-bug icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Quality Control</h1>
                            <p className="text-justify">
                                Web application prioritises security and offers a bug-free
                                application with no compromises.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SharedServicesThree;
