import React from 'react';
import livelinensectionimage4 from '../../../assets/images/webnstack/livelinen-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-30 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={livelinensectionimage4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">RESULTS & PROCESS</h1>
                            <p>
                                The completion of the website revamp project for LiveLinen marks a
                                significant achievement in meeting the client's requirements and
                                achieving their business goals.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Our team delivered a comprehensive solution that included a fully
                                responsive user interface, addressing the client's concerns about
                                speed issues and ensuring an engaging digital user experience.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                LiveLinen's unique vision and expectations were successfully
                                integrated into the development process, preserving the essence of
                                their brand.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The website's performance remained robust, and the client observed a
                                notable increase in sales, reflecting the positive impact of the
                                changes.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                One of our primary objectives was to ensure a seamless transition,
                                and we achieved this by implementing the revamp with no data loss or
                                downtime for LiveLinen's business operations.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The success of this project reinforces our commitment to delivering
                                tailored solutions that enhance the online presence and performance
                                of our clients, showcasing our dedication to their success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
