import React from 'react';
import workThumb from '../../assets/images/Angular-section-4.png';

function WorkPart() {
    return (
        <div className="appie-how-it-work-area pt-10">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="how-it-work-thumb text-center">
                            <img src={workThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-how-it-work-content">
                            <h2 className="title">Custom Website Solutions</h2>
                            <p className="font-size16px-p">
                                The complete set of JS technologies is something we fully
                                comprehend. For medium-sized to big and startup enterprises, we
                                create AngularJS web and mobile apps by putting to use our core
                                development and coding expertise.
                            </p>
                            <p className="font-size16px-p">
                                {' '}
                                Additionally, we provide the best Angular JS development solutions
                                for a wide range of applications, from social networking application
                                development to marketplaces and e-commerce. As a team, we work with
                                a variety of libraries to demonstrate how you can have a fully
                                configurable workflow to meet your needs.
                            </p>
                            <p className="font-size16px-p">
                                {' '}
                                We have a track record of creating Angular online and mobile
                                applications that work perfectly. Therefore, allow AngularJS
                                development services to help you achieve your company goals now.
                            </p>
                            <p className="font-size16px-p">
                                {' '}
                                We provide a variety of complete software solutions. The advantages
                                of a Framework that is highly transparent, expandable, and
                                spontaneous programmes created by our skilled developers are also
                                provided to our clients.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkPart;
