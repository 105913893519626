import React from 'react';
import aboutThumb from '../../assets/images/about-thumb.png';

function AboutHomeTwo() {
    return (
        <section className="appie-about-area mb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-thumb">
                                        <img src={aboutThumb} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-about-content">
                                        <span> ABOUT US</span>
                                        <h3 className="title">
                                            YOUR PARTNER FOR ECOMMERCE INNOVATION
                                        </h3>
                                        <p>
                                            WebNStack is a top-notch website and app development
                                            company. We are known for being innovators and
                                            passionate problem-solver to deliver high-standard
                                            eCommerce solutions and services to unlock the
                                            possibilities of the start-up to large enterprises. Our
                                            end-to-end solutions are based on the newest and
                                            innovative tools and technologies.
                                        </p>
                                        <p>
                                            We have a team of proficient and well-competent
                                            developers and design engineers with the motive to
                                            transform your ideas into reality. With the perfect
                                            blend and eCommerce technologies, we offer high-quality
                                            services that fit the newest market trends.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="appie-about-service mt-30">
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <h4 className="title">Our philosophy</h4>
                                                <p>
                                                    {' '}
                                                    Bringing your commerce idea to life and
                                                    providing eCommerce solutions for all your
                                                    needs.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="appie-about-service mt-30">
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <h4 className="title">Our vision</h4>
                                                <p>
                                                    To bridge the gap between technology and the
                                                    eCommerce environment the partner of choice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutHomeTwo;
