import React from 'react';
import SporthuisSection4 from '../../../assets/images/webnstack/sporthuis-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-30 pb-50" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={SporthuisSection4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">RESULTS & PROCESS</h2>
                            <p>
                                Following a successful deployment to production, SportHuis e-stores
                                underwent a seamless transition into a multistore environment,
                                showcasing the implementation of cutting-edge solutions.
                                Importantly, the deployment did not disrupt the websites'
                                performance, ensuring a smooth and reliable online shopping
                                experience for users.
                            </p>
                            <p>
                                The overall improvement in the digital user experience has been a
                                key driver in this success. Customers now enjoy a heightened online
                                shopping journey, characterized by streamlined navigation, enhanced
                                aesthetics, and an overall user-friendly interface. SportHuis is
                                positioned to offer its customers the finest quality apparels and
                                accessories tailored to their favorite sports. The upgrade in the
                                digital user experience has created an environment where users can
                                easily explore and access a diverse range of products, providing
                                them with the utmost convenience and satisfaction.
                            </p>
                            <p>
                                {' '}
                                SportHuis' commitment to delivering an unparalleled online shopping
                                experience has not only met but exceeded customer expectations,
                                contributing to sustained growth in the competitive market. In
                                summary, SportHuis' journey from deployment to a multistore
                                environment reflects a commitment to excellence, where digital
                                innovation has not only preserved custom functionalities but has
                                also significantly elevated the digital user experience, resulting
                                in increased sales and customer satisfaction.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
