import React from 'react';
import aboutThumb7 from '../../assets/images/webnstack/app-dev.png';
import aboutThumb5 from '../../assets/images/webnstack/migration.png';
import aboutThumb4 from '../../assets/images/webnstack/plugins.png';
import aboutThumb6 from '../../assets/images/webnstack/web-dev.png';

function About() {
    return (
        <section className="appie-about-8-area pt-100 pb-100 Solutions-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title mb-30">
                            <h1 className="appie-title create-margin-top">
                                We bring everything that's required to create
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div>
                            <h1 className="title" style={{ fontSize: '30px' }}>
                                Development of AngularJS Plugins
                            </h1>
                            <p>
                                Customers just like online retailers that offer a simple and safe
                                buying experience. We at WebNStack work with our skilled Magento
                                developers to include premium payment gateways into the eCommerce
                                websites of our clients. This is how our clients’ e-commerce sites
                                become user-friendly and appeal to their customers’ simplicity of
                                use.
                            </p>

                            <div className="thumb pt-10">
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="appie-about-8-box Angular-JS-Plugins-margin-bottom">
                            <h1 className="title" style={{ fontSize: '30px' }}>
                                Solutions for Angular Migration
                            </h1>
                            <p>
                                If a website’s operation is clunky and the user experience is poor,
                                it cannot be useful. Our specialists conduct security checks as part
                                of the Magento support and maintenance solution to ensure that the
                                shop is always up and running smoothly.
                            </p>

                            <div className="thumb mr-30">
                                <img src={aboutThumb5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-7 ">
                        <div>
                            <h1 className="title" style={{ fontSize: '30px' }}>
                                Web Development with AngularJS
                            </h1>
                            <p>
                                We are skilled in creating straightforward to complex online stores
                                that can be simply handled whenever and wherever. A fluid web store
                                will provide you the opportunity to connect with a worldwide
                                audience. So join forces with us for rapid turnaround,
                                cost-effective Magento services customised to your unique business
                                needs. You can get assistance from our skilled developers.
                            </p>

                            <div className="thumb">
                                <img src={aboutThumb6} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="appie-about-8-box">
                            <h1 className="title" style={{ fontSize: '30px' }}>
                                AngularJS Application Development
                            </h1>
                            <p>
                                Our team of committed Magento developers are experts in creating
                                aesthetically appealing, user-friendly themes that give your store
                                the competitive edge. With the support of our talented and
                                imaginative designers and developers, this aids in creating a
                                captivating shop.
                            </p>

                            <div className="thumb mr-30">
                                <img src={aboutThumb7} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
