import React from 'react';
import rkbulderssection2 from '../../../assets/images/webnstack/2nd-section-media-world.png';
import rkbuilderssection3 from '../../../assets/images/webnstack/3rd-section-media-world.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10
        "
            id="features"
        >
            <div className="container pt-120 mob-p-0">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={rkbulderssection2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                In our collaboration with Media World Egypt, our team at DigifyMENA
                                faced the challenge of adapting to dynamic client demands, primarily
                                centered around API changes and the resolution of pressing issues
                                within their Magento application. The client sought a comprehensive
                                solution to enhance the functionality and performance of their
                                platform, necessitating a meticulous approach to address API
                                integrations seamlessly. Our dedicated team successfully tackled the
                                intricate API modifications, ensuring a smoother and more efficient
                                data exchange process. Additionally, we delved into troubleshooting
                                and resolving critical issues within the Magento application,
                                implementing strategic fixes to enhance overall user experience and
                                operational efficiency. Furthermore, DigifyMENA took charge of the
                                server maintenance, optimizing server performance to align with
                                Media World Egypt's evolving requirements. Our commitment to
                                addressing these challenges head-on underscores our dedication to
                                delivering tailored solutions and ensuring the sustained success of
                                our client's digital infrastructure.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                In response to the multifaceted challenges presented by Media World
                                Egypt, DigifyMENA executed a comprehensive technical overhaul. Our
                                team implemented intricate API changes to accommodate evolving
                                client demands, ensuring seamless data integration. We meticulously
                                diagnosed and resolved critical issues within the Magento
                                application, applying strategic fixes to enhance user experience and
                                operational efficiency. The server maintenance aspect of the project
                                involved optimizing server performance, aligning it with the
                                client's dynamic requirements.
                            </p>
                            <p>
                                Simultaneously, our team undertook the meticulous maintenance of the
                                attached Magento application, employing a proactive approach to
                                identify and promptly address potential issues. Routine updates were
                                seamlessly executed, contributing to improved performance, enhanced
                                data integrity, and overall stability.
                            </p>
                            <p>
                                Client's technical interventions were not only problem-solving but
                                also forward-looking, positioning Media World Egypt for sustained
                                digital success. Our dedicated approach to addressing complex
                                technical issues underscores our commitment to delivering tailored
                                solutions in the ever-evolving landscape of digital platforms.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={rkbuilderssection3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
