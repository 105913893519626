import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import almonaplastLogo from '../../assets/images/webnstack/almonaplast-logo.png';
import toyfiestaLogo from '../../assets/images/webnstack/toyfiesta-logo.png';

function TestimonialHomeTwo() {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    return (
        <section className="appie-testimonial-2-area mb-90" id="testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-testimonial-2-box">
                            <div
                                className="appie-testimonial-slider-2"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    onClick={sliderPrev}
                                    className="prev slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-left" />
                                </span>
                                <Slider ref={sliderRef} {...settings}>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={toyfiestaLogo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(5) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    I’m grateful to the “WebNStack” team since they
                                                    have the skills to simplify user data
                                                    administration and create a user-friendly
                                                    interface. Their successful efforts—which won
                                                    awards—helped me achieve my goal of having a
                                                    digital presence.
                                                </p>
                                                <div className="author-info">
                                                    <h5 className="title">Gavin C.</h5>
                                                    <span>Australlia</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={almonaplastLogo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(4.9) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Webnstack is a team of integrity. Honest and
                                                    very quick on development. I am very satisfied
                                                    with their services and will re-hire them again
                                                    for sure
                                                </p>
                                                <div className="author-info">
                                                    <h5 className="title">Hend Gomaa</h5>

                                                    <span>Saudi Arab</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                                <span
                                    onClick={sliderNext}
                                    className="next slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeTwo;
