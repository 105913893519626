import React, { useState } from 'react';

function Faq({ className }) {
    const [showQues, setQues] = useState();
    const openQuestion = (value) => {
        setQues((prevValue) => (prevValue === value ? null : value));
    };
    return (
        <section className={`appie-faq-area pb-95 hire-padding-bottom30 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title pt-50 font-size--20px">
                                Frequently Asked Questions
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(1)}
                                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="according font-size--19px faq-sec-font-size">
                                                How will I be in touch with the developer and work{' '}
                                                reports?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 1 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p className="margin-top7px">
                                                    WebNStack assigns a Lead Manager to your
                                                    project. He/she will remain in contact with you.
                                                    These individuals are in charge of providing you
                                                    with regular updates. We will be available via
                                                    any means of contact you like.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(2)}
                                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--19px faq-sec-font-size faq-pr17">
                                                How much you charge for a expert developer?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 2 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p className="margin-top7px">
                                                    At WebNStack, experience levels range from
                                                    beginner to highly adept expert. As a result,
                                                    our fees may vary depending on the nature of
                                                    your project and the developer you select. For
                                                    additional information, please contact us at
                                                    info@webnstack.com.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(3)}
                                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--19px faq-sec-font-size faq-pr30">
                                                How much experience does your developers have?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 3 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p className="margin-top7px">
                                                    We have a massive number of qualified Magento 2,
                                                    WooCommerce, and Shopify developers with notable
                                                    expertise, depending on the clients requirements
                                                    and budget (from 2 years to 15 years). You may
                                                    select any of them for your project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(4)}
                                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title pr-5 faq-padding-12px">
                                            <h1 className="font-size--19px faq-sec-font-size faq-pr17">
                                                What communication channels are prefferable to your
                                                developers?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 4 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p className="margin-top7px">
                                                    You can reach us at any time using the email
                                                    address and phone number shown on our website.
                                                    In addition, we are quite active on all social
                                                    media platforms such as LinkedIn, Skype, and
                                                    Facebook. You may also contact us through any
                                                    CRM channel you want.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;
