import React from 'react';
import iconThree from '../../assets/images/gatsby.js.png';
import iconSix from '../../assets/images/graphql.png';
import iconFive from '../../assets/images/mobx.png';
import iconSeven from '../../assets/images/nextjs.png';
import iconOne from '../../assets/images/reactjs.png';
import iconFour from '../../assets/images/redux.png';
import iconTwo from '../../assets/images/webpack.png';

function ServicesReactjs() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-0 pb-100 react-js-sce-padding-bottom"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20-important react-js-margin-top">
                                WebNStack React Js Technologies Stack
                            </h1>
                            <p className="font-size-16px">
                                Empowering innovation: Explore our robust react.js technologies
                                stack for cutting-edge web development.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icons">
                                <img src={iconOne} alt="" />
                            </div>
                            <h1 className="appie-title">React.js</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icons">
                                <img src={iconTwo} alt="" />
                            </div>
                            <h1 className="appie-title">Webpack</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icons">
                                <img src={iconThree} alt="" />
                            </div>
                            <h1 className="appie-title">Gatsby.js</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icons">
                                <img src={iconFour} alt="" />
                            </div>
                            <h1 className="appie-title">Redux</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icons">
                                <img src={iconFive} alt="" />
                            </div>
                            <h1 className="appie-title">Mobx</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icons">
                                <img src={iconSix} alt="" />
                            </div>
                            <h1 className="appie-title">GraphQL</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icons">
                                <img src={iconSeven} alt="" />
                            </div>
                            <h1 className="appie-title">Next.js</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesReactjs;
