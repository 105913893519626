import React from 'react';
import glowbympisection2 from '../../../assets/images/webnstack/glow-by-mpi-2nd.png';
import glowbympisection3 from '../../../assets/images/webnstack/glow-by-mpi-5t.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10
        "
            id="features"
        >
            <div className="container pt-120 mob-p-0">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={glowbympisection2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The client, MPI, approached us with a unique challenge to develop a
                                Magento-based mobile application for both iOS and Android platforms.
                                The aim was to enhance the customer experience by introducing the
                                "Glow By MPI" app, designed to streamline the process of ordering
                                MPI cosmetics and skincare products.{' '}
                            </p>
                            <p>
                                The application needed to serve both doctors and clients, offering a
                                seamless way to order products, accumulate points for rewards, and
                                stay updated on exclusive deals and promotions. The challenge
                                involved integrating Magento functionality into a mobile interface
                                while ensuring a user-friendly experience for skincare enthusiasts.
                            </p>
                            <p>
                                {' '}
                                Additionally, the app aimed to provide users with easy access to the
                                best skincare deals, promotions, and a platform to connect with
                                their preferred doctors for personalized treatments. The ultimate
                                goal was to prioritize users' skin health through an engaging and
                                efficient mobile application.
                            </p>
                            <p>
                                With the challenge at hand, our team embarked on creating a dynamic,
                                Magento-powered mobile app that aligns with MPI's commitment to
                                skincare excellence, providing users with a convenient and rewarding
                                platform for their skincare needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                To meet MPI's challenge of developing a Magento-based mobile
                                application for iOS and Android, our dedicated team of developers
                                executed a strategic plan to bring the "Glow By MPI" app to life. We
                                seamlessly integrated Magento functionality into the mobile app,
                                ensuring a harmonious connection between the e-commerce platform and
                                the user-friendly interface of the application.
                            </p>
                            <p>
                                {' '}
                                Our design team focused on creating an intuitive and visually
                                appealing interface, prioritizing a seamless user experience for
                                both doctors and clients. The app's design aimed to enhance product
                                browsing, ordering, and points accumulation.
                            </p>
                            <p>
                                {' '}
                                To align with MPI's vision of rewarding customer loyalty, we
                                implemented a robust points system. Users could collect points
                                through various transactions, motivating them to engage with the app
                                regularly.
                            </p>
                            <p>
                                {' '}
                                The app was equipped with features that provided users early access
                                to the best skincare deals and exclusive promotions, fostering a
                                sense of exclusivity and value for the app users.Facilitating
                                doctor-client interactions, the app included functionalities for
                                users to find and connect with their preferred doctors, ensuring a
                                personalized skincare journey. Rigorous testing procedures were
                                implemented to guarantee the app's functionality across both iOS and
                                Android platforms, delivering a bug-free and reliable user
                                experience. Our commitment extended beyond development as we
                                provided launch support to ensure a smooth introduction of the "Glow
                                By MPI" app to the target audience
                            </p>
                            <p>
                                {' '}
                                By successfully navigating and executing these steps, we not only
                                met MPI's challenge but also delivered a feature-rich,
                                Magento-integrated mobile application that elevated the skincare
                                experience for both doctors and clients. The "Glow By MPI" app
                                stands as a testament to our expertise in translating unique
                                challenges into innovative solutions.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={glowbympisection3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
