import React from 'react';
import workThumb from '../../assets/images/how-it-work-thumb.png';

function WorkPart() {
    return (
        <div className="appie-how-it-work-area pt-10 pb-60 PROVIDED">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="how-it-work-thumb text-center">
                            <img src={workThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-how-it-work-content">
                            <h2 className="title">ECOMMERCE SERVICES PROVIDED</h2>
                            <p className="font-size16px-p">
                                The pinnacle formula of how WebNStack work.
                            </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>1</span>
                                        <h1 className="title">UX/UI DEVELOPMENT</h1>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>2</span>
                                        <h1 className="title">MIGRATION SERVICESE</h1>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>3</span>
                                        <h1 className="title">IT CONSULTANCY</h1>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>4</span>
                                        <h1 className="title">WEB DEVELOPMENT​</h1>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>5</span>
                                        <h1 className="title">DEVELOPERS / TEAMS</h1>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>6</span>
                                        <h1 className="title">TRAININGS SESSIONS​</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkPart;
