import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import careers6thsection from '../../assets/images/webnstack/careers-6th-section.png';

function Features() {
    return (
        <section
            className="appie-features-area-2 appie-features-area-5 pt-60 crer-pg-pdd-top-30"
            id="features"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                Fostering Diversity, Equity, and Inclusion: Our Core Value
                            </h3>
                            <p>
                                At WebNStack, we steadfastly uphold a commitment to fostering a
                                workplace that embraces diversity, equity, and inclusion. We
                                recognize that a diverse workforce is not only an asset but a
                                genuine differentiator in driving innovation and achieving optimal
                                business outcomes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 pb-100">
                        <div className="appie-features-boxes">
                            <h4 className="title mb-10">
                                Our Approach: Providing Fair Opportunities
                            </h4>
                            <p>
                                WebNStack is dedicated to providing our team members with a fair
                                opportunity to reach their full potential within an inclusive
                                environment. We value each individual, ensuring they are treated
                                with respect and equality. By embracing diversity, we harness the
                                essential components that lead to heightened creativity, increased
                                productivity, and innovative solutions.
                            </p>
                            <h4 className="title pt-15 mb-10">
                                Equal Employment Opportunity and Affirmative Action: Our Pledge
                            </h4>
                            <p>
                                WebNStack proudly stands as an Equal Employment Opportunity and an
                                Affirmative Action Employer. We vehemently oppose discrimination and
                                are unwavering in our commitment to creating an environment that
                                celebrates differences. We do not tolerate any form of
                                discrimination, whether based on race, religion, sex, color, age,
                                national origin, pregnancy, sexual orientation, physical ability, or
                                any other characteristic.
                            </p>
                            <h4 className="title pt-15 mb-10">
                                Guiding Principles in Employment Decisions
                            </h4>
                            <p>
                                In every facet of employment, from the hiring process to separation,
                                we adhere to the highest standards. Our decisions are grounded in
                                business requirements, candidate merit, qualifications, and strict
                                compliance with local laws. This ensures a workplace where diversity
                                thrives, equality prevails, and every team member is empowered to
                                contribute their best to our collective success.
                                <br />
                                Join us at WebNStack, where our commitment to diversity, equity, and
                                inclusion shapes a vibrant and thriving professional community.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={careers6thsection} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape13} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape15} alt="" />
            </div>
        </section>
    );
}

export default Features;
