import React from 'react';
import aboutThumb4 from '../../assets/images/webnstack/2nd-section-image-1.png';
import aboutThumb5 from '../../assets/images/webnstack/2nd-section-image-2.png';
import aboutThumb6 from '../../assets/images/webnstack/2nd-section-image-3.png';
import aboutThumb7 from '../../assets/images/webnstack/2nd-section-image-4.png';
import aboutThumb8 from '../../assets/images/webnstack/2nd-section-image-5.png';
import aboutThumb9 from '../../assets/images/webnstack/2nd-section-image-6.png';

function About() {
    return (
        <section className="appie-about-8-area pt-100 pb-100 something">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title mb-30">
                            <h1 className="appie-title Create">
                                THE BENEFITS OF HEADLESS ECOMMERCE PLATFORMS
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-about-12-box">
                            <h1 className="title Countless-B2C" style={{ fontSize: '30px' }}>
                                Countless B2C / B2B Connections
                            </h1>
                            <p>
                                A headless ecommerce platform provides a strong assurance that
                                multiple business concepts and webpages may be built on top of the
                                same online marketplace. One backbone serves as the hub for all of
                                your digital platforms.
                            </p>

                            <div className="thumb">
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="appie-about-8-box">
                            <h1 className="title">Highly User Friendly</h1>
                            <p>
                                Software for headless commerce fully satisfies the current demand
                                for a successful omnichannel user experience with multi-device
                                access. Utilizing headless commerce software allows for complete
                                flexibility.
                            </p>
                            <div className="thumb mr-30">
                                <img src={aboutThumb5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-about-10-box mt-5 mb-5 Market">
                            <h1
                                className="title  Countless-B2C Quicker"
                                style={{ fontSize: '30px' }}
                            >
                                Quicker Time to Market
                            </h1>
                            <p>
                                A business must give something a try in order to judge whether it is
                                a better approach to do e-commerce. With the least amount of
                                expense, work, and risk, you may use a headless platform to
                                establish a new e-commerce channel and test your company concept in
                                new markets, target audiences, and niches.
                            </p>

                            <div className="thumb">
                                <img src={aboutThumb6} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="appie-about-8-box mt-60 Cross">
                            <h1 className="title">Cross-Channel Consistency</h1>
                            <p>
                                With a headless approach, content and functionality can be delivered
                                consistently across various channels, ensuring a seamless experience
                                for customers whether they are on a website, mobile app, or
                                other touchpoints.
                            </p>
                            <div className="thumb mr-30">
                                <img src={aboutThumb7} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-about-12-box">
                            <h1 className="title Countless-B2C" style={{ fontSize: '30px' }}>
                                Include New Channels
                            </h1>
                            <p>
                                Different market categories, such as geographic areas, target
                                markets, or brand customers, call for different platforms. A
                                headless commerce system enables quick and easy channel development
                                and modification to satisfy customer demands.
                            </p>

                            <div className="thumb pt-15 customers">
                                <img src={aboutThumb8} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="appie-about-8-box">
                            <h1 className="title">Search Engine Friendly</h1>
                            <p>
                                Website speed, which is important for ranking in search engine
                                results, is improved by the separation of frontend and backend
                                activities. Utilizing headless commerce software allows for complete
                                flexibility.
                            </p>
                            <div className="thumb mr-30">
                                <img src={aboutThumb9} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
