import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import SharedTestimonial from '../Shared/SharedTestimonial';
import About from './About';
import AboutTwo from './AboutTwo';
import Download from './Download';
import Faq from './Faq';
import FeaturesTwo from './FeaturesTwo';
import Hero from './Hero';
import Projects from './Projects';
import Services from './Services';
import ServicesTwo from './ServicesTwo';
import Sponser from './Sponser';

function HireEcommerceDeveloper() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Hire Ecommerce Developer | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader action={drawerAction.toggle} />
            <Hero />
            <ServicesTwo />
            <AboutTwo />
            <Services />
            <About />
            <Projects />
            <FeaturesTwo />
            <Faq />
            <Download />
            <SharedTestimonial />
            <Sponser />
            <SharedFooter />
            <BackToTop className="back-to-top-2" />
        </>
    );
}

export default HireEcommerceDeveloper;
