import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterWithNewsletter from '../Shared/SharedFooterWithNewsletter';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import Features from './Features';
import Hero from './Hero';
import Services from './Services';
import ServicesFive from './ServicesFive';
import ServicesFour from './ServicesFour';
import ServicesThree from './ServicesThree';
import ServicesTwo from './ServicesTwo';
import Sponser from './Sponser';

function Careers() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Careers | IT Consulting &amp; eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <Hero />
            <Services />
            <ServicesThree />
            <ServicesFour />
            <ServicesFive />
            <Features />
            <ServicesTwo />
            <Sponser />
            <SharedFooterWithNewsletter />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default Careers;
