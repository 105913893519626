import React from 'react';
import thumb from '../../assets/images/shopify/5th-section-image.png';

function Traffic({ className }) {
    return (
        <section className={`appie-traffic-area pt-100 pb-60 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="appie-traffic-title specific">
                            <div className="span-color">SNEAK-A-PEEK</div>
                            <h1 className="title">SHOPIFY POINT OF SALE WEBSITE DEVELOPERS</h1>
                            <p>
                                With Shopify POS software, you can personalize your retail checkout.
                                For a completely integrated sales environment, integrate a retail
                                Point of Sale (POS) system with any online e-commerce system. With a
                                custom API interface, our development team can get your POS data
                                where it needs to go. Please let us know what you require. Our
                                developers have worked on retail websites before and can help you
                                grow your in-store solutions with specific features customized to
                                your company, such as:
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="row">
                                    <div className="col-6 SHOPIFY">
                                        <ul className="liste-image">
                                            <li>Product personalization</li>
                                            <li>Gift registry services</li>
                                            <li>Inventory and stock synchronization</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 SHOPIFY">
                                        <ul className="liste-image">
                                            <li>Donation programs</li>
                                            <li>Customer loyalty programs</li>
                                            <li>In-store shipping services</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default Traffic;
