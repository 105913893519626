import React from 'react';
import workThumb from '../../assets/images/hirewebdeveloper/hire-web-developer-11th-section.png';

function WorkPart() {
    return (
        <div className="appie-how-it-work-area pt-100 pb-100 hire-web-developer-features-section-padding">
            <div className="container">
                <h2 className="appie-title text-center pb-50 hire-web-developer-appie-title-size">
                    Why Choose WebNStack For Development
                </h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="how-it-work-box border p-3">
                            <h1 className="title mb-10 font-weight-500 font-size25px">
                                Affordably Priced
                            </h1>
                            <p>
                                In our quest to be an affordable development company, we stand out
                                with budget-friendly solutions. Our friendly team ensures that you
                                don't need to look any further for cost-effective development
                                services. We prioritize offering the ideal solutions that fit your
                                budgetary requirements without compromising on quality.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="how-it-work-box border p-3">
                            <h1 className="title mb-10 font-weight-500 font-size25px">
                                Customer Fulfillment
                            </h1>
                            <p>
                                Finding developers with cutting-edge skills can be a challenge, but
                                with our extensive network of skilled professionals, we make it
                                easier for you. Our developers not only possess advanced skills but
                                are also dedicated to helping you advance your projects. Your
                                satisfaction is our priority, and our team is committed to ensuring
                                a seamless development journey.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="how-it-work-thumb text-center">
                            <img src={workThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="appie-how-it-work-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="how-it-work-box border p-3">
                                        <h1 className="title font-weight-500  font-size25px">
                                            Timetable Flexibility
                                        </h1>
                                        <p>
                                            Collaborating closely with our clients, we tailor our
                                            development process to meet the demands and requirements
                                            of your project. This flexibility ensures that we not
                                            only provide top-notch services but also do so within
                                            the reasonable timeframes and schedules.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="how-it-work-box border p-3">
                                        <h1 className="title font-weight-500 font-size25px">
                                            Years of Experience ​
                                        </h1>
                                        <p>
                                            With years of experience under our belt, you can trust
                                            that our developers bring a wealth of expertise to the
                                            table. Drawing on a rich background in various
                                            technologies and industries, our team is well-equipped
                                            to provide you with reliable and effective solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="how-it-work-box border p-3">
                            <h1 className="title mb-10 font-weight-500 font-size25px">
                                Dedicated Teams ​ ​
                            </h1>
                            <p>
                                At WebNStack, our developers stand out as the best in their field.
                                Having successfully delivered high-end business solutions on a
                                variety of frameworks, our dedicated teams are adept at working
                                swiftly to align with your project objectives. Whether it's web
                                development, mobile apps, or other tech solutions, our teams are
                                equipped to meet your needs.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="how-it-work-box border p-3">
                            <h1 className="title mb-10 font-weight-500 font-size25px">
                                Support & Service ​ ​
                            </h1>
                            <p>
                                Our commitment to your project doesn't end with its development.
                                From the initial stages to the launch and beyond, our skilled
                                developers provide unwavering support. We go the extra mile by
                                offering free technical assistance for three months after your
                                website goes live on the development server, ensuring a smooth and
                                hassle-free experience for you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkPart;
