import React from 'react';
import aboutThumb from '../../../assets/images/dealon2nd-section.png';
import aboutThumb3 from '../../../assets/images/dealon3rd-section.png';

function About() {
    return (
        <section className="appie-about-3-area pt-200 pb-100 mob-p-0" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The DealOn project poses a challenge as it embarks on the journey of
                                Magento upgrade, web optimization, and ongoing maintenance for both
                                the website and the connected Magento application. The need for a
                                seamless transition to a newer Magento version, ensuring
                                compatibility with the latest technologies and security standards.
                            </p>
                            &nbsp;
                            <p>
                                Web optimization presents an additional layer of complexity,
                                demanding a thorough analysis and enhancement of the website's
                                performance, speed, and overall responsiveness. This involves
                                fine-tuning various elements, such as images, scripts, and server
                                configurations, to deliver an optimized user experience. Striking
                                the right balance between visual appeal and efficient functionality
                                is crucial to meet the expectations of DealOn's user base.
                            </p>
                            &nbsp;
                            <p>
                                The maintenance aspect of the project introduces an ongoing
                                challenge, requiring a proactive approach to identify and address
                                potential issues promptly. This involves monitoring website and
                                mobile application performance, ensuring data integrity, and
                                implementing routine updates to maintain security and stability. The
                                challenge lies in establishing a robust maintenance framework that
                                minimizes downtime, optimizes resource utilization, and provides a
                                seamless experience for both users and administrators.
                            </p>
                            &nbsp;
                            <p>
                                DealOn's success in this project hinges on the ability to overcome
                                these challenges, ensuring a smooth transition, an optimized web
                                presence, and sustained functionality for its users.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                </div>
                <div className="row align-items-center mt-100 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                Our team successfully executed a comprehensive Magento upgrade,
                                ensuring a seamless transition to a newer version while guaranteeing
                                compatibility with the latest technologies and security standards.
                                This involved resolving numerous theme deprecations, overcoming
                                obstacles to maintain a cohesive and modern web presence.
                            </p>
                            &nbsp;
                            <p>
                                Simultaneously, we undertook the task of web optimization,
                                conducting a thorough analysis to enhance the website's performance,
                                speed, and overall responsiveness. Fine-tuning elements such as
                                images, scripts, and server configurations, we achieved an optimized
                                user experience from grade D to grade A, striking the delicate
                                balance between visual appeal and efficient functionality.
                            </p>
                            &nbsp;
                            <p>
                                To address challenges in the connected mobile application, we
                                provided robust solutions, ensuring that the app aligns seamlessly
                                with the upgraded Magento version.
                            </p>
                            &nbsp;
                            <p>
                                The ongoing maintenance challenge was met with a proactive approach,
                                monitoring both the website and mobile application performance,
                                implementing routine updates, and addressing potential issues
                                promptly. Our team established a robust maintenance framework,
                                minimizing downtime, optimizing resource utilization, and delivering
                                a seamless experience for both users and administrators.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
