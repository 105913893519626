import React from 'react';
import heroThumb from '../../assets/images/hero-thumb-7.png';

function HeroHomeSix() {
    return (
        <section className="appie-hero-area appie-hero-6-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-hero-thumb-6">
                            <div
                                className="thumb wow animated fadeInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="600ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-hero-content appie-hero-content-6">
                            <span>ONE PLATFORM. ENDLESS POSSIBILITIES.</span>
                            <h1 className="appie-title">Magento Development.</h1>
                            <p>
                                WebNStack is a leading Magento development company that offers you
                                end-to-end Magento Solutions. We offer magento development services
                                ranging from custom development to thome development to Magento 1 to
                                Magento 2 upgrading services as a Magento development servee
                                pronder. in the event that wants guidance, We also provide Magento
                                consultancy. We believe in customing and enhancing your Magent
                                e-commerce website in order to help you grow your business online.
                            </p>
                            <ul>
                                <li>
                                    <a className="item-2" href="/contacts">
                                        <span>Reach Us</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroHomeSix;
