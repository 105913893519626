import React from 'react';
import team1 from '../../assets/images/webnstack/ceo/Bhawana-Dhanwaria.jpg';

function SharedTeamAbout() {
    return (
        <section
            className="appie-team-area appie-team-about-area mb-100 mobile-development-margin-bottom"
            style={{ background: '#ffffff' }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-cente">
                            <h3 className="appie-title mobile-development-text-align">
                                Our Leadership
                            </h3>
                            <p className="font-size16px-p">
                                The WebNStack team is passionate about the work we do, committed to
                                our culture and process, and intelligent about our approach through
                                custom eCommerce development, product design, QA and consultancy
                                services.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="appie-team-item appie-team-item-about appie-team-background">
                            <div className="thumb image-padding">
                                <img src={team1} alt="" />
                            </div>
                            <div className="row name-ceo co-founder">
                                <div className="col-md-12">
                                    <h6 className="name-color">Bhawana Dhanwaria</h6>
                                    <p>CO-Founder & CEO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mr-20">
                            <p className="pt-60 pb-20 crer-pg-pdd-top-30">
                                Welcome to WebNStack IT Solutions, where brilliance meets
                                innovation! Our team is more than just a group of IT professionals;
                                we are a dynamic collective of infinite talents who have carved our
                                niche in the ever-evolving world of eCommerce.
                            </p>
                            <p className="pb-20">
                                At WebNStack, we take pride in our distinctiveness and
                                effectiveness. Each member of our team brings a unique perspective
                                to the table, inspiring one another to make decisions that stand
                                out. Together, we are a fusion of smart ideas and lucrative
                                solutions, constantly challenging our own limitations and paving the
                                way for a future ahead of its time.
                            </p>
                            <p>
                                Our culture thrives on continuous learning and cutting-edge
                                technology. We embrace innovation that surpasses the boundaries of
                                advanced tech, contributing to a better world with a touch of
                                sophistication. Join us on this journey as we redefine the standards
                                in the IT industry, always staying a step ahead and infusing a
                                classy touch of excellence into everything we do.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SharedTeamAbout;
