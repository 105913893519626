import React from 'react';
import rkbuilderssection4 from '../../../assets/images/webnstack/rk-builders-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-20 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={rkbuilderssection4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">RESULTS & PROCESS</h1>
                            <p>
                                The successful launch of the RKBuilders website was a well-timed
                                achievement, garnering positive feedback from both the client and
                                investors. To achieve this, we are actively engaged in continued
                                WordPress development, focusing on areas such as better
                                optimization, lead generation, and increased conversion rates.
                            </p>
                            <p>
                                Preserving RKBuilders' custom vision and expectations was a priority
                                throughout the development process, ensuring that the website aligns
                                seamlessly with their unique requirements.
                            </p>
                            <p>
                                {' '}
                                In response to the client's expectations, our dedicated team crafted
                                a website that precisely reflects RKBuilders' vision. Understanding
                                the need for better optimization, we implemented strategies to
                                enhance the website's overall performance, ensuring a seamless user
                                experience and improved search engine visibility.
                            </p>
                            <p>
                                {' '}
                                In addition to optimization efforts, our team designed a modern and
                                robust website that serves as a showcase for RKBuilders' prior
                                projects. The website not only highlights the company's past
                                successes but also effectively communicates the range of services
                                offered to potential consumers.
                            </p>
                            <p>
                                As we continue our partnership in WordPress development, our shared
                                goal is to propel RKBuilders to new heights by maximizing lead
                                generation and improving conversion rates. Through collaborative
                                efforts and a commitment to delivering tailored solutions, we look
                                forward to contributing to RKBuilders' continued success in the
                                digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
