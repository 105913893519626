import React from 'react';
import mobileDevelopmentService from '../../assets/images/hirewebdeveloper/hire-web-developer-5th-section.png';

function ServicesTwo() {
    return (
        <section
            className="appie-services-2-area appie-services-8-area pt-90 pb-50 hire-web-developer-padding-top-bottom"
            id="service"
        >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                We Assist You in Achieving Your business Goals
                            </h1>
                            <p>
                                WebNStack’s developers are experts who understand, advise, execute,
                                and provide what is required.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 pt-100">
                        <h1 className="hire-web-developer-h1-size">WHAT WE CAN DO FOR YOU</h1>
                        <p>
                            {' '}
                            Moreover, our dedication to continuous improvement drives us to stay
                            updated with the latest industry trends and emerging technologies,
                            ensuring that our clients receive cutting-edge solutions. With a
                            customer-centric approach, we go beyond meeting expectations to exceed
                            them, building lasting relationships based on trust and quality.
                        </p>
                        <p className="pt-20 hire-features-two-padding">
                            {' '}
                            In addition, our collaborative development process involves regular
                            checkpoints and client feedback loops, fostering an environment of
                            transparency and shared vision. This iterative approach ensures that the
                            final deliverables not only meet but often surpass the initial project
                            objectives. At WebNStack, your project's success is not just a goal;
                            it's a commitment we uphold in every aspect of our service delivery.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="service-thumb hire-web-services">
                            <img src={mobileDevelopmentService} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesTwo;
