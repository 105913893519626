import React from 'react';
import { Link } from 'react-router-dom';

function ServicesThree({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-60 crer-pg-pdd-btm-30  ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-8">
                        <div className="appie-section-title">
                            <h3 className="appie-title pt-60 crer-pg-mg-top">More About Us </h3>
                            <p>Join our team to craft excellence, delivering seamless service.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-users icon-size-30"></i>
                            </div>

                            <Link to="/about-us">
                                <h4 className="title">About Us</h4>
                            </Link>
                            <p className="text-justify">
                                We the powerhouse of efficiency, driven by a humble dedication to
                                serve you seamlessly.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-address-card icon-size-30"></i>
                            </div>
                            <Link to="/team">
                                <h4 className="title">Team Members</h4>{' '}
                            </Link>

                            <p className="text-justify">
                                Together, we strive for excellence, turning challenges into
                                opportunities marked by innovation and skill.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesThree;
