import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import AboutUsTwo from './components/AboutUs/AboutUsTwo';
import Angular from './components/Angular';
import Blog from './components/Blog';
import Careers from './components/Careers';
import Contact from './components/Contact';
import EcommerceDevelopment from './components/EcommerceDevelopment';
import pageNotFound from './components/Error';
import HeadlessCommerce from './components/HeadlessCommerce';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Hire from './components/Hire';
import HireEcommerceDeveloper from './components/HireEcommerceDeveloper';
import HireWebDeveloper from './components/HireWebDeveloper';
import Home from './components/Home';
import HomeDark from './components/HomeDark';
import HomeEight from './components/HomeEight';
import HomeFive from './components/HomeFive';
import HomeFour from './components/HomeFour';
import HomeOne from './components/HomeOne';
import HomeRtl from './components/HomeRtl';
import HomeSeven from './components/HomeSeven';
import HomeSix from './components/HomeSix';
import HomeThree from './components/HomeThree';
import Hometwo from './components/HomeTwo';
import ItServices from './components/ItServices';
import MagentoDevelopment from './components/MagentoDevelopment';
import MagentoMigration from './components/MagentoMigration';
import MobileDevelopment from './components/MobileDevelopment';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import OurProjects from './components/OurProjects';
import OurProjectsAlmonaplast from './components/OurProjects/Almonaplast';
import OurProjectsAssl from './components/OurProjects/Assl';
import OurProjectsCloudearly from './components/OurProjects/Cloudearly';
import OurProjectsDealon from './components/OurProjects/Dealon';
import OurProjectsDeelert from './components/OurProjects/Deelert';
import OurProjectsDeliverzero from './components/OurProjects/Deliverzero';
import OurProjectsElTrivia from './components/OurProjects/ElTrivia';
import OurProjectsFragranceshop from './components/OurProjects/Fragranceshop';
import OurProjectsGlowbympi from './components/OurProjects/Glowbympi';
import OurProjectsGosmarteyewear from './components/OurProjects/Gosmarteyewear';
import OurProjectsHrpayroll from './components/OurProjects/Hrpayroll';
import OurProjectsKakzon from './components/OurProjects/Kakzon';
import OurProjectsKenwoodKitchen from './components/OurProjects/KenwoodKitchen';
import OurProjectsLivelinen from './components/OurProjects/Livelinen';
import OurProjectsMediaworldegypt from './components/OurProjects/Mediaworldegypt';
import OurProjectsRkbuilders from './components/OurProjects/Rkbuilders';
import OurProjectsSandiabio from './components/OurProjects/Sandiabio';
import OurProjectsSporthuis from './components/OurProjects/Sporthuis';
import OurProjectsToyfiesta from './components/OurProjects/Toyfiesta';
import Portfolio from './components/Portfolio';
import Privacy from './components/Privacy';
import ReactJs from './components/ReactJs';
import Service from './components/Service';
import ShopifyDevelopment from './components/ShopifyDevelopment';
import Shops from './components/Shops';
import ShopDetails from './components/Shops/Details';
import Team from './components/Team';
import TermsAndConditions from './components/TermsAndConditions';
import WebDevelopment from './components/WebDevelopment';
import WordPress from './components/WordPress';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                                exact
                                path="/magento-development"
                                component={MagentoDevelopment}
                            />
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/mobile-development" component={MobileDevelopment} />
                            <Route
                                exact
                                path="/ecommerce-development"
                                component={EcommerceDevelopment}
                            />
                            <Route exact path="/web-development" component={WebDevelopment} />
                            <Route
                                exact
                                path="/shopify-development"
                                component={ShopifyDevelopment}
                            />
                            <Route exact path="/our-projects" component={Portfolio} />
                            <Route exact path="/magento-migration" component={MagentoMigration} />
                            <Route exact path="/headless-commerce" component={HeadlessCommerce} />
                            <Route exact path="/wordpress" component={WordPress} />
                            <Route exact path="/react-js" component={ReactJs} />
                            <Route exact path="/angular" component={Angular} />
                            <Route exact path="/it-services" component={ItServices} />
                            <Route exact path="/privacy" component={Privacy} />
                            <Route
                                exact
                                path="/terms-and-conditions"
                                component={TermsAndConditions}
                            />
                            <Route exact path="/hire" component={Hire} />
                            <Route
                                exact
                                path="/hire-ecommerce-developer"
                                component={HireEcommerceDeveloper}
                            />
                            <Route exact path="/hire-web-developer" component={HireWebDeveloper} />
                            <Route exact path="/blog" component={Blog} />
                            <Route exact path="/portfolio" component={OurProjects} />
                            <Route
                                exact
                                path="/our-projects/rkbuilders"
                                component={OurProjectsRkbuilders}
                            />
                            <Route
                                exact
                                path="/our-projects/deelert"
                                component={OurProjectsDeelert}
                            />
                            <Route
                                exact
                                path="/our-projects/sandiabio"
                                component={OurProjectsSandiabio}
                            />
                            <Route
                                exact
                                path="/our-projects/hrpayroll"
                                component={OurProjectsHrpayroll}
                            />
                            <Route
                                exact
                                path="/our-projects/almonaplast"
                                component={OurProjectsAlmonaplast}
                            />
                            <Route
                                exact
                                path="/our-projects/sporthuis"
                                component={OurProjectsSporthuis}
                            />
                            <Route
                                exact
                                path="/our-projects/cloudearly"
                                component={OurProjectsCloudearly}
                            />
                            <Route
                                exact
                                path="/our-projects/kakzon"
                                component={OurProjectsKakzon}
                            />
                            <Route
                                exact
                                path="/our-projects/toyfiesta"
                                component={OurProjectsToyfiesta}
                            />
                            <Route
                                exact
                                path="/our-projects/livelinen"
                                component={OurProjectsLivelinen}
                            />
                            <Route
                                exact
                                path="/our-projects/gosmarteyewear"
                                component={OurProjectsGosmarteyewear}
                            />
                            <Route
                                exact
                                path="/our-projects/fragranceshop"
                                component={OurProjectsFragranceshop}
                            />
                            <Route
                                exact
                                path="/our-projects/deliverzero"
                                component={OurProjectsDeliverzero}
                            />
                            <Route exact path="/our-projects/assl" component={OurProjectsAssl} />
                            <Route
                                exact
                                path="/our-projects/dealon"
                                component={OurProjectsDealon}
                            />
                            <Route
                                exact
                                path="/our-projects/glowbympi"
                                component={OurProjectsGlowbympi}
                            />
                            <Route
                                exact
                                path="/our-projects/eltrivia"
                                component={OurProjectsElTrivia}
                            />
                            <Route
                                exact
                                path="/our-projects/kenwoodkitchen"
                                component={OurProjectsKenwoodKitchen}
                            />
                            <Route
                                exact
                                path="/our-projects/mediaworldegypt"
                                component={OurProjectsMediaworldegypt}
                            />
                            <Route exact path="/careers" component={Careers} />
                            <Route exact path="/team" component={Team} />

                            <Route exact path="/home-one" component={HomeOne} />
                            <Route exact path="/home-two" component={Hometwo} />
                            <Route exact path="/home-three" component={HomeThree} />
                            <Route exact path="/home-four" component={HomeFour} />
                            <Route exact path="/home-five" component={HomeFive} />
                            <Route exact path="/home-six" component={HomeSix} />
                            <Route exact path="/home-seven" component={HomeSeven} />
                            <Route exact path="/home-eight" component={HomeEight} />
                            <Route exact path="/home-dark" component={HomeDark} />
                            <Route exact path="/home-rtl" component={HomeRtl} />
                            <Route exact path="/news" component={News} />
                            <Route exact path="/news/single-news" component={SingleNews} />
                            <Route exact path="/service" component={Service} />
                            <Route exact path="/contacts" component={Contact} />
                            <Route exact path="/page-not-found" component={pageNotFound} />
                            <Route exact path="/about-us-another" component={AboutUsTwo} />
                            <Route exact path="/shops" component={Shops} />
                            <Route exact path="/shops/shop-details" component={ShopDetails} />
                            <Route component={pageNotFound} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
