import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import magentomigrationsection1st from '../../assets/images/webnstack/magento-migration-1st-section-image.png';
import PopupVideo from '../PopupVideo';

function Hero() {
    const [showVideo, setVideoValue] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className="appie-hero-area appie-hero-3-area padding-top-hero-magento-migration MAGENTO-1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-hero-content text-center">
                                <h1 className="appie-title Magento">
                                    MIGRATE YOUR STORE FROM MAGENTO 1 TO MAGENTO 2
                                </h1>
                                <p className="Magento-p">
                                    Magento will no longer support Magento 1 after June 2020, which
                                    is also when e-commerce enterprises are rushing to migrate their
                                    electronic goods from Magento 1 to Magento 2. However, not all
                                    of them are aware of the conversion procedures or the
                                    considerations to make during the process in order to avoid
                                    taking unnecessary risks. So, how do you convert from Magento 1
                                    to Magento 2?
                                </p>
                                <div className="hero-btns">
                                    <Link className="main-btn magento-hero" to="/contacts">
                                        Reach Us
                                    </Link>
                                </div>
                                <div
                                    className="thumb mt-100 wow animated fadeInUp our-schedules"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <img src={magentomigrationsection1st} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
