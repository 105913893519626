import React, { useState } from 'react';
import headless from '../../assets/images/webnstack/headless-7th-section.png';
import PopupVideo from '../PopupVideo';

function Faq() {
    const [showQuestion, setQuestion] = useState(0);
    const [showVideo, setVideoValue] = useState(false);
    const openQuestion = (e, value) => {
        e.preventDefault();
        setQuestion(value);
    };
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <div className="appie-faq-8-area pt-100 pb-100 customers" id="counter">
                <div className="container">
                    <div className="appie-section-title">
                        <h1 className="appie-title Expertise">
                            Unlocking Infinite Possibilities with Headless Ecommerce Solutions
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="appie-section-title">
                                <p className="font-size16">
                                    Explore the Future of Ecommerce Development through our Headless
                                    Architecture FAQs. Learn how Decoupling Frontend and Backend
                                    Transforms User Experiences and Boosts Business Agility.
                                </p>
                            </div>
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp wow fadeIn faq-accrodion"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 0 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 0)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h1 className="font-size--20px font-size16">
                                                    What is headless ecommerce, and how does it
                                                    differ from traditional
                                                    <br /> Ecommerce architecture?
                                                </h1>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 0 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Headless ecommerce refers to the separation
                                                        of the frontend and backend of an ecommerce
                                                        platform. In traditional architecture, the
                                                        frontend and backend are tightly integrated,
                                                        but with headless, they operate
                                                        independently. This allows for more
                                                        flexibility and scalability in design and
                                                        functionality.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 1 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 1)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h1 className="font-size--20px font-size16">
                                                    What are the key advantages of adopting <br />a
                                                    headless ecommerce approach for <br />
                                                    website development?
                                                </h1>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Headless architecture offers several
                                                        benefits, including enhanced flexibility in
                                                        design and user experience, faster
                                                        development cycles, and the ability to
                                                        easily adapt to emerging technologies. It
                                                        allows businesses to create unique and
                                                        personalized customer experiences while
                                                        optimizing performance and scalability.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 2 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 2)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h1 className="font-size--20px font-size16">
                                                    How does headless ecommerce impact omnichannel
                                                    experiences for <br />
                                                    customers?
                                                </h1>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Headless architecture empowers businesses to
                                                        deliver seamless omnichannel experiences by
                                                        decoupling the frontend from the backend.
                                                        This means that content and functionality
                                                        can be easily adapted and delivered across
                                                        various channels, such as web, mobile, and
                                                        IoT devices. This flexibility ensures a
                                                        consistent and engaging experience for
                                                        customers regardless of the device or
                                                        platform they are using.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="faq-play-boxs position col-lg-6">
                            <img src={headless} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faq;
