import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function FooterHomeEight() {
    return (
        <section className="appie-footer-area appie-footer-about-area appie-footer-8-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-about-widget">
                            <div className="logo">
                                <a href="#">
                                    <img src={logo} alt="" />
                                </a>
                            </div>
                            <p>
                                Professional IT Solution for your company. Elevate Your Digital
                                Future with Precision in Code and Innovation in Solutions.
                            </p>
                            <div className="social mt-30">
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/webnstack_/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.twitter.com/webnstack_/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/webnstack_/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/webnstack_/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-linkedin-in" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <div className="footer-navigation">
                            <h4 className="title">ABOUT US</h4>
                            <ul>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/Service">Team</Link>
                                </li>
                                <li>
                                    <a href="/our-projects">Our Projects</a>
                                </li>
                                <li>
                                    <Link to="/careers">Careers</Link>
                                </li>
                                <li>
                                    <Link to="/contacts">Contacts</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-navigation">
                            <h4 className="title">SERVICES</h4>
                            <ul>
                                <li>
                                    <a href="/services/web-development">Web Development</a>
                                </li>
                                <li>
                                    <a href="/services/mobile-development">Mobile Development</a>
                                </li>
                                <li>
                                    <a href="/services/magento-development">Magento Development</a>
                                </li>
                                <li>
                                    <a href="/services/magento-migration">Magento Migration</a>
                                </li>
                                <li>
                                    <a href="/services/shopify-development">Shopify Development</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget-info">
                            <h4 className="title">Get In Touch</h4>
                            <ul>
                                <li>
                                    <a href="#">
                                        <i className="fal fa-envelope" /> info@webnstack.com
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fal fa-phone" /> +91 89055-99339
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fal fa-map-marker-alt" />
                                        1st Floor, 206-207, Heera Nagar-A Dhawas Puliya, Service
                                        Lane, 200 Feet Bypass Rd, Jaipur, Rajasthan, India
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                        >
                            <div className="apps-download-btn">
                                <ul>
                                    <li>
                                        <a className="item-2" href="/privacy">
                                            PRIVACY{' '}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="/terms-and-conditions">
                                            TERMS & CONDITIONS{' '}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="/blog">
                                            BLOG{' '}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-text">
                                <p>Copyright © 2023 – by WebNStack. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterHomeEight;
