import React from 'react';
import aboutThumb from '../../../assets/images/kakzon-2nd-section-image.png';
import aboutThumb3 from '../../../assets/images/kakzon-3rd-section-image.png';
import aboutThumb4 from '../../../assets/images/kakzon-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-200  pb-30" id="features">
            <div className="container">
                <div className="row align-items-center pt-50">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The Customer had ecommerce marketplace website built on Magento. The
                                Customer had an ecommerce marketplace website built on Magento, and
                                recognizing the need for modernization, they sought a
                                Magento-competent development team to update them to the latest
                                Magento 2 version.
                            </p>
                            <p>
                                {' '}
                                Addressing various aspects, our team not only focused on updating
                                the platform but also worked on fixing their website design and
                                responsiveness, ensuring an enhanced user experience across devices.
                            </p>
                            <p>
                                {' '}
                                The company also questioned the cost and scalability of their
                                existing setup, leading to discussions around hosting development
                                for optimized performance and scalability.
                            </p>
                            <p>
                                Viewing the upgrade as an opportunity for improvement, the Customer
                                aimed to introduce website optimization measures.
                            </p>

                            <p>
                                {' '}
                                With a keen eye on increasing sales in the publicly available
                                e-store, the Customer decided to refresh its digital user experience
                                and visual design.{' '}
                            </p>
                            <p>
                                This approach aligned with their broader goal of not just updating
                                the technical infrastructure but also creating a visually appealing
                                and customer-centric online shopping environment.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                Following the successful upgrade, the Webnstack team transformed the
                                website into a Magento multi-marketplace platform.
                            </p>
                            <p>
                                {' '}
                                As part of our comprehensive approach, we updated JavaScript
                                libraries to meet the latest development requirements and
                                implemented Git for streamlined project tracking in alignment with
                                client specifications.
                            </p>
                            <p>
                                Recognizing the customer's engagement with multiple vendors, our
                                team integrated essential marketplace functionalities and modules,
                                including Ced csMarketplace, Ced csProduct, Coderkube DProducts, and
                                Codercube Multivendor.
                            </p>
                            <p>
                                {' '}
                                This transformation not only addressed major design issues but also
                                provided a robust framework for vendors to operate seamlessly within
                                the marketplace.
                            </p>
                            <p>
                                {' '}
                                Firstly, we successfully implemented configurable products for the
                                D-script, allowing for simultaneous updates of quantity and pricing.
                                Another critical aspect of our intervention was the resolution of
                                product swatch issues.
                            </p>
                            <p>
                                {' '}
                                By providing customers with a fast and effortless way to manage
                                products through effective and accurate filters and navigation
                                options, we significantly improved the overall user experience.
                            </p>
                            <p>
                                {' '}
                                This optimization aimed to facilitate convenient product exploration
                                and selection, aligning with the client's objective of improving
                                customer satisfaction.
                            </p>
                            <p>
                                {' '}
                                Webnstack's post-upgrade interventions not only transformed the
                                website into a Magento multi-marketplace platform but also addressed
                                specific challenges related to design, functionality, and user
                                experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h2 className="title">RESULTS & PROCESS</h2>
                                <p>
                                    The Kakzon e-stores underwent a successful upgrade to Magento
                                    2.4.4, seamlessly transitioning into a multistore environment.
                                </p>
                                <p>
                                    {' '}
                                    The entire process was meticulously executed through Git,
                                    ensuring effective version control and project tracking.
                                </p>
                                <p>
                                    {' '}
                                    Importantly, all custom functionality that characterized
                                    Kakzon's unique digital presence was preserved, and the
                                    performance of the websites remained uninterrupted.{' '}
                                </p>
                                <p>
                                    This upgrade marked a significant milestone in Kakzon's digital
                                    journey, contributing to substantial sales growth.
                                </p>
                                <p>
                                    {' '}
                                    The revamped digital user experience played a pivotal role in
                                    achieving this success.
                                </p>
                                <p>
                                    Kakzon's commitment to offering the best quality apparel and
                                    accessories for Gym, bodybuilding, physique, Fitness, and Yoga
                                    was reflected in the upgraded digital platform.
                                </p>
                                <p>
                                    {' '}
                                    The refined website structure, coupled with improved navigation
                                    and responsiveness, ensured that customers could easily explore
                                    and access the diverse range of products offered by Kakzon.
                                </p>
                                <p>
                                    The success of this upgrade is indicative of the strategic and
                                    holistic approach taken by Kakzon in leveraging digital
                                    solutions to enhance customer experience and drive sales.
                                </p>
                                <p>
                                    {' '}
                                    The alignment of technology with the specific needs of the
                                    fitness and wellness market underscores Kakzon's dedication to
                                    providing customers with the highest quality products through an
                                    optimized and user-friendly online platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
