import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import magentoImage from '../../assets/images/webnstack/magento-development.png';

function FeaturesTwo() {
    return (
        <section className="appie-features-area-2 appie-features-area-5" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">Integrations with Magento</h1>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-12">
                        <div className="appie-features-boxes ">
                            <div className="appie-features-box-item appie-features-box-5-item">
                                <h1 className="title">User's Side Additions</h1>
                                <p className="font-size16px-p">
                                    Magento 2 is about more than just the seller platform it’s also
                                    about the needs of the users. Users, as well as website owners
                                    and administrators, can benefit from the upgrades. As a result,
                                    Magento 2 provides a far more secure and upgraded payment
                                    gateway to ensure that payments are completed correctly. A
                                    better checkout procedure has been implemented as a result of
                                    this. Improved search expands the capabilities to help buyers
                                    locate their items more quickly. If your target audience is
                                    mobile browsers, Magento 2 has taken care of it as well.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 ">
                    <div className="col-lg-6">
                        <div className="appie-features-box-item item-3 appie-features-box-5-item">
                            <h1 className="title">Performance</h1>
                            <p className="font-size16px-p">
                                Magento 2 is a fantastic platform with considerably superior
                                performance metrics than its predecessor. Magento 2 can now handle
                                up to 39% more orders and add-to-cart speeds are 66 percent quicker.
                                Not only that, but additional features such as Ajax cart, caching,
                                PHP7, and hosting environment enhancements all assist to boost
                                performance even further.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={magentoImage} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row mt-30 ">
                    <div className="col-lg-12">
                        <div className="appie-features-box-item item-2 appie-features-box-5-item">
                            <h1 className="title">Admin Enhancements</h1>
                            <p className="font-size16px-p">
                                Your time is money, and it is worth everything. Magento 2 developers
                                are aware of this, and as a result, it provides additional benefits
                                to website admins. Product creation has been improved, allowing you
                                to speed up the process by four times when compared to Magento 1.
                                Improved data viewing and filtering ensures you locate goods faster
                                and creates a clutter-free experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape13} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape15} alt="" />
            </div>
        </section>
    );
}

export default FeaturesTwo;
