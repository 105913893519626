import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import videoSlideThree from '../../assets/images/video-slide-three.jpg';
import videoSlideTwo from '../../assets/images/video-slide-two.jpg';
import videoThumb from '../../assets/images/webnstack/homepage-porfolio-section.png';
import videoSlideOne from '../../assets/images/webnstack/video-slide-three.png';

function VideoPlayer({ className }) {
    const sliderRef = useRef();
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section className={`appie-video-player-area pb-60 Glimpse ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="appie-video-player-item">
                            <div className="thumb">
                                <img src={videoThumb} alt="" />
                            </div>
                            <div className="content">
                                <h1 className="title">Glimpse of Our Portfolio</h1>
                                <p>
                                    We’ve flourished our experience across various industries to
                                    bring valuable insights and provide our customers with the
                                    newest market trends.
                                </p>
                                <a
                                    className="main-btn"
                                    href="/our-projects"
                                    aria-label=" Our Portfolio"
                                >
                                    Our Portfolio
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="appie-video-player-slider" style={{ position: 'relative' }}>
                            <span
                                onClick={sliderPrev}
                                className="prev slick-arrow home-page-display"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-left" />
                            </span>
                            <Slider ref={sliderRef} {...settings}>
                                <div className="item">
                                    <img src={videoSlideOne} alt="" />
                                </div>
                                <div className="item">
                                    <img src={videoSlideTwo} alt="" />
                                </div>
                                <div className="item">
                                    <img src={videoSlideThree} alt="" />
                                </div>
                            </Slider>
                            <span
                                onClick={sliderNext}
                                className="next slick-arrow home-page-display"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default VideoPlayer;
