import React from 'react';
import { Link } from 'react-router-dom';
import counterIconFour from '../../assets/images/webnstack/eCommerce-development.png';
import counterIconOne from '../../assets/images/webnstack/headless-commerce.png';
import counterIconThree from '../../assets/images/webnstack/mobile-development.png';
import counterIconTwo from '../../assets/images/webnstack/web-development.png';

function CounterArea({ style }) {
    return (
        <section
            className="appie-counter-area pt-90 pb-200 mob-padding-top-bottom"
            id="counter"
            style={style}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title hire-web-developer-appie-title-size home-page-text-align">
                                An agency is a business that provides specific Services
                            </h1>
                            <p className="font-size14px-p">
                                In less than 24 hours, you may outsource your next service in a few
                                simple steps.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-20">
                        <div
                            className="appie-single-counter mt-30 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <Link to="/headless-commerce">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconOne} alt="" />
                                    </div>
                                    <p
                                        className="para"
                                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                                    >
                                        {' '}
                                        Headless Commerce
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-20">
                        <div
                            className="appie-single-counter mt-30 item-2 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <Link to="/web-development">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconTwo} alt="" />
                                    </div>
                                    <p
                                        className="para"
                                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                                    >
                                        Web Development
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-20">
                        <div
                            className="appie-single-counter mt-30 item-3 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <Link to="/mobile-development">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconThree} alt="" />
                                    </div>
                                    <p
                                        className="para"
                                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                                    >
                                        Mobile Development
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-20 home-page-ecommerce-margin">
                        <div
                            className="appie-single-counter mt-30 item-4 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <Link to="/ecommerce-development">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src={counterIconFour} alt="" />
                                    </div>
                                    <p
                                        className="para"
                                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                                    >
                                        eCommerce Development
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterArea;
