import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import shapeSix from '../../assets/images/shape/shape-6.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';

function Features({ className }) {
    const [tab, setTab] = useState('setting');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (
        <section className={`appie-features-area pt-100 sec-gap-30 ${className}`} id="features ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <p className="font-size-16px">
                                The company WebNStack IT Solution Pvt Ltd,<strong> “we,”</strong> or
                                <strong> “us,” </strong>values the privacy of all of its clients,
                                business partners, event participants, job candidates, and visitors
                                to its website (as described below).
                            </p>
                            <p className="font-size-16px">
                                The Privacy Statement that follows outlines who we are, how we may
                                collect, use, and disclose personal information about you, as well
                                as your privacy rights. To make sure you are completely informed,
                                please take the time to read this privacy statement and any linked
                                declarations in its entirety. Please select the appropriate link
                                from the list below to view a certain policy. Please take note that
                                we could give you further details about our privacy practises if
                                they apply specifically to one of our websites, events, goods, or
                                services.
                            </p>
                            <p className="font-size-16px">
                                Please get in touch with us using the information provided at the
                                bottom of this privacy statement if you have any queries or concerns
                                about how we are using your personal information.
                            </p>
                            <p className="font-size-16px">
                                <strong>DISCLAIMER OF PRIVACY.</strong> Our privacy practises are
                                outlined in our privacy statement. They relate to the gathering,
                                use, and disclosure of information obtained (i) through the
                                WebNStack websites that link to this privacy statement (our
                                “Website”) and (ii) through marketing and outreach initiatives, such
                                as but not limited to surveys, competitions, contests, promotions,
                                conferences, and events hosted by WebNStack or our business partners
                                and sponsors where we gather information from attendees
                                (collectively, “Events”).
                            </p>
                            <p className="font-size-16px">
                                <strong> POLICY ON COOKIES.</strong> When you visit our websites or
                                use our goods, services, or mobile applications, we may use cookies
                                and other tracking technologies.
                            </p>
                            <p className="font-size-16px">
                                <strong> PRIVACY STATEMENT FOR SERVICES.</strong> The use of our
                                hosted software applications and associated support services, as
                                well as expert services including professional services, trainings,
                                and certifications that we offer to clients, are all covered by our
                                services privacy statement. This Services Privacy Statement also
                                outlines our privacy standards with regard to the contact
                                information of customers and partners that we handle in connection
                                with their use of our goods and services, as well as any products,
                                services, or technology that we jointly offer.
                            </p>
                            <p className="font-size-16px">
                                <strong> PRIVACY STATEMENT OF APPLICANT.</strong> Our privacy
                                policies in relation to a person’s application for employment at
                                WebNStack are outlined in our Applicant Privacy Statement.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center-padding-bottom-60 sec-gap-30">
                    <div className="col-lg-3">
                        <div className="appie-features-tabs-btn">
                            <h4 className="appie-title mb-10 font-size20px"> TABLE OF CONTENTS </h4>

                            <div
                                className="nav flx-dir-col flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <Link
                                    onClick={(e) => handleClick(e, 'setting')}
                                    className={`nav-link pl-0 ${tab === 'setting' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    to="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    Purpose
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'report')}
                                    className={`nav-link pl-0 ${tab === 'report' ? 'active' : ''}`}
                                    id="v-pills-profile-tab"
                                    data-toggle="pill"
                                    to="#v-pills-profile"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    About us
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'notice')}
                                    className={`nav-link pl-0 ${tab === 'notice' ? 'active' : ''}`}
                                    id="v-pills-messages-tab"
                                    data-toggle="pill"
                                    to="#v-pills-messages"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    Scope
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'app')}
                                    className={`nav-link pl-0 ${tab === 'app' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    to="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    Where We Get Our Information
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'apps')}
                                    className={`nav-link pl-0 ${tab === 'apps' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    to="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    How We Make Use of the Data Collected
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'AMP')}
                                    className={`nav-link pl-0 ${tab === 'AMP' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    to="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    How We Circulate Collected Information
                                </Link>

                                <Link
                                    onClick={(e) => handleClick(e, 'Legal')}
                                    className={`nav-link pl-0 ${tab === 'Legal' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    to="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    Legal Basis for Processing
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'Security')}
                                    className={`nav-link pl-0 ${
                                        tab === 'Security' ? 'active' : ''
                                    }`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    to="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    Security and discretion
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'Retention')}
                                    className={`nav-link pl-0 ${
                                        tab === 'Retention' ? 'active' : ''
                                    }`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    to="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    Retention
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'Privacy')}
                                    className={`nav-link pl-0 ${tab === 'Privacy' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    to="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    Your Rights to Privacy
                                </Link>
                                <Link
                                    onClick={(e) => handleClick(e, 'General')}
                                    className={`nav-link pl-0 ${tab === 'General' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    to="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    General
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 mt-30">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={`${
                                    tab === 'setting' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Purpose</h3>
                                            <p className="font-size16px-p">
                                                The users and guests of WebNStack IT Solution Pvt
                                                Ltd’s Website (as defined below) and Meetings (as
                                                defined below), (collectively, “
                                                <strong> WebNStack</strong>,” “<strong> we</strong>
                                                ,” or “<strong> us</strong>“), respect the privacy
                                                of those individuals. This privacy statement (the
                                                “Privacy Statement”) provides information about who
                                                we are, the data we gather about you, how we get it,
                                                and the potential uses and disclosures we may make
                                                of such data in connection with the Website and
                                                Events. This privacy statement also outlines the
                                                security precautions we take to safeguard your
                                                personal data and how to get in touch with us with
                                                questions or concerns about how we handle your
                                                information or to exercise your privacy rights
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'report' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">About Us</h3>
                                            <p className="font-size16px-p">
                                                People’s dreams are made better with{' '}
                                                <strong> WebNStack</strong>. Our services and
                                                cloud-based platform streamline and simplify how
                                                work is done. <strong> WebNStack</strong> creates
                                                eCommerce solutions and develops smart, affordable,
                                                and user-friendly online applications, cloud
                                                platforms, and mobile apps that help organisations
                                                streamline operations and provide new revenue
                                                streams for both start-ups and existing enterprises.
                                                People can perform their best job with the
                                                assistance of our solutions and the unique ones
                                                created by our clients and partners. Our company’s
                                                headquarters is in Jaipur, RJ, India. Please visit
                                                our website’s About Us section for detailed
                                                information about <strong> WebNStack</strong>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'notice' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-messages"
                                role="tabpanel"
                                aria-labelledby="v-pills-messages-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Scope</h3>
                                            <p className="font-size16px-p">
                                                The <strong> WebNStack </strong> website, which
                                                links to this privacy statement, and marketing and
                                                outreach initiatives, such as but not limited to
                                                surveys, contests, competitions, promotions,
                                                conferences, and events, where we or our business
                                                partners collect information from registrants and
                                                guests (“<strong> Events</strong>“), are the only
                                                two instances in which we collect, use, and disclose
                                                information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'app' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-settings"
                                role="tabpanel"
                                aria-labelledby="v-pills-settings-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Where We Get Our Information</h3>
                                            <p className="pb-2 font-size16px-p">
                                                As further outlined below, we obtain various types
                                                of information from and about you that, in general,
                                                fall under the following headings: identifiers,
                                                professional or employment-related information,
                                                financial account information, commercial
                                                information, education information, visual
                                                information, and information about internet
                                                activity, among others.
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                <strong>
                                                    1. Data gathered from personal information
                                                </strong>
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                Among the information you voluntarily give to us are
                                                your contact information provided when you register
                                                on our website, contact us, report a problem with
                                                our website, sign up to use a developer or demo
                                                instance of our cloud service, sign up for an event,
                                                or fill out any forms on our website. When you
                                                submit content to our website, take part in polls or
                                                competitions, post on bulletin boards, chat rooms,
                                                blogs, comment threads, forums, or other interactive
                                                features of our website, register, or ask for more
                                                information or services from us, we may receive
                                                comments, feedback, and related information from
                                                you.Your voice, picture, face, or any other
                                                information you might send us about how you use our
                                                website or how you participate in an event
                                                virtually.
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                You may also provide us with other information,
                                                including your billing details.
                                            </p>
                                            <p className="pb-2">
                                                <strong>
                                                    2. Data gathered from third parties.
                                                </strong>
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                In relation to your use of the Website or attendance
                                                at an Event, we may occasionally collect and utilise
                                                information we receive about you from third parties.
                                                For instance, we could get details about you from
                                                our business partners concerning your Event
                                                registration data. To assess the success of our
                                                website and marketing initiatives and to pinpoint
                                                areas for development, we also engage third parties
                                                for reporting and analytics.
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                To update, expand, or give you personalised
                                                information about our goods, services, and Events,
                                                we may combine information you supply with
                                                information we receive from third parties.
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                <strong>
                                                    3. Data that we gather as you browse the
                                                    website.
                                                </strong>
                                            </p>
                                            <p className="pb-2 font-size16px-p">
                                                We may also automatically gather information about
                                                your visits to the Website as you browse through it,
                                                including your IP address, usage patterns, traffic
                                                data, location data, logs and other communication
                                                data, and the resources you access. We may also
                                                gather details about your computer and internet
                                                connection, such as the operating system and browser
                                                you use, as well as information about your computer
                                                and connection. We also use cookies and other
                                                tracking technologies to collect some of this data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'apps' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">
                                                How We Make Use of the Data Collected
                                            </h3>
                                            <div>
                                                We could use the data we have collected about you
                                                for the following things:
                                                <br />
                                                1. To run, maintain, and enhance the website.
                                                <br />
                                                2. To create profiles for you in our databases and
                                                register you for Events you register for with us.
                                                <br />
                                                3. We fulfil your requests for information, goods,
                                                or services from us.
                                                <br />
                                                4. To fulfil our commitments and uphold our rights
                                                under any agreements we have with you, including
                                                without limitation our Website Terms of Use.
                                                <br />
                                                5. To get any necessary consent or to inform you of
                                                any changes to our website.
                                                <br />
                                                6. To provide you the option to take part in our
                                                website’s interactive features whenever you wish to.
                                                <br />
                                                7. For market research, analytics, marketing,
                                                benchmarking, and other commercial needs.
                                                <br />
                                                8. To monitor your online activity for analytics and
                                                marketing reasons, such as the pages you have
                                                visited over time.
                                                <br />
                                                9. To get in touch with you regarding events.
                                                <br />
                                                10. In accordance with your marketing choices, to
                                                contact you for marketing-related objectives (for
                                                example, we may use your information to contact you
                                                to further discuss your interests in our services
                                                and products or other Events). If you request that
                                                we contact you regarding services, we may use your
                                                personal information or authorise certain third
                                                parties to contact you using that information. You
                                                have the option to change your mind at any moment by
                                                using the “”<strong> unsubscribe</strong>“” link in
                                                the emails we send you. See the section below on
                                                “”Your Privacy Rights”” for additional details.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'AMP' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">
                                                How We Circulate Collected Information
                                            </h3>
                                            <div>
                                                We could provide the following groups of recipients
                                                access to personal information you give us:
                                                <br />
                                                1. To the service providers we employ to assist our
                                                company or who work on our behalf, including our
                                                contractors, business partners, and service
                                                providers. Your information will be governed by the
                                                privacy policy of the specific business partner or
                                                sponsor when you share your information with them.
                                                <br />
                                                2. To any competent law enforcement body, regulatory
                                                body, government agency, court, or other third party
                                                to: I comply with any court order, a request from
                                                any competent law enforcement agency, or any other
                                                legal obligation; (ii) enforce or apply the terms of
                                                any other agreement, including the terms of our
                                                website’s terms of use; and (iii) protect the
                                                rights, property, or safety of ServiceNow or others.
                                                <br />
                                                3. To anybody else with your permission to disclose
                                                the information. We don’t share your personal
                                                information with third parties for their marketing
                                                campaigns through sales, rentals, or trades.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'Legal' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Legal Basis for Processing </h3>
                                            <div>
                                                When we need your personal information to carry out
                                                a contract with you, when we have your consent to do
                                                so or you volunteer it, when we need your personal
                                                information to run our business and grow new
                                                business, when we need your personal information to
                                                keep our records up-to-date, or when the processing
                                                is necessary for one of our legitimate business
                                                interests and is not conflicted by your interest in
                                                data protection. In rare circumstances, we may also
                                                be required by law to get personal data from you.
                                                Please get in touch with us via contact details
                                                provided in the “”Contact Information” section below
                                                if you have inquiries or need more details on the
                                                legal justification for which we gather and use your
                                                personal data.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Security' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Security and discretion </h3>
                                            <div>
                                                In order to prevent accidental or illegal
                                                destruction, loss, modification, unauthorised
                                                disclosure, and access to the personal information
                                                collected through the Website and in connection with
                                                Meetings, WebNStack maintains the necessary
                                                technological and organisational safeguards.
                                                Personnel from WebNStack and service providers who
                                                have access to personal data obtained from website
                                                visitors or event participants are obligated to
                                                maintain it secure and secret.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Retention' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Retention </h3>
                                            <div>
                                                Unless a longer retention term is mandated by law,
                                                we will keep your personal information as long as it
                                                is required to accomplish the objectives listed in
                                                this privacy statement or as long as we have a
                                                business need to do so (for example, to comply with
                                                applicable legal, tax or accounting
                                                requirements).When we have no ongoing legitimate
                                                business need to process your personal information,
                                                we will either delete or anonymize it.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Privacy' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">Your Rights to Privacy</h3>
                                            <div>
                                                Regarding the information you give us, we give you a
                                                few options. more specifically Send us an email at
                                                <strong> support@webnstack.com</strong> if you’d
                                                like to view, amend, or request the deletion of any
                                                personal information we collect, use, or distribute.
                                                You can also get in touch with us at any moment to
                                                revoke your permission if we process your personal
                                                information in reliance on it. By selecting the
                                                “”unsubscribe”” or “”opt out”” link in the marketing
                                                emails we send you, you can choose not to receive
                                                our email communications for marketing-related
                                                purposes. Use the contact information shown under
                                                “”Contact Information”” to get in touch with us if
                                                you want to opt out of receiving other types of
                                                marketing (like telemarketing). If you are unhappy
                                                with how we have collected and used your personal
                                                information, you have the right to file a complaint
                                                with a data protection authority. Please contactYou
                                                can also get in to{' '}
                                                <strong> support@webnstack.com </strong> for
                                                additional details. For exercising these rights, you
                                                have the right not to face discrimination. In
                                                compliance with the requirements of any relevant
                                                data protection legislation, we shall reply to such
                                                requests. Please be aware that we may need specific
                                                information from you to confirm your identity in
                                                order to fulfil your request.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'General' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">General</h3>
                                            <p className="pb-2">
                                                <strong>Do Not Track</strong>
                                            </p>
                                            <p className="pb-2">
                                                While WebNStack makes every effort to abide by do
                                                not track (“”DNT””) requests sent by a visitor’s
                                                browser, we cannot promise that WebNStack will
                                                always heed these requests, in part due to the
                                                absence of a widely accepted industry standard for
                                                DNT technology. We continue to keep an eye on
                                                advancements in DNT technology and keep up with
                                                changing DNT industry standards.
                                            </p>
                                            <p className="pb-2">
                                                <strong>
                                                    Third Party Websites and Applications
                                                </strong>
                                            </p>
                                            <p className="pb-2">
                                                There may be links on this page to websites that
                                                WebNStack neither owns nor controls. Therefore, the
                                                information gathered by any third-party site or
                                                application that may connect to or be accessed from
                                                the Website is not covered by this privacy
                                                statement. Even if this information is gathered
                                                through our website or during events, this privacy
                                                statement does not apply to information gathered by
                                                clients, partners, and other third parties, or by
                                                third-party apps or services.
                                            </p>
                                            <p className="pb-2">
                                                <strong>Public Forums and Blogs</strong>
                                            </p>
                                            <p className="pb-2">
                                                Please be aware that whatever you write or reveal in
                                                a public forum will become public knowledge and may
                                                be seen by other users and members of the public.
                                                When selecting to share your personal information or
                                                any other information in our public forums, we
                                                strongly advise you to use extreme caution. Our
                                                Website Terms of Use regulate the appropriate access
                                                and use of the information given on the Website,
                                                including contributions to our public forums. Known
                                                or suspected violations should be notified to our
                                                Privacy team through email at
                                                <strong>
                                                    <a href="support@webnstack.com">
                                                        support@webnstack.com
                                                    </a>
                                                </strong>
                                            </p>
                                            <p className="pb-2">
                                                <strong>Children</strong>
                                            </p>
                                            <p className="pb-2">
                                                Anyone under the age of 16 is not intentionally
                                                subject to our collection of personal information.
                                                Please do not attempt to complete our forms or email
                                                any personal information about yourself to us if you
                                                are under the age of 16. If we become aware that we
                                                have unlawfully obtained personal information from a
                                                child under 16, we shall take steps to immediately
                                                destroy such information.
                                            </p>
                                            <p className="pb-2">
                                                <strong>Contact Information</strong>
                                            </p>
                                            <p className="pb-2">
                                                To ask questions or comment about this Privacy
                                                Statement and our privacy practices or if you need
                                                to update, change or remove your information or
                                                exercise any other rights, contact us at
                                                <strong>
                                                    <a href="support@webnstack.com">
                                                        support@webnstack.com
                                                    </a>
                                                </strong>
                                            </p>
                                            <p className="pb-2">or</p>
                                            <p className="pb-2">
                                                <strong>WebNstack IT Solution Pvt. Ltd.</strong>
                                                <br />
                                                1st Floor, 206-207, Heera Nagar-A, Service Lane,
                                                <br />
                                                200 Feet Bypass Rd, Jaipur 302021
                                                <br />
                                                Rajasthan,
                                                <br />
                                                India
                                            </p>
                                            <p className="pb-2">
                                                All other feedback, comments, requests for technical
                                                support, and other communications relating to the
                                                Website should be directed to
                                                <strong>
                                                    <a href="info@webnstack.com">
                                                        info@webnstack.com
                                                    </a>
                                                </strong>
                                                <br />
                                                E‑mail:
                                                <strong>
                                                    <a href="mailto:sales@webnstack.com">
                                                        sales@webnstack.com
                                                    </a>
                                                </strong>
                                                <br />
                                                <strong>
                                                    <a href="mailto:webnstack@gmail.com">
                                                        webnstack@gmail.com
                                                    </a>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shapeSix} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shapeSeven} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shapeEight} alt="" />
            </div>
        </section>
    );
}

export default Features;
