import React from 'react';
import eltriviaone from '../../../assets/images/webnstack/el-trivia1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area eltrivia-sec-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-kakzon-size">
                                Embark on Egyptian Trivia Adventure with El Trivia
                            </h1>
                            <p>
                                El Trivia by DigifyMENA offers a delightful and free gaming
                                experience, inviting players to engage in friendly challenges with
                                friends on Facebook or compete against random players within the
                                game. Test your knowledge with intriguing trivia questions centered
                                around Egyptian culture, providing an enjoyable and educational
                                experience. El Trivia is designed for everyone, as long as you're
                                cool and have a good grasp of Egyptian culture. Immerse yourself in
                                the fun with lively characters like Afashat, El Mosta7eel, Meshakel,
                                El La3eeb, El Negm, and Sha3by.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={eltriviaone} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
