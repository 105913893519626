import React from 'react';
import workThumb from '../../assets/images/how-it-work-thumb.png';

function WorkPart() {
    return (
        <div className="appie-how-it-work-area pt-50 pb-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="how-it-work-thumb text-center">
                            <img src={workThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-how-it-work-content">
                            <h2 className="title">Unleashing Technological Excellence</h2>
                            <p>Redefining standards with collaborative genuity.</p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>1</span>
                                        <h1 className="title"> Innovative Solutions</h1>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>2</span>
                                        <h2 className="title">Collaborative Excellence</h2>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>3</span>
                                        <h2 className="title">Continuous Learning Culture</h2>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="how-it-work-box">
                                        <span>4</span>
                                        <h2 className="title">Agile Development Framework​</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkPart;
