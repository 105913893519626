import React from 'react';

function ServicesTwo({ className }) {
    return (
        <section className={`appie-services-2-area PROCESS pb-60${className}`} id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-8">
                        <div className="appie-section-title">
                            <h1 className="appie-title SOLUTIONS">
                                DYNAMIC DREAMZ MAGENTO DEVELOPMENT PROCESS
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-chart-area icon-size-30"></i>
                            </div>

                            <h1 className="title">Idea & Analysis</h1>
                            <p className="text-justify">
                                We talk about your idea, determine the target audience and the
                                goals, and then collaboratively build a comprehensive development
                                strategy tailored to your vision and needs.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user icon-size-30"></i>
                            </div>
                            <h1 className="title">User Interface</h1>
                            <p className="text-justify">
                                We provide you with comprehensive and visually appealing web designs
                                that are especially suited to the company you are starting, with an
                                attention to detail and creativity.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-3 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-bug icon-size-30"></i>
                            </div>
                            <h1 className="title">Quality Analysis</h1>
                            <p>
                                In order to achieve perfection, automated programmes and real
                                devices were used to test the constructed Magento website for flaws
                                and problems.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-code icon-size-30"></i>
                            </div>
                            <h1 className="title">Integration & Development</h1>
                            <p className="text-justify">
                                We will be free to implement the code for a realistic overview of
                                the project when we are at the stage of Magento integration and
                                writing all the difficult code.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="far fa-paper-plane icon-size-30"></i>
                            </div>
                            <h1 className="title">Approval on Delivery</h1>
                            <p className="text-justify">
                                You will receive the final, optimised Magento website to provide
                                comments. After receiving your approval, we'll make it live for your
                                clients.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-universal-access icon-size-30"></i>
                            </div>
                            <h1 className="title">Services and Upkeep</h1>
                            <p className="text-justify">
                                After your website has launched, we will go above and beyond to keep
                                you satisfied, whether it involves regular updates, optimizations,
                                or other improvements to ensure success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesTwo;
