import React from 'react';
import { Link } from 'react-router-dom';

function Services({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-100 mob-padding-top home-page-margin0 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-8">
                        <div className="appie-section-title">
                            <h1 className="appie-title">MAGENTO ECOMMERCE SOLUTIONS WE SERVE</h1>
                            <p className="font-size16px-p">
                                Empowering your e-commerce dreams with tailored magento solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                        <div className="appie-section-title text-right font-size16px-p">
                            <Link className="main-btn" to="/ecommerce-development">
                                Ecommerce Development <i className="fal fa-arrow-right" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fa fa-user-tie icon-size-30" />
                            </div>

                            <h1 className="title">Architecture Advisory</h1>
                            <p className="text-justify">
                                Establishing the right ecommerce architecture can support overall
                                business strategy. It’s important to continuously reevaluate your
                                eCommerce architecture to verify it reflects changes to your
                                business strategy, emerging consumer trends, and advancements in
                                technology.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fa fa-credit-card icon-size-30" />
                            </div>
                            <h1 className="title">Integration Solutions</h1>
                            <p className="text-justify">
                                WebNStack attempt hard to integrate third party services, like
                                Finance Systems, CMS, Payment Gateway, PIM, and more. This leads to
                                reduce workloads. Integration is the coordination between a
                                business’ eCommerce website and their back-end systemsk.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-3 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fal fa-link icon-size-30"></i>
                            </div>
                            <h1 className="title">Cloud Solutions</h1>
                            <p className="text-justify">
                                Establishing the right ecommerce architecture can support overall
                                business strategy. It’s important to continuously reevaluate your
                                eCommerce architecture to verify it reflects changes to your
                                business strategy, emerging consumer trends, and advancements in
                                technology.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fa fa-dice-d20 icon-size-30" />
                            </div>
                            <h1 className="title">ERP Solutions</h1>
                            <p className="text-justify">
                                Optimise your eCommerce Supply Chain Management by providing you
                                with numerous options for eCommerce channel integration. The
                                seamless integration of your eCommerce system with a modern,
                                cloud-based ERP solution.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fal fa-sync-alt icon-size-30"></i>
                            </div>
                            <h1 className="title">B2B/B2C Marketplace</h1>
                            <p className="text-justify">
                                Everyone need more than a B2B/B2C strategy. A flexible and
                                intelligent solution we like to call Business to Everyone (B2E) to
                                seize opportunities with up-and-coming channels and business models
                                to meet the high demands of your buyers.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-3 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fal fa-cloud icon-size-30"></i>
                            </div>
                            <h1 className="title">CRM Integration</h1>
                            <p className="text-justify">
                                From initial consultation and system analysis to implementation and
                                ongoing support, our comprehensive services aim to optimize your CRM
                                functionality, improve data accuracy, and ultimately drive better
                                business outcomes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
