import React from 'react';
import almonaImage from '../../assets/images/aboutus/almona.png';
import cloudearlyImage from '../../assets/images/aboutus/cloudearly.png';
import deliverImage from '../../assets/images/aboutus/deliver.zero.png';
import fragranceImage from '../../assets/images/aboutus/fragrance.png';
import kakzonImage from '../../assets/images/aboutus/kakzon.png';
import livelinenImage from '../../assets/images/aboutus/livelinen.png';
import smarteyewearImage from '../../assets/images/aboutus/smarteyewear.png';
import gogreenImage from '../../assets/images/aboutus/to.go.green.png';
import toyfiestaImage from '../../assets/images/aboutus/toyfiesta.png';
import sponserShape from '../../assets/images/sponser-shape.png';
import Sponser11 from '../../assets/images/webnstack/sporthuisone.png';

function WorkingPlatforms({ className }) {
    return (
        <section className={`appie-sponser-area pb-100 Jim-Bradford ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                Pinnacle of Innovation - Our Project Showcase
                            </h1>
                            <p className="font-size16">
                                A glimpse into excellence, unraveling our diverse array of IT
                                triumphs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-sponser-box d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={deliverImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={gogreenImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={cloudearlyImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={kakzonImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={smarteyewearImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={toyfiestaImage} alt="" />
                            </div>
                        </div>
                        <div className="appie-sponser-box item-2 d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={livelinenImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={fragranceImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={almonaImage} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser11} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sponser-shape">
                <img src={sponserShape} alt="" />
            </div>
        </section>
    );
}

export default WorkingPlatforms;
