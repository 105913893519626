import React from 'react';
import heroThumb from '../../../assets/images/1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-almona-top-section almonaplast-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h3 className="appie-title mob-myt-size">
                                Almona's Tailored Presence in Plastic Pipe Manufacturing
                            </h3>
                            <p>
                                Almona is a leading Saudi Arabian plastic pipe manufacturing
                                Company. This Company was founded in 2008, which provide a
                                sophisticated and diverse pipe solutions for hot and cold-water
                                applications, telecommunication networks, sewage and drainage
                                systems together with water and gas infrastructure. Almona’s success
                                is the result of the company’s persistent commitment to continuous
                                innovation and investment in technology, in the relentless pursuit
                                of providing quality products and services. Almona’s vision is to
                                continue improving and innovating their products and systems, and to
                                develop emerging technologies in the fields of building,
                                infrastructure, sewage & drainage, and telecommunications
                                applications.{' '}
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
