import React from 'react';

import { Link } from 'react-router-dom';

import shape from '../../assets/images/shape/shape-10.png';
import WebdevelopmentSection1 from '../../assets/images/webnstack/web-development-1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-4-area padding-top-hero-web-development INDUSTRY">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="appie-hero-content appie-hero-content-4 INDUSTRY-FOCUSED">
                            <span className="font-size16">IMPRESSIVE. OPTIMISED. SOLUTIONS.​</span>
                            <h1 className="appie-title">INDUSTRY-FOCUSED DEVELOPMENT​</h1>
                            <p className="font-size16">
                                Develop a web application tailored to your industry needs. To help
                                your digital product succeed, we commit to comprehensively research
                                your market and build an innovative solution that’s customized to
                                your business, speaks your values and targets the right audience.
                                Using top-performing technologies, we’ve built over 350 products
                                from all over the world and across different industries.
                            </p>
                            <Link className="main-btn" to="/contacts">
                                Get In Touch
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="appie-hero-thumb different appie-hero-thumb-4">
                            <img src={WebdevelopmentSection1} alt="" />
                            <div className="hero-dot"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-shape-1">
                <img src={shape} alt="" />
            </div>
        </section>
    );
}

export default Hero;
