import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-service-area pt-50 pb-50 wordpress-padding-top ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title title font-size20-important">
                                WordPress Development Services
                            </h1>
                            <p className="Magento-p">
                                We handle all of your WordPress development needs, whether you want
                                to start a blog or want to construct a feature-rich website to
                                market your business. As a top WordPress development company, we
                                have completed more than 100 WordPress projects while working with
                                some of the most well-known businesses in the market. To make sure
                                that even the slightest of your requirements is addressed, our
                                WordPress developers adhere to a customer-centric policy. To build a
                                highly functioning and responsive website in the allotted period, we
                                adhere to best SEO strategies and optimisation procedures.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-wordpress-simple icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">WordPress Migration</h1>
                            <p className="text-justify">
                                It’s no longer difficult to switch to an effective approach. With
                                the support of our expert WordPress migration services, you may
                                migrate your current website from platforms like Drupal, Joomla,
                                Squarespace, etc. to WordPress.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-cogs icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Third-party Integrations</h1>
                            <p className="text-justify">
                                When our developers integrate highly-functional APIs with the
                                WordPress CMS, it becomes the finest of its type. This expansion
                                enhances the functionality of your website, providing seamless data
                                and mobile app syncing experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-chart-pie icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Woo commerce Development</h1>
                            <p className="text-justify">
                                Using WordPress to build your online store will advance your
                                company. Your company can manage inventory and sales while making
                                strategic business decisions thanks to our practical eCommerce
                                website solutions.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="far fa-edit icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Theme Conversion</h1>
                            <p className="text-justify">
                                Our WordPress theme conversion services are adapted to your
                                requirements using a responsive, mobile-first approach. Our
                                professionals use the enhanced WordPress features to bring to your
                                PSD designs to life.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-vote-yea icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Tailored Functionality</h1>
                            <p className="text-justify">
                                We create bespoke WordPress websites that are small and operate well
                                in accordance with your business requirements. It generates more
                                leads because to its increased functionality and improved user
                                experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-code-branch icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">WordPress Plugins</h1>
                            <p className="text-justify">
                                Our expert team makes use of WordPress’ capabilities to create
                                incredible plugins that greatly improve your website. We provide
                                plugins that are extremely scalable, simple to use, and compatible
                                with different site versions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
