import React from 'react';
import rkbulders from '../../../assets/images/webnstack/rk-bulders-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-almona-top-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                One-stop solution for interior, constructions, and design.
                            </h1>
                            <p>
                                RK Builders is the best construction organization in Jaipur, who
                                providing the lowest construction costs without sacrificing quality
                                or material. They provide the various facilities independently, such
                                as materials, electricity, wood work, stone work, brick work, colour
                                work, and POP work, before assembling all of the facilities. RK
                                Builders has built many successful homes for people and give to the
                                middle class home owner features. The Management Team of RK Builders
                                with a Specialization in Construction and Infrastructure
                                Development, has vast experience overseeing and implementing
                                Infrastructure and Real Estate projects. RK Builders uses
                                best-in-class value and cost engineering to deliver projects and
                                exists to benefit everyone it meets.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={rkbulders} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
