import React from 'react';

function ServicesHomeThree() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-0 pb-100 we-serve-padding-bottom"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center pt-60 Industries-padding-top">
                            <h1 className="appie-title font-size20-important">
                                Industries We Serve
                            </h1>
                            <p className="font-size-16px">
                                According to your requirements, WebNStack IT Solutions can create
                                one of the best websites that can be customised across all
                                industries. We serve as the foundation for a number of start-up and
                                enterprise-level businesses. By attending to all of your demands and
                                offering you more secure and agile PHP development solutions that
                                adhere to the most recent trends, we assist you in launching or
                                expanding your company market.We guarantee complete customer
                                satisfaction, which is one of the main reasons why our business
                                partner has faith in us..
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className=" fa fa-solid fa-dumpster icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">E-commerce</h1>
                            <p className="text-justify">
                                Experience the ease of shopping right at your fingertips, where
                                online and mobile shopping programs simplify the process of
                                purchasing goods.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className=" fa fa-solid fa-wallet icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Finance</h1>
                            <p className="text-justify">
                                Optimize the transaction process by offering banking services to
                                your consumers and clients on smartphones through our seamless
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fa fa-solid fa-chalkboard icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">E-learning</h1>
                            <p className="text-justify">
                                Enable consumer effective communication between educators and
                                students with our tailored applications for e-learning, fostering an
                                interactive learning.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="far fa-luggage-cart icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Travel</h1>
                            <p className="text-justify">
                                Our dedication lies in crafting sustainable and top-tier Quality
                                solutions, ensuring a transformative and high quality experience in
                                the travel industry.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-utensils-alt icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Food</h1>
                            <p className="text-justify">
                                From doorstep delivery to enhancing your dining experience, we
                                provide solutions that make food delivery and dining effortless.
                                Embark on your delivery journey with us.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-heartbeat icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Fitness</h1>
                            <p>
                                Empower your patients with virtual therapies and medication through
                                our mobile healthcare software, enhancing fluidity and efficiency in
                                healthcare processes.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-building icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Actual Estate</h1>
                            <p className="text-justify">
                                Embark on a futuristic shopping to users and service experience with
                                our online web and mobile applications, redefining the real estate
                                landscape for our clients.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-shopping-cart icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Market</h1>
                            <p className="text-justify">
                                Experience the power of fully automated marketing media solutions
                                that not only increase sales leads but also contribute to expanding
                                your consumer base on social media effortlessly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeThree;
