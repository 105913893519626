import React from 'react';
import heroThumb from '../../assets/images/webnstack/mobdev.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-6-area pb-0 pt-5 MOBILE-APP-DEVELOPMENT">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-hero-thumb-6">
                            <div
                                className="thumb wow animated fadeInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="600ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-hero-content appie-hero-content-6">
                            <span>WHAT WE OFFER</span>
                            <h1 className="appie-title appie-title-6-section">
                                SERVICES FOR MOBILE APP DEVELOPMENT
                            </h1>
                            <p className="font-size16px-p">
                                Custom iOS and Android applications expertly built for every sector
                                to provide an unmatched user experience and achieve your business
                                goals. All of the widely used mobile platforms work well with our
                                scalable mobility solutions. Our team pays great attention to all
                                contemporary screen sizes and platform specifications while
                                developing, designing, and delivering attractive mobile apps to
                                ensure a native look and seamless interactions. We develop highly
                                tailored apps that are compatible with every Android-based device.
                                It consists of apps for wearables, tablets, Smart TVs, smartphones,
                                and more. To discover the real value, look at the best mobile
                                application solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
