import React from 'react';

function Services({ className }) {
    return (
        <section className={`appie-services-2-area companies pb-50 pt-0 ${className}`} id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title Magento">
                                WEBNSTACK'S MAGENTO KNOWLEDGE AND EXPERIENCE​
                            </h1>
                            <p className="font-size16px-p">
                                As one of the top Magento companies, we have a skilled staff that
                                will keep hold of any issue you want our support with. We have a
                                variety of services that we can provide to the communities.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fab fa-magento icon-size-30" />
                            </div>

                            <h1 className="title">Magento 1 & 2 Support</h1>
                            <p className="text-justify">
                                Whether it’s Magento 1 or 2, our professionals understand what you
                                need and let you pick the platform that best suits your needs.
                                Although it is recommended that you use Magento 2 since the security
                                features have been improved, Magento 1 may be used instead, and we
                                are committed to providing you with the necessary assistance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-star icon-size-30" />
                            </div>
                            <h1 className="title">Themed Framework</h1>
                            <p className="text-justify">
                                Magento assists in the creation of platforms from the ground up, but
                                the beauty is in the thematic solutions. Our professionals can
                                create themed platforms for you if you want a certain design for
                                your ecommerce site. Magento provides fully functional themes to
                                assist you in creating stunning and customizable ecommerce systems
                                effortlessly.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-users icon-size-30" />
                            </div>
                            <h1 className="title">Magento Migration</h1>
                            <p className="text-justify">
                                The two most popular Magento versions are Magento 1 and Magento 2.
                                However, it is recommended that you begin utilising Magento 2 as
                                soon as because the security enhancements are just as vital as the
                                functionality changes. As a result, if your platform is running on
                                Magento 1, we can assist you with migrating to Magento 2.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-search icon-size-30" />
                            </div>
                            <h1 className="title">Site Optimization</h1>
                            <p className="text-justify">
                                Not only is it critical to choose the correct ecommerce platform,
                                but site SEO is also crucial. Your website should be well-optimized
                                to run properly across all browsers, platforms, and devices for
                                optimal performance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-meteor icon-size-30" />
                            </div>
                            <h1 className="title">PWA Implementation</h1>
                            <p className="text-justify">
                                PWA implementation can save you a significant amount of time and
                                money. PWAs are created with technologies that are compatible with
                                all standard-compliant browsers. These programmes can help you keep
                                your UI consistent across devices.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-6 tb-g mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-dumpster icon-size-30" />
                            </div>
                            <h1 className="title">Magento Commerce</h1>
                            <p className="text-justify">
                                Magento Commerce can assist you in developing a large-scale
                                ecommerce platform. It not only comes with capabilities that work
                                well with any ecommerce platform, but it also has security
                                advancements that make your payment gateways more safe.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-paint-brush icon-size-30" />
                            </div>
                            <h1 className="title">Innovative Designed Platform</h1>
                            <p className="text-justify">
                                Allow us to create a platform that reflects your vision and future
                                objectives. WebNStack has a team of skilled designers and Magento
                                developers who can assist you in creating a custom platform that
                                meets your company needs.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-globe icon-size-30" />
                            </div>
                            <h1 className="title">Custom Module Development</h1>
                            <p className="text-justify">
                                Not only is it critical to choose the correct ecommerce platform,
                                but site SEO is also crucial. Your website should be well-optimized
                                to run properly across all browsers, platforms, and devices,
                                ensuring a seamless user experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2  item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-users icon-size-30" />
                            </div>
                            <div></div>
                            <h1 className="title">eCommerce - Social APIs</h1>
                            <p className="text-justify">
                                We have Magento developers that know everything there is to know
                                about the platform. They are experts and can help you to develop a
                                Magento website or e-commerce platform, but also give Magento
                                consultation to learn more about it.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
