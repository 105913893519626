import React from 'react';
import kenwood1 from '../../../assets/images/webnstack/1st-section-kenwood-kitchen.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-myt-top-section kenwoodkitchen-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Explore Saudi Flavors of Culinary Delights
                            </h1>
                            <p>
                                The Kenwood Kitchen application offers a culinary journey into the
                                flavors of Saudi cuisine, providing users with a treasure trove of
                                delicious recipes. This interactive platform not only guides users
                                on using Kenwood products effectively but also shares recipes for a
                                variety of delectable dishes, spanning sweets, appetizers, side
                                dishes, pastries, and rice recipes. As a community-driven
                                initiative, the app encourages users to contribute their own cooking
                                creations, fostering a collaborative space for sharing culinary
                                expertise. Whether you're a seasoned chef or a cooking enthusiast,
                                the Kenwood Kitchen app facilitates recipe sharing through various
                                platforms, including WhatsApp, SMS, Facebook, Twitter, and more.
                                Users are encouraged to rate the application, contribute to its
                                improvement, and engage with the Kenwood community on Facebook.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={kenwood1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
