import React from 'react';
import WebdevelopmentSection4 from '../../assets/images/webnstack/web-development-4th-section.png';

function TrafficHomeOne({ className }) {
    return (
        <section className={`appie-traffic-area pt-60 pb-50 Streamlined ${className || ''}`}>
            <div className="container">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 appie-traffic-title pr-0">
                            <span className="preheading font-size14px-p pb-10">
                                Streamlined Workforce Management with HR Payroll Precision
                            </span>
                            <h1 className="title Elevate-Efficiency">
                                Elevate Efficiency and Control with Our Laravel-Powered Solution for
                                Payroll, Attendance, and Customizable Team Management Modules
                            </h1>
                            <p className="font-size16px-p font-size14px-p pt-10">
                                Experience a new era of HR Payroll excellence with our Laravel-built
                                application. Seamlessly manage payroll, employee data, and essential
                                master controls for overtime, advances, gate passes, attendance,
                                banking, and more. Empower your business with our customizable
                                modules tailored to suit your unique needs.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <div className="appie-traffic-service mb-30">
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <h1 className="title">Comprehensive Payroll Management</h1>
                                        <p className="font-size16px-p">
                                            Effortlessly handle payroll processing, tax
                                            calculations, and salary disbursements with our HR
                                            Payroll application. Streamline your payroll operations
                                            and ensure accurate and timely compensation for your
                                            employees.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="appie-traffic-service item-2 mb-30">
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <h1 className="title">Advanced Employee Data Management</h1>
                                        <p className="font-size16px-p">
                                            Centralize and manage crucial employee data efficiently.
                                            From personal details to employment history, our
                                            application provides a secure and organized repository
                                            for all employee information, simplifying HR tasks and
                                            enhancing data accuracy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 traffic-thumb ">
                            <img
                                className="wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                                src={WebdevelopmentSection4}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="appie-traffic-service item-3 mb-30">
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <h1 className="title">Master Controls for Customization</h1>
                                <p className="font-size16px-p">
                                    Tailor the application to your specific needs with robust master
                                    controls. Manage overtime, advances, gate passes, and more with
                                    customizable modules, allowing you to adapt the system to your
                                    organization's unique workflows and requirements.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="appie-traffic-service item-4 mb-30">
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <h1 className="title">Dynamic Attendance Tracking</h1>
                                <p className="font-size16px-p">
                                    Keep track of employee attendance seamlessly. Our application
                                    offers real-time attendance monitoring, reporting, and analysis,
                                    providing insights into workforce attendance patterns and
                                    facilitating informed decision-making for better workforce
                                    management.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TrafficHomeOne;
