import React from 'react';
import counterIconOne from '../../assets/images/webnstack/IT-services/icon/1.png';
import counterIconTwo from '../../assets/images/webnstack/IT-services/icon/2.png';
import counterIconThree from '../../assets/images/webnstack/IT-services/icon/3.png';
import counterIconFour from '../../assets/images/webnstack/IT-services/icon/4.png';

function CounterArea({ style }) {
    return (
        <section
            className="appie-counter-area pt-70 pb-190 padding-top-60p milestones "
            id="counter"
            style={style}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title it-services-text-align ">
                                Milestones of Excellence: Navigating
                            </h1>
                            <h1 className="appie-title mt-0 it-services-text-align ">
                                {' '}
                                Innovation and Growth in the Industry
                            </h1>
                            <p className="font-size16px-p">
                                Our journey of innovation, partnership, and project success in the
                                dynamic digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content padding-bottom-20">
                                <div className="icon">
                                    <img src={counterIconOne} alt="" />
                                </div>
                                <p className="p-3 text-justify">
                                    {' '}
                                    Thriving with a portfolio of 27+ active clients, our IT company
                                    has forged lasting partnerships through the exemplary service
                                    and innovative solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-2 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content padding-bottom-20">
                                <div className="icon">
                                    <img src={counterIconTwo} alt="" />
                                </div>
                                <p className="p-3 text-justify">
                                    Successfully completed 76+ projects, our track record
                                    exemplifies a commitment to excellence, delivering cutting-edge
                                    solutions that exceed client expectations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-3 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content padding-bottom-20">
                                <div className="icon">
                                    <img src={counterIconThree} alt="" />
                                </div>
                                <p className="p-3 text-justify">
                                    Guided by a team of 11+ seasoned advisors, our company leverages
                                    a wealth of experience and expertise to navigate the dynamic
                                    landscape of the IT industry.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-4 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content padding-bottom-20">
                                <div className="icon">
                                    <img src={counterIconFour} alt="" />
                                </div>
                                <p className="p-3 text-justify">
                                    Celebrating 7+ glorious years, our journey is marked by
                                    continuous innovation, growth, and dedication to staying at the
                                    forefront of tech advancements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterArea;
