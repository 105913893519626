import React from 'react';
import deliverzerosection1 from '../../../assets/images/webnstack/deliverzero-1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mb-100 deliverzero-sec-one-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title px-1 mob-myt-size" style={{ padding: '0' }}>
                                Revolutionizing Sustainable Dining: Deliverzero's Unique E-commerce
                                Platform
                            </h1>
                            <p>
                                At DeliverZero, they revolutionize the way we think about food
                                delivery by pioneering a network of returnable, reusable food
                                containers. Their platform seamlessly integrates with restaurants,
                                delivery platforms, and POS systems, providing customers with an
                                eco-friendly alternative for takeout and delivery. Committed to
                                making reuse more accessible and transparent than ever, we embark on
                                a mission to transform the dining experience by minimizing waste and
                                promoting sustainable practices.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={deliverzerosection1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
