import React, { useState } from 'react';
import FunFactThumb from '../../assets/images/fun-fact-thumb.png';
import CounterUpCom from '../../lib/CounterUpCom';
import PopupVideo from '../PopupVideo';

function FunFactHomeThree() {
    const [showVideo, setVideoValue] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className="appie-fun-fact-area" id="fun-fact-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-fun-fact-box wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-fun-fact-content">
                                            <h3 className="title">
                                                OUR APPROACH TO ECOMMERCE SOLUTIONS
                                            </h3>
                                            <p>
                                                We strive to become one of the most successful and
                                                preferred eCommerce solutions partners for various
                                                business needs like Startups, SMBs, and enterprises
                                                providing the best mixture of innovation and
                                                technology leadership. From Studying the business
                                                challenges to improving them through streamlining
                                                and automation, we take a unique approach by fusing
                                                traditional and new methods to develop quality
                                                eCommerce solutions at speed, while retaining our
                                                personal touch and fastidious attitude to planning
                                            </p>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="title">
                                                            <CounterUpCom
                                                                endValue="27"
                                                                sectionSelect="fun-fact-area"
                                                            />
                                                            +
                                                        </h4>
                                                        <span> ACTIVE CLIENTS</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="title">
                                                            <CounterUpCom
                                                                endValue="76"
                                                                sectionSelect="fun-fact-area"
                                                            />
                                                            +
                                                        </h4>
                                                        <span>PROJECTS DONE</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="appie-fun-fact-item">
                                                        <h4 className="title">
                                                            <CounterUpCom
                                                                endValue="11"
                                                                sectionSelect="fun-fact-area"
                                                            />
                                                            +
                                                        </h4>
                                                        <span>Team Advisors</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-fun-fact-play">
                                            <a
                                                onClick={(e) => handleShowVideo(e)}
                                                className="appie-video-popup"
                                                href="https://www.youtube.com/watch?v=EE7NqzhMDms"
                                            >
                                                <i className="fas fa-play" />
                                            </a>
                                            <img src={FunFactThumb} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FunFactHomeThree;
