import React from 'react';
import PayrollSectionImage4 from '../../../assets/images/webnstack/payroll-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-30 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={PayrollSectionImage4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">RESULTS & PROCESS</h1>
                            <p>
                                The successful delivery of our payroll web application marks a
                                significant achievement in meeting the client's requirements and
                                transforming their payroll management system.
                            </p>
                            <p>
                                {' '}
                                By utilizing PHP, JavaScript, and Laravel database technology, the
                                WebNStack Team created a highly functional and user-friendly
                                platform tailored to the client's specific needs.
                            </p>
                            <p>
                                {' '}
                                The implemented user interface, featuring a secure login system with
                                necessary validation fields, Brand Logo, and website elements,
                                provided a seamless and branded experience.
                            </p>
                            <p>
                                {' '}
                                Users can now efficiently calculate attendance and leaves, with the
                                ability to perform actions such as deletion, refreshing, updating,
                                and correction of data.
                            </p>
                            <p>
                                {' '}
                                Import and export functionalities allow for seamless data transfer,
                                ensuring the system's compatibility with external sources.
                            </p>
                            <p>
                                {' '}
                                The successful delivery of this payroll web application not only met
                                but exceeded the client's expectations.
                            </p>
                            <p>
                                {' '}
                                It stands as a testament to our commitment to delivering tailored
                                solutions that enhance efficiency, accuracy, and user experience.
                            </p>
                            <p>
                                {' '}
                                The client now possesses a customized payroll management system,
                                aligning with their vision of transitioning from manual work to
                                smart work in the realm of HR Payroll Applications.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
