import React, { useRef } from 'react';
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import shape12 from '../../../assets/images/shape/shape-12.png';
import shape13 from '../../../assets/images/shape/shape-13.png';
import shape14 from '../../../assets/images/shape/shape-14.png';
import shape15 from '../../../assets/images/shape/shape-15.png';
import showCaseTggOne from '../../../assets/images/webnstack/our-projects-svg/livelinen-screen-image-1.svg';
import showCaseTggThree from '../../../assets/images/webnstack/our-projects-svg/livelinen-screen-image-2.svg';
import showCaseTggTwo from '../../../assets/images/webnstack/our-projects-svg/livelinen-screen-image-3.svg';
import showCaseTggFour from '../../../assets/images/webnstack/our-projects-svg/livelinen-screen-image-4.svg';

function ShowCase() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <section className="appie-showcase-area pt-0 pb-5">
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="appie-section-title text-center">
                                    <h1 className="appie-title">
                                        Innovative Web Solutions:Showcasing{' '}
                                    </h1>
                                    <h2 className="appie-title mt-0">
                                        Our Diverse Portfolio of Excellence
                                    </h2>
                                    <p>
                                        Explore Our Pioneering Marketplaces,From Seamless E-Commerce
                                        Experiences to Cutting-Edge Innovations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row appie-showcase-slider">
                            <div className="col-lg-12">
                                <Slider ref={sliderRef} {...settings}>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={showCaseTggOne} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={showCaseTggTwo} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={showCaseTggThree} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={showCaseTggFour} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="showcase-shape-1">
                        <img src={shape14} alt="" />
                    </div>
                    <div className="showcase-shape-2">
                        <img src={shape13} alt="" />
                    </div>
                    <div className="showcase-shape-3">
                        <img src={shape12} alt="" />
                    </div>
                    <div className="showcase-shape-4">
                        <img src={shape15} alt="" />
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </section>
    );
}

export default ShowCase;
