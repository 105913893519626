import React from 'react';

function ServicesHomeTwo({ className }) {
    return (
        <section className={`appie-services-2-area pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-8">
                        <div className="appie-section-title">
                            <h3 className="appie-title">MAGENTO ECOMMERCE SOLUTIONS WE SERVE</h3>
                            <p>
                                Empowering Your E-Commerce Dreams with Tailored Magento Solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4">
                        <div className="appie-section-title text-right">
                            <a className="main-btn" href="#">
                                ecommerce solutions <i className="fal fa-arrow-right" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-bolt" />
                            </div>

                            <h4 className="title">Architecture Advisory</h4>
                            <p>
                                Establishing the right ecommerce architecture can support overall
                                business strategy. It’s important to continuously reevaluate your
                                eCommerce architecture to verify it reflects changes to your
                                business strategy, emerging consumer trends, and advancements in
                                technology.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-home" />
                            </div>
                            <h4 className="title">Integration Solutions</h4>
                            <p>
                                WebNStack attempt hard to integrate third party services, like
                                Finance Systems, CMS, Payment Gateway, PIM, and more. This leads to
                                reduce workloads. Integration is the coordination between a
                                business’ eCommerce website and their back-end systemsk
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-link" />
                            </div>
                            <h4 className="title">Cloud Solutions</h4>
                            <p>
                                Establishing the right ecommerce architecture can support overall
                                business strategy. It’s important to continuously reevaluate your
                                eCommerce architecture to verify it reflects changes to your
                                business strategy, emerging consumer trends, and advancements in
                                technology.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-bell" />
                            </div>
                            <h4 className="title">ERP Solutions</h4>
                            <p>
                                A complete software solution optimises your eCommerce Supply Chain
                                Management by providing you with numerous options for eCommerce
                                channel integration.The seamless integration of your eCommerce
                                system with a modern, cloud-based ERP solution.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-cog" />
                            </div>
                            <h4 className="title">B2B/B2C Marketplace</h4>
                            <p>
                                Everyone need more than a B2B/B2C strategy. A flexible and
                                intelligent solution we like to call Business to Everyone (B2E) to
                                seize opportunities with up-and-coming channels and business models
                                to meet the high demands of your buyers.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-home" />
                            </div>
                            <h4 className="title">CRM Integration</h4>
                            <p>
                                We specialize in seamlessly integrating Customer Relationship
                                Management (CRM) systems to enhance the efficiency and effectiveness
                                of your business operations. From initial consultation and system
                                analysis to implementation and ongoing support, our comprehensive
                                services aim to optimize your CRM functionality, improve data
                                accuracy, and ultimately drive better business outcomes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeTwo;
