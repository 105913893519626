import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import Svg from './Svg';

function Error() {
    const history = useHistory();
    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    };

    return (
        <div className="pt-150">
            <SharedHeader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-error-content text-center">
                            <Svg />
                            <span>Sorry!</span>
                            <h3 className="title">The page can’t be found.</h3>
                            <p>
                                We're sorry, but the page you're looking for cannot be found. It
                                seems like the link you followed might be outdated or incorrect or
                                there's been a glitch in our code.
                            </p>
                            <div className="row">
                                <div className="mb-30 col-md-12">
                                    <Link
                                        onClick={(e) => goBack(e)}
                                        to="#"
                                        className="button-color main-btn"
                                    >
                                        Go Back <i className="fal fa-arrow-right"></i>
                                    </Link>
                                    <Link to="/" className="ml-10 button-color main-btn">
                                        Homepage <i className="fal fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <p className="mb-70">
                                If you continue to encounter issues or if you believe there's a
                                problem with our website, please contact us. We appreciate your
                                patience and assistance as we work to resolve any issues. Thank you
                                for your understanding!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <SharedFooter />
        </div>
    );
}

export default Error;
