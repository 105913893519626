import React from 'react';
import headles4thsection from '../../assets/images/webnstack/headless-4th-section.png';

function Services() {
    return (
        <section
            className="appie-services-2-area appie-services-8-area pb-60 magento-development-padding "
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title Headless">Headless is Beyond Trend</h1>
                            <p className="Discover">
                                The provision of the ideal customer experience to every audience is
                                the ultimate objective of B2B headless ecommerce. The headless
                                approach gives businesses unmatched freedom and enables frontend
                                developers to design a distinctive user experience. The storefront
                                and backend are separated by the headless BigCommerce architecture,
                                which connects to numerous channels via APIs, including mobile,
                                online, in-store, and email. In a multi-store, omnichannel,
                                multi-experience B2B ecommerce environment, the only way to
                                consistently satisfy the expectations of B2B clients and achieve the
                                appropriate amount of flexibility is by using a headless commerce
                                platform. The software that runs in the backend of a headless
                                website is different from the software that runs in the frontend.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="icon">
                                        <i className="fas fa-signal"></i>
                                    </div>
                                    <h1 className="title">Availability In 24 x 7</h1>
                                    <p className="text-justify">
                                        If you engage a small team or a single developer, they will
                                        be prepared to start working in 72 hours, depending on the
                                        needs.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <div className="icon">
                                        <i className="fas fa-bolt"></i>
                                    </div>
                                    <h1 className="title">Instant Quick Solution</h1>
                                    <p className="text-justify">
                                        Depending on your needs, we will select a specialised team
                                        or a single Web engineer. Or we can assist you in making
                                        one.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <div className="icon">
                                        <i className="far fa-lightbulb"></i>
                                    </div>
                                    <h1 className="title">Submit Your Idea</h1>
                                    <p className="text-justify">
                                        Give us your goals, and we’ll adjust technical
                                        specifications. Or, if you already have criteria, please
                                        submit them to us.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="icon">
                                        <i className="fas fa-pencil-ruler"></i>
                                    </div>
                                    <h1 className="title">Custom UX Design</h1>
                                    <p className="text-justify">
                                        We provide a UX design that enhances user interaction and
                                        increases engagement and time spent on the app.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="service-thumb">
                            <img src={headles4thsection} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
