import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-service-area pt-90 pb-100 WEBNSTACK-ECOMMERCE ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">WEBNSTACK ECOMMERCE AREAS OF FOCUS</h1>
                            <p className="font-size16px-p">
                                We help in driving customer satisfaction, expanding audiences, and
                                boosting sales. We assure you that your website is developed with a
                                robust business strategy and advanced top-notch development
                                technologies.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-shield-alt icon-size-30" />
                            </div>
                            <h1 className="appie-title px-2">SITE DEVELOPMENT & SECURITY</h1>
                            <p className="text-justify">
                                Create a user-friendly, well-designed e-commerce front end that
                                drives sales, engages customers, and brings them back for more.
                                Security should be a high priority for any e-commerce site, ensuring
                                the protection of customer’s information, products, and profits.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-code icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">BACKEND INTEGRATION</h1>
                            <p className="text-justify">
                                Integrate your e-commerce solution with your CRM, ERP, marketing
                                automation, and 3PL to streamline your business processes and reduce
                                time spent processing tasks orders. Understand your customers better
                                with site data and visitor tracking.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-cogs icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">CONVERSION OPTIMIZATION</h1>
                            <p className="text-justify">
                                Understanding how to convert users into buyers is key to long term
                                success in online retail. Sell to more than one location? Create a
                                user experience that encourages all of your customers – no matter
                                what language they speak – to engage with online store.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-user-alt icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">USER EXPERIENCE INTERFACE</h1>
                            <p className="text-justify">
                                Users expect a frictionless experience when researching products,
                                checking out, and then receiving their products. Our team is here to
                                ensure that your end to end customer buying experience is Five
                                Stars, delivering best satisfaction, productivity and
                                efficiency at every step.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
