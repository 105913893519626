import React from 'react';
import eltrivia4 from '../../../assets/images/webnstack/el-trivia-4th-section-el-trivia.png';

function About() {
    return (
        <section className="appie-about-3-area pt-20 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={eltrivia4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h3 className="title">RESULTS & PROCESS</h3>
                            <p>
                                The successful delivery of the El Trivia project reflects our team's
                                commitment to excellence. Following the implementation of API
                                changes and issue resolutions, the gaming experience underwent a
                                seamless transformation, ensuring smooth integration and enhanced
                                functionality. Rigorous testing and debugging procedures were
                                instrumental in achieving a robust API structure.
                            </p>
                            <p>
                                In parallel, the maintenance of the Magento application resulted in
                                improved performance, data integrity, and overall stability. Routine
                                updates were seamlessly executed, contributing to an optimized and
                                secure gaming platform. Our proactive approach to addressing
                                potential issues ensured a reliable Magento environment.
                            </p>
                            <p>
                                Furthermore, our technical support extended to server optimizations,
                                resulting in a more responsive infrastructure capable of handling
                                dynamic user interactions within the El Trivia game. The technical
                                interventions not only met but exceeded client expectations,
                                creating a resilient, efficient, and enjoyable gaming platform for
                                El Trivia by DigifyMENA. The successful collaboration stands as a
                                testament to our technical expertise and dedication to delivering
                                solutions that elevate the digital experience for both the client
                                and end-users.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
