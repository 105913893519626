import React from 'react';
import sponserShape from '../../assets/images/sponser-shape.png';
import showCaseTggSaven from '../../assets/images/webnstack/career-magento.png';
import showCaseTggOne from '../../assets/images/webnstack/career-mailchimp.png';
import showCaseTggSix from '../../assets/images/webnstack/career-pwa.png';
import showCaseTggTwo from '../../assets/images/webnstack/career-shopify.png';
import showCaseTggFour from '../../assets/images/webnstack/career-woocommerce.png';
import showCaseTggThree from '../../assets/images/webnstack/career-wordpress.png';
import showCaseTggFive from '../../assets/images/webnstack/git.png';

function Sponser({ className }) {
    return (
        <section className={`appie-sponser-area pb-100 ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">WHO WILL YOU LEARN WITH?</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-sponser-box d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggThree} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggFour} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggFive} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggSix} alt="" />
                            </div>
                        </div>
                        <div className="appie-sponser-box item-2 d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggOne} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggTwo} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={showCaseTggSaven} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sponser-shape">
                <img src={sponserShape} alt="" />
            </div>
        </section>
    );
}

export default Sponser;
