import React from 'react';
import kenwood2 from '../../../assets/images/webnstack/2nd-section-kenwood-kitchen.png';
import kenwood3 from '../../../assets/images/webnstack/3rd-section-keenwood-kitchen.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10
        "
            id="features"
        >
            <div className="container pt-120 mob-p-0">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={kenwood2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The Kenwood Kitchen project by DigifyMENA presented a multifaceted
                                challenge encompassing API changes, issue resolutions, maintenance
                                of the attached Magento application, and server optimization. The
                                client's demand for API modifications required a meticulous approach
                                to ensure seamless integration and functionality within the
                                application. Our team not only implemented these changes but also
                                addressed associated issues, guaranteeing a smooth user experience
                                for individuals exploring Saudi cuisine.{' '}
                            </p>
                            <p>
                                Simultaneously, the maintenance of the Magento application posed its
                                own set of challenges, demanding constant vigilance to identify and
                                promptly address potential issues. Routine updates were implemented
                                to enhance performance, monitor data integrity, and uphold the
                                overall stability and security of the platform.
                            </p>
                            <p>
                                Furthermore, our technical expertise extended to assisting Kenwood
                                Kitchen with server-related challenges, involving optimizations to
                                improve responsiveness and handle dynamic user interactions
                                effectively. This multifaceted project demanded a holistic and
                                proactive approach to deliver effective solutions, ensuring the
                                continued success and optimal performance of the Kenwood Kitchen
                                application.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                In response to the challenges posed by the Kenwood Kitchen project,
                                our technical team executed a series of strategic interventions. To
                                address the client's demand for API changes and issue resolutions,
                                we conducted a comprehensive analysis and seamlessly implemented
                                modifications to ensure smooth integration and functionality within
                                the application. Rigorous testing and debugging procedures were
                                integral to achieving a robust API structure, enhancing the overall
                                user experience for those engaging with Saudi cuisine recipes.
                            </p>
                            <p>
                                {' '}
                                Simultaneously, our team undertook the meticulous maintenance of the
                                attached Magento application, employing a proactive approach to
                                identify and promptly address potential issues. Routine updates were
                                seamlessly executed, contributing to improved performance, enhanced
                                data integrity, and overall stability. The optimized Magento
                                platform now ensures a secure and efficient digital space for users
                                exploring Kenwood Kitchen.
                            </p>
                            <p>
                                {' '}
                                Furthermore, our technical expertise extended to assisting Kenwood
                                Kitchen with server-related challenges. Through strategic
                                optimizations, we improved server responsiveness, creating an
                                infrastructure capable of handling dynamic user interactions
                                effectively. This holistic technical approach not only met but
                                exceeded client expectations, resulting in a resilient, efficient,
                                and enjoyable user experience for Kenwood Kitchen by DigifyMENA. The
                                successful collaboration stands as a testament to our technical
                                proficiency and commitment to delivering solutions that enhance the
                                digital landscape for both the client and end-users.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={kenwood3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
