import React from 'react';

function ServicesThree() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-60 pb-60 Technology"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title Expertise excellence">
                                Technology Expertise
                            </h1>
                            <p className="Discover">
                                Discover our vision, mission, strategy and philosophy that drive our
                                commitment to excellence.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                Speed
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-tachometer-alt"></i>
                            </div>
                            <h1 className="appie-title">Speed Optimisation</h1>
                            <p className="text-justify">
                                Stop losing customers as a result of your website's slow
                                performance. Please them with a store that operates quickly.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-ad"></i>
                            </div>
                            <h1 className="appie-title">Seo effective</h1>
                            <p className="text-justify">
                                Rank your page higher and more easily than the opposition. Static
                                websites are quick, light, simple to scan, Google prefers them.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-digital-ocean"></i>
                            </div>
                            <h1 className="appie-title">Digital secure</h1>
                            <p className="text-justify">
                                Static pages eliminate the need for any direct access to the
                                database, dependencies, user data, or sensitive data.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-magic"></i>
                            </div>
                            <h1 className="appie-title">Rich Designs</h1>
                            <p className="text-justify">
                                With your experimental ideas, you may be more imaginative and
                                innovative without sacrificing speed.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-reply-all"></i>
                            </div>
                            <h1 className="appie-title">Quick response</h1>
                            <p className="text-justify">
                                The user would be able to view them as soon as the developer makes
                                them on the front-end.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUps
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-mobile"></i>
                            </div>
                            <h1 className="appie-title">Mobile responsive</h1>
                            <p className="text-justify">
                                Ensure that your website functions flawlessly across all platforms
                                and browsers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesThree;
