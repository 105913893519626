import React from 'react';
import heroThumb from '../../../assets/images/dealon1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mb-100 dealon-sec-one-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Sellers Fee-Free Ecommerce Marketplace for Seamless Online Trading
                            </h1>
                            <p>
                                DealOn is a dynamic multivendor online store that serves as a
                                vibrant hub for UK independent businesses, providing a diverse
                                selection of products through its marketplace. With a unique
                                approach, DealOn stands out by eliminating barriers for UK
                                businesses and start-ups, offering a platform with no shop setup,
                                listing, or monthly fees. Here, sellers can showcase and sell their
                                products without incurring any costs, making it an accessible and
                                supportive space for businesses to thrive and connect with a broader
                                audience.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
