import React from 'react';
import aboutThumb from '../../../assets/images/gosmarteyewear/2nd-section.png';
import aboutThumb3 from '../../../assets/images/gosmarteyewear/3rd-section.png';
import aboutThumb4 from '../../../assets/images/gosmarteyewear/4th-section.png';

function About() {
    return (
        <section className="appie-about-3-area pt-200 pb-50" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The GoSmartEyewear client expressed a crucial need to elevate their
                                website's user experience.
                            </p>
                            <p>
                                {' '}
                                The primary focus was on implementing animation, particularly the
                                scrollTo functionality, to streamline navigation within the shopping
                                cart, which featured multiple fields on the Magento 2 platform.
                            </p>
                            <p>
                                {' '}
                                In response to client feedback, substantial modifications were
                                required in the Magento 2 cart and product options module to enhance
                                overall website functionality.
                            </p>
                            <p>
                                Additionally, addressing issues within the Magento 2 platform itself
                                was paramount to ensure a stable and reliable online shopping
                                environment for users.
                            </p>
                            <p>
                                {' '}
                                The project also included updating the product template to not only
                                improve functionality but also to enhance the website's aesthetic
                                appeal.
                            </p>
                            <p>
                                {' '}
                                The overarching goal was to comprehensively address these client
                                needs, creating a seamless and visually appealing shopping
                                experience on the GoSmartEyewear website.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                To address the challenges faced by GoSmartEyewear, the WebNStack
                                team implemented a series of strategic solutions.
                            </p>
                            <p>
                                {' '}
                                Firstly, we integrated custom animations, specifically incorporating
                                scrollTo functionality.
                            </p>
                            <p>
                                {' '}
                                This enhancement was pivotal in facilitating smooth navigation to
                                the next required field in the shopping cart, particularly vital for
                                those with multiple fields on Magento 2.
                            </p>
                            <p>
                                Furthermore, our team of Magento experts dedicatedly modified the
                                cart and product options module, aligning them precisely with the
                                client's requirements to ensure optimal functionality. This involved
                                meticulous adjustments to enhance the overall user experience during
                                the shopping process.
                            </p>
                            <p>
                                {' '}
                                In addition to module modifications, we conducted thorough fixes and
                                updates within the Magento 2 platform itself. This comprehensive
                                approach was aimed at resolving issues and boosting the overall
                                performance of the website, creating a more stable and reliable
                                online shopping environment.
                            </p>
                            <p>
                                {' '}
                                Not stopping there, we took steps to update the product template.
                            </p>
                            <p>
                                {' '}
                                Beyond improving functionality, this update was designed to enhance
                                aesthetics and user interaction.{' '}
                            </p>
                            <p>
                                The result was a more seamless and visually engaging shopping
                                experience on the GoSmartEyewear website, aligning with the client's
                                vision and meeting their specific needs.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h1 className="title">RESULTS & PROCESS</h1>
                                <p>
                                    The successful implementation of our solutions for
                                    GoSmartEyewear resulted in an enhanced and dynamic online
                                    shopping experience.
                                </p>
                                <p>
                                    {' '}
                                    The custom animations, including scrollTo functionality, not
                                    only improved user engagement but also significantly streamlined
                                    the checkout process, making it more intuitive and
                                    user-friendly.
                                </p>
                                <p>
                                    {' '}
                                    The modifications made to the cart and product options module
                                    played a crucial role in creating a more robust and
                                    user-friendly e-commerce platform.
                                </p>
                                <p>
                                    {' '}
                                    These enhancements contributed to a smoother and more efficient
                                    overall shopping experience for GoSmartEyewear's customers.
                                </p>
                                <p>
                                    {' '}
                                    Our fixes and updates to the Magento 2 platform addressed
                                    existing issues, ensuring the website's stability and
                                    performance.
                                </p>
                                <p>
                                    {' '}
                                    This comprehensive approach aimed at creating a reliable online
                                    environment for users, fostering trust in the platform.
                                </p>
                                <p>
                                    {' '}
                                    The updated product template not only improved aesthetics but
                                    also created a visually appealing interface for customers,
                                    enhancing the overall brand image.
                                </p>
                                <p>
                                    {' '}
                                    The result is a successful delivery that aligns seamlessly with
                                    GoSmartEyewear's vision of providing a seamless and delightful
                                    shopping experience for their users, meeting and exceeding their
                                    expectations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
