import React from 'react';

function ServicesWithicon() {
    return (
        <section className="pt-60 react-js-padding-top0" id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20-important">
                                WebNStack's React Js Development Process
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-search-plus icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title ">Project Research</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-react icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title">React Js Development</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-sitemap icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title">Custom Integrations</h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-bug icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title">
                                Quality <br />
                                Analysis
                            </h1>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-5
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-rocket icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title">
                                Project <br />
                                Launch
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesWithicon;
