import React from 'react';
import featureThumb from '../../assets/images/features-thumb-2.jpg';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function FeaturesHomeTwo() {
    return (
        <section className="appie-features-area-2 pt-90 pb-100" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title appie-section-title-2 text-center">
                            <h3 className="appie-title">WE ORGANIZE OUR PRODUCTION PROCESS</h3>
                            <p>
                                Magento Mastery: Seamless Development with Expert Analysis, Rigorous
                                Testing, and Creative Design.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-features-boxes">
                            <div className="appie-features-box-item">
                                <h4 className="title">ANALYSIS</h4>
                                <p>
                                    WebNStack is the partner of choice for many of the world’s
                                    leading enterprises, SMEs and technology challengers. We help
                                    businesses elevate their value through custom software
                                    development, product design, QA and consultancy services. Our
                                    product design service lets you prototype, test and validate
                                    your ideas.
                                </p>
                            </div>
                            <div className="appie-features-box-item item-2">
                                <h4 className="title">DESIGN</h4>
                                <p>
                                    While integrating the security with the DevOps approach has
                                    always been of high demand, this requires a completely different
                                    range of processes and tools. DevOps is already known to boast
                                    of an agile development methodology. Now when the inputs of the
                                    security experts I’ll be brought in a collaborative environment,
                                    the project will have an array of effective security safeguards.
                                </p>
                            </div>
                            <div className="appie-features-box-item item-3">
                                <h4 className="title">TESTING</h4>
                                <p>
                                    In spite of disturbing the agile development process of the
                                    DevOps approach, the additional security inputs only enhance the
                                    safeguards and reliability of the project. So, in a way,
                                    DevSecOps will only extend the benefits of a DevOps approach
                                    further with security inputs. DevOps is already known to boast
                                    of an agile development methodology.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={featureThumb} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default FeaturesHomeTwo;
