import React from 'react';
import magentomigrationsection4th from '../../assets/images/webnstack/4th-section.png';

function AboutTwo() {
    return (
        <section className="appie-about-3-area pb-60 animated" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={magentomigrationsection4th} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutTwo;
