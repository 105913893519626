import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import downloadThumbThree from '../../../assets/images/assl.png';
import downloadThumb from '../../../assets/images/gosmarteyewear/dealon.png';
import downloadThumb2 from '../../../assets/images/gosmarteyewear/deelert.png';
import downloadThumbTwo from '../../../assets/images/gosmarteyewear/kakzon.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-100 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">Diverse Solutions,Shared Excellence:</h1>
                            <h1 className="appie-title mt-0">
                                {' '}
                                A Showcase of Our Related Projects
                            </h1>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="px-4 py-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/dealon" aria-label="Dealon">
                                        Dealon
                                    </Link>
                                </h1>
                                <p>
                                    Sellers fee-free ecommerce marketplace for seamless online
                                    trading.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="px-4 py-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/kakzon" aria-label="Kakzon">
                                        Kakzon
                                    </Link>
                                </h1>
                                <p>
                                    Sport in style-showcasing our ecommerce excellence in sportswear
                                    stores.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumbTwo} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="px-4 py-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/assl" aria-label="ASSL">
                                        ASSL
                                    </Link>
                                </h1>
                                <p>
                                    Safeguarding trust platform showcasing a variety of security
                                    services.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumbThree} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="px-4 py-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/deelert" aria-label="Deelert">
                                        Deelert
                                    </Link>
                                </h1>
                                <p>
                                    Customized Ecommerce Marketplace for Furniture, Interior, and
                                    Home Décor.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumb2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
