import React from 'react';
import aboutThumb from '../../assets/images/homepage-3rd-section-image.png';

function About() {
    return (
        <section className="appie-about-area mb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-box wow animated fadeInUp pb-40"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-thumb mob-photo-size">
                                        <img src={aboutThumb} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-about-content pr-0">
                                        <span className="about-us-size"> ABOUT US</span>
                                        <h1 className="title mobile-heading-size">
                                            YOUR PARTNER FOR ECOMMERCE INNOVATION
                                        </h1>
                                        <p className="text-align-start font-size16px-p">
                                            WebNStack is a top-notch website and app development
                                            company. We are known for being innovators and
                                            passionate problem-solver to deliver high-standard
                                            eCommerce solutions and services to unlock the
                                            possibilities of the start-up to large enterprises. Our
                                            end-to-end solutions are based on the newest and
                                            innovative tools and technologies.
                                        </p>
                                        <p className="text-align-start font-size16px-p">
                                            We have a team of proficient and well-competent
                                            developers and design engineers with the motive to
                                            transform your ideas into reality. With the perfect
                                            blend and eCommerce technologies, we offer high-quality
                                            services that fit the newest market trends.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="appie-about-service mt-30">
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <h1 className="title">Our Mission</h1>
                                        <p className="font-size16px-p">
                                            {' '}
                                            We take a unique approach by fusing traditional and new
                                            methods to develop quality eCommerce solutions at speed,
                                            while retaining our personal touch and fastidious
                                            attitude to planning.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="appie-about-service mt-30">
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <h1 className="title">Our Vision</h1>
                                        <p className="font-size16px-p">
                                            {' '}
                                            Our vision is to bridge the gap between technology and
                                            the eCommerce environment the partner of choice for many
                                            of the world’s leading enterprises, SMEs and technology
                                            challengers.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="appie-about-service mt-30">
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <h1 className="title">Our Philosophy</h1>
                                        <p className="font-size16px-p">
                                            {' '}
                                            Our philosophy is to embrace innovation, and new
                                            technologies to benefit our customers. Bringing your
                                            commerce idea to life and providing eCommerce solutions
                                            for all your needs. Solutions that will drive your
                                            business forward, freeing you to do the work of
                                            delighting your customers.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="appie-about-service mt-30">
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <h1 className="title">Our Strategy</h1>
                                        <p className="font-size16px-p">
                                            We focus on providing exceptional results in the
                                            shortest amount of time possible in an Agile way It’s
                                            important to continuously reevaluate your eCommerce
                                            architecture to verify it reflects changes to your
                                            business strategy, emerging consumer trends, and
                                            advancements in technology.{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
