import React from 'react';

function Services() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-60 pb-100 WE-PROVIDE"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">WE PROVIDE BEST WEB DEVELOPMENT</h1>
                            <p className="font-size16">
                                Tailored web development services for a digital future.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-java icon-size-30 font-weight-bolder"></i>
                            </div>
                            <h1 className="appie-title">Java Development</h1>
                            <p className="text-justify">
                                We’re committed to building sustainable and high-quality Java
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp 
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-php icon-size-30 font-weight-bolder"></i>
                            </div>
                            <h1 className="appie-title">PHP Development</h1>
                            <p className="text-justify">
                                We’re committed to building sustainable and high-quality PHP
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-plus icon-size-30 font-weight-bolder"></i>
                            </div>
                            <h1 className="appie-title">C++ Development</h1>
                            <p className="text-justify">
                                We’re committed to building sustainable and high-quality C++
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-code icon-size-30 font-weight-bolder"></i>
                            </div>
                            <h1 className="appie-title">.NET Development</h1>
                            <p className="text-justify">
                                We’re committed to building sustainable and high-quality Qt
                                solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
