import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterThree from '../Shared/SharedFooterThree';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import SharedProject from '../Shared/SharedProject';
import Features from './Features';
import Hero from './Hero';
import Services from './Services';
import Servicesarea from './Servicesarea';
import Testimonial from './Testimonial';
import Theme from './Theme';
import VideoPlayer from './VideoPlayer';

function EcommerceDevelopment() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Ecommerce Development | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <Hero />
            <Services className="pt-190" />
            <Servicesarea style={{ backgroundColor: '#EEF1F6' }} />
            <Features />
            <Testimonial />
            <VideoPlayer className="pt-100" />
            <Theme />
            <SharedProject />
            <SharedFooterThree />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default EcommerceDevelopment;
