import React from 'react';
import setisfactionsection5 from '../../assets/images/webnstack/5th-section-client-satisfaction.png';
import sectionfastdelivery5th from '../../assets/images/webnstack/5th-section-fast-delivery.png';

function Download({ className }) {
    return (
        <section
            className={`appie-download-3-area Client-Satisfaction ${className || ''}`}
            id="download"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                Efficient Delivery Excellence, Ensuring{' '}
                            </h1>
                            <h1 className="appie-title mt-0">
                                Client Satisfaction and Transparency
                            </h1>
                            <p>
                                Timely results, client delight, and clear communication-our
                                commitment to excellence.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title">Client's Satisfaction And Transparency</h1>
                                <p className="font-size16">
                                    We continuously monitor and gather feedback from all of our
                                    clients in order to improve our services and adapt the delivery
                                    process. This way, we can ensure that our collaboration is
                                    satisfactory to you and future clients. Your input is invaluable
                                    in shaping the success of our ongoing partnerships.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={setisfactionsection5} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="content">
                                <h1 className="title">Fast Delivery Process With Accuracy</h1>
                                <p>
                                    We want you to get your app out there as soon as possible, and
                                    gather feedback from your users. Thanks to the MVP approach
                                    you’ll save money and time, and the scope will be under control.
                                    We discuss priorities, long term plans and get feedback on
                                    regular meetings and shared communication channels.
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={sectionfastdelivery5th} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
