import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PortfolioData from './PortfolioData';

const portfolioContents = {
    title: 'Innovative Solutions',
    projectItems: PortfolioData.filter((item) => item.portfolio_2),
};
const { subtitle, title, projectItems } = portfolioContents;
const imagePerRow = 4;

function Projects() {
    // category
    const [category, setCategory] = useState('ALL');
    // category items
    const cateItems = projectItems.filter((item) => item.category === category);
    // items
    const [items, setItems] = useState(cateItems);
    // load next state
    const [next, setNext] = useState(imagePerRow);

    // handleLoadData
    const handleLoadData = () => {
        setNext((value) => value + 2);
    };

    // all categories
    const allCategories = [...new Set(projectItems.map((c) => c.category))];
    // handleCategory
    const handleCategory = (_category) => {
        setNext(imagePerRow);
        setCategory(_category);
        setItems(projectItems.filter((item) => item.category === _category));
    };

    return (
        <section>
            <div className="tp-project-area pt-0 pb-120 p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-section-box-two text-center">
                                <h1 className="tp-subtitle">{subtitle}</h1>
                                <h2 className="tp-title">{title}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-80">
                        <div className="col-10 p-0 text-center">
                            <div className="tp-project-tab-button masonary-menu">
                                {allCategories.map((c) => (
                                    <button
                                        type="button"
                                        key={c}
                                        className={`${c === category ? 'active' : ''}`}
                                        onClick={() => handleCategory(c)}
                                    >
                                        <span>{c}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row grid gx-45">
                        {items?.slice(0, next)?.map((item) => (
                            <div key={item.id} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="tp-project-item-two p-relative mb-45 fix">
                                    <div className="portfolio-thumb fix">
                                        <img className="w-100" src={item.img} alt="" />
                                    </div>
                                    <div className="tp-portfolio-content-box">
                                        <h3 className="portfolio-animation-title">
                                            <Link to={`/our-projects/${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </h3>
                                        <span>{item.subtitle}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {next < items.length && (
                        <div className="row">
                            <div className="col-12">
                                <div className="tp-project-button text-center mt-25">
                                    <button
                                        type="button"
                                        onClick={handleLoadData}
                                        className="tp-btn-yellow"
                                    >
                                        Load more
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Projects;
