import React from 'react';
import featureThumb from '../../assets/images/homepage-4th-section-image.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function Features() {
    return (
        <section
            className="appie-features-area-2 pt-90 pb-100 mob-padding-top-bottom"
            id="features"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title appie-section-title-2 text-center">
                            <h1 className="appie-title hire-web-developer-appie-title-size">
                                FOR OVER 6 YEARS CUSTOM ECOMMERCE SOLUTIONS
                            </h1>
                            <p className="font-size16px-p">
                                The development of reliable and scalable Ecommerce solutions for any
                                platform, interface, browser, and device. We blend together deep
                                industry expertise and the newest top-notch IT advancements to dig
                                out the crucial requirements and deliver custom solutions and
                                products that perfectly fit into the needs and behavior of their
                                users.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-features-boxes">
                            <div className="appie-features-box-item">
                                <h1 className="title">eCommerce</h1>
                                <p className="font-size16px-p">
                                    Delight your customers with an omnichannel experience with fully
                                    tailored eCommerce solutions to your business requirements.
                                </p>
                            </div>
                            <div className="appie-features-box-item item-2">
                                <h1 className="title">Magento</h1>
                                <p className="font-size16px-p">
                                    We believe in rendering exceptional Magento Development services
                                    to magnetize more traffic and enhance your conversion rates.
                                </p>
                            </div>
                            <div className="appie-features-box-item item-3">
                                <h1 className="title">Shopify</h1>
                                <p className="font-size16px-p">
                                    Our leading Shopify App Development Company provides our clients
                                    various business benefits to make them grow smoothly.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={featureThumb} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default Features;
