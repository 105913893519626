import React from 'react';
import projectThumb from '../../assets/images/shopify/9th-section-image.png';
import MailchimpThree from '../MailchimpThree';

function Project({ className }) {
    return (
        <section className={`appie-project-area pb-100 pt-60 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-project-box wow animated slideInUp get-started"
                            data-wow-duration="1000ms"
                            data-wow-delay="0ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="appie-project-content">
                                        <h1 className="title">Get Started with WebNStack</h1>
                                        <p>
                                            We have skilled Shopify developers available to assist.
                                        </p>
                                        <MailchimpThree
                                            action="https://webnstack.us10.list-manage.com/subscribe/post?u=c6f52f107eeb795a7539991db&amp;id=07592603db"
                                            className="row "
                                            fields={[
                                                {
                                                    name: 'EMAIL',
                                                    placeholder: 'Your Address',
                                                    type: 'email',
                                                    required: true,
                                                    data_type: 'default',
                                                    data_class: 'input-box mt-30 col-md-12',
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="appie-project-thumb">
                                <img src={projectThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Project;
