import { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Cart from '../Cart';
import Drawer from '../Mobile/Drawer';
import SearchModule from '../SearchModule';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import CounterArea from './CounterArea';
import Download from './Download';
import Features from './Features';
import Hero from './Hero';
import Review from './Review';
import Services from './Services';
import Sponser from './Sponser';
import Testimonial from './Testimonial';

function WebDevelopment() {
    const [search, searchAction] = useToggle(false);
    const [cart, cartAction] = useToggle(false);
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        document.title = 'Web Development | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SearchModule value={search} searchToggle={searchAction.toggle} />
            <Cart value={cart} action={cartAction.toggle} />
            <SharedHeader
                action={drawerAction.toggle}
                cartToggle={cartAction.toggle}
                searchToggle={searchAction.toggle}
            />
            <Hero />
            <Services />
            <Features />
            <Review />
            <Download className="pb-90" />
            <CounterArea />
            <Testimonial />
            <Sponser className="pt-90" />
            <SharedFooter />
            <BackToTop className="back-to-top-5" />
        </>
    );
}

export default WebDevelopment;
