import React from 'react';
import { Link } from 'react-router-dom';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import featureThumb from '../../assets/images/webnstack/features-section-four-image.png';

function Features() {
    return (
        <section
            className="appie-features-area-2 pt-90 pb-60 padding-top appie-features-area-background"
            id="features"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title appie-section-title-2 text-center">
                            <h1 className="appie-title text-color-black">WHAT WE ACTUALLY DO​</h1>
                            <p className="text-color-black font-size16px-p">
                                eCommerce Development Solutions WebNStack is the partner of choice
                                for many of the world’s leading enterprises, SMEs and technology
                                challengers. Bringing your commerce idea to life and providing
                                eCommerce solutions for all your needs. Solutions that will drive
                                your business forward, freeing you to do the work of delighting your
                                customers.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-features-boxes">
                            <div className="appie-features-box-item">
                                <h2 className="title">
                                    <Link to="/react-js" className="text-color-code-pink">
                                        React JS
                                    </Link>
                                </h2>
                            </div>
                            <div className="appie-features-box-item item-2">
                                <h2 className="title">
                                    {' '}
                                    <Link to="/magento-development" className="text-color-code">
                                        Magento
                                    </Link>
                                </h2>
                            </div>
                            <div className="appie-features-box-item item-3">
                                <h2 className="title">
                                    {' '}
                                    <Link to="/wordpress" className="text-color-code-green">
                                        WordPress
                                    </Link>
                                </h2>
                            </div>
                            <div className="appie-features-box-item item-4">
                                <h2 className="title">
                                    {' '}
                                    <Link to="/headless-commerce" className="text-color-code-white">
                                        PWA
                                    </Link>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={featureThumb} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default Features;
