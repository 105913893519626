import React from 'react';
import deliverzerosection2 from '../../../assets/images/webnstack/deliverzero-2nd-section.png';
import deliverzerosection3 from '../../../assets/images/webnstack/deliverzero-3rd-section.png';

function About() {
    return (
        <section className="appie-about-3-area pt-200 pb-0 mob-p-0" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={deliverzerosection2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The client sought to create a unique and highly customized
                                e-commerce platform for a reusable container food ordering system.{' '}
                            </p>
                            &nbsp;
                            <p>
                                The complexities involved in the project included organizing store
                                products in an unconventional manner, implementing a user-friendly
                                product filtering system based on irregular geographic delivery
                                zones, and incorporating custom fees specific to each product during
                                the checkout process.
                            </p>
                            &nbsp;
                            <p>
                                Additionally, there was a requirement to build a dedicated app for
                                restaurants to seamlessly communicate with the E-Commerce (Magento)
                                Store.{' '}
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                These challenges presented a multifaceted project that required
                                careful consideration and tailored solutions to meet the client's
                                specific needs.{' '}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                The primary objective was to establish an innovative reusable
                                container food ordering system that could effectively manage
                                restaurants, customers, orders, and reusable containers within a
                                dedicated eco-friendly community.
                            </p>
                            <p>
                                {' '}
                                One of the key complexities involved organizing store products in a
                                virtual manner.
                            </p>
                            <p>
                                {' '}
                                We tackled this challenge by developing a tailored system that
                                aligned with the unique requirements of the platform. The next
                                significant challenge was the implementation of a user-friendly
                                product filtering system based on irregular geographic delivery
                                zones.
                            </p>
                            <p>
                                {' '}
                                To address this, we created filtering mechanism that allowed users
                                to navigate products based on their specific geographic locations.
                            </p>
                            <p>
                                {' '}
                                This not only enhanced user experience but also accommodated the
                                irregularities in delivery zones efficiently. Our team implemented a
                                robust checkout system that dynamically calculated and applied
                                custom fees based on individual product attributes.
                            </p>
                            <p>
                                {' '}
                                This provided a transparent and accurate billing process for
                                customers, ensuring a smooth and reliable transaction experience. We
                                developed a specialized application for restaurants to seamlessly
                                communicate with the E-Commerce (Magento) Store.
                            </p>
                            <p>
                                {' '}
                                This app facilitated real-time communication, order management, and
                                synchronization with the online platform, streamlining operations
                                for restaurants and enhancing overall efficiency. Throughout the
                                development process, we maintained a collaborative and communicative
                                approach with the client. Regular updates, video chats, and
                                screen-sharing sessions were integral to ensuring alignment with the
                                client's vision and addressing any evolving requirements.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={deliverzerosection3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
