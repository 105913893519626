import React, { useState } from 'react';

function Faq({ className }) {
    const [showQues, setQues] = useState();
    const openQuestion = (value) => {
        setQues((prevValue) => (prevValue === value ? null : value));
    };
    return (
        <section
            className={`appie-faq-area solutions pb-90 pt-60 shopify-development-padding-bottom21 ${
                className || ''
            }`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">Unveiling Shopify Development Insights</h1>
                            <p className="font-size16px-p">
                                Your key questions answered-navigating the World of seamless
                                e-commerce solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(1)}
                                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="unveiling-font-size font-size--20px">
                                                What makes Shopify an ideal choice for my <br />{' '}
                                                e-commerce business?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 1 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Shopify is a robust e-commerce platform known
                                                    for its user-friendly interface, scalability,
                                                    and extensive range of features. Whether you're
                                                    a small business or a large enterprise, Shopify
                                                    provides a seamless and customizable solution to
                                                    meet your unique needs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(2)}
                                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="unveiling-font-size unveiling-margin-right font-size--20px ">
                                                Can you migrate my existing store to Shopify?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 2 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Absolutely! Our experienced team specializes in
                                                    seamless migrations. We can efficiently migrate
                                                    your existing store to Shopify, ensuring a
                                                    smooth transition of data, products, and
                                                    customer information while minimizing downtime.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(3)}
                                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="unveiling-font-size font-size--20px">
                                                How can Shopify customization enhance my online{' '}
                                                <br />
                                                store's uniqueness?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 3 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Yes, we offer continuous support and maintenance
                                                    services to ensure your Shopify store operates
                                                    seamlessly. From updates and security patches to
                                                    troubleshooting and feature enhancements, our
                                                    team is dedicated to keeping your e-commerce
                                                    platform running smoothly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(4)}
                                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="unveiling-font-size unveiling-margin-right29 font-size--20px">
                                                Where do I usually find FAQs in a page?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 4 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Naff Oxford vagabond in my flat chinwag blatant
                                                    grub tomfoolery that I bits and bobs up the duff
                                                    cras boot bevvy no biggie.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="faq-text text-center pt-40">
                            <p className="bold-text">
                                Need further assistance?{' '}
                                <a href="mailto:info@webnstack.com">Email us</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;
