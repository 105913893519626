import React from 'react';
import livelinensectionimage1 from '../../../assets/images/webnstack/livelinen-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-height livelinen-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="appie-hero-content text-center">
                            <h1
                                className="appie-title mob-livelinen-size"
                                style={{ fontSize: '60px', padding: '0px' }}
                            >
                                Luxurious Living-Showcasing Exquisite Textile Creations on
                                LiveLinen's Portfolio
                            </h1>
                            <p>
                                LiveLinen is a ultimate destination for luxurious and sustainable
                                linen bedding. The company is dedicated to bring you high-quality,
                                eco-friendly bedding options that will transform the way you sleep.
                                Thier linens are made from the finest European flax and are
                                carefully crafted to create a soft, breathable, and comfortable
                                sleep experience. At LiveLinen, they believe that bedding should not
                                only look beautiful, but also be kind to the environment. That's why
                                they have prioritize sustainability in every aspect of our business,
                                from materials to packaging. They invite us to discover and develop
                                the comfort and elegance of linen bedding with LiveLinen.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={livelinensectionimage1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
