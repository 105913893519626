import React from 'react';
import aboutThumb from '../../../assets/images/2nd-section.png';
import aboutThumb2 from '../../../assets/images/3rd-section.png';
import aboutThumb3 from '../../../assets/images/4th-section.png';

function About() {
    return (
        <section className="almonaplast-padding-top pt-200 pb-50" id="features">
            <div className="container pt-100">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The company embarked on a comprehensive initiative to enhance its
                                online presence by revamping the existing Almonaplast website. The
                                primary goal was to transition the current website to a fully
                                functional WordPress platform, aiming to attract both customers and
                                investors. The project involved the migration of all existing
                                website pages and PDFs to the new WordPress environment.
                            </p>
                            <p>
                                The client emphasized the importance of search engine optimization
                                (SEO) and set aggressive timelines for the project. They were keen
                                on incorporating an SEO plugin to optimize the website for search
                                engines. Additionally, the company prioritized a user-friendly
                                design to enhance the overall appeal of the website and attract a
                                broader audience.
                            </p>
                            <p>
                                To achieve this, the company planned to redesign the website,
                                re-structure the menu, and update the content using a specific
                                template.
                            </p>
                            <p>
                                One of the key requirements from the client was the implementation
                                of a dual-language feature, with the website available in both
                                Arabic and English. The project timeline was aggressive, adding an
                                element of urgency to the successful completion of the website
                                redesign and development.
                            </p>
                            <p>
                                In summary, the company sought to transform the Almonaplast website
                                into a dynamic and attractive online platform, leveraging WordPress,
                                SEO optimization, and a user-friendly design. The incorporation of
                                dual-language functionality and adherence to aggressive timelines
                                added to the complexity of the project, making it a collaborative
                                and challenging endeavor.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                The Webnstack team meticulously executed the project, throughout the
                                collaboration, the team worked closely with the WordPress
                                development, ensuring that every member was up to speed and fully
                                knowledgeable about the client's specific requirements and
                                expectations
                            </p>
                            <p>
                                During the development phase, a key focus was on the seamless
                                migration of various elements from the existing website to the new
                                WordPress platform. This included the transfer of animations,
                                images, and content to multiple pages, ensuring that the essence of
                                the original site was maintained. The team prioritized a
                                user-friendly design, integrating intuitive navigation and visually
                                appealing elements to enhance the overall user experience.
                            </p>
                            <p>
                                The team successfully integrated the required plugins, including SEO
                                plugins, to enhance the website's functionality and visibility on
                                search engines.
                            </p>
                            <p>
                                Furthermore, the website underwent a comprehensive redesign, and the
                                menu structure was restructured to improve navigation and
                                accessibility. The team provided various solutions throughout the
                                development process, addressing specific client needs and ensuring
                                that the final website would seamlessly integrate with all other
                                marketing elements.
                            </p>
                            <p>
                                In summary, the Webnstack team demonstrated a thorough understanding
                                of the client's expectations, effectively executing the migration of
                                content, implementing user-friendly design principles, and
                                integrating essential plugins. The collaborative effort resulted in
                                a redesigned website that not only met the client's requirements but
                                also aligned with contemporary web standards and marketing
                                strategies.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb2} alt="" />
                        </div>
                    </div>
                </div>
                <div className="align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3  wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h1 className="title">RESULTS & PROCESS</h1>
                                <p>
                                    Continuing the collaborative journey, Webnstack is actively
                                    engaging in regular maintenance and updates to ensure the
                                    Almonaplast website stays current, secure, and aligned with
                                    evolving industry standards.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    To foster sustained growth, a proactive content strategy is in
                                    place, featuring regular updates and fresh, relevant content
                                    that resonates with the target audience and supports ongoing SEO
                                    efforts.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    In response to user feedback and market trends, the team is
                                    exploring innovative features and functionalities, aiming to
                                    enhance the website's interactivity and user engagement.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    Webnstack's commitment extends beyond technical aspects, with
                                    ongoing consultations to explore new digital strategies and
                                    ensure that Almonaplast stays ahead in the dynamic digital
                                    landscape.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    The positive impact of the collaborative efforts is not only
                                    reflected in increased sales but also in the establishment of a
                                    robust foundation for future digital initiatives, solidifying
                                    the enduring success of the partnership.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
