import React from 'react';
import iconOne from '../../assets/images/icon/1.png';
import iconTwo from '../../assets/images/icon/2.png';
import iconThree from '../../assets/images/icon/3.png';
import iconFour from '../../assets/images/icon/4.png';

function ServicesHomeThree() {
    return (
        <section className="appie-service-area appie-service-3-area pt-195 pb-100" id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">WE PROVIDE BEST WEB DEVELOPMENT</h3>
                            <p>Tailored Web Development Services for a Digital Future.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={iconOne} alt="" />
                            </div>
                            <h4 className="appie-title">Java Development</h4>
                            <p>
                                We’re committed to building sustainable and high-quality Java
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={iconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">PHP Development</h4>
                            <p>
                                We’re committed to building sustainable and high-quality PHP
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={iconThree} alt="" />
                            </div>
                            <h4 className="appie-title">C++ Development</h4>
                            <p>
                                We’re committed to building sustainable and high-quality C++
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={iconFour} alt="" />
                            </div>
                            <h4 className="appie-title">.NET Development</h4>
                            <p>
                                We’re committed to building sustainable and high-quality Qt
                                solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeThree;
