import React from 'react';
import aboutThumb from '../../../assets/images/frag2nd-section.png';
import aboutThumb3 from '../../../assets/images/frag3rd-section.png';
import aboutThumb4 from '../../../assets/images/frag4th-section.png';

function About() {
    return (
        <section className="appie-about-3-area pt-200 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The client, with an existing e-commerce marketplace built on
                                Magento, sought a proficient development team to address specific
                                challenges.
                            </p>
                            <p>
                                {' '}
                                The primary focus was resolving payment gateway integration issues,
                                including the presentation of simple product images instead of
                                configurable product images in the cart, along with necessary
                                upgrades.
                            </p>
                            <p>
                                {' '}
                                Login issues affecting certain users were a critical concern,
                                prompting the need for a comprehensive fix.
                            </p>
                            <p>
                                {' '}
                                The company aimed to retain accounts through custom changes and
                                address shopping cart issues on both of their websites.
                            </p>
                            <p>
                                {' '}
                                The challenge extended beyond fixing existing problems to
                                implementing strategic enhancements.
                            </p>
                            <p>
                                {' '}
                                The client viewed this development initiative as an opportunity to
                                introduce new databases and implementations to their website.
                            </p>
                            <p>
                                {' '}
                                With a goal to boost sales in the publicly accessible e-store, they
                                envisioned a comprehensive refresh, targeting both the payment
                                gateway experience and the visual design of the shopping cart.
                            </p>
                            <p>
                                {' '}
                                The project unfolded as a multifaceted endeavor, addressing
                                immediate issues while strategically positioning the e-commerce
                                platform for sustained growth and improved user experience.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                In response to the challenges faced by the customer in integrating
                                their payment gateway with a Magento-based e-commerce marketplace,
                                our seasoned team of Magento developers embarked on a collaborative
                                journey.
                            </p>
                            <p>
                                {' '}
                                We effectively identified and addressed issues related to payment
                                gateway integration, login complications, and the display of product
                                images in the cart.
                            </p>
                            <p>
                                {' '}
                                Our approach involved crafting custom solutions to not only resolve
                                immediate concerns but also strategically retain accounts and update
                                shopping cart functionalities across both websites.
                            </p>
                            <p>
                                {' '}
                                Going beyond issue resolution, our team focused on revitalizing the
                                payment gateway experience and enhancing the visual design of the
                                cart. This initiative aimed to deliver a seamless and visually
                                appealing shopping experience for users navigating the platform.
                            </p>
                            <p>
                                {' '}
                                Throughout the development process, our commitment to excellence was
                                manifested in regular updates and transparent communication with the
                                customer.
                            </p>
                            <p>
                                {' '}
                                This ensured that the solutions provided aligned precisely with
                                their specific requirements and overarching business goals.
                            </p>
                            <p>
                                {' '}
                                Leveraging our expertise in Magento development, we are confident in
                                our ability to provide the customer with optimal solutions,
                                transforming their e-commerce challenges into opportunities for
                                growth and success.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-40">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">RESULTS & PROCESS</h2>
                            <p>
                                The result of our efforts resulted in a seamless site launch,
                                aligning perfectly with the aggressive timeline set by the client.
                            </p>
                            <p>
                                The success of the launch not only met but exceeded client
                                expectations, fostering a sense of satisfaction and contentment.
                            </p>
                            <p>
                                Introducing new databases and strategic implementations to the
                                website proved instrumental in enhancing the overall quality of the
                                cart and payment gateway interface.
                            </p>
                            <p>
                                {' '}
                                This transformation was not just a technical upgrade but a pivotal
                                enhancement in the digital user experience.
                            </p>
                            <p>
                                {' '}
                                As a direct outcome, the client reported a noticeable increase in
                                sales, showcasing the tangible impact of our comprehensive
                                solutions.
                            </p>
                            <p>
                                {' '}
                                Our team's commitment to delivering beyond expectations manifested
                                in the functionality of the website, which surpassed initial
                                anticipations.
                            </p>
                            <p>
                                {' '}
                                We provided a robust and tailored solution that precisely met the
                                client's requirements, contributing significantly to the achievement
                                of their overarching business goals.
                            </p>
                            <p>
                                {' '}
                                In essence, the result of our collaborative efforts was not just a
                                successful site launch but a transformative journey that elevated
                                the digital user experience, increased sales, and positioned the
                                client for sustained success in their e-commerce endeavors.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
