import React from 'react';
import aboutThumb from '../../../assets/images/cloudearly-2nd-section-image.png';
import aboutThumb3 from '../../../assets/images/cloudearly-3rd-section-image.png';
import aboutThumb4 from '../../../assets/images/cloudearly-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-100 pb-30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The challenge encompassed transforming a one-page portfolio into a
                                dynamic WordPress website, aiming to captivate both customers and
                                investors.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The client's vision included integrating new animations, images, and
                                multiple pages, with a focus on an engaging and user-friendly
                                design.
                            </p>
                            <p>
                                {' '}
                                The goal was to achieve a fully responsive website while undertaking
                                a comprehensive redesign, menu restructuring, and content update.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Additionally, the project faced the complexity of implementing new
                                functionalities within a tight timeline, coupled with the creation
                                of multiple branding documents, banners, and messaging components.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The collaborative nature of the project added another layer of
                                intricacy, making it a multifaceted endeavor.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                In response to the client's vision, we embarked on a transformative
                                journey, upgrading their static one-page portfolio to a dynamic
                                WordPress website.
                            </p>
                            <p>
                                {' '}
                                Our team effectively integrated new animations and compelling
                                images, crafting a visually captivating experience for potential
                                customers and investors.
                            </p>
                            <p>
                                {' '}
                                The addition of multiple pages allowed for a comprehensive showcase
                                of the company's offerings.
                            </p>
                            <p>
                                {' '}
                                Prioritizing user engagement, we engineered a user-friendly design
                                and ensured a fully responsive website environment, catering to a
                                diverse audience across devices.
                            </p>
                            <p>
                                Redesigning the website involved a meticulous overhaul, including
                                menu restructuring and content updates, breathing new life into the
                                digital space.
                            </p>
                            <p>
                                {' '}
                                To meet aggressive timelines, our team adeptly navigated the
                                complexity of implementing cutting-edge functionalities.
                            </p>
                            <p>
                                {' '}
                                This included incorporating new features seamlessly while
                                accommodating various branding elements such as documents, banners,
                                and messaging components.
                            </p>
                            <p>
                                {' '}
                                The project's collaborative nature added an extra layer of
                                intricacy, yet our dedicated efforts ensured a successful outcome,
                                setting the stage for an enriched online presence that resonates
                                with both customers and investors.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-50 flex-column-reverse flex-lg-row">
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h2 className="title">RESULTS & PROCESS</h2>
                                <p>
                                    The result of our efforts resulted in a seamless site launch,
                                    adhering to the established timeline and garnering satisfaction
                                    from both the client and investors.
                                </p>
                                <p>
                                    {' '}
                                    The website's unveiling marked a pivotal moment for Cloudearly,
                                    setting the stage for further enhancements and growth.
                                </p>
                                <p>
                                    {' '}
                                    In the aftermath of the successful launch, our collaboration
                                    with Cloudearly continues, with a strategic focus on WordPress
                                    development.
                                </p>
                                <p>
                                    {' '}
                                    The ongoing partnership is dedicated to expanding Cloudearly's
                                    digital footprint, emphasizing better optimization, lead
                                    generation, and increased conversion rates.
                                </p>
                                <p>
                                    Crucially, Cloudearly's unique vision and expectations were not
                                    just met but successfully preserved throughout the development
                                    process.
                                </p>
                                <p>
                                    The website's performance remained unaffected, a testament to
                                    our commitment to aligning with Cloudearly's custom
                                    requirements.
                                </p>
                                <p>
                                    {' '}
                                    As a result of the enhanced digital user experience, Cloudearly
                                    has observed a noticeable uptick in sales.
                                </p>
                                <p>
                                    {' '}
                                    This positive shift reflects the effectiveness of our
                                    collaborative efforts in not only meeting but surpassing
                                    Cloudearly's goals, solidifying the success of the project and
                                    paving the way for sustained growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
