import React from 'react';
import heroThumb from '../../../assets/images/assl/1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mb-100 assl-sec-one-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Safeguarding trust ecommerce platform showcasing security services
                            </h1>
                            <p>
                                Amalgamated Security Services Limited stands as a distinguished
                                provider of comprehensive security services, catering to a diverse
                                clientele that spans government agencies, financial institutions,
                                corporate entities, private establishments, retail businesses, and
                                industrial complexes. Our commitment to safeguarding human lives and
                                valuable assets is reflected in an extensive portfolio of products
                                and services. Our unwavering commitment to excellence and a robust
                                network enable us to deliver unparalleled security solutions
                                tailored to meet the unique needs of our valued clients.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
