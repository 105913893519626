import React from 'react';

function ServicesangularOne({ className }) {
    return (
        <section
            className={`appie-service-area mt-60 pt-60 pb-100 angular-margin-top ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title Magento">
                                WHY USE WEBNSTACK TO DEVELOP ANGULAR JS?
                            </h1>
                            <p className="font-size16">
                                Serving the demands with the best AngularJS development services.We
                                create strong Anuglarjs web solutions that captivate our clients and
                                provide the finest user experience by adhering to the cutting-edge
                                and best methodologies, standards, and proactive attitude of
                                Anuglarjs. Our clients benefit from the expertise of our
                                knowledgeable and skilled AngularJS Developers, who effectively
                                apply their knowledge and abilities to create a beautifully designed
                                AngularJS platform to boost brand growth. Regardless of how the
                                requirements behave, we provide the best to satisfy your specific
                                demands, which is why we have expanded our presence across the world
                                by working with different clients.We have a track record of creating
                                Angular online and mobile applications that work perfectly.
                                Therefore, allow AngularJS development services to help you achieve
                                your company goals now.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col min-width-angular-js">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-edit icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Latest Stack</h1>
                            <p className="text-justify">
                                We use all of in trend technologies code versions for your brand.
                            </p>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-sitemap icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Best Team</h1>
                            <p className="text-justify">
                                We have efficient, skill and proficient experienced team as our
                                developers.{' '}
                            </p>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-hands-helping icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Assistance</h1>
                            <p className="text-justify">
                                With the help of our timely maintenance services, build your brand.
                            </p>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-vote-yea icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Cost-Effective</h1>
                            <p className="text-justify">
                                At its Finest, Offering Competitive Pricing for an Exceptional
                                Value.
                            </p>
                        </div>
                    </div>
                    <div className="col min-width-angular-js">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-globe icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Global Acclaim</h1>
                            <p className="text-justify">
                                With International Prestige, we Globally Recognized
                                for our Excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesangularOne;
