import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-service-area pt-60 pb-60 crer-pg-pdd-top-30 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center abt-us-pdd-btm-0">
                            <h1 className="appie-title crer-pg-font-20">How We Work?</h1>
                            <p>WE DELIVER SOLUTION WITH THE GOAL OF TRUSTING RELATIONSHIPS</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-comment icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Discuss The Requirement</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-chart-area icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Analyse and Plan</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-palette icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Develop UI/UX</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-sync icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Process Development </h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-check-double icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Quality Testing </h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-truck-loading icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Live the Project</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
