import React from 'react';
import { Link } from 'react-router-dom';

function Navigation({ lang = false }) {
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {lang ? (
                <ul>
                    <li>
                        <a href="#">
                            أنا <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/">الصفحة الرئيسية 1</Link>
                            </li>
                            <li>
                                <Link to="/home-two">الصفحة الرئيسية 2</Link>
                            </li>
                            <li>
                                <Link to="/home-three">المنزل 3</Link>
                            </li>
                            <li>
                                <Link to="/home-four">المنزل 4</Link>
                            </li>
                            <li>
                                <Link to="/home-five">المنزل 5</Link>
                            </li>
                            <li>
                                <Link to="/home-six">الصفحة الرئيسية 6</Link>
                            </li>
                            <li>
                                <Link to="/home-seven">المنزل 7</Link>
                            </li>
                            <li>
                                <Link to="/home-eight">الصفحة الرئيسية 8</Link>
                            </li>
                            <li>
                                <Link to="/home-dark">الصفحة الرئيسية الظلام</Link>
                            </li>
                            <li>
                                <Link to="/home-rtl">الصفحة الرئيسية Rtl</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/service">خدمة</Link>
                    </li>
                    <li>
                        <a href="#">
                            الصفحات <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/about-us">عن</Link>
                            </li>
                            <li>
                                <Link to="/pageNotFound">خطأ</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            أخبار <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/news">صفحة الأخبار </Link>
                            </li>
                            <li>
                                <Link to="/news/single-news">أخبار واحدة</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/contacts">اتصل</Link>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        <Link to="/">HOME</Link>
                    </li>
                    <li>
                        <Link to="/our-projects">OUR PROJECTS</Link>
                    </li>
                    <li>
                        <Link to="/it-services">
                            IT SERVICES <i className="fal fa-angle-down" />
                        </Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/mobile-development">Mobile Development</Link>
                            </li>
                            <li>
                                <Link to="/ecommerce-development">Ecommerce Development</Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/magento-development">Magento Development</Link>
                                    </li>
                                    <li>
                                        <Link to="/shopify-development">Shopify Development</Link>
                                    </li>
                                    <li>
                                        <Link to="/magento-migration">Magento Migration</Link>
                                    </li>
                                    <li>
                                        <Link to="/headless-commerce">Headless Commerce</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/web-development">Web Development</Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/angular">Angular</Link>
                                    </li>
                                    <li>
                                        <Link to="/wordpress">Wordpress</Link>
                                    </li>
                                    <li>
                                        <Link to="/react-js">React Js</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <Link to="/hire">
                            HIRE
                            <i className="fal fa-angle-down" />
                        </Link>
                        <ul className="sub-menu">
                            <li>
                                <Link to="/hire-ecommerce-developer">Hire Ecommerce Developer</Link>
                            </li>
                            <li>
                                <Link to="/hire-Web-developer">Hire Web Developer</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/about-us">ABOUT US</Link>
                    </li>
                    <li>
                        <Link to="/contacts">CONTACTS</Link>
                    </li>
                </ul>
            )}
        </>
    );
}

export default Navigation;
