import React from 'react';

function ServicesAbout() {
    return (
        <section className="appie-services-2-area pt-70 crer-pg-pdd-top-30" id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-8">
                        <div className="appie-section-title">
                            <h3 className="appie-title">
                                Empowering Digital Excellence Across Globe
                            </h3>
                            <p>
                                Elevate your brand's digital journey with our all-encompassing suite
                                of services, designed to propel your online presence to new heights.
                                From creating seamless online shopping experiences and innovative
                                web solutions to pioneering headless commerce strategies, crafting
                                cutting-edge mobile applications, providing strategic IT
                                consultancy, and seamlessly integrating the latest Trend IT
                                Technologies - we are your end-to-end partner in achieving
                                unparalleled success in the digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp about-us"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fal fa-tv"></i>
                            </div>
                            <h4 className="title">E-commerce Development</h4>
                            <p className="text-justify">
                                Integrating cutting-edge technologies and personalized features to
                                elevate customer engagement and enhance overall brand appeal. With a
                                commitment to innovation, we shape e-commerce solutions that not
                                only meet current demands but also future-proof businesses in the
                                ever-evolving online landscape.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp about-us"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fal fa-code"></i>
                            </div>
                            <h4 className="title">Web Development</h4>
                            <p className="text-justify">
                                At the forefront of web innovation, we bring visions to life through
                                comprehensive website development services. Our team meticulously
                                blends creativity and technology, delivering websites that not only
                                captivate audiences but also excel in functionality, performance,
                                and user experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp about-us"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fal fa-user-friends"></i>
                            </div>
                            <h4 className="title">Headless Commerce </h4>
                            <p className="text-justify">
                                Revolutionizing the digital commerce landscape, we champion headless
                                commerce solutions that decouple the front-end and back-end,
                                offering unparalleled flexibility and scalability. Embrace a
                                future-ready approach with us, where your online presence adapts
                                seamlessly to evolving customer demands.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp about-us"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fal fa-mobile"></i>
                            </div>
                            <h4 className="title">Mobile Development </h4>
                            <p className="text-justify">
                                Empowering businesses in the mobile era, our mobile development
                                expertise spans across iOS and Android platforms. From captivating
                                mobile apps to responsive web solutions, we harness the latest
                                technologies to ensure your brand thrives in the rapidly evolving
                                mobile landscape.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp about-us"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fal fa-retweet"></i>
                            </div>
                            <h4 className="title">IT Consultancy </h4>
                            <p className="text-justify">
                                Guiding businesses through the complexities of IT, our consultancy
                                services provide strategic insights and solutions. From the
                                infrastructure optimization to digital transformation, we
                                collaborate with clients to navigate challenges, harness
                                opportunities, and achieve sustainable growth.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp about-us"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fal fa-bell"></i>
                            </div>
                            <h4 className="title">Trend IT Technologies</h4>
                            <p className="text-justify">
                                Staying ahead of the curve, we leverage cutting-edge technologies to
                                drive innovation. From AI and machine learning to blockchain and
                                IoT, we seamlessly integrate trendsetting technologies, ensuring our
                                solutions align with the dynamic landscape of the IT industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesAbout;
