import React from 'react';
import { Link } from 'react-router-dom';
import ecommercedevelopmentsection1st from '../../assets/images/webnstack/ecommerce-development-7th-section-1st-box-image.png';
import ecommercedevelopmentsection2st from '../../assets/images/webnstack/ecommerce-development-7th-section-2nd-box-image.png';

function Theme({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-100 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                Tailor-Made Storefronts for Headless eCommerce
                            </h1>
                            <p className="font-size16px-p">
                                Elevate your online presence with pwa technologies and frameworks,
                                tailored to perfection headless eCommerce.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title">
                                    Learn More About The Most Flexible B2B Headless eCommerce
                                    Platform?
                                </h1>
                                <p>
                                    Gain proficiency in PWA development with years of experience in
                                    the domain along with PWA technologies and frameworks to every
                                    stage of development and provide them with elegant solutions.
                                </p>
                                <Link
                                    className="main-btn ecommerce-development-btn-padding"
                                    to="/headless-commerce"
                                >
                                    Explore Our Headless Commerce
                                </Link>
                            </div>
                            <div className="thumb text-center">
                                <img src={ecommercedevelopmentsection1st} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="content">
                                <h1 className="title">
                                    Custom Storefront Development For Headless eCommerce?
                                </h1>
                                <p>
                                    Build an online store that is lightning-fast and SEO-friendly
                                    with as many features as you want without compromising any
                                    features that you truly want to use or a level of customer
                                    service that you honestly want to provide.
                                </p>
                                <Link
                                    className="main-btn ecommerce-development-btn-padding"
                                    to="/headless-commerce"
                                >
                                    Explore Our Headless Commerce
                                </Link>
                            </div>
                            <div className="thumb text-right">
                                <img src={ecommercedevelopmentsection2st} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Theme;
