import React from 'react';
import FunFactThumb from '../../assets/images/fun-fact-thumb.png';
import CounterUpCom from '../../lib/CounterUpCom';

function FunFact() {
    return (
        <section className="appie-fun-fact-area" id="fun-fact-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-fun-fact-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="appie-fun-fact-content">
                                        <h3 className="title">
                                            Join Our Elite Team of Development Warriors
                                        </h3>
                                        <p>
                                            Unleash your potential and embark on a journey of
                                            innovation with our skilled team of development
                                            warriors. We're not just a team; we're a family of tech
                                            enthusiasts dedicated to pushing boundaries and
                                            achieving greatness. If you have the passion for coding,
                                            the zeal for problem-solving, and the drive to be the
                                            best, join us on this exciting adventure. Together,
                                            let's shape the future of technology and be the ultimate
                                            warriors of development excellence.
                                        </p>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h4 className="title">
                                                        <CounterUpCom
                                                            endValue="25"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h4>
                                                    <span>Development Warriors</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h4 className="title">
                                                        <CounterUpCom
                                                            endValue="100"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h4>
                                                    <span>PROJECTS DONE</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h4 className="title">
                                                        <CounterUpCom
                                                            endValue="15"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h4>
                                                    <span> Learning Initiatives</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-fun-fact-play">
                                        <img src={FunFactThumb} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FunFact;
