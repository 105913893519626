import React from 'react';
import { Link } from 'react-router-dom';
import dealon1 from '../../../assets/images/webnstack/dealon.png';
import deelert1 from '../../../assets/images/webnstack/deelert.png';
import kakzonHamadAlbulooshi1 from '../../../assets/images/webnstack/kakzonHamadAlbulooshi.png';
import toyfiesta1 from '../../../assets/images/webnstack/toyfiesta.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-100 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Diverse Solutions,Shared Excellence:</h3>{' '}
                            <h3 className="appie-title mt-0">A Showcase of Our Related Projects</h3>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/dealon">Dealon</Link>
                                </h4>
                                <p>
                                    Sellers Fee-Free Ecommerce Marketplace for Seamless Online
                                    Trading
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={dealon1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/kakzon">Kakzon</Link>
                                </h4>
                                <p>
                                    Sport in Style - Showcasing Our Ecommerce Excellence in
                                    Sportswear Stores
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={kakzonHamadAlbulooshi1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/toyfiesta">Toyfiesta</Link>
                                </h4>
                                <p>
                                    Whimsical Wonders: A Tailored Ecommerce Toy Store Experience for
                                    Kids
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={toyfiesta1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-3">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/deelert">Deelert</Link>
                                </h1>
                                <p>
                                    Customized Ecommerce Marketplace for Furniture, Interior, and
                                    Home Décor
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={deelert1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
