import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterThree from '../Shared/SharedFooterThree';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import SharedProject from '../Shared/SharedProject';
import SharedShowCase from '../Shared/SharedShowCase';
import About from './About';
import Download from './Download';
import FunFact from './FunFact';
import Hero from './Hero';
import Services from './Services';
import ServicesHire from './ServicesHire';

function Hire() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Hire | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <Hero />
            <Services />
            <FunFact />
            <About />
            <ServicesHire />
            <SharedShowCase />
            <Download />
            <SharedProject />
            <SharedFooterThree />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default Hire;
