import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import HeroNews from './HeroNews';
import Projects from './Projects';

function Portfolio() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Our Projects | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <HeroNews
                title="Our Projects"
                breadcrumb={[
                    { link: '/', title: 'Home' },
                    { link: '/our-projects', title: 'Our Projects' },
                ]}
            />
            <Projects />
            <SharedFooter />
            <BackToTop />
        </>
    );
}

export default Portfolio;
