import React from 'react';

function Services() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-195 pb-100 react-js-padding-bottom-20 react-js-padding-top0"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 mt-5 react-js-margin-top-0">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20-important">
                                WebNStack's Premier React.js Services
                            </h1>
                            <p className="font-size-16px">
                                Crafting seamless user experiences and high-performance web
                                applications using the power of react.js
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3 react-js-margin-top-0
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-check-circle icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Developing Interactive Platforms</h1>
                            <p className="text-justify">
                                We develop users interact platform with our exceptional and highly
                                skilled React JS technical professionals, providing a new dimension
                                to the development of your company.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-sitemap icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Migrating to React Js</h1>
                            <p className="text-justify">
                                For the hassle-free transfer of your data to the most cutting-edge
                                platform created by our team, our React JS professionals provide you
                                highly-distinguished migration services.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-react icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">React JS Web Development</h1>
                            <p className="text-justify">
                                Our React JS web development services enable us to provide a rich
                                user experience for your company. Our React JS developers provide
                                responsive solutions that give you an entirely web presence.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="far fa-gem icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Tailored Development</h1>
                            <p className="text-justify">
                                We adjust the React JS software for you after determining the needs
                                of your company. As the top React JS development firm, we offer
                                cutting-edge solutions for your need.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="far fa-smile icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">User Experience</h1>
                            <p className="text-justify">
                                Employ our React JS developers to create eye-catching, highly
                                dynamic UI/UX designs with a safe, secure and attractive online
                                platform that is unique to your company succcess and growth.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-user icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Custom Plugins</h1>
                            <p className="text-justify">
                                Custom plugins and extensions for your online and mobile apps are
                                developed by our React Js developers. We are the leading React Js
                                development firm, providing cutting-edge technical advancements.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
