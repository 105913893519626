import React, { useState } from 'react';

function Faq({ className }) {
    const [showQues, setQues] = useState();
    const openQuestion = (value) => {
        setQues((prevValue) => (prevValue === value ? null : value));
    };
    return (
        <section
            className={`appie-faq-area pt-30 pb-60 appie-faq-area pt-30 pb-60 ${className || ''}`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20-important">
                                Unveiling Wordpress Development Insights
                            </h1>
                            <p className="font-size-16px">
                                Your key questions answered-navigating the world of seamless
                                e-commerce solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(1)}
                                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--20px font-size16">
                                                Is WordPress right for me?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 1 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    WordPress currently runs 44% of all websites on
                                                    the internet and is the most widely used content
                                                    management system in the world. It facilitates
                                                    the creation and modification of programmes as
                                                    well as layouts by designers and developers. It
                                                    also has a tendency to rank higher in search
                                                    results, which increases the likelihood that
                                                    more people will visit your website.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(2)}
                                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--20px font-size16">
                                                Will I get dedicated WordPress developers for my
                                                <br />
                                                website?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 2 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Yes, you may employ specialised WordPress
                                                    developers at WebNStack who will concentrate
                                                    exclusively on the construction of your website
                                                    while satisfying all of your needs and providing
                                                    superior results.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(6)}
                                    className={`accrodion ${showQues === 6 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--20px font-size16">
                                                Will you create a website that is mobile-responsive?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 6 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Websites that are mobile-responsive are
                                                    essential for customers that anticipate traffic
                                                    from mobile devices. Our tech experts are
                                                    skilled in creating customised WordPress
                                                    websites that are responsive to mobile devices
                                                    and allow the customer to drive traffic at an
                                                    unrivalled rate.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(3)}
                                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--20px font-size16 wordpress-margin-right">
                                                Do you design customised themes and plugins?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 3 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Yes. Additionally, we provide custom WordPress
                                                    theme and plugin development services. You may
                                                    discuss any specific design or feature
                                                    requirements you may have with our team, and we
                                                    will take care of it.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(4)}
                                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--20px font-size16 wordpress-margin-right">
                                                Is WordPress a better CMS to use?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 4 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    WordPress is now much more than simply a
                                                    blogging platform. It is now the industry's top
                                                    CMS and can be completely scaled and customised
                                                    to fit the demands of your company. It is
                                                    license-free and can compete with any enterprise
                                                    solution on the market.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(5)}
                                    className={`accrodion ${showQues === 5 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="font-size--20px font-size16 wordpress-margin-right">
                                                What price structures are offered?{' '}
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 5 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    WordPress developers can be hired on an hourly,
                                                    fixed, or on-site basis. The price varies for
                                                    each custom WordPress development job. To
                                                    discuss your needs, get in touch with our
                                                    specialists right now.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="faq-text text-center pt-40">
                            <p className="bold-text">
                                Need further assistance?{' '}
                                <a href="mailto:info@webnstack.com">Email us</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;
