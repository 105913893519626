import React from 'react';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';
import wordpressImage from '../../assets/images/webnstack/wordpress-1st-section-image-1.png';
import wordpressImages from '../../assets/images/webnstack/wordpress-1st-section-image-2.png';

function Hero({ className }) {
    return (
        <section
            className={`appie-hero-area pb-0 padding-top-hero-wordpress WordPress-padding-top ${
                className || ''
            }`}
        >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-hero-content">
                            <span className="preheading Magento-p">
                                Hire A WordPress Developers
                            </span>
                            <h1 className="appie-title">
                                In comparison to normal websites, we create and design with the next
                                level of functionality
                            </h1>
                            <p className="Magento-p">
                                Our Experts at WebNStack IT Solution are educated and experienced in
                                their respective industries. Feel free to employ Developers from our
                                organization for industry-specific ideas based on your expectations.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-hero-thumb wordpress-height-317">
                            <div
                                className="thumb wow animated"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img className="wordpress-img-width" src={wordpressImage} alt="" />
                            </div>
                            <div
                                className="thumb-2 wow animated fadeInRight box-shadow-none Our-Experts-wordpress"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <img className="wordpress-img-siz" src={wordpressImages} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-shape-1">
                <img src={shapeTwo} alt="" />
            </div>
            <div className="hero-shape-2">
                <img src={shapeThree} alt="" />
            </div>
            <div className="hero-shape-3">
                <img src={shapeFour} alt="" />
            </div>
        </section>
    );
}

export default Hero;
