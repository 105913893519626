import React, { useState } from 'react';
import CounterUpCom from '../../lib/CounterUpCom';
import PopupVideo from '../PopupVideo';

function Faq() {
    const [showQuestion, setQuestion] = useState(0);
    const [showVideo, setVideoValue] = useState(false);
    const openQuestion = (e, value) => {
        e.preventDefault();
        setQuestion(value);
    };
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <div className="appie-faq-8-area pt-100 pb-100" id="counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="appie-section-title">
                                <h1 className="appie-title mobile-development-text-align">
                                    Looking to build an app?
                                </h1>
                                <p className="font-size16px-p">
                                    We develop apps the generate revenue and drive growth.
                                </p>
                            </div>
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp wow fadeIn faq-accrodion"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 0 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 0)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h1
                                                    className="according font-size16"
                                                    style={{ fontSize: '20px' }}
                                                >
                                                    How do you ensure the security of mobile
                                                    <br />
                                                    applications developed by your company?
                                                </h1>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 0 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Security is a top priority for us. We employ
                                                        industry-best practices throughout the
                                                        development lifecycle, including secure
                                                        coding, rigorous testing, and compliance
                                                        with the latest security standards. Our
                                                        commitment to data protection ensures that
                                                        your mobile applications are not only
                                                        feature-rich but also robust and secure.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 1 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 1)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h1
                                                    className="according font-size16"
                                                    style={{ fontSize: '20px' }}
                                                >
                                                    Can you integrate third-party services and{' '}
                                                    <br /> APIs into mobile apps?
                                                </h1>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Absolutely. We understand the importance of
                                                        seamless integration with third-party
                                                        services and APIs to enhance the
                                                        functionality and features of your mobile
                                                        app. Our development team has extensive
                                                        experience in integrating a wide range of
                                                        services, such as payment gateways, social
                                                        media, and analytics tools, ensuring your
                                                        app is both versatile and powerful.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`accrodion ${
                                            showQuestion === 2 ? 'active' : ''
                                        }`}
                                        onClick={(e) => openQuestion(e, 2)}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h1
                                                    className="according font-size16"
                                                    style={{ fontSize: '20px' }}
                                                >
                                                    Can you handle both iOS and Android app
                                                    development?
                                                </h1>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQuestion === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Absolutely. Our mobile development team is
                                                        proficient in both iOS and Android
                                                        platforms. Whether you need a native app for
                                                        a specific platform or a cross-platform
                                                        solution, we have the skills and experience
                                                        to deliver high-quality applications that
                                                        cater to the diverse needs of your
                                                        target audience.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="faq-play-box">
                    <div className="faq-play-counter">
                        <div className="box-1">
                            <h1 className="text-white " style={{ fontSize: '22px' }}>
                                <CounterUpCom endValue="27" sectionSelect="counter" />+
                            </h1>
                            <span>ACTIVE CLIENTS</span>
                        </div>
                        <div className="box-1 box-2">
                            <h1 className="text-white " style={{ fontSize: '22px' }}>
                                <CounterUpCom endValue="76" sectionSelect="counter" />+
                            </h1>
                            <span>PROJECTS DONE</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faq;
