import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-service-area Shopifyversesss pt-60 pb-60 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title FOCUS">WEBNSTACK'S AREAS OF FOCUS</h1>
                            <p className="font-size16px-p">
                                We help in driving customer satisfaction, expanding audiences, and
                                boosting sales. We assure you that your website is developed with a
                                robust business strategy and advanced top-notch development
                                technologies.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-headset icon-size-30 icon-size-35"></i>
                            </div>
                            <h1 className="appie-title">Contact Us</h1>
                            <p className="text-justify">
                                Send us your website brief if you have one so we can determine
                                whether we’re a suitable fit for your project. We may assist you in
                                organising requirements and objectives with website brief if you
                                don’t already have one.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-tasks icon-size-30 icon-size-35"></i>
                            </div>
                            <h1 className="appie-title">Reqirements</h1>
                            <p className="text-justify">
                                If Webnstack thinks your project would be a suitable fit, we’ll set
                                up a discovery meeting. We will better comprehend with your
                                instructions and the size and complexity of your project thanks to
                                this conversation.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-quote-left icon-size-30 icon-size-35"></i>
                            </div>
                            <h1 className="appie-title">Concept & Quote</h1>
                            <p className="text-justify">
                                We’ll be able to provide you a website proposal and quotation, as
                                well as an anticipated timescale for your project, after we have a
                                thorough understanding of your development needs and the scope of
                                your project.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user icon-size-30 icon-size-35"></i>
                            </div>
                            <h1 className="appie-title">USER EXPERIENCE </h1>
                            <p className="text-justify">
                                Users expect a frictionless experience when researching products,
                                checking out and then receiving their products. Our team is here to
                                ensure that your end to end customer buying experience is 5 stars!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
