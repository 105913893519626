import React from 'react';
import rkbulderssection2 from '../../../assets/images/webnstack/rk-builders-2nd-section-image.png';
import rkbuilderssection3 from '../../../assets/images/webnstack/rk-builders-3rd-section-image.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10
        "
            id="features"
        >
            <div className="container pt-120 mob-p-0">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={rkbulderssection2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The company aimed to enhance its digital presence within the
                                construction industry by commissioning the development of a
                                WordPress website for their construction services.{' '}
                            </p>
                            <p>
                                The primary objective was to establish a digital hub where the
                                company could not only display their diverse array of services but
                                also highlight their latest completed projects and ongoing ventures
                                through detailed descriptions and visually engaging images.
                                Additionally, the client placed a strong emphasis on achieving full
                                responsiveness, ensuring that the website would adapt seamlessly
                                across various devices, catering to the diverse preferences of their
                                audience.
                            </p>
                            <p>
                                {' '}
                                In summary, the company's vision encompassed the development of a
                                WordPress website that not only showcased their construction
                                services comprehensively but also presented their portfolio of
                                projects in a visually compelling manner. The incorporation of
                                animations, high-quality images, multiple pages, and a user-friendly
                                design aimed to elevate their online presence, attracting and
                                engaging their target audience in the competitive construction
                                market. The emphasis on full responsiveness underscored their
                                commitment to providing an optimal user experience across all
                                digital platforms.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                The WebNStack team collaborated closely with the client to craft a
                                new construction website tailored to their unique needs. Our focus
                                was on creating a seamless user experience, and we achieved this by
                                developing an easily navigable gallery page featuring the client's
                                top project categories. Each project item within the gallery was
                                enriched with multiple images, detailed project descriptions, and a
                                user-friendly lightbox feature, allowing for an optimal presentation
                                of project photos.
                            </p>
                            <p>
                                {' '}
                                Throughout the website development process, we incorporated new
                                animations, dynamic images, and enriched content across multiple
                                pages. This approach not only added visual appeal but also
                                contributed to a more engaging and informative digital space.
                            </p>
                            <p>
                                {' '}
                                Recognizing the importance of accessibility across various devices,
                                we ensured the website was fully responsive, offering a seamless
                                experience for both desktop and mobile users.
                            </p>
                            <p>
                                {' '}
                                In summary, the collaboration with WebNStack resulted in the
                                creation of a dynamic and visually appealing construction website.
                                Through careful attention to detail, including the incorporation of
                                animations, rich content, and a user-friendly design, we have
                                successfully delivered a platform that not only showcases the
                                client's projects but also provides a comprehensive representation
                                of their services.
                            </p>
                            <p>
                                {' '}
                                The website's responsiveness across desktop and mobile platforms
                                ensures a broad reach and optimal engagement for all visitors.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={rkbuilderssection3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
