import React from 'react';
import { Link } from 'react-router-dom';
import assl1 from '../../../assets/images/webnstack/assl.png';
import dealon1 from '../../../assets/images/webnstack/dealon.png';
import deliverzero12 from '../../../assets/images/webnstack/deliverzero.png';
import sporthuis1 from '../../../assets/images/webnstack/sporthuis.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-80 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Diverse Solutions,Shared Excellence:</h3>
                            <h3 className="appie-title mt-0">
                                {' '}
                                A Showcase of Our Related Projects
                            </h3>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="px-4 py-4">
                                <h4 className="title">
                                    <Link to="/our-projects/dealon">Dealon</Link>
                                </h4>
                                <p>
                                    Sellers Fee-Free Ecommerce Marketplace for Seamless Online
                                    Trading.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={dealon1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="px-4 py-4">
                                <h4 className="title">
                                    <Link to="/our-projects/deliverzero"> Deliverzero</Link>
                                </h4>
                                <p>
                                    Revolutionizing Sustainable Dining: Deliverzero's Unique
                                    E-commerce Platform.{' '}
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={deliverzero12} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-2">
                                <h4 className="title">
                                    <Link to="/our-projects/assl">ASSL</Link>
                                </h4>
                                <p>
                                    Safeguarding trust ecommerce platform showcasing a variety of
                                    security services.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={assl1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-3">
                                <h4 className="title">
                                    <Link to="/our-projects/sporthuis">Sporthuis</Link>
                                </h4>{' '}
                                <p>
                                    Fitness essentials unleashed-ecommerce marketplace for sport and
                                    gym equipment.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={sporthuis1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
