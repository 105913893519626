import React from 'react';
import hireforthsection from '../../assets/images/Image-1.png';
import hireforthsectionimage1 from '../../assets/images/image-2.png';

function About() {
    return (
        <section className="appie-about-3-area pt-100 hire-pt0 mob-pt30" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="appie-traffic-title">
                        <h1 className="title hire-font-size hire-show-case-padding">
                            Employ Devoted First-Class Developers We Go Above and Beyond to
                            Accelerate Your Company Development
                        </h1>
                        <p>
                            Hiring WebNStack’s dedicated developers is a fantastic strategy to
                            assure project quality and timeliness. Employing dedicated developers
                            can help you save money and avoid the risks associated with outsourcing.
                            Companies that hire dedicated developers may be assured of the
                            competence and experience of the individual they are hiring, as well as
                            having direct access to the developer throughout the project’s lifetime.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft abt-us-mg-btm-0"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={hireforthsection} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div>
                                <p className="mob-ml8">
                                    At WebNStack, we specialize in offering a diverse range of
                                    online, mobile, eCommerce, CRM, and data solutions tailored to
                                    address specific business challenges. Our mission is centered
                                    around resolving a multitude of unique business difficulties by
                                    developing customized and efficient software applications.
                                </p>
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <p className="mob-ml8">
                                    We are driven by enthusiasm to bring your ideas to life and help
                                    you achieve your goals through the seamless integration of
                                    innovative solutions. Our dedicated team is committed to
                                    providing comprehensive support and expertise to ensure your
                                    business thrives in the ever-evolving digital landscape.
                                </p>
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <p className="mob-ml8">
                                    Partner with WebNStack, where dedication, experience, and a
                                    forward-thinking approach converge to transform your ideas into
                                    unparalleled solutions.
                                </p>
                            </div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="row">
                            <ul className="liste-image mob-ml8">
                                <li>Customers benefit from a comprehensive approach</li>
                                <li> Full control over your project</li>
                                <li>
                                    Development that is cost-effective, cheap, favourable, and
                                    adaptable
                                </li>
                                <li>Full command over your project's files and code</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center flex-column-reverse flex-lg-row">
                    <div className="appie-traffic-title mb-0">
                        <h1 className="title mobile-heading-size">
                            WEBNSTACK'S DEVELOPERS POTENTIAL
                        </h1>
                        <p>
                            INVEST IN QUALITY WEB DEVELOPMENT FOR A STANDOUT ONLINE PRESENCE WITH
                            OUR KNOWLEDGE AND SKILL.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div>
                                <p className="mob-ml8">
                                    Our commitment to excellence extends across the entire spectrum
                                    of software development, providing a one-stop solution for our
                                    clients. From conceptualization and design to development,
                                    testing, and deployment, we streamline the entire life cycle to
                                    maximize efficiency and minimize costs. Having successfully
                                    executed over 70 projects, our experience spans various
                                    industries, ensuring that we understand the unique challenges
                                    and opportunities that each sector presents.
                                </p>
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <p className="mob-ml8">
                                    At WebNStack, we thrive on turning ideas into reality, and our
                                    passion for seamless solutions is reflected in the success
                                    stories of our clients. You're partnering with a dedicated group
                                    of professionals committed to achieving your goals through
                                    innovation, integrity, and excellence.
                                </p>
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <p className="mob-ml8">
                                    Beyond our extensive project portfolio, we are dedicated to
                                    pushing the boundaries of innovation. Our teams continually
                                    explore emerging technologies and methodologies to stay at the
                                    forefront of the industry. By embracing the latest advancements,
                                    we ensure that your solutions not only meet current needs but
                                    are also future-proof, setting the stage for sustained success
                                    in an ever-evolving digital landscape.
                                </p>
                            </div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="row">
                            <ul className="liste-image mob-ml8">
                                <li>Customer satisfaction is our primary priority</li>
                                <li>Daily project reporting</li>
                                <li>Developers direct communication availability</li>
                                <li>Technical help will be available at all times</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight abt-us-mg-btm-0"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={hireforthsectionimage1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
