import React, { useRef } from 'react';
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import showCaseTggOne from '../../assets/images/webnstack/team-section-4.svg';
import showCasetwo from '../../assets/images/webnstack/team-section-4Slider.svg';
import showCasethree from '../../assets/images/webnstack/team-section-4Sliderb.svg';
import showCasefore from '../../assets/images/webnstack/team-section-4Sliderbd.svg';

function ShowCase() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <section className="appie-showcase-area pt-0 mt-5 pb-4">
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="appie-section-title text-center">
                                    <h3 className="appie-title">OUR UNFORGETTABLE TEAM MEMORIES</h3>
                                    <p>
                                        From team-building activities and company events to everyday
                                        moments in the office, these memories remind us of the
                                        strong bonds we’ve formed and the shared experiences that
                                        have shaped us as a team. Join us as we take a walk down
                                        memory lane and relive some of our favourite
                                        moments together.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row appie-showcase-slider">
                            <div className="col-lg-12">
                                <Slider ref={sliderRef} {...settings}>
                                    <div>
                                        <div>
                                            <img src={showCaseTggOne} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={showCasetwo} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={showCasethree} alt="" />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={showCasefore} alt="" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </section>
    );
}

export default ShowCase;
