import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import DevelopmentServices from './DevelopmentServices';
import Faq from './Faq';
import Features from './Features';
import Hero from './Hero';
import Sponser from './Sponser';

import Project from './Project';
import Services from './Services';

import Testimonial from './Testimonial';
import Traffic from './Traffic';

function WordPress() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Wordpress | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader action={drawerAction.toggle} />
            <Hero />
            <Services />
            <Features />
            <Traffic />
            <DevelopmentServices />
            <Testimonial />
            <Faq />
            <Sponser />
            <Project />
            <SharedFooter />
            <BackToTop />
        </>
    );
}

export default WordPress;
