import React from 'react';
import SporthuisSectionImage1 from '../../../assets/images/webnstack/sporthuis-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area sporthuis-sec-padding-top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-kakzon-size">
                                Fitness Essentials Unleashed-Ecommerce Marketplace for Sport and Gym
                                Equipment
                            </h1>
                            <p>
                                SportHuis is a sportsapperals / sportswear online marketplace that
                                offers the highest quality clothing and accessories for your
                                favourite sport whether you play tennis, football, Hockey, Fitness
                                or Running. With exclusive brands, superior materials, and the most
                                affordable shipping prices, Sporthuis provides with biggest brands
                                like Adidas, Nike, Babolat, Ascis, Wilson, Bjorn, Borg, Dita,
                                Princess, Brabo and many more with the greatest online shopping
                                experience. Sporthuis is becoming the most popular platform.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={SporthuisSectionImage1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
