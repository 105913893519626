import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ecommercedevelopmentsection1st from '../../assets/images/webnstack/ecommerce-development-1st-section.png';
import PopupVideo from '../PopupVideo';

function Hero() {
    const [showVideo, setVideoValue] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className="appie-hero-area appie-hero-5-area appie-hero-3-area padding-top-hero-ecommerce-development NEEDS">
                <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-lg-10">
                            <div className="appie-hero-content text-center">
                                <h1 className="appie-title">eCommerce Development </h1>
                                <p>
                                    WEBNSTACK ECOMMERCE INTERFACE-A COMPLETE COMPREHENSIVE SOLUTIONS
                                    TO YOUR NEEDS
                                </p>
                                <div className="hero-btns">
                                    <Link
                                        className="main-btn magento-hero main-btn-border-color"
                                        to="/contacts"
                                    >
                                        Get a Quote
                                    </Link>
                                </div>
                                <div
                                    className="thumb mt-80 wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <img src={ecommercedevelopmentsection1st} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
