import React from 'react';
import heroThumb from '../../../assets/images/cloudearly-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-cloudearly-top-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center mb-70">
                            <h1 className="appie-title mob-myt-size">
                                Cloud Innovation Unleashed-Cloudearly's Tailored Solutions in the
                                Digital Sky
                            </h1>
                            <p>
                                Founded in 2019, Cloudearly is a professional services firm
                                partnered with Toptal which provides comprehensive consultancy,
                                implementation, and support services on cloud platform, primarily
                                focusing on Salesforce. Cloudearly is a team of highly skilled
                                Salesforce professionals specializing in delivering extensive
                                initiatives, and guiding organizations through more than 25
                                Salesforce implementations. The company has strategically built and
                                facilitated cross-functional teams, fostering automation and
                                innovation, yielding rapid results. Exploiting their vast knowledge,
                                communication skills, and industry experience, they enhance the
                                client journey, validating them as a trusted advisor to customers.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
