import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Drawer({ drawer, action, lang }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        // e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(item === value ? '' : value);
        }
    };
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {lang ? (
                <>
                    <div
                        onClick={(e) => action(e)}
                        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                    ></div>
                    <div className="offcanvas_menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className={`offcanvas_menu_wrapper offcanvas-overflow ${
                                            drawer ? 'active' : ''
                                        }`}
                                    >
                                        <div className="canvas_close">
                                            <a href="#" onClick={(e) => action(e)}>
                                                <i className="fa fa-times"></i>
                                            </a>
                                        </div>
                                        <div className="offcanvas-brand text-center mb-40">
                                            <img src={logo} alt="" />
                                        </div>
                                        <div id="menu" className="text-left ">
                                            <ul className="offcanvas_main_menu">
                                                <li
                                                    onClick={(e) => handler(e, 'home')}
                                                    id="home"
                                                    className="menu-item-has-children active"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                    <a href="#">أنا</a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'home' ? itemSize : '0px',
                                                        }}
                                                    >
                                                        <li>
                                                            <Link to="/">الصفحة الرئيسية 1</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-two">
                                                                الصفحة الرئيسية 2
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-three">المنزل 3</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-four">المنزل 4</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-five">المنزل 5</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-six">
                                                                الصفحة الرئيسية 6
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-seven">المنزل 7</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-eight">
                                                                الصفحة الرئيسية 8
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-dark">
                                                                الصفحة الرئيسية الظلام
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/home-rtl">
                                                                الصفحة الرئيسية Rtl
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'service')}
                                                    id="service"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/service">خدمة</Link>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'pages')}
                                                    id="pages"
                                                    className="menu-item-has-children active"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                    <a href="#">الصفحات</a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'pages' ? itemSize : '0px',
                                                        }}
                                                    >
                                                        <ul className="sub-menu">
                                                            <li>
                                                                <Link to="/about-us">عن</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/error">خطأ</Link>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'news')}
                                                    id="news"
                                                    className="menu-item-has-children active"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                    <a href="#">أخبار</a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'news' ? itemSize : '0px',
                                                        }}
                                                    >
                                                        <ul className="sub-menu">
                                                            <li>
                                                                <Link to="/news">
                                                                    صفحة الأخبار{' '}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/news/single-news">
                                                                    أخبار واحدة
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'contact')}
                                                    id="contact"
                                                    className="menu-item-has-children active"
                                                >
                                                    <Link to="/contacts">اتصل</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="offcanvas-social">
                                            <ul className="text-center">
                                                <li>
                                                    <a href="https://www.facebook.com/webnstackindia/">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://x.com/webnstack_?">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/webnstack_">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/webnstack/">
                                                        <i className="fab fa-dribbble"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="footer-widget-info">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-envelope"></i>{' '}
                                                        support@appie.com
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-phone"></i> +(642) 342
                                                        762 44
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="fal fa-map-marker-alt"></i>{' '}
                                                        442 Belle Terre St Floor 7, San Francisco,
                                                        AV 4206
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        onClick={(e) => action(e)}
                        className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
                    ></div>
                    <div className="offcanvas_menu">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className={`offcanvas_menu_wrapper offcanvas-overflow ${
                                            drawer ? 'active' : ''
                                        }`}
                                    >
                                        <div className="canvas_close click-aro">
                                            <a href="#" onClick={(e) => action(e)}>
                                                <i className="fa fa-times"></i>
                                            </a>
                                        </div>
                                        <div className="offcanvas-brand text-center mb-40">
                                            <img src={logo} alt="" />
                                        </div>
                                        <div id="menu" className="text-left ">
                                            <ul className="offcanvas_main_menu">
                                                <li>
                                                    <Link to="/">HOME</Link>
                                                </li>
                                                <li>
                                                    <Link to="/our-projects">OUR PROJECTS</Link>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'services')}
                                                    id="services"
                                                    className="menu-item-has-children active cursor-it-services"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                    <a
                                                        className="it-sevicers-display-inline-block"
                                                        href="/it-services"
                                                    >
                                                        IT SERVICES
                                                    </a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'services'
                                                                    ? itemSize
                                                                    : '0px',
                                                        }}
                                                    >
                                                        <li>
                                                            <Link to="/mobile-development">
                                                                Mobile Development
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a href="/ecommerce-development">
                                                                Ecommerce Development
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <Link to="/magento-development">
                                                                Magento Development
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/shopify-development">
                                                                Shopify Development
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/magento-migration">
                                                                Magento Migration
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/headless-commerce">
                                                                Headless Commerce
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/web-development">
                                                                Web Development
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/angular">Angular</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/wordpress">Wordpress</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/react-js">React Js</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li
                                                    onClick={(e) => handler(e, 'hire')}
                                                    id="hire"
                                                    className="menu-item-has-children active cursor-it-services"
                                                >
                                                    <span className="menu-expand">
                                                        <i className="fa fa-angle-down cursor-it-services"></i>
                                                    </span>
                                                    <a
                                                        className="it-sevicers-display-inline-block"
                                                        href="/hire"
                                                    >
                                                        HIRE
                                                    </a>
                                                    <ul
                                                        className="sub-menu"
                                                        style={{
                                                            height:
                                                                item === 'hire' ? itemSize : '0px',
                                                        }}
                                                    >
                                                        <li>
                                                            <Link to="/hire-ecommerce-developer">
                                                                Hire Ecommerce Developer
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/hire-Web-developer">
                                                                Hire Web Developer
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/about-us">ABOUT US</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contacts">CONTACTS</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="offcanvas-social">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="https://www.facebook.com/webnstackindia/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="facebook"
                                                    >
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://x.com/webnstack_?"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="twitter"
                                                    >
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.instagram.com/webnstack_"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="instagram"
                                                    >
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.linkedin.com/company/webnstack/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="linkedin"
                                                    >
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="footer-widget-info width-100">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="mailto:info@webnstack.com"
                                                        aria-label="info@webnstack.com"
                                                    >
                                                        <i className="fal fa-envelope" />{' '}
                                                        info@webnstack.com
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="tel:8905599339"
                                                        aria-label="+91 89055-99339"
                                                    >
                                                        <i className="fal fa-phone rotate-custom" />{' '}
                                                        +91 89055-99339
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://maps.app.goo.gl/LfwX5SKEyHXgn2ux5"
                                                        aria-label=" 1st Floor, 206-207, Heera Nagar-A Dhawas Puliya, Service
                                        Lane, 200 Feet Bypass Rd, Jaipur, Rajasthan, India"
                                                    >
                                                        <i className="fal fa-map-marker-alt" />
                                                        1st Floor, 206-207, Heera Nagar-A Dhawas
                                                        Puliya, Service Lane, 200 Feet Bypass Rd,
                                                        Jaipur, Rajasthan, India
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Drawer;
