import React from 'react';
import mobileDevelopmentService from '../../assets/images/angularThree.png';

function Services() {
    return (
        <section
            className="appie-services-2-area appie-services-8-area pt-90 pb-55 BENEFITS-padding-top"
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title appie-title">
                                KEY BENEFITS OF ANGULAR PLATFORM
                            </h1>
                            <p className="WebNStack-font-size">
                                Use WebNStack to quickly design UI views using a flexible and
                                powerful template syntax. For our clients, we provide angular.js
                                applications with enriched features. Our software operates
                                independently and is built with.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fal fa-tv" />
                            </div>
                            <h1 className="title">Universal</h1>
                            <p className="text-justify">
                                For nearly instantaneous rendering in only HTML and CSS, serve the
                                first view of your application on Node.js®,.NET, PHP, and other
                                servers.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fal fa-code" />
                            </div>
                            <h1 className="title">Merging codes</h1>
                            <p className="text-justify">
                                With the new Component Router, Angular apps load more rapidly since
                                users only load the code necessary to render the requested view.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="service-thumb angular-display">
                            <img src={mobileDevelopmentService} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fal fa-user-friends" />
                            </div>
                            <h1 className="title">Angular CLI</h1>
                            <p className="text-justify">
                                Command line tools allows the users for quick construction, addition
                                of components and testing, and then rapid deployment getting
                                intelligent completion.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fal fa-mobile" />
                            </div>
                            <h1 className="title">Graphics</h1>
                            <p className="text-justify">
                                Through Angular’s user-friendly API, you can easily create
                                high-performance, intricate cinematic and animation timelines with
                                very little coding.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fal fa-user-friends" />
                            </div>
                            <h1 className="title">Scripting</h1>
                            <p className="text-justify">
                                In order to provide you all the advantages of handwritten code with
                                the efficiency of framework, Angular converts your templates into
                                code for JS virtual machines.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                    float
                  "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fal fa-mobile" />
                            </div>
                            <h1 className="title">Web</h1>
                            <p className="text-justify">
                                Using the same Angular techniques you’ve mastered for the web
                                together with the ability to access native OS APIs, you can create
                                desktop-installed apps.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
