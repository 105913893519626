import React from 'react';
import PayrollstSectionImage from '../../../assets/images/webnstack/payroll-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-hrpayroll-top-section mob-hrpayroll-top-section-height hrpayroll-sec-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-hrpayroll-size">
                                Workforce Management: Showcasing HR Payroll Web Application Success
                            </h1>
                            <p>
                                Bindal paper Mills limited emerges as part of the highly dynamic and
                                rapid growing Bindal Group. Bindal’s has a diversified Business
                                Portfolio in Manufacturing of Cut pack A4 copier paper, writing and
                                printing grades and its Notebooks.The Group fundamental business
                                philosophy is dictated by the ever growing needs of the customer for
                                Quality products, backed by robust service Culture and competitive
                                Costs. Bindal’s significant investment in the high end Writing and
                                Printing Paper (WPP) sector is a modern Greenfield plant in
                                Muzaffarnagar, UP, India in the year 2009.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={PayrollstSectionImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
