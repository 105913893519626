import React from 'react';

function ServicesThree({ className }) {
    return (
        <section
            className={`appie-service-area pt-60 pb-100 Marketplace ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title Growth">Growth</h1>
                            <p className="font-size16px-p">
                                With our “Growing” solutions, you may grow your company beyond all
                                reasonable expectations and reach new heights.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-map-marked icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Sale on Marketplace</h1>
                            <p className="text-justify">
                                Our integration solutions effortlessly link your store(s) to a
                                variety of international markets, freeing you from managing multiple
                                platforms.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-digital-ocean icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Search Engine Marketing</h1>
                            <p className="text-justify">
                                Solutions & services for digital marketing that strengthen your
                                brand identification. solutions that address all aspects of your
                                visibility and relevance!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-magic icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Automated Marketing</h1>
                            <p className="text-justify">
                                With the help of our innovative, top-notch marketing automation, you
                                can identify and target your customers, promote more successfully
                                and boost sales.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesThree;
