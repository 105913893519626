import React from 'react';
import heroThumb from '../../../assets/images/frag1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mb-100 mob-fragranceshop-top-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="appie-hero-content text-center">
                            <h1
                                className="appie-title mob-kakzon-size"
                                style={{ fontSize: '60px' }}
                            >
                                ONE STOP DESTINATION FOR LUXURY FRAGRANCE AND BEAUTY BRANDS
                            </h1>
                            <p>
                                FragranceShop.com is an online retailer that offers a wide range of
                                fragrances and beauty products. They have been in business since
                                1996 and have established themselves as a trusted source for
                                high-quality fragrances at competitive prices. Their website
                                features a vast selection of designer and brand name fragrances for
                                both men and women, including perfumes, colognes, and body sprays.
                                They also offer a range of skincare and beauty products, such as
                                moisturizers, makeup, and haircare. In addition to their retail
                                business, FragranceShop.com also operates a wholesale division at
                                wholesale.fragranceshop.com. This division specializes in selling
                                fragrances and beauty products in bulk to businesses and individuals
                                who are looking to resell them.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
