import React from 'react';
import PayrollSection2 from '../../../assets/images/webnstack/payroll-2nd-section-image.png';
import PayrollSection3 from '../../../assets/images/webnstack/payroll-3rd-section-image.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10
        "
            id="features"
        >
            <div className="container pt-100">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft mob-pt-6"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={PayrollSection2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The company enquires the development of a comprehensive payroll
                                management system using PHP in Laravel, aiming to streamline
                                financial operations and employee data management.
                            </p>
                            <p>
                                {' '}
                                The primary focus was on overseeing monthly compensation
                                calculations, making payroll and reports the core functionalities of
                                the application.
                            </p>
                            <p>
                                {' '}
                                The specific requirements included features for tax deduction, ESI
                                and PF information for employees, mass overtime management, and
                                gatepass controls.{' '}
                            </p>
                            <p>
                                The company aimed to enhance efficiency by incorporating
                                capabilities to manage advances, birthday reminders, and more.
                            </p>
                            <p>
                                {' '}
                                The vision was to transition from manual work to a smart work
                                approach through the implementation of our HR Payroll Application.
                            </p>
                            <p>
                                {' '}
                                Critical to improving salary calculation accuracy was the need for a
                                system that could record and manage all employees' attendance.
                            </p>
                            <p>
                                {' '}
                                In summary, the company aimed to revolutionize its manual processes,
                                boost accuracy in salary calculations, and introduce smart workflows
                                with the development of a tailor-made HR Payroll Application,
                                utilizing the power of PHP in the Laravel framework.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-40 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                In response to the client's outlined requirements, the WebNStack
                                Team embarked on the development of a robust payroll web
                                application, leveraging PHP, JavaScript, and Laravel database
                                technology.
                            </p>
                            <p>
                                {' '}
                                The primary objective was to create a user-friendly platform that
                                seamlessly addressed the client's payroll management needs. The
                                application commenced with a well-designed user interface, featuring
                                a secure login system with required validation fields for Username
                                and Password.
                            </p>
                            <p>
                                {' '}
                                Within the payroll application, our team incorporated essential
                                features for attendance and leave management.
                            </p>
                            <p>
                                {' '}
                                Users gained the ability to perform various actions, including
                                deleting, refreshing, updating, and correcting data.
                            </p>
                            <p>
                                {' '}
                                The application facilitated the calculation of basic salaries
                                through intuitive and accessible fields, enhancing overall user
                                experience and efficiency.
                            </p>
                            <p>
                                {' '}
                                A notable inclusion was the support for data import and export,
                                enabling seamless data transfer between the payroll application and
                                external sources.
                            </p>
                            <p>
                                {' '}
                                Additionally, the system featured a PDF format sharing
                                functionality, ensuring convenient sharing and distribution of
                                payroll-related information.
                            </p>
                            <p>
                                {' '}
                                By integrating these user-friendly features, the payroll web
                                application addressed the core functionalities outlined by the
                                client, including attendance and leave management, basic salary
                                calculation, and data sharing capabilities.
                            </p>
                            <p>
                                {' '}
                                The project reflected a tailored solution that not only met the
                                client's initial challenge but also surpassed by providing an
                                intuitive, efficient, and customizable payroll management system.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={PayrollSection3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
