import React from 'react';
import iconFour from '../../assets/images/webnstack/businessanalyst.png';
import iconTwo from '../../assets/images/webnstack/magentodeveloper.png';
import iconThree from '../../assets/images/webnstack/phpdeveloper.png';
import iconOne from '../../assets/images/webnstack/reactjsdeveloper.png';

function Services() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-195 mt-100 crer-pg-padding-0"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title crer-pg-font-20"> CURRENT OPENINGS</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <p>
                                <a href="https://forms.gle/dWuu4wfnRNuLmUuaA" aria-label="icon">
                                    <img src={iconTwo} alt="" />
                                </a>
                            </p>

                            <h2 className="appie-title">Magento Developer</h2>
                            <p>Experience: 1+ years</p>
                            <div className="hero-btns">
                                <a
                                    className="main-btn text-color-code-white-careers"
                                    href="https://forms.gle/dWuu4wfnRNuLmUuaA"
                                >
                                    Apply Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <p>
                                <a href="https://forms.gle/dWuu4wfnRNuLmUuaA" aria-label="icon">
                                    <img src={iconThree} alt="" />
                                </a>
                            </p>

                            <h2 className="appie-title">PHP Developer</h2>
                            <p>Experience: 1.5 + years</p>
                            <div className="hero-btns">
                                <a
                                    className="main-btn text-color-code-white-careers"
                                    href="https://forms.gle/dWuu4wfnRNuLmUuaA"
                                >
                                    Apply Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <p>
                                <a href="https://forms.gle/dWuu4wfnRNuLmUuaA" aria-label="icon">
                                    <img src={iconFour} alt="" />
                                </a>
                            </p>
                            <h2 className="appie-title">Business Analyst</h2>
                            <p>Experience: 1+ years</p>
                            <div className="hero-btns">
                                <a
                                    className="main-btn text-color-code-white-careers"
                                    href="https://forms.gle/dWuu4wfnRNuLmUuaA"
                                >
                                    Apply Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <p>
                                <a href="https://forms.gle/dWuu4wfnRNuLmUuaA" aria-label="icon">
                                    <img src={iconOne} alt="" />
                                </a>
                            </p>{' '}
                            <h2 className="appie-title">React Js Developer</h2>
                            <p>Experience: 1+ years</p>
                            <div className="hero-btns">
                                <a
                                    className="main-btn text-color-code-white-careers"
                                    href="https://forms.gle/dWuu4wfnRNuLmUuaA"
                                >
                                    Apply Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
