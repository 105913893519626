import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import SharedTeamAbout from '../Shared/SharedTeamAbout';
import CounterArea from './CounterArea';
import Faq from './Faq';
import Features from './Features';
import Hero from './Hero';
import Project from './Project';
import Services from './Services';
import Testimonial from './Testimonial';
import Traffic from './Traffic';

function ShopifyDevelopment() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Shopify Development | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader action={drawerAction.toggle} />
            <Hero />
            <Services />
            <Features />
            <CounterArea />
            <Traffic />
            <Testimonial />
            <SharedTeamAbout />
            <Faq />
            <Project />
            <SharedFooter />
            <BackToTop />
        </>
    );
}

export default ShopifyDevelopment;
