import React from 'react';
import aboutThumb4 from '../../assets/images/webnstack/about-thumb-4.png';
import thumb5 from '../../assets/images/about-thumb-5.png';

function AboutHomeEight() {
    return (
        <section className="appie-about-8-area pt-100 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title mb-30">
                            <h3 className="appie-title">
                                We bring everything <br />
                                that's required to build apps
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-about-8-box">
                            <h3 className="title">What We Do</h3>
                            <p>
                                Our software development center provides you with the best
                                resources, expertise.
                            </p>
                            <ul>
                                <li>Upgrade your engineering capacity.</li>
                                <li>Employ global development work standards.</li>
                                <li>Utilize cutting-edge technologies and tools.</li>
                                <li>Maintains transparency throughout the project.</li>
                            </ul>
                            <div className="thumb">
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="appie-about-8-box">
                            <h3 className="title">What You Can Do</h3>
                            <p>
                                Our dedicated software development team enables you to do many
                                things.
                            </p>
                            <ul>
                                <li>Cut additional costs.</li>
                                <li>Increase the speed of delivery.</li>
                                <li>Access expertise that is not available in-house.</li>
                                <li>Solely focus on your business objectives.</li>
                            </ul>
                            <div className="thumb mr-30">
                                <img src={thumb5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutHomeEight;
