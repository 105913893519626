import React from 'react';
import ExpertqualityImage from '../../assets/images/webnstack/Expert-quality-analysis.png';
import deploymentinnovationImage from '../../assets/images/webnstack/deployment-innovation.png';
import drivengrowthImage from '../../assets/images/webnstack/driven-growth-approach.png';
import expertcertifiedImage from '../../assets/images/webnstack/expert-certified-professionals.png';
import featureImage from '../../assets/images/webnstack/feature-rich-us.png';
import greatperformanceImage from '../../assets/images/webnstack/great-performance.png';
import ontimedeliveryImage from '../../assets/images/webnstack/on-time-delivery.png';
import ITprofessionalsImage from '../../assets/images/webnstack/qualify-IT-professionals.png';
import seofriendlyImage from '../../assets/images/webnstack/seo-friendly.png';

function ServiceOfReact1() {
    return (
        <section className="appie-service-area appie-service-3-area pt-0" id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={featureImage} alt="" />

                            <h1 className="appie-title">Feature Rich Us</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={seofriendlyImage} alt="" />
                            <h1 className="appie-title">Dynamically SEO-friendly</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <img src={greatperformanceImage} alt="" />
                            <h1 className="appie-title">Effectively Great Performance</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <img src={ITprofessionalsImage} alt="" />
                            <h1 className="appie-title">Qualified IT Professionals</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <img src={ontimedeliveryImage} alt="" />
                            <h1 className="appie-title">On-time Delivery</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <img src={drivengrowthImage} alt="" />
                            <h1 className="appie-title">Growth Driven Approach</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <img src={expertcertifiedImage} alt="" />
                            <h1 className="appie-title">Expert Certified Professionals</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <img src={deploymentinnovationImage} alt="" />
                            <h1 className="appie-title">Deployment of Innovations</h1>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <img src={ExpertqualityImage} alt="" />
                            <h1 className="appie-title">Expert Quality Analysis</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceOfReact1;
