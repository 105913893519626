import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import HeroNews from '../News/HeroNews';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import Forms from './Forms';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Contacts | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo drawer={drawer} action={drawerAction.toggle} />
            <HeroNews
                title="Contact Us"
                breadcrumb={[
                    { link: '/home', title: 'Home' },
                    { link: '/contact', title: 'Contact Us' },
                ]}
            />
            <Forms />
            <SharedFooter />
            <BackToTop />
        </>
    );
}

export default Contact;
