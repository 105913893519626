import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import featureThumb from '../../assets/images/webnstack/webnsrack-hire-home.png';
import featureThumbHire from '../../assets/images/webnstack/webnsrack-hiree-home-home.png';

function FeaturesTwo() {
    return (
        <section
            className="appie-features-area-2 pt-90 pb-100 portfolio-pt30 hire-ecommerce-pb20"
            id="features"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title appie-section-title-2 text-center features-pb0">
                            <h2 className="text-light hire-ecommerce-heading-size">
                                OUR TEAM PROFICIENT SKILLS
                            </h2>
                            <p>
                                Transform your online store with expert ecommerce
                                developers-outsource now!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-md-12">
                        <div className=" row appie-features-boxes d-flex">
                            <div className="col-md-6">
                                <h1
                                    className="text-light font-weight-normal pb-20 faq-sec-font-size"
                                    style={{ fontSize: '18px' }}
                                >
                                    We are a group of skilled and professional eCommerce developers
                                    who excel at their jobs and deliver the desired results. Unleash
                                    Your Ecommerce Potential-Hire Skilled Certified Developers for
                                    Outsourced Solutions to obtain responsive websites that are
                                    custom-tailored to your requirements. With a proven track record
                                    of successful projects and a commitment to staying at the
                                    forefront of industry trends, our team is dedicated to elevating
                                    your online presence and driving success in the digital
                                    marketplace. Partner with us for a transformative eCommerce
                                    experience.
                                </h1>
                                <ul className="text-light liste-image">
                                    <li>Visual design expertise</li>
                                    <li>User experience design perfection</li>
                                    <li>
                                        Years of experience with Git version control Expert in
                                        responsive design without reliance on framework Solid
                                        understanding
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="appie-features-thumb wow animated fadeInRight col-md-6"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pt-30 mob-p-0">
                        <div className=" row appie-features-boxes d-flex">
                            <div
                                className="appie-features-thumb wow animated fadeInRight col-md-6 hire-ecommerce-pb20"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumbHire} alt="" />
                            </div>
                            <div className="col-md-6">
                                <h1
                                    className="text-light font-weight-normal pb-20 faq-sec-font-size"
                                    style={{ fontSize: '18px' }}
                                >
                                    Our hiring professional developers service, as a recognized web
                                    development firm, can help your online company achieve new
                                    heights of recognition and success at the best price possible,
                                    owing to our years of experience and expertise in the field of
                                    PHP-based open-source e-commerce systems. With a client-centric
                                    approach, we tailor solutions to meet your specific needs,
                                    ensuring a seamless and cost-effective collaboration. Elevate
                                    your online presence with our dedicated team of technical
                                    experts.
                                </h1>
                                <ul className="text-light liste-image">
                                    <li>Ideal for Migration Solutions</li>
                                    <li>Third-party app integration expertise</li>
                                    <li>
                                        Knowledge of PHP and MVC frameworks, as well as Payment
                                        Gateway Integration
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="appie-section-title text-right mailto-pt20">
                        <a className="main-btn" href="mailto:info@webnstack.com">
                            HIRE NOW <i className="fal fa-arrow-right" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default FeaturesTwo;
