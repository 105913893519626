import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import user from '../../assets/images/webnstack/Gavin-C..png';
import hamadImage from '../../assets/images/webnstack/Hamad-Albulooshi.png';
import hendImage from '../../assets/images/webnstack/Hend-Goma.png';
import itthikritk from '../../assets/images/webnstack/Itthikrit-K..png';
import Jimbradford from '../../assets/images/webnstack/Jim-Bradford.png';
import user2 from '../../assets/images/webnstack/richardo.png';

function SharedTestimonialTwo() {
    const articleCarosel = useRef();
    const peopleCarosel = useRef();
    const sliderNext = () => {
        articleCarosel.current.slickNext();
        peopleCarosel.current.slickNext();
    };
    const sliderPrev = () => {
        articleCarosel.current.slickPrev();
        peopleCarosel.current.slickPrev();
    };
    const settingsForArticle = {
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const settingsForPeople = {
        centerMode: true,
        centerPadding: '0px',
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
    };
    return (
        <div className="appie-testimonial-about-area pb-45 magento-development-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center pt-60 COUNTRIES-WORLDWIDE">
                            <h1 className="appie-title Magento">
                                WE ARE TRUSTED 15+ COUNTRIES WORLDWIDE
                            </h1>
                            <p className="font-size16px-p font-size16">
                                Empowering success stories: our clients speak, we deliver.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div
                            className="testimonial-about-slider-active"
                            style={{ position: 'relative' }}
                        >
                            <span
                                onClick={sliderPrev}
                                className="prev slick-arrow"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-left"></i>
                            </span>
                            <Slider {...settingsForArticle} ref={articleCarosel}>
                                <div className="testimonial-parent-item">
                                    <div className="testimonial-box">
                                        <div className="icon">
                                            <i className="fas fa-quote-left"></i>
                                        </div>
                                        <div className="ratings-icon">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            Professional and quick, always one step ahead. I will
                                            re-hire for sure. The best Magento 2 expert we have ever
                                            worked with! Recommended.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonial-parent-item">
                                    <div className="testimonial-box">
                                        <div className="icon">
                                            <i className="fas fa-quote-left"></i>
                                        </div>
                                        <div className="ratings-icon">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            WebNStack was great to work with! Looking forward to
                                            working with them on more projects in the future! Every
                                            Issue was dealt as soon as we acknowledge about it.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonial-parent-item">
                                    <div className="testimonial-box">
                                        <div className="icon">
                                            <i className="fas fa-quote-left"></i>
                                        </div>
                                        <div className="ratings-icon">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            WebNStack was able to resolve quickly an issue on an
                                            existing website. Thank you very much for the efforts.
                                            Will definitely hire the team again.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonial-parent-item">
                                    <div className="testimonial-box">
                                        <div className="icon">
                                            <i className="fas fa-quote-left"></i>
                                        </div>
                                        <div className="ratings-icon">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            WebNStack is a team of integrity. Honest and very quick
                                            on development. I am very satisfied with their services
                                            and will re-hire them again for sure.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonial-parent-item">
                                    <div className="testimonial-box">
                                        <div className="icon">
                                            <i className="fas fa-quote-left"></i>
                                        </div>
                                        <div className="ratings-icon">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            We need assistance to get a custom-built website based
                                            on the Porto theme being categorized under e-commerce
                                            business. We admire WebNStack’s project management team
                                            for their coordination in the website development.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonial-parent-item">
                                    <div className="testimonial-box">
                                        <div className="icon">
                                            <i className="fas fa-quote-left"></i>
                                        </div>
                                        <div className="ratings-icon">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                                <li>
                                                    <i className="fas fa-star"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            I’m grateful to the “WebNStack” team since they have the
                                            skills to simplify user data administration and create a
                                            user-friendly interface. Their successful efforts—which
                                            won awards—helped me achieve my goal of having a digital
                                            presence.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                            <span
                                onClick={sliderNext}
                                className="next slick-arrow"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-8 col-sm-10">
                        <div className="testimonial-box-about-slider-small-active">
                            <Slider {...settingsForPeople} ref={peopleCarosel}>
                                <div className="item">
                                    <div className="thumb">
                                        <img src={user} alt="" />
                                    </div>
                                    <div className="content text-center margin-left-right-10">
                                        <h1 className="title">Gavin C</h1>
                                        <span>Australlia</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="thumb">
                                        <img src={user2} alt="" />
                                    </div>
                                    <div className="content text-center margin-left-right-10">
                                        <h1 className="title">Ricardo</h1>
                                        <span>Brazil</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="thumb">
                                        <img src={hamadImage} alt="" />
                                    </div>
                                    <div className="content text-center margin-left-right-10">
                                        <h1 className="title">Hamad A</h1>
                                        <span>Bahrain</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="thumb">
                                        <img src={hendImage} alt="" />
                                    </div>
                                    <div className="content text-center margin-left-right-10">
                                        <h1 className="title">Hend</h1>
                                        <span>Saudi Arab</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="thumb">
                                        <img src={Jimbradford} alt="" />
                                    </div>
                                    <div className="content text-center margin-left-right-10">
                                        <h1 className="title">Jim Bradford</h1>
                                        <span>New York</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="thumb">
                                        <img src={itthikritk} alt="" />
                                    </div>
                                    <div className="content text-center margin-left-right-10">
                                        <h1 className="title">Itthikrit K</h1>
                                        <span>Thailand</span>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SharedTestimonialTwo;
