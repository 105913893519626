import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import downloadThumb from '../../assets/images/1-image.png';
import downloadThumbTwo from '../../assets/images/2-image.png';

function Download({ className }) {
    return (
        <section
            className={`appie-download-3-area pt-50 pb-50 mob-padding-top-bottom ${
                className || ''
            }`}
            id="download"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20px-p">
                                Elevate Your Business Globally
                            </h1>
                            <p>Unlock potential with our remote team solutions.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title font-size20px-p">
                                    Transform Your Business with a Global Remote Team
                                </h1>
                                <p>
                                    Unlock the potential of your business by creating a dedicated
                                    remote team overseas. At WebNStack, we offer you the opportunity
                                    to revolutionize your operations with our skilled professionals.
                                    Transform your business with our expertise.
                                </p>
                                <Link className="main-btn" to="/team">
                                    Explore Our Remote Team
                                </Link>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="content">
                                <h1 className="title font-size20px-p">
                                    Explore a Free 2-Day Trial Experience{' '}
                                </h1>
                                <p>
                                    Experience the difference with a free 2-day trial, where you can
                                    witness firsthand the expertise and commitment of our team. Join
                                    hands with us to take your business to new heights through
                                    seamless collaboration and unmatched efficiency.
                                </p>
                                <a className="main-btn" href="mailto:info@webnstack.com">
                                    Start Your Free 2-Day Trial 
                                </a>
                            </div>
                            <div className="thumb text-right">
                                <img src={downloadThumbTwo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
