import React from 'react';
import { Link } from 'react-router-dom';
import almonaplast1 from '../../../assets/images/webnstack/almonaplast.png';
import cloudearly1 from '../../../assets/images/webnstack/cloudearly.png';
import sandiabio1 from '../../../assets/images/webnstack/sandiabio.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-80 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">Diverse Solutions,Shared Excellence:</h1>
                            <h1 className="appie-title mt-0">
                                {' '}
                                A Showcase of Our Related Projects
                            </h1>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title">
                                    <Link to="/our-projects/almonaplast" aria-label="AlmonaPlast">
                                        AlmonaPlast
                                    </Link>
                                </h1>
                                <p>
                                    Seamless Precision, Almona's Tailored Digital Presence in
                                    Plastic Pipe Manufacturing.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={almonaplast1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="content">
                                <h1 className="title">
                                    <Link to="/our-projects/sandiabio" aria-label="Sandia Biotech">
                                        Sandia Biotech
                                    </Link>
                                </h1>
                                <p>
                                    Revolutionizing Cancer Diagnostics-Sandia Biotech's FluorAbody®
                                    Digital Technology.{' '}
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={sandiabio1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title">
                                    <Link to="/our-projects/cloudearly" aria-label="Cloudearly">
                                        Cloudearly
                                    </Link>
                                </h1>
                                <p>
                                    Cloud Innovation Unleashed-Cloudearly's Tailored Solutions in
                                    the Digital Sky.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={cloudearly1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
