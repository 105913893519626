import React from 'react';
import IconOne from '../../assets/images/icon/1.png';
import IconTwo from '../../assets/images/icon/2.png';
import IconThree from '../../assets/images/icon/3.png';
import IconFour from '../../assets/images/icon/4.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">WEBNSTACK ECOMMERCE AREAS OF FOCUS</h3>
                            <p>
                                We help in driving customer satisfaction, expanding audiences, and
                                boosting sales. We assure you that your website is developed with a
                                robust business strategy and advanced top-notch development
                                technologies.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                                <span>1</span>
                            </div>
                            <h4 className="appie-title">SITE DEVELOPMENT & SECURITY</h4>
                            <p>
                                Create a user-friendly, well-designed e-commerce front end that
                                drives sales and brings customers back for more. Also, Security
                                should be a high priority for any e-commerce site. Protecting your
                                customer’s information, your products, and your profits.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                                <span>2</span>
                            </div>
                            <h4 className="appie-title">BACKEND INTEGRATION & ANALYTICS</h4>
                            <p>
                                Integrate your e-commerce solution with your CRM, ERP, marketing
                                automation, and 3PL to streamline your business processes and reduce
                                time spent processing orders. Understand your customers better with
                                site data and visitor tracking.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                                <span>3</span>
                            </div>
                            <h4 className="appie-title">CONVERSION OPTIMIZATION</h4>
                            <p>
                                Understanding how to convert users into buyers is key to long term
                                success in online retail. Sell to more than one location? Create a
                                user experience that encourages all of your customers – no matter
                                what language they speak – to engage with your online store.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                                <span>4</span>
                            </div>
                            <h4 className="appie-title">USER EXPERIENCE DESIGN</h4>
                            <p>
                                Users expect a frictionless experience when researching products,
                                checking out and then receiving their products. Our team is here to
                                ensure that your end to end customer buying experience is 5 stars!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
