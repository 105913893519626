import React from 'react';
import { Link } from 'react-router-dom';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function Download({ className }) {
    return (
        <section className={`appie-download-area pt-70 pb-160 mb-90 mob-mb-0  ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="appie-download-content">
                            <a href="mailto:info@webnstack.com">Request an Demo</a>
                            <h1 className="title">
                                Effortless HR <br />
                                Payroll <br />
                                Management
                            </h1>
                            <p>
                                Unleashing the Power of Our Laravel-Based <br />
                                HR Payroll Application for Seamless
                                <br /> Workforce Operations. Transforming <br />
                                HR Processes with Advanced Features <br />
                                Tailored for the Digital Workforce in
                                <br /> Your Digital Environment.
                            </p>
                            <ul>
                                <li>
                                    <Link to="/our-projects/hrpayroll">
                                        <span>Explore Our HR Payroll Solutions</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="download-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="download-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="download-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default Download;
