import React from 'react';
import Bankingandinsurance from '../../assets/images/webnstack/banking-and-insurance.png';
import Ecommerceandretail from '../../assets/images/webnstack/ecommerce-and-retail.png';
import Educationtechnolgy from '../../assets/images/webnstack/education-technolgy.png';
import Eventandtickets from '../../assets/images/webnstack/event-and-tickets.png';
import Foodresturant from '../../assets/images/webnstack/food-resturant.png';
import pharmacyandhealthcare from '../../assets/images/webnstack/pharmacy-and-health-care.png';
import realestateandproperty from '../../assets/images/webnstack/real-estate-and-property.png';
import Travelandtransport from '../../assets/images/webnstack/travel-and-transport.png';

function CounterArea({ style }) {
    return (
        <section
            className="appie-counter-area pb-190 Education-Technology"
            id="counter"
            style={style}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title">
                                Tailored Web Solutions for Diverse Industries
                            </h1>
                            <p className="font-size16">
                                Crafting Custom Web Applications Aligned with Your Unique Industry
                                Dynamics. Innovative web development with a focus on your industry.
                                Our commitment goes beyond just technology-we conduct in-depth
                                market research to create a bespoke digital solution that aligns
                                with your business values and resonates with your target audience.
                                Leveraging cutting-edge technologies, we've successfully delivered
                                over 350 tailored products globally, spanning a multitude of
                                industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={pharmacyandhealthcare} alt="" />
                                </div>
                                <p>Pharmacy And Health Care</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-2 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={Eventandtickets} alt="" />
                                </div>
                                <p>Event And Tickets</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-3 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={Foodresturant} alt="" />
                                </div>
                                <p>Food And Restaurant</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-4 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={Bankingandinsurance} alt="" />
                                </div>
                                <p>Banking And Insurance</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 pt-30">
                        <div
                            className="appie-single-counter mt-30 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={realestateandproperty} alt="" />
                                </div>
                                <p>Real Estate And Property</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-30">
                        <div
                            className="appie-single-counter mt-30 item-2 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={Travelandtransport} alt="" />
                                </div>
                                <p>Travel And Transportation</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-30">
                        <div
                            className="appie-single-counter mt-30 item-3 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={Ecommerceandretail} alt="" />
                                </div>
                                <p>E-commerce and Retail</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-30">
                        <div
                            className="appie-single-counter mt-30 item-4 wow animated fadeInUp Tailored-Web"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={Educationtechnolgy} alt="" />
                                </div>
                                <p>Education Technology (EdTech)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterArea;
