import React from 'react';

function ServicesAngular({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-10 BENEFITS-padding-top ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end pt-60 TO-HELP">
                    <div className="col-lg-10 col-md-8">
                        <div className="appie-section-title">
                            <h1 className="appie-title Expertise">
                                TO HELP YOU SUCCEED, WE ARE HERE
                            </h1>
                            <p>
                                We are one of the top service providers for Angular application
                                development. For the best Angular web development services and
                                solutions to meet all of your company demands, see our expert!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user icon-size-30"></i>
                            </div>

                            <h1 className="title">User Contentment</h1>
                            <p className="text-justify">
                                We provide you with one of the top teams to develop a feature-rich
                                application and web interface that will not only meet but exceed
                                your expectations. To ensure your complete pleasure, we employ the
                                most recent technology and state-of-the-art Angular.js programming
                                tools in all of our applications.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-sitemap icon-size-30"></i>
                            </div>
                            <h1 className="title">Dynamic Team</h1>
                            <p className="text-justify">
                                We are experts at offering you one of the most adaptable teams that
                                can work around your schedule. This will facilitate the
                                development’s progression in the sequence you choose and make the
                                process appear simple even when it is not. Our agile team offers you
                                out-of-the-box solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-laptop-code icon-size-30"></i>
                            </div>
                            <h1 className="title">Native Software</h1>
                            <p className="text-justify">
                                In order to give you with a higher-quality web interface, we make
                                use of some of the top open source technologies, knowledge, and
                                skills. We provide you a premium native solution that is smooth so
                                you may succeed in business. We offer the greatest native outlook
                                and customization to our clients.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-link icon-size-30"></i>
                            </div>
                            <h1 className="title">Anytime Interaction</h1>
                            <p className="text-justify">
                                We recognize that discussing a project may occasionally be a
                                persistent and demanding process; therefore, we provide you with
                                open and transparent communication at every opportunity. For the
                                discussion, we had assembled a dedicated and experienced team that
                                would oversee all interactions diligently.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-location-arrow icon-size-30"></i>
                            </div>
                            <h1 className="title">Effective strategys</h1>
                            <p>
                                We offer comprehensive online solutions to our clients, covering
                                everything from strategy to development. Our services provide you
                                one of the most creative methods and are more profitable for your
                                company. One of the greatest, most reliable high performances with
                                effective web solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-clipboard-check icon-size-30"></i>
                            </div>
                            <h1 className="title">Tailored Technologies</h1>
                            <p className="text-justify">
                                In order to provide you with the highest quality experience, whether
                                it be a web solution or an application, we combine our knowledge
                                with the newest technology during the development process. We work
                                hard to provide you with the most cutting-edge development tools and
                                solutions for your company.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesAngular;
