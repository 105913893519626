import React from 'react';
import livelinensectionimage2 from '../../../assets/images/webnstack/livelinen-2nd-section-image.png';
import livelinensectionimage3 from '../../../assets/images/webnstack/livelinen-3rd-section-image.png';

function About() {
    return (
        <section className="pt-200 pb-40 livelinen-padding-top" id="features">
            <div className="container pt-100">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft mob-img-size"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={livelinensectionimage2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The challenge at hand involved a comprehensive revamp of the
                                existing Livelinen website, coupled with the implementation of an
                                online store using Magento 2.
                            </p>
                            <p>
                                {' '}
                                The company faced notable speed issues with their current hosting
                                provider, Hostinger, prompting the need for migration to a more
                                robust platform such as AWS or DigitalOcean.
                            </p>
                            <p>
                                The complexity of the challenge lay in finding a team with expertise
                                in Magento store migration, ensuring a seamless transition.{' '}
                            </p>
                            <p>
                                The client's vision extended beyond mere migration – they sought to
                                elevate their online presence by incorporating an SEO plugin.
                            </p>
                            <p>
                                {' '}
                                The aggressive timelines added an additional layer of complexity,
                                demanding swift and efficient execution.
                            </p>
                            <p>
                                {' '}
                                The overarching goal was to not only resolve speed issues but also
                                enhance user experience through an appealing and user-friendly
                                design.
                            </p>
                            <p>
                                {' '}
                                A fully responsive website environment was a non-negotiable
                                requirement, reflecting the company's commitment to accessibility
                                across various devices.
                            </p>
                            <p>
                                {' '}
                                Furthermore, the client emphasized the need for the best maintenance
                                mode to ensure a smooth transition and ongoing website management
                                during the revamping process.
                            </p>
                            <p>
                                {' '}
                                The challenge, therefore, was multifaceted, encompassing technical
                                expertise, tight timelines, and a keen focus on user-centric design
                                and functionality.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                Our team executed a comprehensive strategy to revamp their existing
                                website and introduce a dynamic online store built on Magento 2.
                            </p>
                            <p>
                                {' '}
                                The primary concern of speed issues with Hostinger prompted a
                                meticulous migration plan to transfer the website to a more robust
                                hosting environment, considering both AWS and DigitalOcean as viable
                                options.
                            </p>
                            <p>
                                {' '}
                                Our team, well-versed in the intricacies of Magento store migration,
                                ensured a seamless transition, mitigating potential disruptions to
                                the website's functionality.
                            </p>
                            <p>
                                {' '}
                                The focus extended beyond migration, with a dedicated effort to
                                enhance the website's performance and user appeal.
                            </p>
                            <p>
                                {' '}
                                Recognizing the client's desire to boost online visibility, we
                                integrated a powerful SEO plugin into the revamped website.
                            </p>
                            <p>
                                {' '}
                                The aggressive timelines were met with proactive project management,
                                ensuring swift execution without compromising on quality.
                            </p>
                            <p>
                                {' '}
                                The user-friendly design was meticulously crafted to attract and
                                engage visitors effectively.
                            </p>
                            <p>
                                {' '}
                                A pivotal aspect of our approach was the development of a fully
                                responsive website environment, catering to diverse devices and
                                ensuring a seamless user experience across platforms.
                            </p>
                            <p>
                                {' '}
                                The implementation of the best maintenance mode facilitated a smooth
                                transition during the revamping process, showcasing our commitment
                                to minimizing disruptions and ensuring ongoing website management.
                            </p>
                            <p>
                                {' '}
                                In essence, our strategic approach encompassed not only technical
                                expertise in Magento migration but also a keen understanding of SEO
                                optimization, user-centric design, and responsive development.
                            </p>
                            <p>
                                {' '}
                                The result was a revitalized online presence for Livelinen, poised
                                to captivate and engage its audience effectively.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={livelinensectionimage3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
