import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import Ecommercedevelopementsection2nd from '../../assets/images/webnstack/ecommerce-4th-section.png';

function Features() {
    return (
        <section className="appie-features-area-2 appie-features-area-5 pt-60 pb-100" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                Intergate & Support all payment gateways
                            </h1>
                            <p className="font-size16px-p ">
                                WebNStack is the partner of choice for many of the world’s leading
                                enterprises, SMEs, and technology challengers. We accept 100+
                                payment gateways across all over the world, according to your
                                convenience. We provide one destination for all the buyers and
                                sellers to sell and buy their products, the platform is based on
                                fast and secure features.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-features-boxes">
                            <div className="appie-features-box-item appie-features-box-5-item pl-5 pr-4">
                                <h1 className="title">Extensive Payment Support.</h1>
                            </div>
                            <div className="appie-features-box-item item-2 appie-features-box-5-item pl-5 pr-4">
                                <h1 className="title">Dedicated Support 24/7.</h1>
                            </div>
                            <div className="appie-features-box-item item-3 appie-features-box-5-item pl-5 pr-4">
                                <h1 className="title">Effortless Data Handling.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={Ecommercedevelopementsection2nd} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape13} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape15} alt="" />
            </div>
        </section>
    );
}

export default Features;
