import React from 'react';

function ServicesThree() {
    return (
        <section className="appie-service-area appie-service-3-area pt-30 pb-60" id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                Key Features of Our Payroll Web Application Solution
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-tasks icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Dynamic Data Management</h1>
                            <p className="text-justify">
                                Empowered users with the ability to perform various data operations
                                such as deletion, refreshing, updating, and correction, providing a
                                dynamic and responsive interface for effective payroll management.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
                appie-single-service-padding"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-calculator icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Efficient Salary Calculation</h1>
                            <p className="text-justify">
                                Streamlined the process of calculating basic salaries through
                                user-friendly fields, improving accuracy and efficiency in payroll
                                management. This feature simplifies the complex task of salary
                                computation.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
                appie-single-service-padding"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-random icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Versatile Data Transfer</h1>
                            <p className="text-justify">
                                Enabled seamless data transfer with import and export
                                functionalities, facilitating the exchange of information between
                                payroll application and external sources ensuring convenient and
                                standardized data.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
                appie-single-service-padding"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-id-badge icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Customizable Branding</h1>
                            <p className="text-justify">
                                Branding elements such as Brand Logo and website integration into
                                the user interface, providing a customized look and feel. This
                                feature aligns the application with client's brand identity
                                appearance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesThree;
