import React from 'react';
import { Link } from 'react-router-dom';
import downloadThumbOne from '../../../assets/images/gosmarteyewear/dealon.png';
import deliverzero from '../../../assets/images/webnstack/deliverzero.png';
import eltriviaOne from '../../../assets/images/webnstack/eltriviaOne.png';
import mediaworldone from '../../../assets/images/webnstack/glowbympi.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-80 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">Diverse Solutions, Shared Excellence:</h1>
                            <h1 className="appie-title mt-0">
                                {' '}
                                A Showcase of Our Related Projects
                            </h1>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/glowbympi">Glow By MPI</Link>
                                </h1>
                                <p>Revolutionizing Skincare with a Mobile App Experience.</p>
                            </div>
                            <div className="thumb text-center">
                                <img src={mediaworldone} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/eltrivia">El Trivia</Link>
                                </h1>
                                <p>Embark on Egyptian Trivia Adventure with El Trivia.</p>
                            </div>
                            <div className="thumb text-right">
                                <img src={eltriviaOne} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/dealon">Dealon</Link>
                                </h1>
                                <p>
                                    Sellers Fee-Free Ecommerce Marketplace for Seamless Online
                                    Trading.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumbOne} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/deliverzero">Deliver Zero</Link>
                                </h4>
                                <p>
                                    Revolutionizing Sustainable Dining: Deliverzero's Unique
                                    E-commerce Platform.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={deliverzero} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
