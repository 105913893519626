import React from 'react';

function Services() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-195 pb-100 hire-web-developer-appie"
            id="service"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-left hire-web-developer-section-title">
                            <h1 className="appie-title hire-web-developer-appie-title-size">
                                Why should you hire a developer from WebNStack?
                            </h1>
                            <p className="text-left font-size14px-p">
                                We are a well-known company that offers specialized software
                                developers and programmers
                            </p>
                            <p className="text-left pt-0 mt-0 font-size14px-p">
                                We provide a qualified and devoted team hired exclusively for you to
                                complete your projects.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-user-tie icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title">Professionalism</h1>
                            <p className="text-justify">
                                We assist you in developing remote dedicated developers, creating
                                accurate work plans. Our crew maintains a professional demeanour and
                                strive to make your experience with us a positive one.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-weight icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Scalable</h1>
                            <p className="text-justify">
                                Our experts create scalable websites that can meet the needs of your
                                expanding business. Your site's performance will not suffer as a
                                result of having thousands of articles and pages.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-searchengin icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">SEO Optimization</h1>
                            <p className="text-justify">
                                We implement SEO-friendly elements into website and writing
                                structured code in accordance with SEO rules, allowing your website
                                to rank high in search engine results.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-headset icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Service Representatives</h1>
                            <p className="text-justify">
                                Entirely focused on providing excellent communication and honest
                                reporting for each developer you hire.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-user-check icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Certified Developers</h1>
                            <p className="text-justify">
                                WebNStack offers a competent team of globally certified developers
                                and experts with over 7 years of IT industry experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-shipping-fast icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Quickness and Effectiveness</h1>
                            <p className="text-justify">
                                Our team is well-versed in working with important data, we able to
                                achieve the best productivity on any given project.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
