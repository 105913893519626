import React from 'react';
import shopify from '../../assets/images/webnstack/shopify-outline-white.svg';

function ServicesHire({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-100 mob-pt30 mob-padding-top-bottom ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-8">
                        <div className="appie-section-title">
                            <h1 className="appie-title hire-web-developer-appie-title-size">
                                For Your New Projects, Hire Dedicated Developers
                            </h1>
                            <p>
                                For Your New Projects, Hire Dedicated Developers is an excellent way
                                to ensure quality and timely delivery of projects.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-magento icon-size-30"></i>
                            </div>

                            <h1 className="title">Hire Magento Developers</h1>
                            <p className="text-justify">
                                Hiring Magento 2 developers for your forthcoming project would save
                                you money on in-house resources and ensure that you receive the
                                finest possible output from the developer. We have been successful
                                in maintaining long-term relationships with our renowned clients all
                                around the world thanks to our economical Magento developer hiring
                                service.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-php icon-size-30"></i>
                            </div>
                            <h1 className="title">Hire PHP Developers</h1>
                            <p className="text-justify">
                                WebNStack provides Team of skilled for basic and complex
                                enterprise-level websites, web applications, eCommerce solutions,
                                and content management systems. Our skilled PHP developers are
                                well-versed in all major PHP frameworks, including CodeIgniter,
                                Laravel, and others. We keep our clients updated on a regular basis
                                through proactive conversation.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="shopify-background icon-size-30">
                                <img src={shopify} alt="" />
                            </div>
                            <h1 className="title">Hire Shopify Developers</h1>
                            <p className="text-justify">
                                WebNStack's Shopify eCommerce developers can create necessary
                                Shopify stores from the ground up, with outstanding features and the
                                user experience. We offer services that improve your store's user
                                experience, making it SEO-friendly and exceptionally functional. Our
                                developers are committed to delivering top-notch solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-wordpress icon-size-30"></i>
                            </div>
                            <h1 className="title">Hire WordPress Developers</h1>
                            <p className="text-justify">
                                WebNStack provides WordPress development services at reasonable
                                prices. Our skilled WordPress developers provide the development
                                solutions to help your company thrive in this competitive world and
                                increasing sales. You can hire the best WordPress developers for
                                your project. WebNStack offers specialized WordPress developers to
                                help you grow your business.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fab fa-react icon-size-30"></i>
                            </div>
                            <h1 className="title">Hire React Js Developers</h1>
                            <p className="text-justify">
                                Hire WebNStack's ReactJs developers for timely delivery of Android
                                and iOS mobile applications. We deliver high-quality ReactJS
                                applications while servicing companies of every kind. Being a
                                prominent development firm, we provide our clients with services in
                                a variety of industrial verticals. Our talented developers deliver
                                on-time solutions in accordance with your project specifications.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-mobile icon-size-30"></i>
                            </div>

                            <h1 className="title">Hire PWA Developers</h1>
                            <p className="text-justify">
                                Hire PWA developers from WebNStack to optimize your website and
                                mobile apps. The developers at WebNStack can assist you in
                                developing high-performance, visually appealing, and user-friendly
                                websites and mobile applications. Offline capabilities, responsive
                                design, and other advanced features can be added to your website
                                with the assistance of a skilled PWA developer.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHire;
