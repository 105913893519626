import React from 'react';
import shapeTen from '../../assets/images/shape/shape-10.png';
import shapeEleven from '../../assets/images/shape/shape-11.png';
import shapeTwelve from '../../assets/images/shape/shape-12.png';
import shapeNine from '../../assets/images/shape/shape-9.png';
import heroThumb from '../../assets/images/webnstack/it-services-1st-section.png';
import MailchimpFour from '../MailchimpFour';

function Hero() {
    return (
        <section className="appie-hero-area-2 mb-70 padding-top-hero-it-services it-services-padding-top">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="appie-hero-content-2">
                            <span>ONE PLATFORM. ENDLESS POSSIBILITIES.</span>
                            <h1 className="appie-title">IT Infrastructure from A to Z</h1>
                            <p className="font-size16px-p">
                                We are a team of 12+ skilled software developers with a collective
                                experience of more than 6 years in developing high-quality
                                solutions.
                            </p>
                            <MailchimpFour
                                action="https://webnstack.us10.list-manage.com/subscribe/post?u=c6f52f107eeb795a7539991db&amp;id=07592603db"
                                className="row"
                                fields={[
                                    {
                                        name: 'EMAIL',
                                        placeholder: 'info@webnstack.com',
                                        type: 'email',
                                        required: true,
                                        data_type: 'default',
                                        data_class:
                                            'input-box fal fa-envelope envelope it-services-left-3',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="appie-hero-thumb-3 wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
            >
                <img src={heroThumb} alt="" />
            </div>
            <div className="hero-shape-1">
                <img src={shapeNine} alt="" />
            </div>
            <div className="hero-shape-2">
                <img src={shapeTen} alt="" />
            </div>
            <div className="hero-shape-3">
                <img src={shapeEleven} alt="" />
            </div>
            <div className="hero-shape-4">
                <img src={shapeTwelve} alt="" />
            </div>
        </section>
    );
}

export default Hero;
