import React from 'react';
import mobileDevelopmentService from '../../assets/images/webnstack/mobile-development-service.png';

function ServicesHomeEight() {
    return (
        <section className="appie-services-2-area appie-services-8-area pt-90 pb-55" id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title">
                            <h3 className="appie-title">
                                How Can Our Innovative App Solutions Boost Your Sales?
                            </h3>
                            <p>
                                With us, your search for the top app developers is over. Your app
                                development plan will be effectively launched with the assistance of
                                our highly qualified mobile app developers. We provide mobile app
                                development using the newest technology with deep experience and a
                                distinctive strategy to achieve the greatest possible success for
                                your company.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="icon">
                                        <i className="fal fa-tv" />
                                    </div>
                                    <h4 className="title">Tailored Approach</h4>
                                    <p>
                                        To provide a mobile app that shapes the future wave of
                                        business, we employ distinctive and specialised development
                                        methodologies in line with your requirements.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <div className="icon">
                                        <i className="fal fa-code" />
                                    </div>
                                    <h4 className="title">Support & Assistance</h4>
                                    <p>
                                        To keep your app competitive, strong, and safe, we believe
                                        in providing dependable app support and improvement
                                        services. We are up to date on the newest features and
                                        issues.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <div className="icon">
                                        <i className="fal fa-user-friends" />
                                    </div>
                                    <h4 className="title">Cross-platform and Native</h4>
                                    <p>
                                        Because of their experience in both native and
                                        cross-platform app development, our app developers can
                                        guarantee that your app is created correctly.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="icon">
                                        <i className="fal fa-mobile" />
                                    </div>
                                    <h4 className="title">Custom Centric UX Design</h4>
                                    <p>
                                        We provide a UX design that enhances user interaction, puts
                                        an emphasis on effectiveness, and increases engagement and
                                        time spent on the app.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="service-thumb">
                            <img src={mobileDevelopmentService} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeEight;
