import React from 'react';
import { Link } from 'react-router-dom';
import toyfiestaImage from '../../assets/images/webnstack/toyfiesta-image.png';

function Download({ className }) {
    return (
        <section
            className={`appie-download-3-area pt-60 magento-development-padding ${className || ''}`}
            id="download"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">
                                Innovative Solutions for Global Growth and Sustainability
                            </h1>
                            <p className="font-size16px-p">
                                Dive into our diverse portfolio, featuring success stories from
                                WebNStack and pioneering sustainable container management solutions.
                                Explore how we navigate challenges, craft bespoke solutions, and
                                drive impactful results for our clients.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title">
                                    Empowering Toyfiesta's Global Expansion Journey
                                </h1>
                                <p>
                                    To keep the store up to date with the evolving and expanding
                                    company demands. The project was creative and difficult, and it
                                    involved the execution of the Magento Platform and implementing
                                    & adjusting components before moving on to web design and
                                    marketplace development.
                                </p>
                                <Link className="main-btn" to="our-projects/toyfiesta/">
                                    Explore Our Toyfiesta
                                </Link>
                            </div>
                            <div className="thumb text-center">
                                <img src={toyfiestaImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
