import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterThree from '../Shared/SharedFooterThree';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import About from './About';
import AboutTwo from './AboutTwo';
import Faq from './Faq';
import FunFact from './FunFact';
import Hero from './Hero';
import Project from './Project';
import Services from './Services';
import ServicesFour from './ServicesFour';
import ServicesThree from './ServicesThree';
import ServicesTwo from './ServicesTwo';

function MagentoMigration() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Magento Migration | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <Hero />
            <Services />
            <AboutTwo />
            <FunFact />
            <ServicesTwo />
            <About />
            <ServicesThree />
            <ServicesFour />
            <Faq />
            <Project />
            <SharedFooterThree />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default MagentoMigration;
