import React from 'react';
import counterIconTwo from '../../assets/images/icon/Design-binding.png';
import counterIconThree from '../../assets/images/icon/configuring-routs.png';
import counterIconFour from '../../assets/images/icon/html-design.png';
import counterIconOne from '../../assets/images/icon/testing-techniques.png';

function CounterAreaAngular({ style }) {
    return (
        <section
            className="appie-counter-area pt-0 pb-190 CHECK-THEM-ALL-padding-bottom"
            id="counter"
            style={style}
        >
            <div className="container mb-100 angular-margin-bottom">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title Universal-font-size">CHECK THEM ALL</h1>
                            <p>EXTENDED ARRAYS</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconOne} alt="" />
                                </div>
                                <h1 style={{ fontSize: '22px' }}> Testing </h1>
                                <h1 style={{ fontSize: '22px' }}>Techniques</h1>
                                <p className="ml-2 mr-2 text-justify mt-2">
                                    Developers may write code with AngularJS independently of the
                                    rest of the programmed. You may process the segments and modules
                                    that need testing .
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-2 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconTwo} alt="" />
                                </div>
                                <h1 style={{ fontSize: '22px' }}>Double</h1>
                                <h1 style={{ fontSize: '22px' }}>Binding</h1>
                                <p className="ml-2 mr-2 text-justify mt-2">
                                    Rapidly create a template in AngularJS that connects several
                                    components with various models whenever any HTML and view
                                    component values are different.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-3 wow animated fadeInUp Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconThree} alt="" />
                                </div>
                                <h1 style={{ fontSize: '22px' }}>Configuring </h1>
                                <h1 style={{ fontSize: '22px' }}>Routes</h1>
                                <p className="ml-2 mr-2 text-justify mt-2">
                                    The process of switching between views. All information is shown
                                    on a single page with single page apps. Developers are not
                                    required to direct consumers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-counter mt-30 item-4 wow animated fadeInU Innovation"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconFour} alt="" />
                                </div>
                                <h1 style={{ fontSize: '22px' }}>HTML</h1>
                                <h1 style={{ fontSize: '22px' }}>Design</h1>
                                <p className="ml-2 mr-2 text-justify mt-2">
                                    In AngularJS, user interfaces are created in HTML. This language
                                    uses declaratives. As a result, it has shorter tags and is
                                    simple to understand and code.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterAreaAngular;
