import React from 'react';

function ServicesFour() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-60 pb-100 Empower"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title Growth">Empower</h1>
                            <p className="font-size16px-p">
                                Utilize the newest digital technologies to strengthen your
                                enterprise solutions. We deliver customized industry specific
                                solutions based on the motive to reach you at the international
                                level.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-university icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Banking</h1>
                            <p className="text-justify">
                                Providing you with the best banking and insurance solutions
                                available. In order to provide you better results we offer entirely
                                tailored solutions based on the requirements of your banking and
                                insurance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-fish icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Food</h1>
                            <p className="text-justify">
                                Make your own customisations and innovations. One of the greatest
                                services provided here is the creation of practical applications for
                                ordering meals online and reserving restaurant tables from
                                anywhere with ease.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-route icon-size-30"></i>{' '}
                            </div>
                            <h1 className="appie-title">Travel</h1>
                            <p className="text-justify">
                                The demand for travel and transportation services has always been
                                quite high, and this industry has a lot of potential. We provide you
                                with specialised web development services that will help you grow
                                your company.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-prescription-bottle-alt icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Pharmacy</h1>
                            <p className="text-justify">
                                With our extensive subject understanding, we provide you the most
                                user-friendly solution. Top IT solution for your medical practise.
                                Our committed and skilled engineers, developers, are always working
                                to provide you with better and more cutting-edge solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-5
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fal fa-user-graduate icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Education</h1>
                            <p className="text-justify">
                                The premier online tutoring and education platform, Webnstack,
                                enables students to access lectures from any location. All of this
                                is made possible by the internet’s connectivity and great web speed
                                thanks to an organised IT solution provider.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesFour;
