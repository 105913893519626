import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Faq({ className }) {
    const [showQues, setQues] = useState();

    const openQuestion = (value) => {
        setQues((prevValue) => (prevValue === value ? null : value));
    };
    return (
        <section className={`appie-faq-area pb-95 ${className || ''}`}>
            <div className="container pt-60 padding-top0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title Growth">
                                Unveiling Magento Migration Insights
                            </h1>
                            <p className="font-size16px-p">
                                Your key questions answered-navigating the world of seamless
                                e-commerce solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(1)}
                                    className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="according font-size--20px font-size16">
                                                What is Magento Migration?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 1 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    Magento migration is the process of moving an
                                                    online store from an earlier Magento <br /> 1
                                                    version to the most recent Magento 2 version in
                                                    order to take advantage of Magento 2's features.
                                                    The Magento migration entails migrating the
                                                    complete store, including its products, orders,
                                                    customers, and other data. It is a complicated
                                                    process that should only be carried out by a
                                                    professional agency under the supervision of
                                                    expert Magento developers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(2)}
                                    className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner ">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="according font-size--20px font-size16">
                                                Why do I need to Migrate to Magento 2?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 2 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    In areas such as site performance, security,
                                                    administration, customer experience, shipping,
                                                    inventory management, and more, Magento 2
                                                    outperforms Magento 1. Only by excelling in this
                                                    area will your company be able to survive in a
                                                    competitive market. Being in Magento 1 isn't
                                                    going to help you with this. Furthermore, modern
                                                    clients expect improved payment, shipping, and
                                                    customer service features. These features are
                                                    only available in Magento 2, which is why you
                                                    need upgrade to Magento 2.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="faq-accordion wow fadeInRight mt-30"
                            data-wow-duration="1500ms"
                        >
                            <div
                                className="accrodion-grp animated fadeIn faq-accrodion wow"
                                data-wow-duration="1500ms"
                                data-grp-name="faq-accrodion"
                            >
                                <div
                                    onClick={() => openQuestion(3)}
                                    className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px">
                                            <h1 className="according font-size--20px font-size16">
                                                What is the cost of Magento Migration?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 3 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    The cost varies from the website to website and
                                                    the hourly rates of the Company. Also, a number
                                                    of services you include for the Magento 1 to
                                                    Magento 2 migration also affect the cost.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => openQuestion(4)}
                                    className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                >
                                    <div className="accrodion-inner">
                                        <div className="accrodion-title faq-padding-12px pr-5">
                                            <h1 className="according font-size--20px font-size16">
                                                How long does it take to migrate from Magento 1 to
                                                Magento 2?
                                            </h1>
                                        </div>
                                        <div
                                            className="accrodion-content"
                                            style={{
                                                display: showQues === 4 ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="inner faq-padding-12px">
                                                <p>
                                                    It depends on your project and your
                                                    requirements. Price varies according to your
                                                    requirements and features.
                                                    <b />
                                                    For more information &nbsp;
                                                    <Link to="/contacts">Contact us</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="faq-text text-center pt-40">
                            <p className="bold-text">
                                Need further assistance?{' '}
                                <Link to="mailto:info@webnstack.com">Email us</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;
