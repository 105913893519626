import React from 'react';
import aboutThumb from '../../../assets/images/assl/2nd-section.png';
import aboutThumb3 from '../../../assets/images/assl/3rd-section.png';
import aboutThumb2 from '../../../assets/images/assl/4th-section.png';

function About() {
    return (
        <section className="appie-about-3-area pt-200 pb-100 mob-p-0" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The challenge at hand involves resolving a myriad of issues
                                following a migration from Magento 1 to Magento 2, accompanied by
                                the integration of new plugins and a distinct theme.
                            </p>
                            &nbsp;
                            <p>
                                The client is grappling with bugs in the Magento Community Open
                                Source platform, particularly with the functionality of the "Coupon
                                code" when utilized by admins during order creation. Additionally,
                                the third-party Shop by Brand plugin presents multiple obstacles,
                                including difficulties in adding to categories, errors in uploading
                                brand-related images, and challenges in saving brand names.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The follow-up email system encounters various errors, and the search
                                feature faces issues with the Ajax dropdown. Complicating matters,
                                the FAQ section experiences SQL errors, and the HidePrice
                                functionality poses challenges in concealing prices in specific
                                sections.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The overarching theme adds another layer of complexity with layout
                                issues related to third-party plugins and general errors. Successful
                                resolution necessitates a comprehensive approach involving
                                debugging, careful examination of third-party plugins, and
                                collaboration with an external technical team to ensure a seamless
                                and error-free user experience on the Magento 2 platform.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                </div>
                <div className="row align-items-center mt-100 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                The WebNStack team swiftly took charge of the multifaceted challenge
                                faced by our client in the Magento 2 migration and subsequent plugin
                                and theme integration. Beginning with the Magento Community Open
                                Source platform, our developers delved into debugging the "Coupon
                                code" issue, ensuring its functionality aligns seamlessly with the
                                admin order creation process.{' '}
                            </p>
                            &nbsp;
                            <p>
                                Turning our attention to the third-party Shop by Brand plugin, our
                                experts meticulously addressed each identified problem. We rectified
                                issues related to category additions, errors in new category
                                creation, and challenges in uploading brand logo and banner images.{' '}
                            </p>
                            &nbsp;
                            <p>
                                We methodically tackled errors in the Email Filtering Header,
                                Pending SMS, email reception, and the Blacklist test, ensuring a
                                streamlined communication process. The search functionality's Ajax
                                dropdown was expertly debugged, restoring its smooth operation. In
                                the FAQ section, our developers addressed the SQL error hindering
                                the viewing process, guaranteeing an error-free experience for
                                users.{' '}
                            </p>
                            &nbsp;
                            <p>
                                For the HidePrice functionality, we meticulously corrected issues
                                such as the absence of "Button Text" on the product page and
                                challenges in testing form messages within the Contact and Quote
                                forms. We successfully ensured the proper hiding of prices in
                                upsell/cross-sell sections.
                            </p>
                            &nbsp;
                            <p>
                                Navigating through theme-related challenges, particularly with the
                                Marketplace Seller Sizechart, our team adjusted the "size chart"
                                link to seamlessly fit within the product information section.
                                Additionally, we tackled layout issues with the Add to Quote/Hide
                                Price buttons, ensuring a visually appealing and functional design.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-100">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">RESULTS & PROCESS</h2>
                            <p>
                                The collaborative efforts of the WebNStack team culminated in the
                                successful technical resolution of the complex challenges
                                encountered during the Magento 2 migration and subsequent
                                integration of new plugins and themes.{' '}
                            </p>
                            &nbsp;
                            <p>
                                The identified issues in the Magento Community Open Source platform,
                                including the "Coupon code" functionality, were expertly debugged,
                                ensuring a smooth experience for admins during order creation.{' '}
                            </p>
                            &nbsp;
                            <p>
                                In addressing the third-party Shop by Brand plugin, our team not
                                only rectified category-related challenges and brand image upload
                                issues but also ensured the proper saving of brand names.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The follow-up email system now operates seamlessly, overcoming
                                errors in the Email Filtering Header, Pending SMS, and email
                                reception. The search functionality, FAQ section, and HidePrice
                                functionality were thoroughly debugged, resulting in error-free user
                                interactions.{' '}
                            </p>
                            &nbsp;
                            <p>
                                The WebNStack team's technical prowess extended to theme-related
                                challenges, notably the Marketplace Seller Sizechart, where the
                                "size chart" link was seamlessly integrated into the product
                                information section.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Additionally, layout issues with the Add to Quote/Hide Price buttons
                                were successfully addressed, providing both visual appeal and
                                functionality. Throughout this process, close collaboration with the
                                external technical team facilitated efficient communication and
                                problem-solving.{' '}
                            </p>
                            &nbsp;
                            <p>
                                The result is a Magento 2 platform that stands as a testament to
                                successful technical delivery – streamlined, error-free, and
                                optimized for an enhanced user experience.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
