import React from 'react';
import { Link } from 'react-router-dom';
import BlogImg1 from '../../assets/images/blog/p1.jpg';
import BlogImg2 from '../../assets/images/blog/p2.jpg';
import BlogImg3 from '../../assets/images/blog/p3.jpg';
import BlogImg4 from '../../assets/images/blog/p4.jpg';

function BlogSideBar() {
    return (
        <div className="blog-sidebar">
            <aside className="widget widget-search">
                <form className="search-form" action="#" method="post">
                    <input type="search" name="s" placeholder="Search..." />
                    <button type="submit">
                        <i className="fal fa-search"></i>
                    </button>
                </form>
            </aside>
            <aside className="widget widget-categories">
                <h3 className="widget-title">Categories</h3>
                <ul>
                    <li>
                        <Link to="#">Web Design</Link>
                        <span>(24)</span>
                    </li>
                    <li>
                        <Link to="#">Marketing</Link>
                        <span>(15)</span>
                    </li>
                    <li>
                        <Link to="#">Frontend</Link>
                        <span>(8)</span>
                    </li>
                    <li>
                        <Link to="#">IT & Software</Link>
                        <span>(13)</span>
                    </li>
                    <li>
                        <Link to="#">Photography</Link>
                        <span>(4)</span>
                    </li>
                    <li>
                        <Link to="#">Technology</Link>
                        <span>(16)</span>
                    </li>
                    <li>
                        <Link to="#">General</Link>
                        <span>(12)</span>
                    </li>
                </ul>
            </aside>
            <aside className="widget widget-trend-post">
                <h3 className="widget-title">Popular Posts</h3>
                <div className="popular-post">
                    <Link to="single-post.html">
                        <img src={BlogImg1} alt="" />
                    </Link>
                    <h5>
                        <Link to="single-post.html">Using creative problem Solving</Link>
                    </h5>
                    <span>March 10, 2020</span>
                </div>
                <div className="popular-post">
                    <Link to="single-post.html">
                        <img src={BlogImg2} alt="" />
                    </Link>
                    <h5>
                        <Link to="single-post.html">Fundamentals of UI Design</Link>
                    </h5>
                    <span>Jan 14, 2020</span>
                </div>
                <div className="popular-post">
                    <Link to="single-post.html">
                        <img src={BlogImg3} alt="" />
                    </Link>
                    <h5>
                        <Link to="single-post.html">Making music with Other people</Link>
                    </h5>
                    <span>April 12, 2020</span>
                </div>
                <div className="popular-post">
                    <Link to="single-post.html">
                        <img src={BlogImg4} alt="" />
                    </Link>
                    <h5>
                        <Link to="single-post.html">Brush strokes energize Trees in paintings</Link>
                    </h5>
                    <span>July 4, 2020</span>
                </div>
            </aside>
            <aside className="widget">
                <h3 className="widget-title">Popular Tags</h3>
                <div className="tags">
                    <Link to="#">Bisy LMS</Link>
                    <Link to="#">Design</Link>
                    <Link to="#">General</Link>
                    <Link to="#">Online</Link>
                    <Link to="#">Prevention</Link>
                    <Link to="#">Artist</Link>
                    <Link to="#">Education</Link>
                    <Link to="#">Motivation</Link>
                    <Link to="#">Politico</Link>
                    <Link to="#">Live Cases</Link>
                </div>
            </aside>
        </div>
    );
}

export default BlogSideBar;
