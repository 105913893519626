import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function SharedFooterThree({ className }) {
    const currentYear = new Date().getFullYear();
    return (
        <section className={`appie-footer-area appie-footer-3-area ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-about-widget footer-about-widget-3">
                            <div className="logo">
                                <Link to="#" aria-label="logo">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                            <p>
                                Professional IT Solution for your company. Elevate Your Digital
                                Future with Precision in Code and Innovation in Solutions.
                            </p>

                            <div className="social mt-30">
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/webnstackindia/"
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="facebook"
                                        >
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://x.com/webnstack_?"
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="twitter"
                                        >
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/webnstack_"
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="instagram"
                                        >
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/company/webnstack/"
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label="linkedin"
                                        >
                                            <i className="fab fa-linkedin-in" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 width-50">
                        <div className="footer-navigation footer-navigation-3">
                            <h1 className="title">ABOUT US</h1>
                            <ul>
                                <li>
                                    <Link to="/about-us" aria-label="About Us">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/team" aria-label="Team">
                                        Team
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/our-projects" aria-label=" Our Projects">
                                        Our Projects
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/careers" aria-label=" Careers">
                                        Careers
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contacts" aria-label="Contacts">
                                        Contacts
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 width-50">
                        <div className="footer-navigation footer-navigation-3">
                            <h1 className="title">SERVICES</h1>
                            <ul>
                                <li>
                                    <Link to="/web-development" aria-label=" Web Development">
                                        Web Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mobile-development" aria-label="Mobile Development">
                                        Mobile Development
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/magento-development"
                                        aria-label=" Magento Development"
                                    >
                                        Magento Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/magento-migration" aria-label="Magento Migration">
                                        Magento Migration
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/shopify-development"
                                        aria-label="Shopify Development"
                                    >
                                        Shopify Development
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-widget-info">
                            <h1 className="title text-align-start">Get In Touch</h1>
                            <ul>
                                <li className="text-align-start">
                                    <a
                                        href="mailto:info@webnstack.com"
                                        aria-label="info@webnstack.com"
                                    >
                                        <i className="fal fa-envelope" /> info@webnstack.com
                                    </a>
                                </li>
                                <li className="text-align-start">
                                    <a href="tel:8905599339" aria-label="+91 89055-99339">
                                        <i className="fal fa-phone rotate-custom" /> +91 89055-99339
                                    </a>
                                </li>
                                <li className="text-align-start">
                                    <a
                                        href="https://maps.app.goo.gl/LfwX5SKEyHXgn2ux5"
                                        aria-label="1st Floor, 206-207, Heera Nagar-A Dhawas Puliya, Service
                                        Lane, 200 Feet Bypass Rd, Jaipur, Rajasthan, India"
                                    >
                                        <i className="fal fa-map-marker-alt" />
                                        1st Floor, 206-207, Heera Nagar-A Dhawas Puliya, Service
                                        Lane, 200 Feet Bypass Rd, Jaipur, Rajasthan, India
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35 mt-5
              "
                        >
                            <div className="apps-download-btn">
                                <ul className="mobile-display">
                                    <li>
                                        <Link className="item-2" to="/privacy" aria-label="PRIVACY">
                                            PRIVACY{' '}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="item-2"
                                            to="/terms-and-conditions"
                                            aria-label="TERMS & CONDITIONS"
                                        >
                                            TERMS & CONDITIONS{' '}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="copyright-text">
                                <p>
                                    Copyright © {currentYear} – by WebNStack. All Rights Reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SharedFooterThree;
