import React from 'react';
import { Link } from 'react-router-dom';

function Hero() {
    return (
        <>
            <div className="appie-about-top-title-area padding-top-hero-about-us abt-us-pdd-top-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="appie-about-top-title">
                                <h2 className="title">YOUR PARTNER FOR ECOMMERCE INNOVATION</h2>
                                <p>Connect with Us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area crer-pg-pdd-top-30 contant-mg-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="appie-about-page-content">
                                <h3 className="title crer-pg-font-size-30">
                                    Join our team to create the best tech solutions
                                </h3>
                                <p>
                                    WebNStack designs your eCommerce Solutions and builds
                                    intelligent, cost-effective and intuitive web applications,
                                    cloud systems, and mobile apps that help streamline processes
                                    for businesses as well as create new revenue streams for
                                    start-ups and established businesses alike. We can help to
                                    maintain and modernize your IT infrastructure and possibilities
                                    not only for today but for the future! by solving various
                                    eCommerce infrastructure-specific issues a business may face.
                                </p>
                                <Link to="/contacts">
                                    Connect with Us <i className="fal fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
