import React from 'react';
import FunFactThumb from '../../assets/images/fun-fact-thumb.png';
import CounterUpCom from '../../lib/CounterUpCom';

function FunFact() {
    return (
        <section className="appie-fun-fact-area pt-50 mb-90 abt-us-mg-btm-30" id="fun-fact-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-fun-fact-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="appie-fun-fact-content">
                                        <h1 className="title">
                                            OUR APPROACH TO ECOMMERCE SOLUTIONS
                                        </h1>
                                        <p>
                                            We strive to become one of the most successful and
                                            preferred eCommerce solutions partners for various
                                            business needs like Startups, SMBs, and enterprises
                                            providing the best mixture of innovation and technology
                                            leadership. From Studying the business challenges to
                                            improving them through streamlining and automation, we
                                            take a unique approach by fusing traditional and new
                                            methods to develop quality eCommerce solutions at speed,
                                            while retaining our personal touch and fastidious
                                            attitude to planning.
                                        </p>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h1 className="title">
                                                        <CounterUpCom
                                                            endValue="27"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h1>
                                                    <span> ACTIVE CLIENTS</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h1 className="title">
                                                        <CounterUpCom
                                                            endValue="76"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h1>
                                                    <span>PROJECTS DONE</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="appie-fun-fact-item">
                                                    <h1 className="title">
                                                        <CounterUpCom
                                                            endValue="11"
                                                            sectionSelect="fun-fact-area"
                                                        />
                                                        +
                                                    </h1>
                                                    <span>Team Advisors</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-fun-fact-play">
                                        <img src={FunFactThumb} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FunFact;
