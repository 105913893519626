import React from 'react';
import { Link } from 'react-router-dom';
import dealon4 from '../../../assets/images/webnstack/dealon.png';
import Deelert2 from '../../../assets/images/webnstack/deelert.png';
import kakzonHamadAlbulooshi3 from '../../../assets/images/webnstack/kakzonHamadAlbulooshi.png';
import Toyfiesta1 from '../../../assets/images/webnstack/toyfiesta.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-80 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Diverse Solutions,Shared Excellence: </h3>
                            <h3 className="appie-title mt-0">A Showcase of Our Related Projects</h3>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/dealon">Dealon </Link>
                                </h4>
                                <p>
                                    Sellers fee-free ecommerce marketplace for seamless online
                                    trading
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={dealon4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    {' '}
                                    <Link to="/our-projects/kakzon">Kakzon</Link>
                                </h4>
                                <p>
                                    Sport in style - showcasing our ecommerce excellence in
                                    sportswear stores
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={kakzonHamadAlbulooshi3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/deelert">Deelert</Link>
                                </h4>
                                <p>
                                    Customized Marketplace for Furniture, Interior, and Home Décor
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={Deelert2} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/toyfiesta">Toyfiesta</Link>
                                </h4>
                                <p>
                                    Whimsical wonders: a tailored ecommerce toy store experience for
                                    kids
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={Toyfiesta1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
