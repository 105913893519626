import React from 'react';
import aboutThumb from '../../../assets/images/dealon4th-section.png';

function Testimonial() {
    return (
        <section className="appie-about-3-area pt-0 pb-100" id="features">
            <div className="container">
                <div className="row align-items-center mt-100">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h3 className="title">RESULTS & PROCESS</h3>
                            <p>
                                In overcoming these challenges, DealOn's project not only
                                experienced a smooth transition but also achieved an optimized web
                                presence with sustained functionality. Our technical expertise and
                                proactive problem-solving approach were pivotal in ensuring the
                                success of this venture, aligning DealOn with the latest industry
                                standards and elevating its digital platform for an enhanced user
                                experience.
                            </p>
                            &nbsp;
                            <p>
                                The final delivery of the DealOn project represents a significant
                                achievement in overcoming the challenges of Magento upgrade, web
                                optimization, and ongoing maintenance. The resolution of numerous
                                theme deprecations underscored our commitment to maintaining a
                                modern and cohesive web presence.
                            </p>
                            &nbsp;
                            <p>
                                The web optimization, boasts improved performance, increased speed,
                                and heightened responsiveness. Fine-tuning elements such as images,
                                scripts, and server configurations resulted in an optimized user
                                experience. Issues related to performance, data integrity, and user
                                experience were successfully resolved, enhancing the overall mobile
                                application for DealOn's users.
                            </p>
                            &nbsp;
                            <p>
                                Routine updates were seamlessly executed, minimizing downtime,
                                optimizing resource utilization, and ultimately providing a seamless
                                experience for both end-users and administrators.
                            </p>
                            &nbsp;
                            <p>
                                As a result, DealOn is well-equipped to navigate the dynamic digital
                                landscape with confidence and efficiency.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Testimonial;
