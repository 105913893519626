import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import 'react-phone-input-2/lib/style.css';

function Forms() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const [isInvalid, setIsInvalid] = useState({
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        subject: false,
        message: false,
    });

    const [successMsg, setSuccessMsg] = useState('');
    const [recaptcha, setRecaptcha] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setIsInvalid({ ...isInvalid, [name]: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const invalidFields = Object.keys(formData).filter((key) => !formData[key]);

        setIsInvalid((prevIsInvalid) => {
            const newIsInvalid = { ...prevIsInvalid };
            invalidFields.forEach((field) => {
                newIsInvalid[field] = true;
            });
            return newIsInvalid;
        });

        if (invalidFields.length > 0) {
            console.log('Please enter all required fields');
            return;
        }

        const recaptchaValue = window.grecaptcha?.getResponse();
        if (!recaptchaValue) {
            setRecaptcha('Please complete the ReCAPTCHA');
            return;
        }

        try {
            const response = await axios.post(
                'http://webnstack-api.test.webnstack.com/contacts',
                formData
            );
            console.log('res form data', response);
            if (response.status === 201 || response.status === 200) {
                setSuccessMsg('Form submitted successfully!');
                setIsFormSubmitted(true);
                // reset form data
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                });
                // reset recaptcha value
                window.grecaptcha?.reset();
            } else {
                setSuccessMsg('Error submitting form. Please try again.');
            }
        } catch (error) {
            if (error.response) {
                console.error('Server Response Data:', error.response.data);
                console.error('Server Response Status:', error.response.status);
            }
        }
    };
    useEffect(() => {
        if (isFormSubmitted) {
            const timeoutId = setTimeout(() => {
                setSuccessMsg('');
                setIsFormSubmitted(false);
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
        return undefined;
    }, [isFormSubmitted]);

    function onChange(value) {
        console.log('Captcha value:', value);
        setRecaptcha('');
    }

    return (
        <>
            <section className="contact-section  contact-pdd-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 pt-50 crer-pg-pdd-top-30">
                            <div className="contact--info-area">
                                <h3>CONTACT US</h3>
                                <p>
                                    Give us a call or drop by anytime, we endeavour to answer all
                                    enquiries within 24 hours on business days. We will be happy to
                                    answer your questions.
                                </p>
                                <div className="single-info">
                                    <h4 className="single-infoh4">OFFICE ADDRESS:</h4>
                                    <p>
                                        <a
                                            href="https://maps.app.goo.gl/LfwX5SKEyHXgn2ux5"
                                            aria-label="link"
                                        >
                                            <i className="fal fa-map-marker-alt"></i>
                                        </a>
                                        1st Floor, 206-207, Heera Nagar-A Dhawas Puliya, Service
                                        Lane, 200 Feet Bypass Rd, Jaipur, Rajasthan, India
                                        <br />
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>CALL US ON</h5>
                                    <p>
                                        <a href="tel:8905599339" aria-label="number">
                                            <i className="fal fa-phone rotate-custom"></i>
                                        </a>
                                        +91 89055-99339
                                        <br />
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>MAIL US</h5>
                                    <p>
                                        <a href="mailto:info@webnstack.com" aria-label="text">
                                            <i className="fal fa-envelope"></i>
                                        </a>
                                        info@webnstack.com
                                        <br />
                                        sales@webnstack.com
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <div className="footer-about-widget contact-txt-aln-lft">
                                        <div className="social">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="https://www.facebook.com/webnstackindia/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="facebook"
                                                    >
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://x.com/webnstack_"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="twitter"
                                                    >
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.instagram.com/webnstack_"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="instagram"
                                                    >
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.linkedin.com/company/webnstack/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label="linkedin"
                                                    >
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form form-color contact-pdd-mg-top-20 contant-pdd-43-15-65">
                                <h4>READY TO GET STARTED?</h4>
                                <p>
                                    Your email address will not be published. Required fields are
                                    marked .
                                </p>
                                <form
                                    onSubmit={handleSubmit}
                                    className="row needs-validation"
                                    noValidate
                                >
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                            required
                                            value={formData.first_name}
                                            className={`form-control ${
                                                isInvalid.first_name ? 'is-invalid' : ''
                                            }`}
                                            onChange={handleChange}
                                        />
                                        {isInvalid.first_name && (
                                            <div className="invalid-feedback mb-2">
                                                Please enter your first name
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                            required
                                            value={formData.last_name}
                                            className={`form-control ${
                                                isInvalid.last_name ? 'is-invalid' : ''
                                            }`}
                                            onChange={handleChange}
                                        />
                                        {isInvalid.last_name && (
                                            <div className="invalid-feedback mb-2">
                                                Please enter your last name
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            required
                                            value={formData.email}
                                            className={`form-control ${
                                                isInvalid.email ? 'is-invalid' : ''
                                            }`}
                                            onChange={handleChange}
                                        />
                                        {isInvalid.email && (
                                            <div className="invalid-feedback mb-2">
                                                Please enter a valid email address
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder="Phone"
                                            required
                                            value={formData.phone}
                                            className={`form-control ${
                                                isInvalid.phone ? 'is-invalid' : ''
                                            }`}
                                            onChange={handleChange}
                                        />
                                        {isInvalid.phone && (
                                            <div className="invalid-feedback mb-2">
                                                Please enter your phone number
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                            value={formData.subject}
                                            className={`form-control ${
                                                isInvalid.subject ? 'is-invalid' : ''
                                            }`}
                                            onChange={handleChange}
                                        />
                                        {isInvalid.subject && (
                                            <div className="invalid-feedback mb-2">
                                                Please enter the subject
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                            required
                                            value={formData.message}
                                            className={`form-control ${
                                                isInvalid.message ? 'is-invalid' : ''
                                            }`}
                                            onChange={handleChange}
                                        />
                                        {isInvalid.message && (
                                            <div className="invalid-feedback mb-2">
                                                Please enter your message
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <ReCAPTCHA
                                            sitekey=" 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                            onChange={(value) => onChange(value)}
                                        />
                                        {recaptcha && (
                                            <div className="text-danger">{recaptcha}</div>
                                        )}
                                    </div>
                                    <div className="col-md-5 text-left">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                    {successMsg && (
                                        <div className="col-md-7 text-success d-flex justify-content-center align-items-center">
                                            {successMsg}
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    src="https://maps.google.com/maps?q=webnstack%20206-207%20Heera%20Nagar-A%20Dhawas%20Puliya%2C%20Service%20Lane%2C%20200%20Feet%20Bypass%20Rd%2C%20Jaipur&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title="webnstack 206-207 Heera Nagar-A Dhawas Puliya, Service Lane, 200 Feet Bypass Rd, Jaipur"
                    aria-label="webnstack 206-207 Heera Nagar-A Dhawas Puliya, Service Lane, 200 Feet Bypass Rd, Jaipur"
                ></iframe>
            </div>
        </>
    );
}

export default Forms;
