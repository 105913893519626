import React from 'react';
import amezon from '../../assets/images/webnstack/amazon.png';
import bling from '../../assets/images/webnstack/bling.png';
import erp from '../../assets/images/webnstack/erp.png';
import google from '../../assets/images/webnstack/google-analytics.png';
import Magento from '../../assets/images/webnstack/magento2.png';
import mail from '../../assets/images/webnstack/mailchimp.png';
import net from '../../assets/images/webnstack/netsuite.png';
import anymarketing from '../../assets/images/webnstack/our-projects-svg/anymarket.svg';
import frenet from '../../assets/images/webnstack/our-projects-svg/frenet.svg';
import zopim from '../../assets/images/webnstack/our-projects-svg/zopim.svg';
import zendesk from '../../assets/images/webnstack/zendesk.png';
import zoho from '../../assets/images/webnstack/zoho.png';

function ServicesHomeTwo({ className }) {
    return (
        <section className={`appie-services-2-area ${className}`} id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title pt-60 OUR-PROUD-padding-top">
                            <h1 className="appie-title">OUR PROUD EVOLVING PARTNERS</h1>
                            <p className="text-justify font-size16px-p ">
                                Every business demands a shift with a changing digital world, so
                                pick our eCommerce & retail solutions to boost your sales.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={Magento} alt="" />
                            </div>

                            <h1 className="title">Magento 2</h1>
                            <p className="text-justify">
                                Magento 2 is the best platform for online store on the planet (and
                                galaxies), there are more than 500 thousand online stores.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={amezon} alt="" />
                            </div>
                            <h1 className="title">Amazon</h1>
                            <p className="text-justify">
                                Shakes, Amazon selected us to participate in the Activate program!
                                Our Customers Thrive on AWS Excellence!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={erp} alt="" />
                            </div>
                            <h1 className="title">ERP</h1>
                            <p className="text-justify">
                                Cool ERP for your company to keep your finances beautiful and your
                                team not to waste more time with invoices and shipping labels.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={zopim} alt="" />
                            </div>

                            <h1 className="title">Zopim</h1>
                            <p className="text-justify">
                                The most used chat in the world is here too, for every time a
                                customer says hi to you, answer them there.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={anymarketing} alt="" />
                            </div>
                            <h1 className="title">Anymarket</h1>
                            <p className="text-justify">
                                Selling on the best marketplaces is easy after the AnyMarket guys
                                arrived with us.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={frenet} alt="" />
                            </div>
                            <h1 className="title">Frenet</h1>
                            <p className="text-justify">
                                Frenet Offers Couriers with Diverse Carrier Options for Your
                                Customers to Choose From!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={zoho} alt="" />
                            </div>

                            <h1 className="title">zoho box</h1>
                            <p className="text-justify">
                                Offers an extensive array of the most varied tools, ensuring you
                                have access to a comprehensive suite for seamless productivity.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={zendesk} alt="" />
                            </div>
                            <h1 className="title">Zendesk</h1>
                            <p className="text-justify">
                                Without Zendesk it is very likely that the service team will take a
                                long time to respond to customers. check it out!
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={bling} alt="" />
                            </div>
                            <h1 className="title">Bling</h1>
                            <p className="text-justify">
                                A complete ERP with everything you need to manage your online sales
                                finances.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={mail} alt="" />
                            </div>
                            <h1 className="title">Mailchimp</h1>
                            <p className="text-justify">
                                The prettiest and smartest monkey is on Wishtech too, with all the
                                intelligence of email marketing and automation.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={google} alt="" />
                            </div>
                            <h1 className="title">Google's Analytics</h1>
                            <p className="text-justify">
                                Google's Analytics incorporates the most important tools, Tag
                                Manager and Analytics, all available for optimal functionality and
                                performance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={net} alt="" />
                            </div>

                            <h1 className="title">Net Suite</h1>
                            <p className="text-justify">
                                NetSuite's integrates essential tools, encompassing ERP, CRM, and
                                SuiteAnalytics, providing a solution for streamlined operations.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeTwo;
