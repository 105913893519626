import React from 'react';
import heroThumb from '../../../assets/images/webnstack/sandiabio-1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mb-100 sandiabio-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="appie-hero-content text-center">
                            <h1
                                className="appie-title mob-myt-size"
                                style={{ fontSize: '60px', padding: '0' }}
                            >
                                Revolutionizing Cancer Diagnostics-Sandia Biotech's FluorAbody®
                                Digital Technology
                            </h1>
                            <p>
                                Sandia Biosciences is a biotechnology company that specializes in
                                the development and commercialization of innovative tools for cell
                                analysis and drug discovery. The company's mission is to improve the
                                accuracy and efficiency of drug discovery by providing researchers
                                with high-quality and reliable cell analysis tools. Sandia
                                Biosciences offers a range of products, including microelectrode
                                arrays, impedance-based assays, and cell culture systems. These
                                products are designed to enable researchers to study the electrical
                                and mechanical properties of cells, as well as their behavior and
                                response to drugs and other stimuli.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
