import React from 'react';
import toyfiesta1 from '../../../assets/images/webnstack/toyfiesta-2nd-section-image.png';
import toyfiesta2 from '../../../assets/images/webnstack/toyfiesta-3rd-section-image.png';
import toyfiesta3 from '../../../assets/images/webnstack/toyfiesta-4th-secion-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-180 pb-30" id="features">
            <div className="container pt-20">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={toyfiesta1} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge.</h1>
                            <p>
                                To keep the store up to date with the evolving and expanding company
                                demands, they discovered that the assistance was insufficient to put
                                all of their suggestions for online shop optimization and future
                                development into action.{' '}
                            </p>{' '}
                            &nbsp;
                            <p>
                                The company wanted to improve its appalling online presence in order
                                to draw clients globally.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The project was creative and difficult, and it involved the
                                execution of the Magento Platform and Implementing & adjusting
                                components before moving on to web design and marketplace
                                development.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  mt-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0 ">
                            <h1 className="title">WHAT WE DID</h1>
                            <p>
                                The Webnstack team collaborated closely with Magento development,
                                dedicating efforts to fully understand and align with the client's
                                expectations.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Our engagement included the implementation of crucial components
                                such as Amasty, Mirasvit, Zippay/Afterpay/Braintree Paypal Module,
                                and Catch Marketplace Integration.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Notably, we detected and integrated Automated Smart Price
                                Adjustments and Profit Improvement, ensuring a tailored approach for
                                Bundle and Grouped Products.{' '}
                            </p>
                            &nbsp;
                            <p>
                                Beyond this, our team played a pivotal role in enhancing the
                                Framework's Starshipit Integration and seamlessly integrating Social
                                Login functionality through Gmail and Facebook.
                            </p>
                            &nbsp;
                            <p>
                                Our comprehensive solutions extended to include features such as
                                Multiple Wishlists, Onestep Checkout, Mega Menus, Notifications, and
                                Speed Optimizer.
                            </p>
                            &nbsp;
                            <p>
                                Each of these elements was meticulously integrated to ensure synergy
                                with other marketing strategies, resulting in an effective and
                                cohesive website delivery that met and exceeded the client's
                                expectations.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={toyfiesta2} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row  mt-30 flex-column-reverse flex-lg-row"></div>
                <div className="row align-items-center mt-30">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={toyfiesta3} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">RESULTS & PROCESS.</h1>
                            <p>
                                The successful launch of the Toyfiesta website not only adhered to
                                the planned timeline but also garnered positive feedback from the
                                client and investors, reflecting the overall satisfaction with the
                                project's execution.
                            </p>
                            <p>
                                {' '}
                                Recognizing the potential for further growth and optimization, our
                                collaboration has extended into ongoing Magento development.
                            </p>
                            <p>
                                {' '}
                                During the migration process to Magento 2 and the integration of a
                                multistore framework, meticulous efforts were taken to preserve
                                Toyfiesta's unique custom functionality.{' '}
                            </p>
                            <p>
                                The successful execution of this transition ensured that the
                                website's performance remained steadfast, unaffected by the
                                complexity of the changes.
                            </p>
                            <p>
                                {' '}
                                This seamless integration not only reflected our technical prowess
                                but also underscored our commitment to preserving and enhancing the
                                client's existing digital infrastructure.
                            </p>
                            <p>
                                {' '}
                                Looking ahead, the collaborative journey with Toyfiesta continues,
                                with a strategic focus on transitioning their online store to a
                                Progressive Web App (PWA).{' '}
                            </p>
                            <p>
                                This forward-looking approach aligns with industry trends and
                                positions Toyfiesta for sustained success in the ever-evolving
                                digital landscape.
                            </p>
                            <p>
                                {' '}
                                The commitment to innovation and continuous improvement underscores
                                our dedication to delivering enduring value to our clients, and we
                                look forward to further milestones in our ongoing partnership.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
