import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import featureThumb from '../../assets/images/webnstack/webnsrack-hire.png';
import featureThumbHire from '../../assets/images/webnstack/webnsrack-hiree.png';

function FeaturesTwo() {
    return (
        <section
            className="appie-features-area-2 pt-90 pb-100 hire-web-developer-features-section-padding"
            id="features"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title appie-section-title-2 text-center">
                            <h2 className="text-light hire-web-developer-appie-title-size">
                                Experience the difference with our team of proficient web developers
                            </h2>
                            <p>
                                We provide extensive imaginative web developer with great technical
                                skills to construct websites that meet your needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-md-12">
                        <div className=" row appie-features-boxes d-flex">
                            <div className="col-md-6">
                                <h1
                                    className="text-light font-weight-normal pb-20"
                                    style={{ fontSize: '18px' }}
                                >
                                    WebNStack is the leading online website creation company with a
                                    team of highly qualified experts in minimalist, realistic,
                                    interactive, and Industrial web Development. It’s simple to
                                    recruit our incredible talent; we’re ready to start working on
                                    your Development project right away. Our web developers have
                                    been thoroughly verified and are trusted by our clients for our
                                    development projects.
                                </h1>
                                <ul className="text-light liste-image">
                                    <li>Reporting on a daily/weekly/monthly basis</li>
                                    <li>Timely delivery</li>
                                    <li>Quicker access to resources</li>
                                    <li>Solutions that are costly</li>
                                </ul>
                            </div>
                            <div
                                className="appie-features-thumb wow animated fadeInRight col-md-6"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 pt-30">
                        <div className=" row appie-features-boxes d-flex">
                            <div
                                className="appie-features-thumb wow animated fadeInRight col-md-6 hire-features-two-padding"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img src={featureThumbHire} alt="" />
                            </div>
                            <div className="col-md-6">
                                <h1
                                    className="text-light font-weight-normal pb-20"
                                    style={{ fontSize: '18px' }}
                                >
                                    Employ professionals from WebNStack, which assists in offering
                                    excellent web development with a unique way to integrate into
                                    your business. Years of experience with all of the successful
                                    equipment and processes result in the desired output. We have
                                    proven our worth by creating one-of-a-kind websites with
                                    high-quality content that appear straight up in the most popular
                                    search engines.
                                </h1>
                                <ul className="text-light liste-image">
                                    <li>Full command over the team</li>
                                    <li>Models of Flexible Engagement</li>
                                    <li>Large technological stack</li>
                                    <li>Simple to use</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="appie-section-title text-right">
                        <a className="main-btn" href="mailto:info@webnstack.com">
                            HIRE NOW
                            <i className="fal fa-arrow-right" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default FeaturesTwo;
