import React from 'react';
import career1stImage from '../../assets/images/webnstack/career-1st.png';
import career2ndImage from '../../assets/images/webnstack/career-2nd.png';
import career3rdImage from '../../assets/images/webnstack/career-3rd.png';
import career4thImage from '../../assets/images/webnstack/career-4th.png';
import career5thImage from '../../assets/images/webnstack/career-5th.png';
import career6thImage from '../../assets/images/webnstack/career-6th.png';

function ServicesFive() {
    return (
        <section className="appie-services-2-area appie-services-8-area" id="service">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title">
                            <h3 className="appie-title">How We Hire?</h3>
                            <p>
                                At WebNStack, our commitment to putting people first begins the
                                moment we connect. We value your unique skills and aspirations, and
                                our hiring process is designed to ensure a seamless and mutually
                                beneficial journey.
                                <br />
                                Explore Job Opportunities by discovering career paths that align
                                with your skills and aspirations. Explore our job opportunities and
                                find the perfect fit for your professional journey.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-lg-4 col-md-6 col-xl-2 text-center">
                        <img src={career1stImage} alt="" />
                        <div className="hire-stap-img">
                            <div className="text-overlay hire-box">
                                <h4 className="mt-15 font-size16px">Job Opportunities</h4>
                                <div className="fs-3 hire-text-width">
                                    <p className="hire-stap-pra">
                                        Discover career paths that align with your skills, interests
                                        and aspirations. Explore our job opportunities and find the
                                        perfect fit for your perfect complete professional journey
                                        through WebNStack.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-6 col-xl-2 text-center">
                        <img src={career2ndImage} alt="" />
                        <div className="text-overlay hire-stap-img">
                            <div className="text-overlay hire-box">
                                <h4 className="mt-15 font-size16px"> Application </h4>
                                <div className="fs-3 hire-text-width">
                                    <p className="hire-stap-pra">
                                        Once you've identified a role that resonates with you and
                                        submit your application through our easy user friendly
                                        application form and wait for the team to connect with you
                                        from submission.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-6 col-xl-2 text-center">
                        <img src={career3rdImage} alt="" />
                        <div className="text-overlay hire-stap-img">
                            <div className="text-overlay hire-box">
                                <h4 className="mt-15 font-size16px"> Shortlisting</h4>
                                <div className="fs-3 hire-text-width">
                                    <p className="hire-stap-pra">
                                        Our dedicated team reviews applications with a keen eye for
                                        talent. All shortlisted candidates will be notified and
                                        invited to proceed to the next stages of the hiring process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-6 col-xl-2 text-center">
                        <img src={career4thImage} alt="" />
                        <div className="text-overlay hire-stap-img">
                            <div className="text-overlay hire-box">
                                <h4 className="mt-15 font-size16px"> Technical Interview</h4>
                                <div className="fs-3 hire-text-width">
                                    <p className="hire-stap-pra">
                                        We conduct a thorough technical interview. This step allows
                                        us to delve deeper into your expertise and assess how well
                                        you align with the basic technical requirements of the
                                        position.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-6 col-xl-2 text-center">
                        <img src={career5thImage} alt="" />
                        <div className="text-overlay hire-stap-img">
                            <div className="text-overlay hire-box">
                                <h4 className="mt-15 font-size16px">HR Interview</h4>
                                <div className="fs-3 hire-text-width">
                                    <p className="hire-stap-pra">
                                        The HR interview provides an opportunity for us to get to
                                        know you on a personal level. We discuss your career goals,
                                        values, and how your unique qualities can contribute to the
                                        dynamic.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-4 col-md-6 col-xl-2 text-center">
                        <img src={career6thImage} alt="" />
                        <div className="text-overlay hire-stap-img">
                            <div className="text-overlay hire-box">
                                <h4 className="mt-15 font-size16px"> Offer Decision</h4>
                                <div className="fs-3 hire-text-width">
                                    <p className="hire-stap-pra">
                                        Upon the successful completion of the interviews, we extend
                                        a job offer to candidates who align with our values, vision
                                        and demonstrate the skills required for the role
                                        to the onboarding.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="10 custom-font-size mt-20">
                    Join WebNStack and embark on a journey where your skills are celebrated,
                    aspirations are valued, and people come first. We look forward to welcoming
                    you to our team!
                </p>
            </div>
        </section>
    );
}

export default ServicesFive;
