import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Cart from '../Cart';
import TestimonialHomeOne from '../HomeOne/TestimonialHomeOne';
import DownloadHomeThree from '../HomeThree/DownloadHomeThree';
import ServicesHomeThree from '../HomeThree/ServicesHomeThree';
import ShowCaseHomeThree from '../HomeThree/ShowCaseHomeThree';
import SponserHomeTwo from '../HomeTwo/SponserHomeTwo';
import Drawer from '../Mobile/Drawer';
import SearchModule from '../SearchModule';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import FeaturesHomeFive from './FeaturesHomeFive';
import HeroHomeFive from './HeroHomeFive';

function HomeFive() {
    const [search, searchAction] = useToggle(false);
    const [cart, cartAction] = useToggle(false);
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SearchModule value={search} searchToggle={searchAction.toggle} />
            <Cart value={cart} action={cartAction.toggle} />
            <SharedHeader
                action={drawerAction.toggle}
                cartToggle={cartAction.toggle}
                searchToggle={searchAction.toggle}
            />
            <HeroHomeFive />
            <ServicesHomeThree />
            <FeaturesHomeFive />
            <DownloadHomeThree className="pb-90" />
            <ShowCaseHomeThree />
            <TestimonialHomeOne />
            <SponserHomeTwo className="pt-90" />
            <SharedFooter />
            <BackToTop className="back-to-top-5" />
        </>
    );
}

export default HomeFive;
