import React from 'react';
import signupThumb from '../../assets/images/signup-thumb.png';

import MailchimpTwo from '../MailchimpTwo';

function SharedInformationSection({ className }) {
    return (
        <div className={`appie-signup-area ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-signup-box">
                            <span>Drop Your Contact For Better Information.</span>
                            <h1 className="title">Get Started</h1>
                            <MailchimpTwo
                                action="https://webnstack.us10.list-manage.com/subscribe/post?u=c6f52f107eeb795a7539991db&amp;id=07592603db"
                                className="row"
                                fields={[
                                    {
                                        name: 'NAME',
                                        placeholder: 'Full name',
                                        type: 'text',
                                        required: true,
                                        data_type: 'default',
                                        data_class: 'input-box',
                                    },
                                    {
                                        name: 'EMAIL',
                                        placeholder: 'Enter your email',
                                        type: 'email',
                                        required: true,
                                        data_type: 'default',
                                        data_class: 'input-box',
                                    },
                                ]}
                            />
                            <div className="thumb">
                                <img src={signupThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SharedInformationSection;
