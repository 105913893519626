import React from 'react';

function Services() {
    return (
        <section
            className="appie-service-area appie-service-3-area pt-195 pb-100 platform"
            id="service"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title pt-60 text-center digital">
                            <h1 className="appie-title font-size30px">Control</h1>
                            <p className="text-justify font-size16px-p ">
                                A digital hub for company interactions is created by our schedules
                                Magento 2 marketplace solution assembled in a wide selection of
                                items for professional audiences on a online platform.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service Increase appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-handshake icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Premium Business Scenario</h1>
                            <p className="text-justify">
                                RFQ, submit purchase requests, configure tier pricing, and
                                streamlined product searches will provide your B2B marketplace a
                                premium experience.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service  Increase appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-chart-line icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Increase Marketing Outreach</h1>
                            <p className="text-justify">
                                With the help of your marketing efforts, our marketplace solution
                                may increase the reach and merchant reach of your online B2B
                                marketplace.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service Increase  appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-signal icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Increases B2B Sales</h1>
                            <p className="text-justify">
                                Enhanced reports, deeper insights, and customer group management can
                                help you increase conversion opportunities on your marketplace.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service Increase appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-credit-card icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Scalability</h1>
                            <p className="text-justify">
                                Investigate several options for expanding your Magento Marketplace,
                                including different income methods, international sales,
                                Omni-channel visibility, and more.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service  Increase appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-handshake icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Deployment of APIs</h1>
                            <p className="text-justify">
                                Integration is simple with our Magento 2 Marketplace plugin. Add
                                more APIs, such as CRM, ERP, POS, etc., without experiencing
                                compatibility problems.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="
                appie-single-service  Increase appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon icon-size-35">
                                <i className="fas fa-store icon-size-30"></i>
                            </div>
                            <h1 className="appie-title">Command Smoothly</h1>
                            <p className="text-justify">
                                A complete B2C multi-vendor marketplace platform that allows for
                                seamless management without the need for any technical knowledge.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
