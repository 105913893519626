import React from 'react';
import heroThumb from '../../../assets/images/deelert-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-deelert-top-section deelert-sec-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center mb-70">
                            <h1 className="appie-title mob-myt-size">
                                Customized Marketplace for Furniture, Interior, and Home Décor
                            </h1>
                            <p>
                                Discover exceptional value and impeccable design at Deelert, a
                                popular furniture store in Thailand. Whether you're furnishing your
                                office, bedroom, dining room, or kitchen, we offer excellent options
                                to meet your needs. With a wealth of experience in the furniture
                                industry, our team brings knowledge and understanding to ensure you
                                find the best options for your space. Don't miss out on our
                                outstanding offers-explore our diverse range of categories today and
                                elevate your living spaces with Deelert.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
