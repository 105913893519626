import React from 'react';
import { Link } from 'react-router-dom';
import hero from '../../assets/images/angularOne.png';
import shapeOne from '../../assets/images/shape/1.png';
import shapeTwo from '../../assets/images/shape/2.png';
import shapeThree from '../../assets/images/shape/3.png';
import shapeFour from '../../assets/images/shape/4.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-8-area pt-5 pb-5 padding-top-hero-angular webnstack-to-develop">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-hero-content appie-hero-content-8">
                            <h1 className="appie-title angular-font-size">
                                Our AngularJS development services
                            </h1>
                            <p className="Angular-font-size-p">
                                Professional web developers are employed by us to provide the best
                                Angular JS development services to our clients, ensuring flexibility
                                and scalability.Get a reliable, simple, and interactive programming
                                environment by using AngularJs.
                            </p>
                            <ul>
                                <li>
                                    <Link to="/contacts">
                                        <span>Let's Start</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-hero-thumb-6">
                            <div
                                className="thumb text-center wow animated"
                                data-wow-duration="1000ms"
                                data-wow-delay="600ms"
                            >
                                <img src={hero} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-8-shape-1">
                <img src={shapeThree} alt="" />
            </div>
            <div className="home-8-shape-2">
                <img src={shapeFour} alt="" />
            </div>
            <div className="home-8-shape-3">
                <img src={shapeOne} alt="" />
            </div>
            <div className="home-8-shape-4">
                <img src={shapeTwo} alt="" />
            </div>
        </section>
    );
}

export default Hero;
