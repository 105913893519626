import React from 'react';
import heroThumb from '../../../assets/images/webnstack/kakzon-1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area kakzon-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-kakzon-size">
                                Sport in Style-Showcasing Our Ecommerce Excellence in Sportswear
                                Stores
                            </h1>
                            <p className="font-size16px-p">
                                Kakzon is a sportswear online marketplace that offers the highest
                                quality clothing and accessories for yoga, bodybuilding, fitness,
                                and the gym. With exclusive brands, superior materials, and the most
                                affordable shipping prices, Kakzon provides the MENA area with the
                                greatest online shopping experience. Kakzon is becoming the most
                                popular platform for shopping a sportswear brands, and it’s now
                                expanding internationally to provide you the best sportswear brands
                                and sports accessories at the best possible price, as well as the
                                best possible service and prompt product delivery to your home.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
