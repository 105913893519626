import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterWithNewsletter from '../Shared/SharedFooterWithNewsletter';
import SharedHeader from '../Shared/SharedHeader';
import SharedInformationSection from '../Shared/SharedInformationSection';
import SharedTeamAbout from '../Shared/SharedTeamAbout';
import FunFact from './FunFact';
import Hero from './Hero';
import ShowCase from './ShowCase';
import WorkPart from './WorkPart';

function Team() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Team | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader drawer={drawer} action={drawerAction.toggle} />
            <Hero />
            <SharedTeamAbout />
            <FunFact />
            <ShowCase />
            <WorkPart className="pt-100" />
            <SharedInformationSection />
            <SharedFooterWithNewsletter className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Team;
