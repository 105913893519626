import React from 'react';
import heroThumb from '../../assets/images/hire-ecommerce-developer-1st-section.png';
import shapeTen from '../../assets/images/shape/shape-10.png';
import shapeEleven from '../../assets/images/shape/shape-11.png';
import shapeTwelve from '../../assets/images/shape/shape-12.png';
import shapeNine from '../../assets/images/shape/shape-9.png';
import MailchimpFour from '../MailchimpFour';

function Hero() {
    return (
        <section className="appie-hero-area-2 padding-top-hire-ecommerce-developer hire-ecommerce-pb20 home-hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 appie-hero-content-2">
                        <span>Hire Us</span>
                        <h1 className="appie-title">Hire Ecommerce Developer</h1>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="appie-hero-content-2">
                            <p className="font-size16px-p">
                                We are a company specializing in providing outsourcing services for
                                ecommerce development to clients worldwide. Our team consists of
                                experienced and skilled developers who have expertise in developing
                                custom ecommerce solutions using the latest technologies. We offer a
                                wide range of services including website design and development,
                                payment gateway integration, mobile commerce, and more. Our goal is
                                to provide high-quality and cost-effective solutions to our clients
                                and help them achieve their business goals through the use of
                                technology. With our flexible engagement models and a commitment to
                                meeting deadlines, we aim to be a trusted partner for all your
                                ecommerce development needs.
                            </p>
                            <MailchimpFour
                                action="https://webnstack.us10.list-manage.com/subscribe/post?u=c6f52f107eeb795a7539991db&amp;id=07592603db"
                                className="row"
                                fields={[
                                    {
                                        name: 'EMAIL',
                                        placeholder: 'hr@webnstack.com',
                                        type: 'email',
                                        required: true,
                                        data_type: 'default',
                                        data_class: 'input-box fal fa-envelope envelope',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="appie-hero-thumb-3 wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
            >
                <img src={heroThumb} alt="" />
            </div>
            <div className="hero-shape-1">
                <img src={shapeNine} alt="" />
            </div>
            <div className="hero-shape-2">
                <img src={shapeTen} alt="" />
            </div>
            <div className="hero-shape-3">
                <img src={shapeEleven} alt="" />
            </div>
            <div className="hero-shape-4">
                <img src={shapeTwelve} alt="" />
            </div>
        </section>
    );
}

export default Hero;
