import React from 'react';
import { Link } from 'react-router-dom';
import aboutThumb from '../../assets/images/hire-ecommerce-developer-2nd-section.png';

function AboutTwo() {
    return (
        <section className="appie-about-area">
            <div>
                <h1 className="appie-title text-center text-area-font-Size-44 hire-ecommerce-heading-size">
                    WebNStack Improves Your Development Process
                </h1>
                <p className="text-center mt-2">
                    Hire top-notch developers today by outsourcing to us to run smoothly.
                </p>
            </div>
            <div className="container pt-30">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-thumb">
                                        <img src={aboutThumb} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-about-content">
                                        <span className="hire-developers-size">
                                            HIRE DEVELOPERS
                                        </span>
                                        <p>
                                            We are a company specializing in providing outsourcing
                                            services for ecommerce development to clients worldwide.
                                            Our team consists of experienced and skilled developers
                                            who have expertise in developing custom ecommerce
                                            solutions using the latest technologies. We offer a wide
                                            range of services including website design and
                                            development, payment gateway integration, mobile
                                            commerce, and more. Our goal is to provide high-quality
                                            and cost-effective solutions to our clients and help
                                            them achieve their business goals through the use of
                                            technology. With our flexible engagement models and a
                                            commitment to meeting deadlines, we aim to be a trusted
                                            partner for all your ecommerce development needs.
                                        </p>
                                        <p>
                                            From custom ecommerce development to platform
                                            integration and maintenance, we provide end-to-end
                                            support to ensure your online store is always running
                                            smoothly.
                                        </p>
                                    </div>
                                    <div className="appie-section-title text-left pt-20">
                                        <Link
                                            className="main-btn hire-ecommerce-btn"
                                            to="/contacts"
                                        >
                                            Connect with our Developers{' '}
                                            <i className="fal fa-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutTwo;
