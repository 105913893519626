import React from 'react';
import eltrivia2 from '../../../assets/images/webnstack/el-trivia-2nd-section-image.png';
import eltrivia3 from '../../../assets/images/webnstack/el-trivia-3rd-section-el-trivia.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10 eltrivia-sec-padding-top
        "
            id="features"
        >
            <div className="container pt-120 mob-p-0 eltrivia-sec-padding-top">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={eltrivia2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The El Trivia project by DigifyMENA presented a challenge that
                                involved addressing API changes and resolving associated issues,
                                coupled with the ongoing maintenance of the attached Magento
                                application. The client's demand for API modifications required a
                                meticulous approach to ensure seamless integration and functionality
                                within the game. Our team undertook the task of not only
                                implementing these changes but also troubleshooting any emerging
                                issues to guarantee a smooth user experience.
                            </p>
                            <p>
                                Additionally, the maintenance of the Magento application posed its
                                own set of challenges, requiring constant supervision to identify
                                and address potential issues promptly. This involved routine
                                updates, performance monitoring, and ensuring data integrity to
                                uphold the stability and security of the platform.
                            </p>
                            <p>
                                Beyond the digital realm, our commitment to comprehensive support
                                extended to assisting El Trivia by DigifyMENA with server-related
                                challenges. This multifaceted project demanded a holistic and
                                proactive approach to deliver effective solutions, ensuring the
                                continued success and optimal performance of the El Trivia gaming
                                experience.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-0 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                In response to the challenges posed by El Trivia's evolving
                                requirements, our technical team executed a series of strategic
                                solutions. Addressing the client's demand for API changes and issue
                                resolutions, we conducted a thorough analysis of the existing API
                                structure and seamlessly implemented the necessary modifications.
                                This involved meticulous testing and debugging to ensure smooth
                                integration and functionality within the gaming environment.
                            </p>
                            <p>
                                Simultaneously, our team took charge of the maintenance of the
                                attached Magento application, employing a proactive approach to
                                address potential issues promptly. Routine updates were implemented
                                to enhance performance, monitor data integrity, and uphold the
                                overall stability and security of the Magento platform.
                            </p>
                            <p>
                                Furthermore, we extended our technical expertise to support El
                                Trivia by DigifyMENA with server-related challenges. This involved
                                optimizing server configurations, addressing performance
                                bottlenecks, and ensuring a robust infrastructure to handle the
                                game's dynamic user interactions.
                            </p>
                            <p>
                                {' '}
                                Through a combination of API enhancements, Magento application
                                maintenance, and server optimization, our technical interventions
                                were geared towards creating a resilient and efficient gaming
                                platform for El Trivia. Our commitment to delivering tailored
                                solutions ensured that the client's objectives were not only met but
                                exceeded, contributing to the seamless and enjoyable experience of
                                El Trivia players.{' '}
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={eltrivia3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
