import React from 'react';
import aboutThumb from '../../assets/images/hire-ecommerce-page-3rd-section.png';

function About() {
    return (
        <section className="appie-about-area">
            <div className="container">
                <h1 className="title text-center text-area-font-Size-44 hire-ecommerce-heading-size">
                    Ecommerce Success Starts with the Right
                </h1>{' '}
                <h1 className="title text-center text-area-font-Size-44 hire-ecommerce-heading-size">
                    Team-Hire the Best Developers Today
                </h1>
                <div className="row pt-30">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-thumb">
                                        <img src={aboutThumb} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-about-content">
                                        <span>WHY CHOOSE US ?</span>

                                        <p>
                                            Hiring eCommerce developers simply assures that your
                                            project’s requirements are satisfied and that it is
                                            handled with care and expertise. Meeting expectations
                                            and ensuring client satisfaction with the results are
                                            critical aspects of what is being done. Hiring dedicated
                                            developers prioritises such goals over all others.You
                                            also get global support and maintenance 24 hours a day,
                                            7 days a week. You also receive high-quality work in a
                                            timely manner, resulting in the greatest outcomes. In
                                            addition, we have other alternatives that allow us to
                                            engage professional developers and select the finest
                                            models. With years of expertise and skill in multiple
                                            Platforms available, the eCommerce specialists design
                                            speedier to let clients to get up and running faster
                                            than anybody else. Choosing the service to employ
                                            qualified eCommerce developers provides top-quality
                                            development services at competitive pricing. Hiring
                                            specialised eCommerce workers respects privacy by
                                            assuring the safety and security of company initiatives
                                            and data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
