import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';

import SharedFooterWithNewsletter from '../Shared/SharedFooterWithNewsletter';
import SharedHeader from '../Shared/SharedHeader';
import SharedInformationSection from '../Shared/SharedInformationSection';
import SharedServicesThree from '../Shared/SharedServicesThree';
import WorkingPlatforms from './WorkingPlatforms';

import SharedTeamAbout from '../Shared/SharedTeamAbout';
import FunFact from './FunFact';
import Hero from './Hero';
import Services from './Services';
import ServicesAbout from './ServicesAbout';
import WorkPart from './WorkPart';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        document.title = 'About Us | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader drawer={drawer} action={drawerAction.toggle} />
            <Hero />
            <ServicesAbout />
            <SharedServicesThree style={{ paddingTop: '10px' }} />
            <FunFact />
            <WorkPart className="pt-100" />
            <Services />
            <SharedTeamAbout />
            <WorkingPlatforms />
            <SharedInformationSection />
            <SharedFooterWithNewsletter className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AboutUs;
