import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import featureThumb from '../../assets/images/webnstack/hire-web-development-4th-section.png';

function Features() {
    return (
        <section
            className="appie-features-area-2 pt-90 pb-100 hire-web-developer-padding-top-bottom"
            id="features"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title appie-section-title-2 text-center">
                            <h1 className="appie-title hire-web-developer-appie-title-size">
                                Full Stack Web Services for Exceptional Solutions
                            </h1>
                            <p>
                                Hire developers for your business is an important decision as it can
                                help you create a website that is both active and functional.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-30 align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-features-boxes">
                            <div className="appie-features-box-item">
                                <h1 className="title">Strategy for Business</h1>
                                <p>
                                    Every new opportunity necessitates a specific approach and
                                    technique. Our staff recognises that and adheres to that
                                    one-of-a-kind plan throughout.
                                </p>
                            </div>
                            <div className="appie-features-box-item item-2">
                                <h1 className="title">Proposal for a Company</h1>
                                <p>
                                    We develop a business proposal based on the needs and strategy.
                                    Our developers are significantly more evolved in terms of
                                    functioning and delivering.
                                </p>
                            </div>
                            <div className="appie-features-box-item item-3">
                                <h1 className="title">Carry out the Negotiation</h1>
                                <p>
                                    It is not always about when it comes to developing excellent and
                                    healthy relationships. We understand your concerns and are ready
                                    to work on your behalf regarding value.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-features-thumb wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img src={featureThumb} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shape15} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shape14} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shape13} alt="" />
            </div>
        </section>
    );
}

export default Features;
