import React from 'react';
import glowbympi1 from '../../../assets/images/webnstack/glow-by-mpi-1st.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-hrpayroll-top-section glowbympi-seo-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Glow By MPI, Revolutionizing Skincare with a Mobile App Experience
                            </h1>
                            <p>
                                Glow By MPI" is a groundbreaking mobile application that streamlines
                                the process of ordering MPI cosmetics and skincare products.
                                Designed for both doctors and clients, the app offers a seamless
                                experience, allowing users to earn redeemable points, access
                                exclusive deals, and discover skincare routines tailored to their
                                needs. With quarterly rewards, special promotions, and the ability
                                to connect with skincare professionals, Glow By MPI prioritizes
                                user-friendly skincare solutions.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={glowbympi1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
