const PortfolioData = [
    // home page
    // Website Design
    {
        id: 1,
        img: '/assets/img/project/project-1.jpg',
        title: 'Hosting Website Design',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'Website Design',
        big: true,
        home: true,
    },
    {
        id: 2,
        img: '/assets/img/project/project-2.jpg',
        title: 'Medical Website',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'Website Design',
        sm: true,
        home: true,
    },
    {
        id: 3,
        img: '/assets/img/project/project-4.jpg',
        title: 'Pet Care Website',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'Website Design',
        sm: true,
        home: true,
    },
    // Branding Design
    {
        id: 4,
        img: '/assets/img/project/brand-1.jpg',
        title: 'Web Application Design',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'Branding Design',
        big: true,
        home: true,
    },
    {
        id: 5,
        img: '/assets/img/project/brand-2.jpg',
        title: 'Globar Art Design',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'Branding Design',
        sm: true,
        home: true,
    },
    {
        id: 6,
        img: '/assets/img/project/brand-3.jpg',
        title: 'Modern Branding Design',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'Branding Design',
        sm: true,
        home: true,
    },
    // App Design
    {
        id: 7,
        img: '/assets/img/project/app-1.jpg',
        title: 'Banking App Management',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'App Design',
        big: true,
        home: true,
    },
    {
        id: 8,
        img: '/assets/img/project/app-2.jpg',
        title: 'Financial App Design',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'App Design',
        sm: true,
        home: true,
    },
    {
        id: 9,
        img: '/assets/img/project/app-3.jpg',
        title: 'Yoga App Design',
        sm_text: 'At Collax we specialize in designing, building, shipping...',
        category: 'App Design',
        sm: true,
        home: true,
    },

    // home three page
    {
        id: 10,
        img: '/assets/img/project/project-9.jpg',
        title: 'Book art design',
        category: 'Branding',
        home_3: true,
    },
    {
        id: 11,
        img: '/assets/img/project/project-10.jpg',
        title: 'App design',
        category: 'Marketing',
        home_3: true,
    },
    {
        id: 12,
        img: '/assets/img/project/project-6.6.jpg',
        title: 'UI UX design',
        category: 'Web Design',
        home_3: true,
    },
    {
        id: 13,
        img: '/assets/img/project/project-6.5.jpg',
        title: 'Logo design',
        category: 'Development',
        home_3: true,
    },
    // home four
    // category website
    {
        id: 14,
        img: '/assets/img/project/project-4.1.jpg',
        title: 'Book art design',
        subtitle: 'Branding',
        category: 'Website',
        big: true,
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 15,
        img: '/assets/img/project/project-4.2.jpg',
        title: 'Graphic Design',
        subtitle: 'Design',
        category: 'Website',
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 16,
        img: '/assets/img/project/project-4.3.jpg',
        title: '3d Digital Art',
        subtitle: 'Design',
        category: 'Website',
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 17,
        img: '/assets/img/blog-details/blog-big-5.jpg',
        title: '3d Digital Art',
        subtitle: 'Design',
        category: 'Website',
        big: true,
        portfolio_masonry: true,
    },
    {
        id: 18,
        img: '/assets/img/project/project-4.5.jpg',
        title: 'Mobile App Design',
        subtitle: 'Design',
        category: 'Website',
        home_4: true,
        portfolio_masonry: true,
    },
    // add or portfolio masonry items
    {
        id: 19,
        img: '/assets/img/blog-details/blog-sm-6.jpg',
        title: 'Architecture Design',
        subtitle: 'Design',
        category: 'Website',
        portfolio_masonry: true,
    },
    {
        id: 20,
        img: '/assets/img/project/project-4.jpg',
        title: 'Web Design',
        subtitle: 'Design',
        category: 'Website',
        home_4: true,
        portfolio_masonry: true,
    },
    // category landing page
    {
        id: 21,
        img: '/assets/img/project/project-4.1.jpg',
        title: 'Book art design',
        subtitle: 'Branding',
        category: 'Landing Page',
        big: true,
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 22,
        img: '/assets/img/project/project-4.4.jpg',
        title: 'Web Design',
        subtitle: 'Design',
        category: 'Landing Page',
        portfolio_masonry: true,
    },
    {
        id: 23,
        img: '/assets/img/project/project-4.3.jpg',
        title: '3d Digital Art',
        subtitle: 'Design',
        category: 'Landing Page',
        portfolio_masonry: true,
    },
    {
        id: 24,
        img: '/assets/img/blog-details/blog-big-5.jpg',
        title: '3d Digital Art',
        subtitle: 'Design',
        category: 'Landing Page',
        big: true,
        portfolio_masonry: true,
    },
    // category ios App
    {
        id: 25,
        img: '/assets/img/project/project-4.2.jpg',
        title: 'Graphic Design',
        subtitle: 'Design',
        category: 'ios App',
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 26,
        img: '/assets/img/blog-details/blog-big-5.jpg',
        title: '3d Digital Art',
        subtitle: 'Design',
        category: 'ios App',
        big: true,
        portfolio_masonry: true,
    },
    {
        id: 27,
        img: '/assets/img/project/project-4.4.jpg',
        title: 'Web Design',
        subtitle: 'Design',
        category: 'ios App',
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 28,
        img: '/assets/img/project/project-4.3.jpg',
        title: '3d Digital Art',
        subtitle: 'Design',
        category: 'ios App',
        home_4: true,
        portfolio_masonry: true,
    },
    // category Branding Design
    {
        id: 29,
        img: '/assets/img/project/project-4.1.jpg',
        title: 'Book art design',
        subtitle: 'Branding',
        category: 'Branding Design',
        big: true,
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 30,
        img: '/assets/img/project/project-4.2.jpg',
        title: 'Graphic Design',
        subtitle: 'Design',
        category: 'Branding Design',
        home_4: true,
        portfolio_masonry: true,
    },
    {
        id: 31,
        img: '/assets/img/blog-details/blog-sm-6.jpg',
        title: 'Architecture Design',
        subtitle: 'Design',
        category: 'Branding Design',
        portfolio_masonry: true,
    },
    // portfolio page 2
    // category website

    {
        id: 'kakzon',
        img: '/assets/images/kakzonportfolio.png',
        title: 'KAKZON',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'hrpayroll',
        img: '/assets/images/hr-payrollportfolio.png',
        title: 'HR PAYROLL',
        subtitle: '[design, development]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'toyfiesta',
        img: '/assets/images/toyfiestaportfolio.png',
        title: 'TOYFIESTA',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'deelert',
        img: '/assets/images/deelertportfolio.png',
        title: 'DEELERT',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'livelinen',
        img: '/assets/images/livelinenportfolio.png',
        title: 'LIVELINEN',
        subtitle: '[eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'sandiabio',
        img: '/assets/images/sandiabioportfolio.png',
        title: 'SANDIABIO',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'almonaplast',
        img: '/assets/images/almonaplastportfolio.png',
        title: 'ALMONAPLAST',
        subtitle: '[design, development]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'fragranceshop',
        img: '/assets/images/frangranceportfolio.png',
        title: 'FRAGRANCESHOP',
        subtitle: '[eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'kenwoodkitchen',
        img: '/assets/images/food-and-drinksportfolio.png',
        title: 'KENWOOD KITCHEN',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'sporthuis',
        img: '/assets/images/sporthuis1portfolio.png',
        title: 'SPORTHUIS',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'dealon',
        img: '/assets/images/dealonportfolio.png',
        title: 'DEALON',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'cloudearly',
        img: '/assets/images/cloudearlyportfolio.png',
        title: 'CLOUDEARLY',
        subtitle: '[design, development]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'eltrivia',
        img: '/assets/images/el-triviaportfolio.png',
        title: 'El TRIVIA',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'deliverzero',
        img: '/assets/images/deliverzeroportfolio.png',
        title: 'DELIVERZERO',
        subtitle: '[design , development , eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'rkbuilders',
        img: '/assets/images/rk-buildersportfolio.png',
        title: 'RK BUILDERS',
        subtitle: '[design, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'gosmarteyewear',
        img: '/assets/images/gosmarteyewearportfolio.png',
        title: 'GOSMARTEYEWEAR',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'glowbympi',
        img: '/assets/images/glow-by-mpi-portfolio.png',
        title: 'GLOW BY MPI',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'mediaworldegypt',
        img: '/assets/images/mediaworldportfolio.png',
        title: 'MEDIA WORLD EGYPT',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    {
        id: 'assl',
        img: '/assets/images/asslportfolio.png',
        title: 'ASSL',
        subtitle: '[development, eCommerce]',
        category: 'ALL',
        portfolio_2: true,
    },
    // portfolio page 2
    // category landing page
    {
        id: 'kakzon',
        img: '/assets/images/kakzonportfolio.png',
        title: 'KAKZON',
        subtitle: '[development, eCommerce]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'hrpayroll',
        img: '/assets/images/hr-payrollportfolio.png',
        title: 'HR PAYROLL',
        subtitle: '[design, development]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'toyfiesta',
        img: '/assets/images/toyfiestaportfolio.png',
        title: 'TOYFIESTA',
        subtitle: '[development, eCommerce]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'almonaplast',
        img: '/assets/images/almonaplastportfolio.png',
        title: 'ALMONAPLAST',
        subtitle: '[design, development]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'cloudearly',
        img: '/assets/images/cloudearlyportfolio.png',
        title: 'CLOUDEARLY',
        subtitle: '[design, development]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'deliverzero',
        img: '/assets/images/deliverzeroportfolio.png',
        title: 'DELIVERZERO',
        subtitle: '[design , development , eCommerce]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'rkbuilders',
        img: '/assets/images/rk-buildersportfolio.png',
        title: 'RK BUILDERS',
        subtitle: '[design, eCommerce]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    {
        id: 'gosmarteyewear',
        img: '/assets/images/gosmarteyewearportfolio.png',
        title: 'GOSMARTEYEWEAR',
        subtitle: '[development, eCommerce]',
        category: 'DESIGN',
        portfolio_2: true,
    },
    // portfolio page 2
    // category ios app
    {
        id: 'toyfiesta',
        img: '/assets/images/toyfiestaportfolio.png',
        title: 'TOYFIESTA',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'kakzon',
        img: '/assets/images/kakzonportfolio.png',
        title: 'KAKZON',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'assl',
        img: '/assets/images/asslportfolio.png',
        title: 'ASSL',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'sporthuis',
        img: '/assets/images/sporthuis1portfolio.png',
        title: 'SPORTHUIS',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'sandiabio',
        img: '/assets/images/sandiabioportfolio.png',
        title: 'SANDIABIO',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'livelinen',
        img: '/assets/images/livelinenportfolio.png',
        title: 'LIVELINEN',
        subtitle: '[eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'gosmarteyewear',
        img: '/assets/images/gosmarteyewearportfolio.png',
        title: 'GOSMARTEYEWEAR',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'deelert',
        img: '/assets/images/deelertportfolio.png',
        title: 'DEELERT',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'dealon',
        img: '/assets/images/dealonportfolio.png',
        title: 'DEALON',
        subtitle: '[development, eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    {
        id: 'fragranceshop',
        img: '/assets/images/frangranceportfolio.png',
        title: 'FRAGRANCESHOP',
        subtitle: '[eCommerce]',
        category: 'eCommerce',
        portfolio_2: true,
    },
    // portfolio page 2
    // category branding design
    {
        id: 'hrpayroll',
        img: '/assets/images/hr-payrollportfolio.png',
        title: 'HR PAYROLL',
        subtitle: '[design, development]',
        category: 'APPLICATION',
        portfolio_2: true,
    },
    {
        id: 'dealon',
        img: '/assets/images/dealonportfolio.png',
        title: 'DEALON',
        subtitle: '[development, eCommerce]',
        category: 'APPLICATION',
        portfolio_2: true,
    },
    {
        id: 'glowbympi',
        img: '/assets/images/glow-by-mpi-portfolio.png',
        title: 'GLOW BY MPI',
        subtitle: '[development, eCommerce]',
        category: 'APPLICATION',
        portfolio_2: true,
    },
    {
        id: 'eltrivia',
        img: '/assets/images/el-triviaportfolio.png',
        title: 'El TRIVIA',
        subtitle: '[development, eCommerce]',
        category: 'APPLICATION',
        portfolio_2: true,
    },
    {
        id: 'mediaworldegypt',
        img: '/assets/images/mediaworldportfolio.png',
        title: 'MEDIA WORLD EGYPT',
        subtitle: '[development, eCommerce]',
        category: 'APPLICATION',

        portfolio_2: true,
    },
    {
        id: 'kenwoodkitchen',
        img: '/assets/images/food-and-drinksportfolio.png',
        title: 'KENWOOD KITCHEN',
        subtitle: '[development, eCommerce]',
        category: 'APPLICATION',
        portfolio_2: true,
    },
    // service page recent product
    {
        id: 51,
        img: '/assets/img/service/service-9.1.jpg',
        title: 'Godaddy user flow solution',
        subtitle: (
            <>
                At Collax we specialize in designing, building, <br /> shipping and scaling beautifu
            </>
        ),
        duration: '.3s',
        delay: '.5s',
        service_p: true,
    },
    {
        id: 52,
        img: '/assets/img/service/service-9.2.jpg',
        title: 'What is ui/ux design trend',
        subtitle: (
            <>
                At Collax we specialize in designing, building, <br /> shipping and scaling beautifu
            </>
        ),
        duration: '.5s',
        delay: '.7s',
        service_p: true,
    },
    {
        id: 53,
        img: '/assets/img/service/service-9.3.jpg',
        title: 'Create you design system like',
        subtitle: (
            <>
                At Collax we specialize in designing, building, <br /> shipping and scaling beautifu
            </>
        ),
        duration: '.7s',
        delay: '.9s',
        service_p: true,
    },
];

export default PortfolioData;
