import React from 'react';
import { Link } from 'react-router-dom';
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';
import heroThumbOne from '../../assets/images/shopify/section-image1.png';
import heroThumbTwo from '../../assets/images/shopify/section-image2.png';

function Hero({ className }) {
    return (
        <section
            className={`appie-hero-area padding-top-hero-shopify-development Shopifyverse ${
                className || ''
            }`}
        >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="appie-hero-content Shopifyverses">
                            <span className="preheading">Welcome To WebNStack's Shopifyverse</span>
                            <h1 className="appie-title">
                                Custom Shopify App Development At Its Best
                            </h1>
                            <p className="font-size16px-p">
                                With a custom interface, we can take your Shopify e-commerce
                                business to the next level. A website can help you reach new clients
                                and markets while also improving service to existing ones. Do you
                                have a great idea but aren’t sure how to make it happen? Do you
                                require functionality that goes beyond Shopify’s regular features?
                                Whatever your Shopify demands are, our Shopify Experts have a
                                solution for all of you here.
                            </p>
                            <ul>
                                <li>
                                    <Link className="item-2" to="/contacts">
                                        Want to explore about Shopify?
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-hero-thumb Custom-Shopify">
                            <div
                                className="thumb wow animated"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <img className="shopifyimg" src={heroThumbOne} alt="" />
                            </div>
                            <div
                                className="thumb-2 shadow-none wow animated shopifymig image-box"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <img className="shopifyimg2" src={heroThumbTwo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-shape-1">
                <img src={shapeTwo} alt="" />
            </div>
            <div className="hero-shape-2">
                <img src={shapeThree} alt="" />
            </div>
            <div className="hero-shape-3">
                <img src={shapeFour} alt="" />
            </div>
        </section>
    );
}

export default Hero;
