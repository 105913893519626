import React from 'react';
import heroThumb from '../../../assets/images/gosmarteyewear/1st-section.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mb-100 gosmarteyewear-sec-one-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Redefining Eyewear for the Youngest Eyes at GoSmartEyewear
                            </h1>
                            <p>
                                GoSmartEyewear, driven by a mission to provide the finest quality
                                eyewear for babies, toddlers, and children, boasts over a decade of
                                expertise in crafting and delivering high-quality children's eyewear
                                at an affordable cost. Exceptional eyewear gained the trust of
                                parents, a natural evolution occurred—parents sought the same level
                                of quality for themselves. This paradigm shift led GoSmartEyewear to
                                expand its offerings, now encompassing lenses and frames tailored to
                                meet the eyewear needs of the entire family. The project stands as a
                                testament to GoSmartEyewear's dedication to delivering excellence
                                and meeting the evolving demands of its valued clientele.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={heroThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
