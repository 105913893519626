import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import SharedTestimonial from '../Shared/SharedTestimonial';
import About from './About';
import CounterArea from './CounterArea';
import Download from './Download';
import Features from './Features';
import Hero from './Hero';
import Services from './Services';
import Sponser from './Sponser';
import VideoPlayer from './VideoPlayer';

function Home() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        document.title = 'Home | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader action={drawerAction.toggle} />
            <Hero />
            <Services />
            <About />
            <Features />
            <CounterArea />
            <VideoPlayer />
            <Download />
            <SharedTestimonial />
            <Sponser />
            <SharedFooter />
            <BackToTop className="back-to-top-2" />
        </>
    );
}

export default Home;
