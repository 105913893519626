import React from 'react';
import Sponser1 from '../../assets/images/Sponser/asana.png';
import Sponser10 from '../../assets/images/Sponser/bitbucket.png';
import Sponser2 from '../../assets/images/Sponser/cloudflare.png';
import Sponser7 from '../../assets/images/Sponser/github.png';
import Sponser6 from '../../assets/images/Sponser/hotjar.png';
import Sponser5 from '../../assets/images/Sponser/jira.png';
import Sponser4 from '../../assets/images/Sponser/magento.png';
import Sponser3 from '../../assets/images/Sponser/shopify.png';
import Sponser8 from '../../assets/images/Sponser/slack.png';
import Sponser9 from '../../assets/images/Sponser/zendesk.png';
import sponserShape from '../../assets/images/sponser-shape.png';

function Sponser({ className }) {
    return (
        <section className={`appie-sponser-area pb-100 mb-50 ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20px-p mob-margin-top0">
                                WebNStack works best with <br />
                                your favorite platforms
                            </h1>
                            <p className="font-size14px-p">
                                WE ARE TRUSTED 15+ COUNTRIES WORLDWIDE
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-sponser-box d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item ht-100">
                                <img src={Sponser1} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item ht-100">
                                <img src={Sponser2} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item ht-100">
                                <img src={Sponser3} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item ht-100">
                                <img src={Sponser4} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item ht-100">
                                <img src={Sponser5} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item ht-100">
                                <img src={Sponser6} alt="" />
                            </div>
                        </div>
                        <div className="appie-sponser-box item-2 d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser7} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser8} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser9} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser10} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sponser-shape">
                <img src={sponserShape} alt="" />
            </div>
        </section>
    );
}

export default Sponser;
