import React, { useEffect } from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/Drawer';
import SharedFooter from '../../Shared/SharedFooter';
import SharedHeaderTwo from '../../Shared/SharedHeaderTwo';
import About from './About';
import Download from './Download';
import Hero from './Hero';

import ShowCase from './ShowCase';

function OurProjectsGosmarteyewear() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'GOSMARTEYEWEAR | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <Hero />
            <About />
            <ShowCase />
            <Download />
            <SharedFooter />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default OurProjectsGosmarteyewear;
