import React from 'react';
import aboutThumb from '../../assets/images/reactsecFourOne.png';
import aboutThumb3 from '../../assets/images/reactsecFourTwo.png';

function About() {
    return (
        <section className="appie-about-3-area pt-60 pb-0" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">Easy WebNStack's Building User Interfaces</h1>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="appie-traffic-service mb-30">
                                    <div className="icon react-js-margin-bottom">
                                        <div className="iconic">
                                            <i className="fas fa-code background-box-shadow-none"></i>
                                        </div>
                                    </div>
                                    <h1 className="title d-inline pl-3 reactjs-font-size">
                                        IMPROVED SYNTAX
                                    </h1>
                                    <p className="font-size16px-p reactjs-about-padding">
                                        Making interactive UIs is simple using Syntactically correct
                                        React. React will effectively update and render the
                                        appropriate components as your data changes if you provide
                                        straightforward views for each state in your application.
                                        React accepts the notion that additional UI logic-how events
                                        are handled, how the state evolves over time, and how the
                                        data is organised for display-is inextricably linked with
                                        rendering logic. Syntax views improve the predictability and
                                        debuggability of your code.
                                    </p>
                                </div>

                                <div className="appie-traffic-service item-2 mb-30">
                                    <div className="icon react-js-margin-bottom">
                                        <div className="iconic">
                                            <i className="fas fa-project-diagram background-box-shadow-none"></i>
                                        </div>
                                    </div>
                                    <h1 className="title d-inline pl-3 reactjs-font-size">
                                        COMPONENT ELEMENT
                                    </h1>
                                    <p className="font-size16px-p reactjs-about-padding">
                                        Build self-contained, enclosed components, then combine them
                                        to create intricate user interfaces. React separates issues
                                        through loosely connected pieces called “components” that
                                        contain both, as opposed to artificially dividing technology
                                        by putting HTML and code in different files. You can simply
                                        transmit rich data through your app and keep state off of
                                        the structure since component functionality is defined in
                                        JavaScript rather than templates.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title">
                            <h1 className="title">Unleashing the Full Potential of React.js</h1>
                            <p className="font-size16px-p">
                                This adaptability extends React's utility beyond traditional web
                                applications, making it a comprehensive solution for diverse
                                development needs. Whether you're building a dynamic user interface,
                                a cross-platform mobile application, or implementing server-side
                                rendering, React's versatility remains a valuable asset throughout
                                the development lifecycle. Its ecosystem, supported by a vast
                                library of reusable components and a vibrant community, contributes
                                to the seamless integration and scalability of
                                React-powered projects.
                            </p>
                        </div>
                        <div className="row"></div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
