import React from 'react';
import deliverzerosection4 from '../../../assets/images/webnstack/deliverzero-4th-section.png';

function Testimonial() {
    return (
        <section className="appie-about-3-area mt-0 pb-50" id="features">
            <div className="container">
                <div className="row align-items-center mt-40">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={deliverzerosection4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">RESULTS & PROCESS</h1>
                            <p>
                                The successful delivery of the Deliverzero project has resulted in
                                the creation of a groundbreaking and highly customized e-commerce
                                platform.
                            </p>
                            <p>
                                {' '}
                                This innovative solution successfully manages the intricacies of a
                                reusable container food ordering system within an eco-friendly
                                community.
                            </p>
                            <p>
                                {' '}
                                Our team addressed the challenges with precision, organizing store
                                products unconventionally, implementing a user-friendly geographic
                                filtering system, and incorporating dynamic custom fees during
                                checkout.
                            </p>
                            <p>
                                {' '}
                                The result was a highly customized e-commerce platform that not only
                                met the complex challenges posed by the project but also exceeded
                                the client's expectations, providing a cutting-edge solution for
                                Deliverzero's unique needs.
                            </p>
                            <p>
                                {' '}
                                The developed platform stands as a testament to our commitment to
                                providing tailored solutions that align with the client's specific
                                needs.
                            </p>
                            <p>
                                {' '}
                                The seamless communication between restaurants and the E-Commerce
                                (Magento) Store through the dedicated app further enhances
                                operational efficiency.
                            </p>
                            <p>
                                {' '}
                                Overall, the project's success is marked by a cutting-edge,
                                user-centric e-commerce platform that not only meets but exceeds the
                                unique requirements of Deliverzero, showcasing Webnstack's expertise
                                in delivering impactful and customized solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Testimonial;
