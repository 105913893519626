import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-100 hire-web-developer-appie ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-8 col-md-6">
                        <div className="appie-section-title hire-web-developer-section-title">
                            <h1 className="appie-title">
                                Elevate your website to the next level with our skilled development
                                team
                            </h1>
                            <p>
                                We have a team of specialists with a wealth of knowledge in their
                                respective industries. Their strategy remains, dynamic, and
                                market-exclusive.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user icon-size-30"></i>
                            </div>

                            <h1 className="title">Junior Programmers</h1>
                            <p className="text-justify">
                                Our Junior Developers with 1 to 2 years of experience understand the
                                client’s goals and guarantee that the entire process meets
                                standards. They have deep understanding and do their best effort to
                                generate amazing and distinctive growth.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user-plus icon-size-30"></i>
                            </div>
                            <h1 className="title">Senior Programmers</h1>
                            <p className="text-justify">
                                With 2 to 8 years of experience, senior techincal developers are
                                highly skilled and proficient throughout the development process.
                                They brings us with the standard code and achieve great success,
                                bug-free, innovative solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-3 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-user-tie icon-size-30"></i>
                            </div>
                            <h1 className="title">Managers of Projects</h1>
                            <p className="text-justify">
                                Our project managers understand how to manage and complete projects.
                                Expert Team specializes in development, and our project leaders pay
                                close attention to every minute aspect in the development process to
                                ensure customer satisfaction.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-paint-brush icon-size-30"></i>
                            </div>
                            <h1 className="title">Designers of UI/UX</h1>
                            <p className="text-justify">
                                In this era of competition, our developers understand the necessity
                                of the websites for businesses. They are knowledgeable in all of the
                                current web technologies and provide great web design and
                                development services to clients.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-globe icon-size-30"></i>
                            </div>
                            <h1 className="title">Web Designers</h1>
                            <p className="text-justify">
                                WebNStack’s developers are exceptional and devoted. We have a team
                                of experienced web developers with years of industry expertise. Our
                                developers have a visionary approach to the future of development,
                                ensuring innovation and excellence.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-search icon-size-30"></i>
                            </div>
                            <h1 className="title">Reviewers/Testers</h1>
                            <p className="text-justify">
                                One of the most important parts of any effective solution is quality
                                assurance. We believe in providing the highest quality services in
                                the industry, QA team evaluates every project we work on and assists
                                us in providing bug-free solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
