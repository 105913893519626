import React from 'react';
import sponserShape from '../../assets/images/sponser-shape.png';
import Sponser3 from '../../assets/images/webnstack/intehrations/Catch.png';
import Sponser4 from '../../assets/images/webnstack/intehrations/Google-Pay.png';
import Sponser9 from '../../assets/images/webnstack/intehrations/QUICK-BOOKS.png';
import Sponser10 from '../../assets/images/webnstack/intehrations/STRIPE.png';
import Sponser1 from '../../assets/images/webnstack/intehrations/active-campaign.png';
import Sponser2 from '../../assets/images/webnstack/intehrations/algolia.png';
import Sponser5 from '../../assets/images/webnstack/intehrations/m2e.png';
import Sponser6 from '../../assets/images/webnstack/intehrations/mail-chimp.png';
import Sponser7 from '../../assets/images/webnstack/intehrations/net-suite.png';
import Sponser8 from '../../assets/images/webnstack/intehrations/paypal.png';

function SponserHomeTwo({ className }) {
    return (
        <section className={`appie-sponser-area pb-100 ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center pt-60 Integrations">
                            <h1 className="appie-title">INTEGRATIONS WITH MAGENTO</h1>
                            <p className="font-size16px-p">
                                We can link your e-commerce website with a number of pre-existing
                                platforms, including email marketing, inventory tracking,
                                accounting, CRM, and e-point-of-sale systems. Additionally, we
                                collaborate with several other processors, such as NetSuite,
                                Sagepay, Paypal, stripe, M2e Pro, Mailchimp, Quickbook,
                                ActiveCampaign, Catch marketplace, Algolia Engine, Google Shopping.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-sponser-box d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser1} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser2} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser3} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser4} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser5} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser6} alt="" />
                            </div>
                        </div>
                        <div className="appie-sponser-box item-2 d-flex justify-content-center">
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser7} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser8} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser9} alt="" />
                            </div>
                            <div className="sponser-item sponser-item-item">
                                <img src={Sponser10} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sponser-shape">
                <img src={sponserShape} alt="" />
            </div>
        </section>
    );
}

export default SponserHomeTwo;
