import React from 'react';
import aboutThumb from '../../../assets/images/deelert-2nd-section-image.png';
import aboutThumb3 from '../../../assets/images/deelert-3rd-section-image.png';
import aboutThumb4 from '../../../assets/images/deelert-4th-section-image.png';

function About() {
    return (
        <section className="appie-about-3-area pt-100 pb-40 mob-p-0" id="features">
            <div className="container">
                <div className="row align-items-center mt-150">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The challenge presented by the client's Magento 2 site hosted on AWS
                                Lightsail was multifaceted, primarily revolving around slow loading
                                times.
                            </p>
                            <p>
                                {' '}
                                The sluggish performance raised concerns about a potential
                                misconfiguration in the server setup or issues with caching
                                mechanisms.
                            </p>
                            <p>
                                {' '}
                                To address this, the task at hand included a comprehensive
                                optimization of the website speed, necessitating a thorough
                                examination and potential reconfiguration of the server setup to
                                align it with best practices.
                            </p>
                            <p>
                                {' '}
                                Concurrently, efforts were directed towards enhancing caching
                                strategies to ensure efficient content delivery.
                            </p>
                            <p>
                                {' '}
                                Adding complexity to the challenge was the client's directive to
                                optimize the website speed using standard code practices.
                            </p>
                            <p>
                                {' '}
                                This involved scrutinizing the existing codebase, identifying
                                bottlenecks, and implementing industry best practices to streamline
                                performance.
                            </p>
                            <p>
                                {' '}
                                The client sought integration with Facebook Pixel and Google
                                Analytics for advanced analytics capabilities, requiring meticulous
                                implementation to ensure accurate tracking and data collection for
                                marketing insights.
                            </p>
                            <p>
                                {' '}
                                The project extended to encompass Magento upgrades and ongoing
                                maintenance.{' '}
                            </p>
                            <p>
                                Upgrading the Magento platform demanded precision to prevent
                                disruptions and maintain compatibility with existing configurations,
                                while continuous maintenance was vital for sustained improvements in
                                website performance and functionality.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                Our team executed a comprehensive strategy to enhance site
                                performance and functionality. The primary concern was the slow
                                loading times, potentially stemming from server and caching issues.
                            </p>
                            <p>
                                We identified potential misconfigurations and implemented
                                adjustments to align it with industry best practices.
                            </p>
                            <p>
                                Simultaneously, we optimized caching mechanisms to ensure efficient
                                content delivery, resolving the underlying causes of the sluggish
                                performance.
                            </p>
                            <p>
                                A key aspect of our solution involved optimizing the website speed
                                through adherence to standard code practices. We conducted a
                                thorough review of the existing codebase, identifying and rectifying
                                bottlenecks to streamline performance.
                            </p>
                            <p>
                                {' '}
                                This not only addressed the immediate issue but also laid the
                                foundation for sustained improvements.
                            </p>
                            <p>
                                Responding to the client's directive, we seamlessly integrated
                                Facebook Pixel and Google Analytics, empowering them with advanced
                                analytics capabilities. This involved precise implementation to
                                ensure accurate tracking and data collection for informed marketing
                                insights.
                            </p>
                            <p>
                                The project also included Magento upgrades, executed with precision
                                to prevent disruptions and maintain compatibility with existing
                                configurations. Ongoing maintenance became a cornerstone, ensuring
                                sustained improvements in website performance and functionality.
                            </p>
                            <p>
                                In conclusion, our strategic approach encompassed server
                                optimization, caching enhancements, codebase refinement, third-party
                                integrations, and platform upgrades. The collaborative efforts
                                resulted in transforming the client's Magento 2 website into a
                                faster, more efficient, and seamlessly integrated online platform,
                                effectively overcoming the challenges initially posed by slow
                                loading times.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="align-items-center mt-30 flex-column-reverse flex-lg-row">
                    <div className="row mt-0">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h2 className="title">RESULTS & PROCESS</h2>
                                <p>
                                    The successful delivery of our solution to the client's Magento
                                    2 website challenge hosted on AWS Lightsail has resulted in a
                                    remarkable transformation.
                                </p>
                                <p>
                                    {' '}
                                    Addressing the issue of slow loading times, our team executed a
                                    comprehensive strategy to enhance site performance and
                                    functionality.
                                </p>
                                <p>
                                    {' '}
                                    Our meticulous examination and optimization of the server setup
                                    resolved potential misconfigurations, ensuring it adheres to
                                    industry best practices.
                                </p>
                                <p>
                                    {' '}
                                    Simultaneously, improvements to caching mechanisms were
                                    implemented, streamlining content delivery and eliminating the
                                    root causes of sluggish performance.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    In response to the client's directive, we seamlessly integrated
                                    Facebook Pixel and Google Analytics, providing them with
                                    advanced analytics capabilities.
                                </p>
                                <p>
                                    {' '}
                                    In summary, our strategic and collaborative efforts resulted in
                                    the successful transformation of the client's Magento 2 website.
                                </p>
                                <p>
                                    {' '}
                                    It is now a faster, more efficient, and seamlessly integrated
                                    online platform, effectively overcoming the initial challenges
                                    of slow loading times.
                                </p>
                                <p>
                                    {' '}
                                    The delivered solution aligns with industry best practices,
                                    empowering the client with enhanced performance and advanced
                                    analytics capabilities for informed decision-making.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
