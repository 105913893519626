import React from 'react';

function Services({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-100 padding-bottom-60 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6 col-md-8">
                        <div className="appie-section-title">
                            <h1 className="appie-title">OUR SERVICES​</h1>
                            <p className="font-size16px-p it-services-text-align-left">
                                WE OFFER A WIDE VARIETY OF IT SERVICES​
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-mobile icon-size-30" />
                            </div>

                            <h1 className="title">Mobile Development</h1>
                            <p className="text-justify">
                                We don't merely have good coding skills. Our experience sets us
                                apart from other companies.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-globe icon-size-30" />
                            </div>
                            <h1 className="title">WEB Development</h1>
                            <p className="text-justify">
                                Create complex enterprise software, ensure reliable software
                                integration, modernise your legacy system.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-wand-magic icon-size-30" />
                            </div>
                            <h1 className="title">UI/UX Design</h1>
                            <p className="text-justify">
                                Build the product you need on time with an experienced team that
                                uses a clear and effective design.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-bug icon-size-30" />
                            </div>
                            <h1 className="title">QA & Testing</h1>
                            <p className="text-justify">
                                Turn to our experts to perform comprehensive, multi-stage testing
                                and auditing of your software.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-headset icon-size-30" />
                            </div>
                            <h1 className="title">IT Consultancy</h1>
                            <p className="text-justify">
                                Trust our top minds to eliminate workflow pain points, implement new
                                tech, and consolidate app.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2  item-6 mt-30 wow animated fadeInUp tb-g"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-users icon-size-30" />
                            </div>
                            <h1 className="title">Expert Team</h1>
                            <p className="text-justify">
                                Our client all had huge success with thought-ware & inspiring
                                developing approach over the last decade.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
