import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import BlogSideBar from './BlogSideBar';
import Blogs from './Blogs';
import HeroNews from './HeroNews';

function News() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Blog | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <HeroNews
                title="Blogs"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                ]}
            />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <Blogs />
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <BlogSideBar />
                        </div>
                    </div>
                </div>
            </section>
            <SharedFooter />
            <BackToTop />
        </>
    );
}

export default News;
