import React from 'react';
import { Link } from 'react-router-dom';

import almonaplast1 from '../../../assets/images/webnstack/almonaplast.png';
import assl1 from '../../../assets/images/webnstack/assl.png';
import rkbuilders1 from '../../../assets/images/webnstack/rk-builders.png';
import sandiabio1 from '../../../assets/images/webnstack/sandiabio.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-80 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Diverse Solutions,Shared Excellence:</h3>{' '}
                            <h3 className="appie-title mt-0">
                                {' '}
                                A Showcase of Our Related Projects
                            </h3>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/rkbuilders">RK Builders</Link>
                                </h4>
                                <p>One-stop solution for interior, constructions, and design.</p>
                            </div>
                            <div className="thumb text-center">
                                <img src={rkbuilders1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-4 px-4">
                                <h4 className="title">
                                    <Link to="/our-projects/AlmonaPlast">AlmonaPlast</Link>
                                </h4>
                                <p>Almona's Tailored Presence in Plastic Pipe Manufacturing.</p>
                            </div>
                            <div className="thumb text-right">
                                <img src={almonaplast1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-3 px-3">
                                <h4 className="title">
                                    {' '}
                                    <Link to="/our-projects/ASSL">ASSL</Link>
                                </h4>
                                <p>
                                    Safeguarding trust ecommerce platform showcasing security
                                    services.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={assl1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-3 px-3">
                                <h4 className="title">
                                    <Link to="/our-projects/sandiabio">Sandia Biotech</Link>
                                </h4>
                                <p>
                                    Revolutionizing Diagnostics-Sandia Biotech's FluorAbody® Digital
                                    Technology.
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={sandiabio1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
