import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HireSectionImage1 from '../../assets/images/webnstack/hire-1st-section-image.png';
import PopupVideo from '../PopupVideo';

function Hero() {
    const [showVideo, setVideoValue] = useState(false);
    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <section className="appie-hero-area appie-hero-3-area mob-hire-top-section hire-page-pad0 hire-top-section-height">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="appie-hero-content text-center mb-5">
                                <h1 className="appie-title mob-hire-size">
                                    Revolutionize Your Online Platform With Our Professional Web
                                    Developers
                                </h1>
                                <p className="font-size14px-p">
                                    Unlock the full potential of your online presence: elevate your
                                    website with our proficient web developers for growth-driven web
                                    applications and eCommerce platforms. Harness the power of
                                    cutting-edge solutions for unparalleled success.
                                </p>
                                <div className="hero-btns">
                                    <Link className="main-btn magento-hero" to="/contacts">
                                        Hire Us
                                    </Link>
                                </div>
                                <div
                                    className="thumb mt-100 wow animated fadeInUp mob-img-size"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <img src={HireSectionImage1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
