import React from 'react';
import aboutThumb from '../../../assets/images/webnstack/sandiabio-2nd-section.png';
import aboutThumb3 from '../../../assets/images/webnstack/sandiabio-3rd-section.png';
import aboutThumb4 from '../../../assets/images/webnstack/sandiabio-4th-section.png';

function About() {
    return (
        <section className="appie-about-3-area pt-180 pb-20" id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={aboutThumb} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The company faced a challenge in aligning its online store with the
                                growing demands of the evolving business.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Recognizing the need for a comprehensive overhaul, it became evident
                                that the existing support system was inadequate to implement the
                                numerous suggestions for optimizing the online shop and facilitating
                                future development.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The primary objective was to address the company's lackluster online
                                presence and position it to attract a global clientele.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                This project presented a creative and complex challenge,
                                necessitating the execution of the Magento Platform and the
                                implementation and adjustment of various components before delving
                                into web design and marketplace development.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                With the aim of creating a new website featuring an attractive user
                                interface on the Magento Platform, the company sought to incorporate
                                upgraded versions to ensure a modern and effective online presence.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                This multifaceted approach reflected a commitment to not only meet
                                current business needs but also to future-proof the platform for
                                sustained growth and adaptability in the dynamic digital landscape.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center mt-30 flex-column-reverse flex-lg-row">
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h2 className="title">WHAT WE DID</h2>
                                <p>
                                    The WebNStack Team collaborated closely with the client to
                                    embark on a transformative journey, creating a new website
                                    featuring an attractive UI designed for both visual appeal and
                                    seamless navigation.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    Leveraging the power of Magento 2.4, we implemented custom
                                    designs and incorporated specific requirements to tailor the
                                    online presence according to the client's vision.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    Our expertise in web design played a crucial role in crafting a
                                    user-friendly interface that not only enhanced the overall
                                    aesthetic appeal but also improved customer engagement,
                                    ultimately driving increased sales.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    Recognizing the importance of online visibility, we meticulously
                                    optimized the website for search engines to attract a global
                                    clientele.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    Throughout the entire development process, our team maintained
                                    transparent communication, providing regular updates and
                                    incorporating client feedback.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    This collaborative approach ensured that the final product met
                                    and exceeded the client's expectations, marking a successful
                                    transformation of their online store.
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-6"></div>
                                <div className="col-lg-12"></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-30">
                        <div className="col-lg-6">
                            <div
                                className="appie-about-thumb-3 wow animated fadeInLeft"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={aboutThumb4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-traffic-title pr-0">
                                <h2 className="title">RESULTS & PROCESS</h2>
                                <p>
                                    WebNStack demonstrated its skills in navigating the challenges
                                    posed by Sandiabio's need for an updated and optimized online
                                    presence.
                                </p>
                                <p>
                                    Leveraging our expertise in the Magento platform, we
                                    meticulously redesigned and developed a brand new website with
                                    an attractive UI, adhering to the upgraded Magento 2.4 version
                                    and incorporating custom designs tailored to the client's
                                    requirements.
                                </p>
                                &nbsp;
                                <p>
                                    The revamped website not only enhances the visual appeal but
                                    also offers a seamless and user-friendly interface, contributing
                                    to improved customer engagement and, subsequently, increased
                                    sales.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    Our collaborative approach involved continuous communication
                                    with the client, incorporating their feedback at every stage of
                                    development to ensure the final product aligns perfectly with
                                    their vision.
                                </p>
                                &nbsp;
                                <p>
                                    Sandiabio's unique and custom functionalities were not just
                                    retained but meticulously preserved during the revamping
                                    process.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    This attention to detail ensures that the brand new websites not
                                    only meet the client's expectations but also exceed them.
                                </p>
                                &nbsp;
                                <p>
                                    {' '}
                                    The result is a robust and optimized online presence that
                                    positions Sandiabio for sustained growth, increased market
                                    impact, and continued success in the digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
