import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import SharedServicesThree from '../Shared/SharedServicesThree';
import SharedTestimonial from '../Shared/SharedTestimonial';
import About from './About';
import Download from './Download';
import Features from './Features';
import FeaturesTwo from './FeaturesTwo';
import Hero from './Hero';
import Services from './Services';
import ServicesTwo from './ServicesTwo';
import Sponser from './Sponser';
import WorkPart from './WorkPart';

function HireWebDeveloper() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Hire Web Developer | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader action={drawerAction.toggle} />
            <Hero />
            <Services />
            <About />
            <Features />
            <ServicesTwo />
            <SharedServicesThree />
            <FeaturesTwo />
            <WorkPart />
            <Download />
            <SharedTestimonial />
            <Sponser />
            <SharedFooter />
            <BackToTop className="back-to-top-2" />
        </>
    );
}

export default HireWebDeveloper;
