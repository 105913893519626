import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import deelertlogo from '../../assets/images/webnstack/Itthikrit-K..png';
import sandiabiologo from '../../assets/images/webnstack/Jim-Bradford.png';
import almonaplastLogo from '../../assets/images/webnstack/almona.png';
import kakzonlogo from '../../assets/images/webnstack/kakzon.png';
import toyfiestaLogo from '../../assets/images/webnstack/toyfiesta-logo.png';

function SharedTestimonial() {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    return (
        <section
            className="appie-testimonial-2-area mb-90 appie-testimonial-2-area"
            id="testimonial"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-testimonial-2-box">
                            <div
                                className="appie-testimonial-slider-2"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    onClick={sliderPrev}
                                    className="prev slick-arrow home-page-display"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-left" />
                                </span>
                                <Slider ref={sliderRef} {...settings}>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={toyfiestaLogo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(5) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    I’m grateful to the “WebNStack” team since they
                                                    have the skills to simplify user data
                                                    administration and create a user-friendly
                                                    interface. Their successful efforts-which won
                                                    awards-helped me achieve my goal of having a
                                                    digital presence.
                                                </p>
                                                <div className="author-info">
                                                    <p className="title">Gavin C.</p>
                                                    <span>Australlia</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={almonaplastLogo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(5) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    WebNStack is a team of integrity, consistently
                                                    delivering honest and prompt development
                                                    services. Our commitment to client satisfaction
                                                    ensures a positive experience, and we look
                                                    forward to the opportunity of working together
                                                    again in the future.
                                                </p>
                                                <div className="author-info">
                                                    <p className="title">Hend Gomaa</p>

                                                    <span>Saudi Arab</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={sandiabiologo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(5) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    WebnStack's Magento expertise is truly
                                                    unmatched. They not only navigated complex
                                                    challenges effortlessly but also managed to
                                                    deliver the project well ahead of the deadline.
                                                    Exceptional work and commitment to excellence!
                                                </p>
                                                <div className="author-info">
                                                    <p className="title">Jim Bradford</p>
                                                    <span>New York</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={kakzonlogo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(5) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    Engaging WebnStack for our integration project
                                                    with Magento proved to be a wise decision. They
                                                    exhibited a deep understanding of Magento and
                                                    its APIs. The team's expertise, clear
                                                    communication, collaborative approach
                                                    significantly contributed to the success of our
                                                    project.
                                                </p>
                                                <div className="author-info">
                                                    <p className="title">Hamad Albulooshi</p>

                                                    <span>Bahrain</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={deelertlogo} alt="" />
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-star" />
                                                    </li>
                                                </ul>
                                                <span>(5) review</span>
                                            </div>
                                            <div className="content">
                                                <p>
                                                    WebnStack has been a positive experience from
                                                    start to finish. Their responsiveness,
                                                    professionalism, skills made the collaboration
                                                    seamless. They delivered the project as
                                                    promised, to timelines and quality. I would not
                                                    hesitate to engage them again for future
                                                    projects.
                                                </p>
                                                <div className="author-info">
                                                    <p className="title">Itthikrit K.</p>

                                                    <span>Thailand</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                                <span
                                    onClick={sliderNext}
                                    className="next slick-arrow home-page-display"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SharedTestimonial;
