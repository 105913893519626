import React from 'react';
import aboutThumb from '../../assets/images/webnstack/hire-web-development-3rd-section.png';

function About() {
    return (
        <section className="appie-about-area mb-100">
            <div className="container">
                <h1 className="appie-title text-center pb-30 hire-web-developer-h1-size">
                    CONNECT WITH WEBNSTACK FOR GROWTH-DRIVEN <br />
                    WEB APPLICATIONS AND ECOMMERCE PLATFORMS
                </h1>
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="appie-about-box wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-thumb">
                                        <img src={aboutThumb} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="appie-about-content">
                                        <span> HIRE US</span>
                                        <p>
                                            WebNStack provides full stack development services to
                                            help improve web applications and solutions. Our
                                            developer provides decent work and design for your
                                            website’s development. We provide a web development
                                            service that improves your website and provides
                                            exceptional solutions. Our developers create
                                            growth-driven web applications and eCommerce Platforms.
                                            Our services includes e-commerce platforms, PWAs,
                                            Magento Detailed Websites, large-scale WordPress
                                            Websites, Customized web applications, and Agile
                                            Framework Solutions that satisfy end-user and business
                                            needs. Our website represents your work; and help you
                                            build the growth and explains what your company is all
                                            about and what services you provide. If you want to
                                            extend your business worldwide, WebNStack’ developers
                                            are the Professional Solution. It is a means for
                                            connecting with both new and existing customers. Our web
                                            developers are expertise and proficient to develop all
                                            of the functions, websites, and other features who
                                            handles and manages your development using their
                                            knowledge and skills.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
