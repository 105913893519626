import React from 'react';
import { Link } from 'react-router-dom';
import downloadThumbThree from '../../../assets/images/assl.png';
import downloadThumbTwo from '../../../assets/images/cloudearly.png';
import downloadThumb from '../../../assets/images/rk-builders.png';
import downloadThumbFour from '../../../assets/images/sandiabio.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-0 pb-80 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title">Diverse Solutions,Shared Excellence:</h1>
                            <h1 className="appie-title mt-0">
                                {' '}
                                A Showcase of Our Related Projects
                            </h1>
                            <p>
                                Explore Our Portfolio of Varied Ventures, Highlighting Expertise and
                                Innovation Across Related Industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-4 px-4">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/rkbuilders" aria-label="RK Builders">
                                        RK Builders
                                    </Link>
                                </h1>
                                <p>One-stop solution for interior, constructions, and design.</p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-3 px-3">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/cloudearly" aria-label="Cloudearly">
                                        Cloudearly
                                    </Link>
                                </h1>
                                <p>
                                    Cloud innovation unleashed - cloudearly's tailored solutions in
                                    the iigital sky
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumbTwo} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="py-3 px-2">
                                <h1 className="title font-size-22">
                                    <Link to="/our-projects/ASSL" aria-label="ASSL">
                                        ASSL
                                    </Link>
                                </h1>
                                <p>
                                    Safeguarding trust ecommerce platform showcasing a variety of
                                    security services
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumbThree} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="py-3 px-2">
                                <h1 className="title  font-size-22">
                                    <Link to="/our-projects/sandiabio" aria-label="Sandia Biotech">
                                        Sandia Biotech
                                    </Link>{' '}
                                </h1>
                                <p>
                                    Revolutionizing cancer diagnostics - sandia biotech's
                                    fluorAbody® digital technology
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumbFour} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
