import React from 'react';
import toyfiestasection1st from '../../../assets/images/webnstack/toyfiesta-1st-section-image.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mob-toyfiesta-top-section toyfiesta-sec-height-45">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Whimsical Wonders: A Tailored Ecommerce Toy Store Experience for
                                Kids
                            </h1>
                            <p>
                                This Australian toy company places a strong emphasis on nurturing
                                the imagination and creativity of special needs children in the
                                digital age. Committed to providing an enriching experience, the
                                company offers a curated selection of toys at the best prices,
                                catering to the diverse needs and interests of children. Through
                                their offerings, the company not only aims to provide quality toys
                                but also promotes the invaluable development of imagination and
                                creativity in every child.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={toyfiestasection1st} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
