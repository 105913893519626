import React from 'react';
import { Link } from 'react-router-dom';

function Project({ className }) {
    return (
        <section className={`appie-project-3-area appie-project-area-background ${className} `}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="
                appie-project-3-box SOLUTION-TOGETHER
                d-block d-md-flex
                justify-content-between
                align-items-center
                wow
                animated
                fadeInUp
              "
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <h1 className="title READY">READY TO CREATE A SOLUTION TOGETHER?</h1>
                            <Link className="main-btn" to="/contacts">
                                Reach Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Project;
