import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import wordpresssectionn4 from '../../assets/images/webnstack/wordpress-4th-section-image.png';

function Traffic({ className }) {
    return (
        <section className={`appie-traffic-area  pb-0 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <h1 className="title font-size20-important">
                            HOW WE VALUE WORDPRESS SOLUTIONS
                        </h1>

                        <div className="appie-traffic-title">
                            <p>
                                We handle all of your WordPress development needs, whether you want
                                to start a blog or want to construct a feature-rich website to
                                market your business. As a top WordPress development company, we
                                have completed more than 100 WordPress projects while working with
                                some of the most well-known businesses in the market. Since you
                                deserve a website that is as unique as your company, we never
                                utilise pre-made themes when creating websites.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h1 className="title">27+</h1>
                                    <p>ACTIVE CLIENTS</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h1 className="title">76+</h1>
                                    <p>PROJECT DONE</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-3">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h1 className="title">25+</h1>
                                    <p>TEAM ADVISORS</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-4">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h1 className="title">7+</h1>
                                    <p>GLORIOUS YEAR</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="traffic-btn mt-50">
                                    <Link className="main-btn" to="/contacts/">
                                        Contacts <i className="fal fa-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={wordpresssectionn4}
                    alt=""
                />
            </div>
        </section>
    );
}

export default Traffic;
