import React from 'react';
import careers4thsection from '../../assets/images/webnstack/careers-4th-section.png';

function ServicesFour() {
    return (
        <section
            className="appie-services-2-area appie-services-8-area pb-50 crer-pg-padding-0 "
            id="service"
        >
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-12 col-md-12">
                        <div className="appie-section-title">
                            <h3 className="appie-title">
                                Fuel Your Potential with WebNStack Careers
                            </h3>
                            <p>
                                At WebNStack, we believe in supercharging your potential, helping
                                you discover your true career path, and igniting your spark. We
                                understand that staying ahead in the industry begins with
                                prioritizing our people.
                                <br />
                                Discover your potential, explore exciting opportunities, and be part
                                of a community that values your growth and success. Join WebNStack
                                and embark on a fulfilling career journey. Join us to experience:
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                    mob-dev
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="icon">
                                        <i className="fas fa-tasks"></i>
                                    </div>
                                    <h4 className="title">Career Opportunities on Your Terms</h4>
                                    <p className="text-justify">
                                        Tailor your career path with opportunities that align with
                                        your aspirations and goals. We provide a flexible and
                                        dynamic environment where you can grow on your terms.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                    mob-dev
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="400ms"
                                >
                                    <div className="icon">
                                        <i className="fas fa-user-tie"></i>
                                    </div>
                                    <h4 className="title">Enriching Job Experiences</h4>
                                    <p className="text-justify">
                                        Dive into roles that challenge and inspire you. We offer
                                        diverse job experiences that nurture your skills, fostering
                                        professional and personal development.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                    mob-dev
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <div className="icon">
                                        <i className="fas fa-user-friends"></i>
                                    </div>
                                    <h4 className="title">Employment You Can Trust In</h4>
                                    <p className="text-justify">
                                        Build your career on a foundation of trust. WebNStack is
                                        committed to creating a secure and supportive work
                                        environment where your contributions are recognized and
                                        valued.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div
                                    className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                    mob-dev
                  "
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <div className="icon">
                                        <i className="fab fa-creative-commons-sa"></i>
                                    </div>
                                    <h4 className="title">A Diverse, International Culture</h4>
                                    <p className="text-justify">
                                        Immerse yourself in a vibrant and inclusive culture that
                                        celebrates diversity. We value your unique perspectives,
                                        creating an international community that appreciates
                                        your whole self.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="service-thumb">
                            <img src={careers4thsection} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesFour;
