import React from 'react';
import rkbulders from '../../../assets/images/webnstack/1st-section-media-world.png';

function Hero() {
    return (
        <section className="appie-hero-area appie-hero-3-area mediaworldegypt-sec-one-height">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-hero-content text-center">
                            <h1 className="appie-title mob-myt-size">
                                Your Exclusive Gateway to Egypt's Premier TV Networks
                            </h1>
                            <p>
                                The premier destination for an unparalleled television experience in
                                Egypt. As the sole and exclusive representative of United Media
                                Services (UMS), they proudly oversee the advertising endeavors of a
                                distinguished roster of TV networks, including ON, DMC, CBC,
                                Al-Hayah, and Sada El-Balad. With an unwavering commitment to
                                excellence, Media World holds the exclusive rights to manage all
                                web-based media venues owned by UMS, ensuring a seamless and dynamic
                                online presence for our audience. Serving as your gateway to the
                                latest and greatest shows, they provide a comprehensive overview of
                                programming from the most renowned channels and TV networks in the
                                country. Their TV grids section offers a detailed schedule of airing
                                and rerun times for every channel, allowing you to plan your viewing
                                experience effortlessly.
                            </p>
                            <div
                                className="thumb mt-100 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <img src={rkbulders} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
