import React from 'react';
import SporthuisSection2 from '../../../assets/images/webnstack/sporthuis-2nd-setion-image.png';
import SporthuisSection3 from '../../../assets/images/webnstack/sporthuis-3rd-section-image.png';

function About() {
    return (
        <section
            className="appie-about-3-area pt-200 pb-10
        "
            id="features"
        >
            <div className="container pt-0">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={SporthuisSection2} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h1 className="title">The Challenge</h1>
                            <p>
                                The Customer had ecommerce marketplace website built on Magento.They
                                were looking for a Magento-competent development team to work on
                                thier warehouse sources issues.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                Creating standalone module and extending the functionality of amasty
                                multi warehouse to expecting workings with all upgrades.
                            </p>
                            &nbsp;
                            <p>
                                The Company wants fixing the email templates of Warehouse double
                                mails and adding thumbnails to mail, issues with smtp configuration,
                                upgrading location attributes to Magento and many more.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                The Customer saw development as an opportunity to introduce some
                                databases and implementations to website.
                            </p>
                            &nbsp;
                            <p>
                                {' '}
                                As they were looking to increase sales in the publicly available
                                e-store, they decided to refresh its digital user experience and
                                visual design of emails.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  mt-30 flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <div className="appie-traffic-title pr-0">
                            <h2 className="title">WHAT WE DID</h2>
                            <p>
                                After a successful development phase, Webnstack's proficient
                                developers transformed the website into a seamlessly functioning and
                                bug-free Magento multi-marketplace platform. A significant aspect of
                                this transformation involved the creation of a standalone module,
                                extending the functionality of the Amasty multi-warehouse system to
                                meet the client's specific requirements.
                            </p>
                            <p>
                                {' '}
                                Recognizing the importance of a streamlined email communication
                                system, our team addressed specific concerns related to Warehouse
                                double mails. Additionally, we enhanced email templates by adding
                                thumbnails to improve visual appeal and engagement. Our experts also
                                tackled issues associated with SMTP configuration, ensuring a
                                reliable and efficient email communication process.
                            </p>
                            <p>
                                {' '}
                                We focused on optimizing the user shopping experience by introducing
                                fast and efficient ways for customers to navigate through products.
                                This involved the implementation of effective and accurate filters,
                                providing users with seamless navigation possibilities.
                            </p>
                            <p>
                                {' '}
                                In summary, Webnstack's successful development efforts not only
                                transformed the website into a robust Magento multi-marketplace
                                platform but also addressed specific issues related to email
                                communication, design, and user experience. By customizing and
                                extending existing modules, our team ensured that the client's
                                requirements were met comprehensively, providing a reliable and
                                user-friendly platform for customers to shop conveniently and
                                efficiently.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-lg-12"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="appie-about-thumb-3 text-right wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img src={SporthuisSection3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
