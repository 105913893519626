import React from 'react';
import customerb2bImage from '../../assets/images/shopify/customer-b2b-b2c.png';
import counterIconThree from '../../assets/images/shopify/dropshipping-logistics.png';
import counterIconTwo from '../../assets/images/shopify/erp-crm-integration.png';
import fashontrendsImage from '../../assets/images/shopify/fashon-and-trends.png';
import fooddeliveryImage from '../../assets/images/shopify/food-delivery.png';
import loyaltyprogramsImage from '../../assets/images/shopify/loyalty-programs.png';
import counterIconFour from '../../assets/images/shopify/medical-healthcare.png';
import posretailImage from '../../assets/images/shopify/pos-retail.png';
import recurringsubscriptionImage from '../../assets/images/shopify/recurring-subscription.png';
import counterIconOne from '../../assets/images/shopify/security-encrypted.png';
import warehouselogisticsImage from '../../assets/images/shopify/warehouse-logistics.png';

function CounterArea({ style }) {
    return (
        <section className="appie-counter-area pb-100 snnak-a-peek" id="counter" style={style}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title">
                            <h1 className="appie-title">
                                OUR EXPERIENCE-EXPERT SHOPIFY DEVELOPMENT
                            </h1>
                            <p className="font-size16px-p">
                                To provide insight and assistance for your website development,
                                you’ll need an experienced eCommerce staff. We’ve launched several
                                successful applications on the Shopify App Store in a variety of
                                sectors, including.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row margin-bottom-shopify-development">
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconOne} alt="" />
                                </div>
                                <p>Security Encryption</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconTwo} alt="" />
                                </div>
                                <p>ERP/CRM Integration</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content pt-40">
                                <div className="icon">
                                    <img src={counterIconThree} alt="" />
                                </div>
                                <p>Dropshipping Logistics</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={counterIconFour} alt="" />
                                </div>
                                <p>Medical & Healthcare</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row margin-bottom-shopify-development">
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={fooddeliveryImage} alt="" />
                                </div>
                                <p> Food & Delivery</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={fashontrendsImage} alt="" />
                                </div>
                                <p>Fashion & Trend</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={warehouselogisticsImage} alt="" />
                                </div>
                                <p>Warehouse Logistics</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={customerb2bImage} alt="" />
                                </div>
                                <p>Customer B2B/B2C</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row margin-bottom-shopify-development">
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={recurringsubscriptionImage} alt="" />
                                </div>
                                <p> Recurring Subscription</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={loyaltyprogramsImage} alt="" />
                                </div>
                                <p>Loyalty Programs</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pt-0">
                        <div
                            className="appie-single-counter fashion-trend mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="counter-content">
                                <div className="icon">
                                    <img src={posretailImage} alt="" />
                                </div>
                                <p>POS & Retail</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterArea;
