import React, { useState } from 'react';
import shapeSix from '../../assets/images/shape/shape-6.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';

function Features({ className }) {
    const [tab, setTab] = useState('setting');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (
        <section className={`appie-features-area pt-100 sec-gap-30 ${className}`} id="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <p>
                                This Service Agreement (“Agreement”) between{' '}
                                <b> WebNStack IT Solution Pvt Ltd.</b> and any of its{' '}
                                <b>user companies</b> (“Consultant/Consultant”) for the purpose of
                                providing professional services (“Services”) or deliverables
                                (“Deliverable”) for software development and consulting shall apply
                                to and govern any Statement of Work(s), project, letter of intent,
                                or other document (“SOW”) executed between the parties. Any word,
                                term, or phrase used in these Terms of Use must be understood in
                                accordance with its usual meaning, unless the context clearly
                                dictates otherwise. This should not be construed as precluding:
                                <b> “Cookies”</b> refers to a collection of information provided
                                from a web server to a browser and saved on the computer’s hard
                                drive where the browser is being visited,<b> “Company”</b> refers to
                                WebNStack IT Solution Pvt Ltd, and <b>“Services” </b>refers to any
                                services offered by the Company. Anyone using the Services offered
                                by the Company and/or anyone using the Company’s website for any
                                reason is referred to as a <b> “User”</b> or <b>“Users“</b>,{' '}
                                <b>“Website”</b> refers to the website run by the Company, which can
                                be found at <b>https://webnstack.com/.</b>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center-padding-bottom-60">
                    <div className="col-lg-3">
                        <h4 className="appie-title mb-10">TABLE OF CONTENTS </h4>
                        <div className="appie-features-tabs-btn">
                            <div
                                className="nav flex-column nav-pills flx-dir-col"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    onClick={(e) => handleClick(e, 'setting')}
                                    className={`nav-link ${tab === 'setting' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    Payments
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'report')}
                                    className={`nav-link ${tab === 'report' ? 'active' : ''}`}
                                    id="v-pills-profile-tab"
                                    data-toggle="pill"
                                    href="#v-pills-profile"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    Acceptance Period
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'notice')}
                                    className={`nav-link ${tab === 'notice' ? 'active' : ''}`}
                                    id="v-pills-messages-tab"
                                    data-toggle="pill"
                                    href="#v-pills-messages"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    Intellectual copyrights
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'Warranties')}
                                    className={`nav-link ${tab === 'Warranties' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    Warranties
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'Limitation')}
                                    className={`nav-link ${tab === 'Limitation' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-limitation"
                                    aria-selected="false"
                                >
                                    Limitation of Liability
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'Content')}
                                    className={`nav-link ${tab === 'Content' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-content"
                                    aria-selected="false"
                                >
                                    Content
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'Amendments')}
                                    className={`nav-link ${tab === 'Amendments' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-amendments"
                                    aria-selected="false"
                                >
                                    Amendments and Privacy
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'Termination')}
                                    className={`nav-link ${tab === 'Termination' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-termination"
                                    aria-selected="false"
                                >
                                    Termination
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'Violation')}
                                    className={`nav-link ${tab === 'Violation' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-violation"
                                    aria-selected="false"
                                >
                                    Violation
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 mt-30">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={`${
                                    tab === 'setting' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Payments</h4>
                                            <p className="font-size16px-p">
                                                After receiving an invoice, the customer must pay it
                                                within 15 days. The customer is responsible for
                                                paying interest on overdue payments at a rate of
                                                1.5% per month or the maximum allowable by relevant
                                                legislation, whichever is less, if there is a delay
                                                in payment of more than 5 days from the due date. If
                                                the Fees or expenditures are not paid when they are
                                                due, Consultant will be released from its duties
                                                under this Agreement but will still be able to use
                                                the Services for which the money is still owed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'report' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Acceptance Period</h4>
                                            <p className="font-size16px-p">
                                                The customer has seven (7) days from the time the
                                                services or deliverables are received (the
                                                “Acceptance Period”) to conduct acceptance tests in
                                                accordance with the acceptance criteria specified in
                                                the SOW (“Acceptance Criteria”). Deliverables or
                                                Services are regarded as accepted by the Customer if
                                                no notice of non-conformance to Acceptance Criteria
                                                is reported throughout the Acceptance Period.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'notice' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-messages"
                                role="tabpanel"
                                aria-labelledby="v-pills-messages-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Intellectual copyrights</h4>
                                            <p className="font-size16px-p">
                                                All right, title, and interest in and to the
                                                Deliverables shall belong to the Customer. Only once
                                                the Consultant has received full payment from the
                                                Customer, then the Customer be awarded the rights,
                                                title, and interest in and to the Deliverables. A
                                                permanent, non-exclusive, globally, transferable,
                                                and royalty-free licence to use such Consultant
                                                Pre-Existing IP solely in combination with the
                                                Deliverables is granted to Customer by Consultant to
                                                the extent that the Deliverables incorporate
                                                Consultant Pre-Existing Intellectual Rights (the
                                                “Consultant Pre-Existing IP”) that is necessary for
                                                the proper functioning of the Deliverables.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Warranties' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-settings"
                                role="tabpanel"
                                aria-labelledby="v-pills-settings-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Warranties</h4>
                                            <p className="font-size16px-p">
                                                The Company makes no guarantees or warranties about
                                                the accuracy, decency, completeness, reliability,
                                                functionality, or availability of any information or
                                                material provided to and/or created by Users.
                                                Additionally, the Company makes no guarantees about
                                                the accurate communication, presentation, or display
                                                of the data made accessible to and/or provided by
                                                Users. The Company disclaims, to the extent
                                                permitted by law, any and all warranties relating to
                                                the Services, including any implied warranties of
                                                merchantability or fitness for a specific purpose
                                                and warranties against infringement of proprietary
                                                rights, including intellectual property rights.The
                                                users hereby disclaim all warranties of any kind,
                                                implied, statutory, or in any communication between
                                                them, including without limitation, the implied
                                                warranties of merchantability, non-infringement,
                                                title, and fitness for a particular purpose. Nor
                                                does the Company provide any warranties as to the
                                                integrity and correctness of the information present
                                                on its Website and as to the services advertised on
                                                the Website or advertised and/or made available
                                                through sites linked thereto.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Limitation' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Limitation of Liability</h4>
                                            <p className="font-size16px-p">
                                                Under no circumstances shall the Company be held
                                                liable in damages of any kind whatsoever to any
                                                User. Under this Agreement, the aggregate liability
                                                of the parties (whether in contract, law (including
                                                negligence), or otherwise) shall not be liable to
                                                the company hereunder. The parties disclaim any loss
                                                of revenue or commercial profits, regardless of how
                                                they were caused, or indirect, special,
                                                consequential, or incidental damages, even if they
                                                were informed of the possibility of such damages.
                                                Even if a limited remedy described in this article
                                                fails to fulfil its primary objective, the
                                                aforementioned liability restrictions will still be
                                                in effect. The content of the Website may not be
                                                copied, broadcast, downloaded, stored (on any
                                                medium), transmitted, shown, or played in whole or
                                                in part, altered in any way, or used for any other
                                                purpose without the Company’s prior written consent.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Content' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Content</h4>
                                            <p className="font-size16px-p">
                                                Any content that is shown on the users website or
                                                that is linked to it is not the responsibility of
                                                the company. The Company does not guarantee that any
                                                review of the material will be done, and it
                                                disclaims all liability and responsibility for the
                                                accuracy, trademark or copyright compliance, or
                                                legality of any content.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Amendments' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Amendments and Privacy</h4>
                                            <b>Terms of Use and Service Amendments</b>{' '}
                                            <p className="font-size16px-p">
                                                The Company retains the right to periodically
                                                change, modify, or replace the Services for any
                                                reason and without prior notice, including the right
                                                to stop providing the Company’s services altogether.
                                                The Company additionally retains the right to amend,
                                                revise, or adjust the current Terms of Use without
                                                prior notice.
                                            </p>
                                            <b> Privacy</b>
                                            <p className="font-size16px-p">
                                                The Company’s privacy policy as set out in this
                                                document shall not apply to any data or information
                                                that a User may reveal to another User. For more
                                                information refer to WebNStack’s privacy page.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Termination' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Termination</h4>
                                            <p className="font-size16px-p">
                                                The Agreement may be terminated by party by giving
                                                the other party sixty (60) days’ notice. If the
                                                other party violates the provisions of this
                                                Agreement and the violation is not remedied within
                                                30 days of receiving notification, either party may
                                                terminate this Agreement immediately. In the event
                                                of termination, the Consultant shall be compensated
                                                pro data for the Services rendered.
                                            </p>
                                            <p className="font-size16px-p">
                                                {' '}
                                                The Company shall have the right at any time and
                                                from time to time to modify or discontinue,
                                                temporarily or permanently, the services provided by
                                                the Company, in whole or in part, with or without
                                                notice.
                                            </p>
                                            <p className="font-size16px-p">
                                                The Company shall not be liable to any User for any
                                                modifications, suspension or discontinuance of the
                                                Services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    tab === 'Violation' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h4>Violation</h4>

                                            <b>Violation of Terms and Conditions </b>
                                            <p className="font-size16px-p">
                                                Any violation of these Terms of Use or any other
                                                Company rights entitles the Company to seek any and
                                                all legal and equitable remedies, including, without
                                                limitation, the termination of the Services, against
                                                the responsible User. Any rights or provisions in
                                                these Terms of Use that are not enforced by the
                                                Company do not constitute a waiver of such rights or
                                                provisions or of any other rights or provisions in
                                                these Terms of Use. The other parts of these Terms
                                                of Use shall be enforceable and, to the extent
                                                permitted by applicable law, in effect in the event
                                                that a court determines, orders, judgments, or in
                                                any other manner finds that one or more of the
                                                rights or provisions included herein are unlawful.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features-shape-1">
                <img src={shapeSix} alt="" />
            </div>
            <div className="features-shape-2">
                <img src={shapeSeven} alt="" />
            </div>
            <div className="features-shape-3">
                <img src={shapeEight} alt="" />
            </div>
        </section>
    );
}

export default Features;
