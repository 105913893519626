import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooterThree from '../Shared/SharedFooterThree';
import SharedHeader from '../Shared/SharedHeader';
import SharedInformationSection from '../Shared/SharedInformationSection';
import SharedShowCase from '../Shared/SharedShowCase';
import SharedTestimonialTwo from '../Shared/SharedTestimonialTwo';
import About from './About';
import CounterAreaAngular from './CounterAreaAngular';
import Hero from './Hero';
import Services from './Services';
import ServicesAngular from './ServicesAngular';
import ServicesangularOne from './ServicesangularOne';
import WorkPart from './WorkPart';

function Angular() {
    const [drawer, setDrawer] = useToggle(false);
    useEffect(() => {
        document.title = 'Angular | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={setDrawer.toggle} />
            <SharedHeader drawer={drawer} action={setDrawer.toggle} />
            <Hero />
            <About />
            <Services />
            <WorkPart />
            <SharedShowCase />
            <ServicesAngular />
            <CounterAreaAngular />
            <ServicesangularOne />
            <SharedTestimonialTwo />
            <SharedInformationSection />
            <SharedFooterThree />
            <BackToTop className="back-to-top-8" />
        </>
    );
}

export default Angular;
