import React, { useRef } from 'react';
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import shape12 from '../../assets/images/shape/shape-12.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import dealonthreeImage from '../../assets/images/webnstack/deliverzero/deliverzero-1.svg';
import dealonfourImage from '../../assets/images/webnstack/deliverzero/deliverzero-2.svg';
import Dealon4 from '../../assets/images/webnstack/our-projects-svg/Dealon4.svg';
import eltrivia1 from '../../assets/images/webnstack/our-projects-svg/eltrivia1.svg';
import eltrivia2 from '../../assets/images/webnstack/our-projects-svg/eltrivia2.svg';
import deliverzerofourImage from '../../assets/images/webnstack/our-projects-svg/fooddrink1.svg';
import fooddrink2l from '../../assets/images/webnstack/our-projects-svg/fooddrink2l.svg';
import deliverzerothreeImage from '../../assets/images/webnstack/our-projects-svg/fooddrink3l.svg';
import glowbympi1 from '../../assets/images/webnstack/our-projects-svg/glowbympi1.svg';
import mediaworldegypt1 from '../../assets/images/webnstack/our-projects-svg/mediaworldegypt1.svg';
import mediaworldegypt2 from '../../assets/images/webnstack/our-projects-svg/mediaworldegypt2.svg';

function ShowCase() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <section className="appie-showcase-area">
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="appie-section-title text-center">
                                    <h1 className="appie-title hire-show-case-padding">
                                        Innovative Mobile Solutions: Showcasing Our
                                    </h1>
                                    <h1 className="appie-title mt-0">
                                        Diverse Portfolio of Excellence{' '}
                                    </h1>
                                    <p className="font-size16px-p">
                                        Explore our pioneering marketplaces, from seamless
                                        e-commerce experiences to cutting-edge innovations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row appie-showcase-slider">
                            <div className="col-lg-12">
                                <Slider ref={sliderRef} {...settings}>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={glowbympi1} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={mediaworldegypt1} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={eltrivia2} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={mediaworldegypt2} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={Dealon4} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={dealonthreeImage} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={deliverzerothreeImage} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={eltrivia1} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={dealonfourImage} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={deliverzerofourImage} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="appie-showcase-item mt-30">
                                            <div className="appie-image-popup">
                                                <img src={fooddrink2l} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="showcase-shape-1">
                        <img src={shape14} alt="" />
                    </div>
                    <div className="showcase-shape-2">
                        <img src={shape13} alt="" />
                    </div>
                    <div className="showcase-shape-3">
                        <img src={shape12} alt="" />
                    </div>
                    <div className="showcase-shape-4">
                        <img src={shape15} alt="" />
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </section>
    );
}

export default ShowCase;
