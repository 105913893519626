import React from 'react';
import downloadThumb from '../../assets/images/bidget-constant-image.png';
import downloadThumbTwo from '../../assets/images/extend-team-image.png';
import downloadThumbThree from '../../assets/images/time-and-matrial-image.png';

function Download({ className }) {
    return (
        <section className={`appie-download-3-area pt-100 pb-70 ${className || ''}`} id="download">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h1 className="appie-title font-size20-important">
                                Models of Business Engagement
                            </h1>
                            <p className="font-size-16px">
                                We assist startups and brands in working gracefully in mobile
                                product innovation through problem-solving skills, successful
                                commercial engagement models, and a commitment to delivering
                                exceptional user experiences.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div
                            className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="content">
                                <h1 className="title">Extended Teams</h1>
                                <p>
                                    We provide the "Extended Team" outsourcing model to give our
                                    clients the option to operate the development team remotely.
                                    With this model, the customer may hire the team remotely,
                                    ensuring flexibility and efficiency.
                                </p>
                            </div>
                            <div className="thumb text-center">
                                <img src={downloadThumb} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="content">
                                <h1 className="title">Time and Materials</h1>
                                <p>
                                    We provide a T&M model that is most suited for Agile projects,
                                    and we can charge an hourly, weekly, or monthly cost for the
                                    amount of resources, materials, or other expenses used in the
                                    development process.
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={downloadThumbTwo} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="content">
                                <h1 className="title">Budget Constant</h1>
                                <p>
                                    This approach works well for small-scale projects with specific
                                    requirements. This is done in accordance with a milestone-based
                                    plan, and all of your processes and tasks are optimized to make
                                    them as quick as possible.
                                </p>
                            </div>
                            <div className="thumb text-right">
                                <img src={downloadThumbThree} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;
