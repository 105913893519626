import React from 'react';
import shopify from '../../assets/images/webnstack/shopify-outline-white.svg';

function ServicesTwo({ className }) {
    return (
        <section
            className={`appie-services-2-area pb-100 hire-ecommerce-pb20 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fab fa-magento icon-size-30"></i>
                            </div>

                            <h1 className="title"> Magento 2 Developers</h1>
                            <p className="text-justify">
                                As a trustworthy Magento Web Development Company, WebNStack provides
                                hiring Magento developers outsourcing services all over the world to
                                meet all of your custom Magento development needs. Furthermore, we
                                provide the best Magento eCommerce development services. With years
                                of experience and skill in the field of PHP-based e-commerce
                                open-source platform, our hiring Magento 2 developer service can
                                help your online business reach unparalleled success.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="shopify-background icon-size-30">
                                <img src={shopify} alt="" />
                            </div>
                            <h1 className="title"> Shopify Developers</h1>
                            <p className="text-justify">
                                Are you looking for skilled, accredited Shopify developers to help
                                you with your project? Our skilled Shopify developers create Shopify
                                stores for all sizes of businesses, in all sectors. We offer web
                                development and design services that improve your store’s customer
                                experience while also making it SEO-friendly. They have extensive
                                expertise developing shopping carts for clients all around the world
                                and also have worked on eCommerce websites in a variety of sectors.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="appie-single-service-2 appie-single-service-3  item-3 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fab fa-wordpress-simple icon-size-30"></i>
                            </div>
                            <h1 className="title"> Woocommerce Developers</h1>
                            <p className="text-justify">
                                WebNStack is a top WooCommerce skilled and expert with a devoted
                                team of WooCommerce designers and developers all around the world.
                                Our resources are capable of handling difficulties that emerge in
                                their WooCommerce shop for businesses of various sizes. WooCommerce,
                                the most adaptable platform for eCommerce shop creation. WebNStack’s
                                certified WooCommerce experts are dedicated to ensuring your online
                                store's success and growth.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesTwo;
