import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeader from '../Shared/SharedHeader';
import Download from './Download';
import Features from './Features';
import FeaturesTwo from './FeaturesTwo';
import Hero from './Hero';
import Project from './Project';
import Services from './Services';
import Sponser from './Sponser';
import VideoPlayer from './VideoPlayer';

function MagentoDevelopment() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'Magento Development | IT Consulting & eCommerce Specialist Agency';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeader action={drawerAction.toggle} />
            <Hero />
            <Services className="pt-90" />
            <BackToTop className="back-to-top-6" />
            <Features className="appie-features-6-area" />
            <VideoPlayer />
            <FeaturesTwo />
            <Sponser />
            <Download className="pt-0 pb-100" />
            <Project />
            <SharedFooter />
        </>
    );
}

export default MagentoDevelopment;
