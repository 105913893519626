import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import SharedFooter from '../Shared/SharedFooter';
import SharedHeaderTwo from '../Shared/SharedHeaderTwo';
import Features from './Features';
import HeroNews from './HeroNews';

function TermsAndConditions() {
    const [drawer, drawerAction] = useToggle(false);
    useEffect(() => {
        document.title = 'terms-and-conditions | IT Consulting & eCommerce Specialist Agency';
    }, []);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <SharedHeaderTwo action={drawerAction.toggle} />
            <HeroNews
                title="
                Privacy Statement"
            />
            <Features />
            <SharedFooter />
            <BackToTop />
        </>
    );
}

export default TermsAndConditions;
